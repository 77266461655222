import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import actions from './cartonizeordersActions';

const initialState = {
  isLoading: false,
  error: '',

  isUpdating: false,
  updateError: '',
};

export const createCartonizeOrder = createAsyncThunk(
  'cartonizeorders/createCartonizeOrder',
  async (cartonize, thunkAPI) => {
    return actions
      .createCartonizeOrder(cartonize)
      .then((r) => r.data)
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

export const getCartonizeOrders = createAsyncThunk(
  'cartonizeorders/getCartonizeOrders',
  (params = {}) => {
    const { id, ...rest } = params;
    if (id) {
      return actions.getUserCartonizeOrders(id, rest);
    }
    return actions.getCartonizeOrders(rest);
  },
);

export const updateCartonizeOrder = createAsyncThunk(
  'cartonizeorders/updateCartonizeOrder',
  async (cartonize, thunkAPI) => {
    return actions
      .updateCartonizeOrder(cartonize)
      .then((r) => r.data)
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

export const deleteCartonizeOrder = createAsyncThunk(
  'cartonizeorders/deleteCartonizeOrder',
  (id, thunkAPI) => {
    return actions
      .deleteCartonizeOrder(id)
      .then((r) => r.data)
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

const cartonizeordersAdapter = createEntityAdapter({
  selectId: (entity) => entity._id,
});

const updatePending = (state, action) => {
  state.isUpdating = true;
  state.updateError = '';
};

const updateError = (state, action) => {
  state.isUpdating = false;
  state.updateError = action.payload.error;
};

const slice = createSlice({
  name: 'cartonizeorders',
  initialState: cartonizeordersAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: {
    [createCartonizeOrder.pending]: updatePending,
    [createCartonizeOrder.rejected]: updateError,
    [createCartonizeOrder.fulfilled]: (state, action) => {
      state.isUpdating = false;
      const { _id, ...changes } = action.payload;
      cartonizeordersAdapter.updateOne(state, { id: _id, changes });
    },

    [getCartonizeOrders.pending]: (state, action) => {
      state.isLoading = true;
      state.error = '';
    },
    [getCartonizeOrders.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    [getCartonizeOrders.fulfilled]: (state, action) => {
      state.isLoading = false;
      const { data } = action.payload;
      state.cartonizeorders = action.payload;
      cartonizeordersAdapter.removeAll(state);
      cartonizeordersAdapter.upsertMany(state, data);
    },
    [updateCartonizeOrder.pending]: updatePending,
    [updateCartonizeOrder.rejected]: updateError,
    [updateCartonizeOrder.fulfilled]: (state, action) => {
      state.isUpdating = false;
      const { _id, ...changes } = action.payload;
      cartonizeordersAdapter.updateOne(state, { id: _id, changes });
    },
    [deleteCartonizeOrder.pending]: updatePending,
    [deleteCartonizeOrder.rejected]: updateError,
    [deleteCartonizeOrder.fulfilled]: (state, action) => {
      state.isUpdating = false;
      cartonizeordersAdapter.removeOne(state, action.payload.id);
    },
  },
});

export const createCartonizeOrdersSelectors = () => {
  return cartonizeordersAdapter.getSelectors((state) => state.cartonizeorders);
};

export const {
  selectById: selectCartonizeById,
  selectIds: selectCartonizeIds,
  selectEntities: selectCartonizeEntities,
  selectAll: selectAllCartonizes,
  selectTotal: selectTotalCartonizes,
} = cartonizeordersAdapter.getSelectors((state) => state.cartonizeorders);

export default slice.reducer;
