import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import actions from './palletizeordersActions';

const initialState = {
  isLoading: false,
  error: '',

  isUpdating: false,
  updateError: '',
};

export const createPalletizeOrder = createAsyncThunk(
  'palletizeorders/createPalletizeOrder',
  async (palletize, thunkAPI) => {
    return actions
      .createPalletizeOrder(palletize)
      .then((r) => r.data)
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

export const getPalletizeOrders = createAsyncThunk(
  'palletizeorders/getPalletizeOrders',
  (params = {}) => {
    const { id, ...rest } = params;
    if (id) {
      return actions.getUserPalletizeOrders(id, rest);
    }
    return actions.getPalletizeOrders(rest);
  },
);

export const updatePalletizeOrder = createAsyncThunk(
  'palletizeorders/updatePalletizeOrder',
  async (palletize, thunkAPI) => {
    return actions
      .updatePalletizeOrder(palletize)
      .then((r) => r.data)
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

export const deletePalletizeOrder = createAsyncThunk(
  'palletizeorders/deletePalletizeOrder',
  (id, thunkAPI) => {
    return actions
      .deletePalletizeOrder(id)
      .then((r) => r.data)
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

const palletizeordersAdapter = createEntityAdapter({
  selectId: (entity) => entity._id,
});

const updatePending = (state, action) => {
  state.isUpdating = true;
  state.updateError = '';
};

const updateError = (state, action) => {
  state.isUpdating = false;
  state.updateError = action.payload.error;
};

const slice = createSlice({
  name: 'palletizeorders',
  initialState: palletizeordersAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: {
    [createPalletizeOrder.pending]: updatePending,
    [createPalletizeOrder.rejected]: updateError,
    [createPalletizeOrder.fulfilled]: (state, action) => {
      state.isUpdating = false;
      const { _id, ...changes } = action.payload;
      palletizeordersAdapter.updateOne(state, { id: _id, changes });
    },
    [getPalletizeOrders.pending]: (state, action) => {
      state.isLoading = true;
      state.error = '';
    },
    [getPalletizeOrders.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    [getPalletizeOrders.fulfilled]: (state, action) => {
      state.isLoading = false;
      const { data } = action.payload;
      state.palletizeorders = action.payload;
      palletizeordersAdapter.removeAll(state);
      palletizeordersAdapter.upsertMany(state, data);
    },
    [updatePalletizeOrder.pending]: updatePending,
    [updatePalletizeOrder.rejected]: updateError,
    [updatePalletizeOrder.fulfilled]: (state, action) => {
      state.isUpdating = false;
      const { _id, ...changes } = action.payload;
      palletizeordersAdapter.updateOne(state, { id: _id, changes });
    },
    [deletePalletizeOrder.pending]: updatePending,
    [deletePalletizeOrder.rejected]: updateError,
    [deletePalletizeOrder.fulfilled]: (state, action) => {
      state.isUpdating = false;
      palletizeordersAdapter.removeOne(state, action.payload.id);
    },
  },
});

export const createPalletizeOrdersSelectors = () => {
  return palletizeordersAdapter.getSelectors((state) => state.palletizeorders);
};

export const {
  selectById: selectPalletizeById,
  selectIds: selectPalletizeIds,
  selectEntities: selectPalletizeEntities,
  selectAll: selectAllPalletizes,
  selectTotal: selectTotalPalletizes,
} = palletizeordersAdapter.getSelectors((state) => state.palletizeorders);

export default slice.reducer;
