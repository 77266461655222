import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Chip from '@material-ui/core/Chip';

const colorChip = (r = 3, g = 155, b = 299) => {
  return withStyles((theme) => ({
    root: {
      color: `rgba(${r}, ${g}, ${b}, 1)`,
    },
    deletable: {
      backgroundColor: 'transparent',
      '&:focus': {
        backgroundColor: `rgba(${r}, ${g}, ${b}, 0.04) !important`,
      },
    },
    outlined: {
      minWidth: theme.spacing(10),
      borderColor: `rgba(${r}, ${g}, ${b}, 1)`,
    },
    deleteIcon: {
      color: `rgba(${r}, ${g}, ${b}, 0.7)`,
      '&:hover': {
        color: `rgba(${r}, ${g}, ${b}, 1)`,
      },
    },
  }))((props) => <Chip variant='outlined' {...props} />);
};

// rgb(76, 175, 80)
export const CompletedChip = colorChip(76, 175, 80);

// rgb(255, 193, 7)
export const PendingChip = colorChip(255, 193, 7);

// rgb(3, 155, 299)
export const ProcessingChip = colorChip();

// rgb(255, 87, 34)
export const FailedChip = colorChip(255, 87, 34);
