import React, { useCallback, useEffect, useState } from 'react';
import { styled } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

import { MdArrowForward } from 'react-icons/md';

import { parseDecimal } from '../../../utils/parsers';
import parseFloatNumber from '../../../utils/parseFloatNumber';

const StyledTitle = styled(DialogTitle)({
  '& > h2': {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const ChangeRateDialog = ({
  shipment = null,
  updateShipment = () => {},
  onClose = () => {},
}) => {
  const [open, setOpen] = useState(false);
  const [order, setOrder] = useState(null);
  const [rate, setRate] = useState('');

  const isAbleUpdate =
    parseFloatNumber(order?.total || 0) !== parseFloatNumber(rate);

  useEffect(() => {
    if (!!shipment) {
      setOrder(shipment);
      setRate(shipment?.total || '0');
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [shipment]);

  const onRateChange = (event) => {
    setRate(parseDecimal(event?.target?.value || ''));
  };

  const onClickUpdate = useCallback(async () => {
    onClose();
    await updateShipment({ ...order, total: rate });
  }, [onClose, updateShipment, rate, order]);

  return (
    <Dialog open={open}>
      <StyledTitle>
        <span>Update Rate</span>
        {order?.order_no && <span>#{order?.order_no}</span>}
      </StyledTitle>
      <DialogContent>
        <DialogContentText>Please enter the new rate:</DialogContentText>
        <TextField
          value={rate}
          onChange={onRateChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                ${order?.total || 0}
                <Box component='span' marginLeft={2} marginRight={2}>
                  <MdArrowForward size={16} />
                </Box>
                $
              </InputAdornment>
            ),
          }}
          variant='outlined'
          fullWidth
          size='small'
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Close</Button>
        <Button
          disabled={!isAbleUpdate}
          onClick={onClickUpdate}
          color='primary'
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeRateDialog;
