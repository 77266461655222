import React, { useEffect, useState } from 'react';
import DashWrapper from '../wrappers/DashWrapper';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from '../../redux/users/usersSlice';
import ReportTable from '../tables/ReportTable';
import UserAutoCompleteTextField from '../common/UserAutoCompleteTextField';
import Box from '@material-ui/core/Box';

const selector = (state) => {
  return {
    isAdmin: state.auth.isAdmin,
    isLoading: state.auth.isLoading,
  };
};

const Reports = () => {
  const dispatch = useDispatch();
  const { isAdmin, isLoading } = useSelector(selector);
  const [userId, setUserId] = useState(null);
  const types = ['daily', 'weekly', 'monthly'];

  useEffect(() => {
    if (!isLoading && isAdmin === true) {
      dispatch(getUsers());
    }
  }, [isLoading, isAdmin, dispatch]);

  const handleChange = (id) => {
    setUserId(id || null);
  };

  return (
    <DashWrapper>
      <Grid container spacing={3}>
        {!isLoading && isAdmin === true && (
          <Grid item xs={12} component={Box} maxWidth={320}>
            <UserAutoCompleteTextField
              label='Customer'
              value={userId}
              onChange={handleChange}
              margin='dense'
            />
          </Grid>
        )}
        {types.map((type, index) => (
          <Grid item xs={12} key={index}>
            <ReportTable isAdmin={isAdmin} userId={userId} type={type} />
          </Grid>
        ))}
      </Grid>
    </DashWrapper>
  );
};

export default Reports;
