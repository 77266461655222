import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '../Container';
import Title from './Title';
import Pallet from './Pallet';
import usePallets from './usePallets';

const Pallets = ({
  fields,
  title = '',
  productName = '',
  products = [],
  disabled,
  readOnly,
}) => {
  const {
    selectedIds,
    handleAddPallet,
    handleReset,
    handleRemove,
  } = usePallets({ fields, productName });

  return (
    <Container
      title={
        <Title
          title={title}
          products={products}
          selectedIds={selectedIds}
          onAddProduct={handleAddPallet}
          onReset={handleReset}
          disabled={disabled}
          readOnly={readOnly}
        />
      }
    >
      {fields.length > 0 ? (
        <Grid container spacing={2}>
          {fields.map((name, index) => (
            <Grid item xs={12} key={name + index}>
              <Pallet
                name={name}
                productName={productName}
                disabled={disabled}
                readOnly={readOnly}
                onRemove={handleRemove(index)}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box>
          {!readOnly ? (
            <Typography variant='subtitle2' color='error'>
              Please add {title}
            </Typography>
          ) : (
            <Typography variant='subtitle2' color='primary'>
              No {title}
            </Typography>
          )}
        </Box>
      )}
    </Container>
  );
};

export default Pallets;
