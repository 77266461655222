const sortUnits = (units) => {
  let cases = 0;
  let looses = 0;
  let pallets = 0;

  units.forEach((unit) => {
    cases += unit.case_qty;
    looses += unit.loose_qty;
    pallets += unit.pallet_qty;
  });

  let sortedUnits = [
    { name: 'Case', Quantity: cases },
    { name: 'Loose', Quantity: looses },
    { name: 'Pallet', Quantity: pallets },
  ];

  return sortedUnits;
};

const sortCases = (cases) => {
  let arr = [];

  cases.forEach((item) => {
    let itemObj = {};
    itemObj['name'] = item.sku;
    itemObj['Pallet'] = item.pallet_qty;
    itemObj['Shelf'] = item.shelf_qty;
    arr.push(itemObj);
  });
  return arr;
};

module.exports = { sortUnits, sortCases };
