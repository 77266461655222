import React, { useState, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

const ShipmentQuoteDialog = (props) => {
  const [submitted, setSubmitted] = useState(false);
  const handleClose = () => {
    props.handleClose(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    props.handleSubmit(e);
  };

  return (
    <div>
      <Dialog
        open={props.openConfirm}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Your Quote'}</DialogTitle>
        <DialogContent>
          {submitted === true ? (
            <Fragment>
              <DialogContentText id="alert-dialog-description">
                Success
              </DialogContentText>
              <Typography variant="body1">
                You have successfully scheduled a shipment with FillStorShip.
              </Typography>
            </Fragment>
          ) : (
            <Fragment>
              <DialogContentText id="alert-dialog-description">
                The amount listed below is the quote we have generated for you.
              </DialogContentText>
              <Typography variant="body1">{props.quote}</Typography>
            </Fragment>
          )}
        </DialogContent>
        <DialogActions>
          {submitted === true ? (
            <Fragment>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
            </Fragment>
          ) : (
            <Fragment>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              {props.user === 'guest' ? (
                <Link to="/register" style={{ textDecoration: 'none' }}>
                  <Button color="primary" autoFocus>
                    Register
                  </Button>
                </Link>
              ) : (
                <Button onClick={handleSubmit} color="primary" autoFocus>
                  Book
                </Button>
              )}
            </Fragment>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ShipmentQuoteDialog;
