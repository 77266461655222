import { useCallback, useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { useNotification } from '../../layout/Notifier';
import { uploadFootage } from '../../../redux/camera/cameraSlice';
import dialogStates from './dialogStates';
import { downloadShopifyOrders } from '../../../redux/shopifyorders/shopifyOrdersSlice';

const initialState = {
  status: null,
  pickedMapper: null,
  allPickedCount: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'initialize':
      return initialState;

    case 'set-status':
      return { ...state, status: action.payload };

    case 'set-pick-mapper':
      return { ...state, pickedMapper: action.payload };

    case 'pick-product':
      const id = action.payload;
      const pickedItem = state.pickedMapper[id];
      pickedItem.picked++;

      return {
        ...state,
        pickedMapper: { ...state.pickedMapper, [id]: pickedItem },
        allPickedCount:
          state.allPickedCount +
          (pickedItem.picked < pickedItem.quantity ? 0 : 1),
      };

    default:
      return state;
  }
};

const useOrderDialog = ({ order, products = [], productName, userId }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const showNotification = useNotification();
  const reduxDispatch = useDispatch();

  useEffect(() => {
    if (!!order) {
      dispatch({ type: 'set-status', payload: order.status.toUpperCase() });
      /** @type {Record<string, PickMapperItem>} */
      const pickList = products.reduce((acc, product) => {
        const id = product[productName]?._id;
        if (!id) return acc;
        /** @type {PickMapperItem} */
        const item = {
          id,
          barcode: product[productName].barcode,
          description: product[productName].description || '',
          sku: product[productName].sku,
          quantity: product.quantity,
          picked: 0,
        };
        return { ...acc, [id]: item };
      }, {});
      dispatch({ type: 'set-pick-mapper', payload: pickList });
    } else {
      dispatch({ type: 'initialize' });
    }
  }, [order, products, productName]);

  const onScan = useCallback(
    (barcode) => {
      try {
        const foundPickedProduct = Object.values(state.pickedMapper).find(
          (item) => item.barcode === barcode,
        );

        // Product not found from the list
        if (!foundPickedProduct) {
          throw new Error(
            'Scanned product not found. Please confirm barcodes match or find the correct product and try again.',
          );
        }

        // Check over pick
        if (foundPickedProduct.picked >= foundPickedProduct.quantity) {
          throw new Error('You scanned extra item.');
        }

        // Pick product
        dispatch({ type: 'pick-product', payload: foundPickedProduct.id });
      } catch (error) {
        showNotification(error.message, 'error');
      }
    },
    [state.pickedMapper, showNotification],
  );

  const onProductClick = useCallback(
    (id) => {
      try {
        const foundPickedProduct = Object.values(state.pickedMapper).find(
          (item) => item.id === id,
        );

        // Product not found from the list
        if (!foundPickedProduct) {
          throw new Error(
            'Scanned product not found. Please confirm barcodes match or find the correct product and try again.',
          );
        }

        // Check over pick
        if (foundPickedProduct.picked >= foundPickedProduct.quantity) {
          throw new Error('Extra item scanned');
        }

        // Pick product
        dispatch({ type: 'pick-product', payload: foundPickedProduct.id });
      } catch (error) {
        showNotification(error.message, 'error');
      }
    },
    [state.pickedMapper, showNotification],
  );

  const onScanError = () => {
    showNotification('Error scanning product. Try again', 'error');
  };

  const reloadOrders = useCallback(() => {
    if (userId) {
      reduxDispatch(downloadShopifyOrders(userId));
    } else {
      reduxDispatch(downloadShopifyOrders());
    }
  }, [userId, reduxDispatch]);

  const onCapture = useCallback(
    async (formData) => {
      const { _id, orderType, order_number } = order;
      formData.append('orderId', _id.toString());
      formData.append('order_number', order_number.toString());
      formData.append('orderType', orderType);

      const response = await reduxDispatch(uploadFootage(formData));

      if (/fulfilled$/.test(response.type)) {
        showNotification(`Uploaded: ${orderType} #${order_number}`, 'success');
        reloadOrders();
      } else {
        showNotification(`Error: ${orderType} #${order_number} `, 'error');
      }
    },
    [order, reduxDispatch, showNotification, reloadOrders],
  );

  return {
    ...state,
    dialogState: dialogStates[state.status] || null,
    allPicked:
      state.pickedMapper &&
      Object.keys(state.pickedMapper).length === state.allPickedCount,
    onScan,
    onProductClick,
    onScanError,
    onCapture,
  };
};

export default useOrderDialog;
