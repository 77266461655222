import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { setDrawerOpen } from '../../../redux/materialDash/materialDashSlice';
import { logout } from '../../../redux/auth/authSlice';

import useListItems from './useListItems';

const selector = (state) => {
  return {
    open: state.materialDash.drawerOpen,
    user: state.auth.user,
    isAdmin: state.auth.isAdmin,
  };
};

const useMaterialDash = () => {
  const { open, user, isAdmin } = useSelector(selector);
  const reduxDispatch = useDispatch();

  const location = useLocation();

  const { paths, pathToTitle } = useListItems();

  const handleDrawerOpen = () => {
    reduxDispatch(setDrawerOpen(true));
  };
  const handleDrawerClose = () => {
    reduxDispatch(setDrawerOpen(false));
  };

  const onLogout = async () => {
    await reduxDispatch(logout());
  };

  return {
    open,
    user,
    isAdmin,
    handleDrawerOpen,
    handleDrawerClose,
    onLogout,
    title: paths.includes(location.pathname)
      ? pathToTitle[location.pathname]
      : '',
  };
};

export default useMaterialDash;
