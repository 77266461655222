import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import NumberFormatCustom from '../common/NumberFormatCustom';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import getStorageQuote from '../../utils/genStorageQuote';

const StorageQuoteDialog = (props) => {
  const [state, setState] = useState({
    numPallets: '',
    weight: '',
    length: '',
    width: '',
    height: '',
    stackable: true,
    duration: '',
    quote: null,
  });

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    let quote = await Number(getStorageQuote(state));
    setState({ ...state, quote });
  };

  useEffect(() => {}, [state.quote]);

  return (
    <div>
      <Dialog open={props.open} onClose={props.handleClose}>
        <DialogTitle align='center'>
          Fill in all fields. If one does not apply, type in 0.
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography align='center'>Number of Pallets</Typography>
              <TextField
                name='numPallets'
                value={state.numPallets}
                onChange={handleChange}
                variant='outlined'
                fullWidth
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography align='center'>Weight (in pounds)</Typography>
              <TextField
                name='weight'
                value={state.weight}
                onChange={handleChange}
                variant='outlined'
                fullWidth
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography align='center'>Length (inches)</Typography>
              <TextField
                name='length'
                value={state.length}
                onChange={handleChange}
                variant='outlined'
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography align='center'>Width (inches)</Typography>
              <TextField
                name='width'
                value={state.width}
                onChange={handleChange}
                variant='outlined'
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography align='center'>Height (inches)</Typography>
              <TextField
                name='height'
                value={state.height}
                onChange={handleChange}
                variant='outlined'
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography align='center'>Stackable</Typography>
              <FormControl variant='outlined' fullWidth>
                <Select
                  name='stackable'
                  value={state.stackable}
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Typography align='center'>Duration (in months)</Typography>
              <TextField
                name='duration'
                value={state.duration}
                onChange={handleChange}
                variant='outlined'
                fullWidth
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        {state.quote !== null ? (
          <>
            <Typography variant='h5' align='center'>
              Your Quote:
            </Typography>
            <Typography variant='h6' align='center'>
              ${state.quote}
            </Typography>
          </>
        ) : null}
        <DialogActions>
          <Button onClick={props.handleClose} color='primary'>
            Cancel
          </Button>
          <Button onClick={() => handleSubmit()} color='primary' autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default StorageQuoteDialog;
