import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';
import { makeStyles } from '@material-ui/core/styles';
import { logout } from '../../../redux/auth/authSlice';
import { useSelector, useDispatch } from 'react-redux';

const UserLoginRegister = () => {
  const dispatch = useDispatch();
  const useStyles = makeStyles((theme) => ({
    buttonBar: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
      background: 'transparent',
    },
    buttonCollapse: {
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    signup: {
      letterSpacing: '2px',
      fontSize: '12px',
    },
    login: {
      border: 'solid thin white',
      borderRadius: '5px',
      padding: theme.spacing(0.5, 2),
      margin: theme.spacing(0.5),
    },
    login_text: {
      color: 'white',
      textDecoration: 'none',
      fontSize: '12px',
      letterSpacing: '2px',
    },
  }));
  const classes = useStyles();

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const onLogout = () => {
    dispatch(logout());
  };

  const [state, setState] = useState({
    anchorEl: null,
  });

  const handleMenu = (e) => {
    setState({ anchorEl: e.currentTarget });
  };

  const handleClose = () => {
    setState({ anchorEl: null });
  };

  const authLinks = (
    <Fragment>
      <Button color='inherit'>
        <Link to='/home' style={{ color: '#FFF', textDecoration: 'none' }}>
          Dashboard
        </Link>
      </Button>
    </Fragment>
  );

  const guestLinks = (
    <Fragment>
      <Button color='inherit' className={classes.signup}>
        <Link to='/register' style={{ color: '#FFF', textDecoration: 'none' }}>
          Sign Up
        </Link>
      </Button>

      <Button className={classes.login}>
        <Link to='/login' className={classes.login_text}>
          Login
        </Link>
      </Button>
    </Fragment>
  );

  return (
    <div>
      <div className={classes.buttonCollapse}>
        <IconButton onClick={handleMenu}>
          <HomeIcon style={{ color: 'white' }} />
        </IconButton>
        <Menu
          id='menu-appbar'
          anchorEl={state.anchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={Boolean(state.anchorEl)}
          onClose={handleClose}
        >
          {isAuthenticated ? (
            <MenuItem>
              <Button
                onClick={onLogout}
                color='inherit'
                style={{ color: 'black' }}
              >
                Logout
              </Button>
            </MenuItem>
          ) : (
            <div>
              <MenuItem>
                <Button color='inherit'>
                  <Link
                    to='/register'
                    style={{ color: 'black', textDecoration: 'none' }}
                  >
                    Sign Up
                  </Link>
                </Button>
              </MenuItem>
              <MenuItem>
                <Button color='inherit'>
                  <Link
                    to='/login'
                    style={{ color: 'black', textDecoration: 'none' }}
                  >
                    Login
                  </Link>
                </Button>
              </MenuItem>
            </div>
          )}
        </Menu>
      </div>
      <div className={classes.buttonBar} id='appbar-collapse'>
        <nav>{isAuthenticated === true ? authLinks : guestLinks}</nav>
      </div>
    </div>
  );
};

export default UserLoginRegister;
