import React from 'react';

import ReadOnlyTextField from './ReadOnlyTextField';

const UserInformation = ({ user }) => {
  return (
    <>
      <ReadOnlyTextField label='ID' value={user.id} />
      <ReadOnlyTextField label='Name' value={user.name} />
      <ReadOnlyTextField label='Email' value={user.email} />
    </>
  );
};

export default UserInformation;
