import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import actions from './shipmentsActions';

const initialState = {
  isLoading: false,
  error: '',

  isUpdating: false,
  updateError: '',
};

export const createShipment = createAsyncThunk(
  'shipments/createShipment',
  async (shipment, thunkAPI) => {
    return actions
      .createShipment(shipment)
      .then((r) => {
        return r.data;
      })
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

export const getShipments = createAsyncThunk(
  'shipments/getShipments',
  (params = {}, thunkAPI) => {
    const { id, ...rest } = params;
    if (id) {
      return actions.getShipmentsByUserId(id, rest);
    }
    return actions.getShipments(rest);
  },
);

export const updateShipment = createAsyncThunk(
  'shipments/updateShipment',
  async (shipment, thunkAPI) => {
    return actions
      .updateShipment(shipment)
      .then((r) => r.data)
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

export const deleteShipment = createAsyncThunk(
  'shipments/deleteShipment',
  (shipment, thunkAPI) => {
    return actions
      .deleteShipment(shipment)
      .then((r) => r.data)
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

const shipmentsAdapter = createEntityAdapter({
  selectId: (entity) => entity._id,
});

const updatePending = (state, action) => {
  state.isUpdating = true;
  state.updateError = '';
};

const updateError = (state, action) => {
  state.isUpdating = false;
  state.updateError = action.payload.error;
};

const slice = createSlice({
  name: 'shipments',
  initialState: shipmentsAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: {
    [createShipment.pending]: updatePending,
    [createShipment.rejected]: updateError,
    [createShipment.fulfilled]: (state, action) => {
      state.isUpdating = false;
      const { _id, ...changes } = action.payload;
      shipmentsAdapter.updateOne(state, { id: _id, changes });
    },
    [getShipments.pending]: (state, action) => {
      state.isLoading = true;
      state.error = '';
    },
    [getShipments.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    [getShipments.fulfilled]: (state, action) => {
      state.isLoading = false;
      const { data } = action.payload;
      state.shipments = action.payload;
      shipmentsAdapter.removeAll(state);
      shipmentsAdapter.upsertMany(state, data);
    },
    [updateShipment.pending]: updatePending,
    [updateShipment.rejected]: updateError,
    [updateShipment.fulfilled]: (state, action) => {
      state.isUpdating = false;
      const { _id, ...changes } = action.payload;
      shipmentsAdapter.updateOne(state, { id: _id, changes });
    },
    [deleteShipment.pending]: updatePending,
    [deleteShipment.rejected]: updateError,
    [deleteShipment.fulfilled]: (state, action) => {
      state.isUpdating = false;
      shipmentsAdapter.removeOne(state, action.payload.id);
    },
  },
});

export const createShipmentsSelectors = () => {
  return shipmentsAdapter.getSelectors((state) => state.shipments);
};

export const {
  selectById: selectShipmentById,
  selectIds: selectShipmentIds,
  selectEntities: selectShipmentEntities,
  selectAll: selectAllShipments,
  selectTotal: selectTotalShipments,
} = shipmentsAdapter.getSelectors((state) => state.shipments);

export default slice.reducer;
