import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import actions from './manualordersActions';

const initialState = {
  isLoading: false,
  error: '',

  isUpdating: false,
  updateError: '',
};

export const createManualOrder = createAsyncThunk(
  'manualorders/createManualOrder',
  async (manual, thunkAPI) => {
    return actions
      .createManualOrder(manual)
      .then((r) => r.data)
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

export const getManualOrders = createAsyncThunk(
  'manualorders/getManualOrders',
  (params = {}) => {
    const { id, ...rest } = params;
    if (id) {
      return actions.getUserManualOrders(id, rest);
    }
    return actions.getManualOrders(rest);
  },
);

export const updateManualOrder = createAsyncThunk(
  'manualorders/updateManualOrder',
  async (manual, thunkAPI) => {
    return actions
      .updateManualOrder(manual)
      .then((r) => r.data)
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

export const deleteManualOrder = createAsyncThunk(
  'manualorders/deleteManualOrder',
  (id, thunkAPI) => {
    return actions
      .deleteManualOrder(id)
      .then((r) => r.data)
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

const manualordersAdapter = createEntityAdapter({
  selectId: (entity) => entity._id,
});

const updatePending = (state, action) => {
  state.isUpdating = true;
  state.updateError = '';
};

const updateError = (state, action) => {
  state.isUpdating = false;
  state.updateError = action.payload.error;
};

const slice = createSlice({
  name: 'manualorders',
  initialState: manualordersAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: {
    [createManualOrder.pending]: updatePending,
    [createManualOrder.rejected]: updateError,
    [createManualOrder.fulfilled]: (state, action) => {
      state.isUpdating = false;
      const { _id, ...changes } = action.payload;
      manualordersAdapter.updateOne(state, { id: _id, changes });
    },
    [getManualOrders.pending]: (state, action) => {
      state.isLoading = true;
      state.error = '';
    },
    [getManualOrders.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    [getManualOrders.fulfilled]: (state, action) => {
      state.isLoading = false;
      const { data } = action.payload;
      state.manualorders = action.payload;
      manualordersAdapter.removeAll(state);
      manualordersAdapter.upsertMany(state, data);
    },
    [updateManualOrder.pending]: updatePending,
    [updateManualOrder.rejected]: updateError,
    [updateManualOrder.fulfilled]: (state, action) => {
      state.isUpdating = false;
      const { _id, ...changes } = action.payload;
      manualordersAdapter.updateOne(state, { id: _id, changes });
    },
    [deleteManualOrder.pending]: updatePending,
    [deleteManualOrder.rejected]: updateError,
    [deleteManualOrder.fulfilled]: (state, action) => {
      state.isUpdating = false;
      manualordersAdapter.removeOne(state, action.payload.id);
    },
  },
});

export const createManualOrdersSelectors = () => {
  return manualordersAdapter.getSelectors((state) => state.manualorders);
};

export default slice.reducer;
