import React from 'react';

import { FieldArray } from 'react-final-form-arrays';

import Container from '../Container';
import HandlingUnits from '../HandlingUnits';

import CommoditiesTitle from './CommoditiesTitle';

import useCommodities from './useCommodities';

const Commodities = ({ name, disabled = false, readOnly = false }) => {
  const { onReset } = useCommodities({ name });

  return (
    <Container
      align='left'
      title={
        <CommoditiesTitle
          disabled={disabled}
          hazName={`${name}.hazardous`}
          readOnly={readOnly}
          onReset={onReset}
        />
      }
    >
      <FieldArray
        name={`${name}.handlingUnits`}
        component={HandlingUnits}
        disabled={disabled}
        readOnly={readOnly}
        subscription={{ value: true }}
      />
    </Container>
  );
};

export default Commodities;
