import React, { useCallback, useEffect, useReducer, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StatusChip from './StatusChip';
import { useNotification } from '../../layout/Notifier';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import moment from 'moment';
import {
  createShopifyOrdersSelectors,
  deleteShopifyOrder,
  getShopifyOrders,
  updateShopifyOrder,
  downloadShopifyOrders,
} from '../../../redux/shopifyorders/shopifyOrdersSlice';
import { Typography } from '@material-ui/core';

/** @type {Column[]} */
const columns = [
  {
    title: 'Shopify Id',
    field: 'order_number',
    editable: 'never',
  },
  {
    title: 'Status',
    field: 'status',
    lookup: {
      // pending: 'Pending',
      fulfilled: 'Fulfilled',
      picked: 'Picked',
      // 'in progress': 'In progress',
      unfulfilled: 'Unfulfilled',
      // rejected: 'Rejected',
    },
    render: (rowData) => <StatusChip status={rowData.status} />,
  },
  {
    title: 'Customer',
    field: 'customer',
    editable: 'never',
  },
  {
    title: 'Email',
    field: 'contact_email',
    editable: 'never',
  },
  {
    title: 'Date',
    field: 'createdAt',
    render: (rowData) => (
      <Typography>{moment(rowData.createdAt).format('MMM Do YYYY')}</Typography>
    ),
    editable: 'never',
  },
];

/**
 * @typedef {Object} State
 * @property {boolean} initialized
 * @property {string | null} userId
 * @property {number} pageSize
 * @property {number} currentPage
 * @property {Object} filter
 * @property {string || null} currentOrderId
 */

/**
 * @typedef {Object} ActionBoolean
 * @property {'set-initialized'} type
 * @property {boolean} payload
 */
/**
 * @typedef {Object} ActionString
 * @property {'set-user-id' | 'set-current-order-id'} type
 * @property {string | null} payload
 */
/**
 * @typedef {Object} ActionNumber
 * @property {'set-page-size' | 'set-current-page'} type
 * @property {number} payload
 */
/**
 * @typedef {Object} ActionObject
 * @property {'set-filter'} type
 * @property {Object} payload
 */

/** @type {State} */
const initialState = {
  initialized: false,
  userId: null,
  pageSize: 100,
  currentPage: 0,
  filter: {},
  currentOrderId: null,
};

/**
 * @typedef {ActionBoolean | ActionString | ActionNumber | ActionObject} Action
 */
/**
 * @param {State} state
 * @param {Action} action
 * @returns {State}
 */
const reducer = (state, action) => {
  switch (action.type) {
    case 'set-initialized':
      return { ...state, initialized: action.payload };

    case 'set-user-id':
      return { ...state, userId: action.payload };

    case 'set-page-size':
      return { ...state, pageSize: action.payload };

    case 'set-current-page':
      return { ...state, currentPage: action.payload };

    case 'set-filter':
      return { ...state, filter: action.payload };

    case 'set-current-order-id':
      return { ...state, currentOrderId: action.payload };

    default:
      return state;
  }
};

const selector = (state) => {
  return {
    auth: state.auth,
    tableData: createShopifyOrdersSelectors()
      .selectAll(state)
      .map((row, index) => ({ ...row, tableData: { id: index } })),
    shopifyOrders: state.shopifyOrders,
  };
};

const currentOrderSelector = (id) => (state) => {
  return {
    currentOrder: id
      ? createShopifyOrdersSelectors().selectById(state, id)
      : null,
  };
};

const useShopifyTable = () => {
  const showNotification = useNotification();
  const { auth, tableData, shopifyOrders } = useSelector(selector);
  const reduxDispatch = useDispatch();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { currentOrder } = useSelector(
    currentOrderSelector(state.currentOrderId),
  );

  useErrorHandler((state) => state.shopifyOrders.updateError);

  const tableRef = useRef();

  // Initialize
  useEffect(() => {
    if (auth.initialized) {
      if (!auth.isAdmin) {
        dispatch({ type: 'set-user-id', payload: auth.user._id });
      }
      dispatch({ type: 'set-initialized', payload: true });
    }
  }, [auth.initialized, auth.isAdmin, auth.user._id]);

  // Get shopify orders
  const loadShopifyOrders = useCallback(() => {
    const rest = {
      page: state.currentPage + 1,
      limit: state.pageSize,
      ...state.filter,
    };
    if (auth.isAdmin && state.userId) {
      reduxDispatch(getShopifyOrders({ id: state.userId, ...rest }));
    } else {
      reduxDispatch(getShopifyOrders({ ...rest }));
    }
  }, [
    auth.isAdmin,
    state.userId,
    state.currentPage,
    state.pageSize,
    state.filter,
    reduxDispatch,
  ]);

  useEffect(() => {
    if (state.initialized || shopifyOrders.isDataStale) {
      loadShopifyOrders();
    }
  }, [state.initialized, shopifyOrders.isDataStale, loadShopifyOrders]);

  const onChangePage = (page) => {
    dispatch({ type: 'set-current-page', payload: page });
  };

  const onChangeRowsPerPage = (pageSize) => {
    dispatch({ type: 'set-page-size', payload: pageSize });
  };

  const onFilterChange = useCallback(
    (e) => {
      const payload = {};
      for (let { column, value } of e) {
        if (column.lookup) {
          payload[column.field] = value;
        } else {
          payload[column.field] = {
            regex: '.*' + value + '.*',
            options: 'i',
          };
        }
      }
      dispatch({
        type: 'set-filter',
        payload: { ...payload, sort: state.filter.sort },
      });
    },
    [state.filter],
  );

  const onOrderChange = useCallback(
    (columnIndex, order) => {
      let payload = {};
      if (columnIndex === -1) {
        const { sort, ...rest } = state.filter;
        payload = { ...rest };
      } else {
        payload = {
          ...state.filter,
          sort: { [columns[columnIndex].field]: order },
        };
      }
      dispatch({ type: 'set-filter', payload });
    },
    [state.filter],
  );

  const onRowDelete = useCallback(
    async (rowData) => {
      await reduxDispatch(deleteShopifyOrder(rowData._id));
    },
    [reduxDispatch],
  );

  const onRowUpdate = useCallback(
    async (newData, oldData) => {
      await reduxDispatch(updateShopifyOrder(newData));
    },
    [reduxDispatch],
  );

  const onDownloadShopifyOrders = useCallback(async () => {
    if (state.userId) {
      const response = await reduxDispatch(downloadShopifyOrders(state.userId));
      if (/rejected$/.test(response.type))
        showNotification(response.payload.error, 'error');
    }
  }, [state.userId, reduxDispatch, showNotification]);

  const onGetVideo = useCallback(
    (event, rowData) => {
      if (!rowData.footageUrl) {
        showNotification('Video footage unavailable', 'error');
      } else {
        window.open(rowData.footageUrl, '_blank');
      }
    },
    [showNotification],
  );

  const onUserChange = (newUserId) => {
    onChangePage(0);
    dispatch({ type: 'set-user-id', payload: newUserId });
  };

  const onRowClick = (event, rowData) => {
    if (tableRef.current?.state?.lastEditingRow === undefined) {
      const { tableData, ...order } = rowData;
      dispatch({ type: 'set-current-order-id', payload: order._id });
    }
  };

  const onDialogClose = () => {
    dispatch({ type: 'set-current-order-id', payload: null });
  };

  const onSubmitFulfill = useCallback(
    (event) => {
      event.preventDefault();
      if (currentOrder) {
        reduxDispatch(
          updateShopifyOrder({ _id: currentOrder._id, status: 'fulfilled' }),
        );
      }
    },
    [reduxDispatch, currentOrder],
  );

  const onSubmitPicked = useCallback(
    (event) => {
      event.preventDefault();
      if (currentOrder) {
        reduxDispatch(
          updateShopifyOrder({ _id: currentOrder._id, status: 'picked' }),
        );
      }
    },
    [reduxDispatch, currentOrder],
  );

  return {
    isLoading: shopifyOrders.isLoading || shopifyOrders.isUpdating,
    isDownloading: shopifyOrders.isDownloading,
    isAdmin: auth.isAdmin,
    columns,
    pagination: shopifyOrders.pagination,
    tableData,
    tableRef,
    currentOrder,
    ...state,
    onChangePage,
    onChangeRowsPerPage,
    onDialogClose,
    onDownloadShopifyOrders,
    onGetVideo,
    onFilterChange,
    onOrderChange,
    onRowClick,
    onRowDelete,
    onRowUpdate,
    onSubmitFulfill,
    onSubmitPicked,
    onUserChange,
  };
};

export default useShopifyTable;
