import React, { Fragment, useReducer, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import DashWrapper from '../../wrappers/DashWrapper';
import ProfileCard from './Cards/ProfileCard';
import CompanyCard from './Cards/CompanyCard';
import CompanyDialog from '../../dialogs/CompanyDialog';
import ShopifyDialog from '../../dialogs/EcommerceDialog/ShopifyDialog';
import WooCommerceDialog from '../../dialogs/EcommerceDialog/WooCommerceDialog';
import PasswordDialog from '../../dialogs/PasswordDialog';
import UserDialog from '../../dialogs/UserDialog';
import { useNotification } from '../../layout/Notifier';
import { updateDetails } from '../../../redux/auth/authSlice';

const useStyles = makeStyles({
  root: {
    border: '1px #e1e1e1 solid',
    borderRadius: '5px',
    marginBottom: '30px',
    marginTop: '30px',
    padding: '30px',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  pl0: {
    paddingLeft: '0px',
  },
});

const initialState = {
  editShopifyOpen: false,
  editWooOpen: false,
  editCompanyOpen: false,
  editUserOpen: false,
  editPasswordOpen: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'set-shopify-dialog-open':
      return { ...state, editShopifyOpen: action.payload };

    case 'set-woo-dialog-open':
      return { ...state, editWooOpen: action.payload };

    case 'set-company-dialog-open':
      return { ...state, editCompanyOpen: action.payload };

    case 'set-user-dialog-open':
      return { ...state, editUserOpen: action.payload };

    case 'set-user-password-open':
      return { ...state, editPasswordOpen: action.payload };

    default:
      return state;
  }
};

const Orders = () => {
  const reduxDispatch = useDispatch();
  const showNotification = useNotification();
  const classes = useStyles();

  const { user } = useSelector((state) => state.auth);
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleShopifyOpen = () => {
    dispatch({ type: 'set-shopify-dialog-open', payload: true });
  };
  const handleShopifyClose = () => {
    dispatch({ type: 'set-shopify-dialog-open', payload: false });
  };
  const handleWooCommerceOpen = () => {
    dispatch({ type: 'set-woo-dialog-open', payload: true });
  };
  const handleWooCommerceClose = () => {
    dispatch({ type: 'set-woo-dialog-open', payload: false });
  };
  const handleCompanyOpen = () => {
    dispatch({ type: 'set-company-dialog-open', payload: true });
  };
  const handleCompanyClose = () => {
    dispatch({ type: 'set-company-dialog-open', payload: false });
  };
  const handleUserOpen = () => {
    dispatch({ type: 'set-user-dialog-open', payload: true });
  };
  const handleUserClose = () => {
    dispatch({ type: 'set-user-dialog-open', payload: false });
  };
  const handlePasswordOpen = () => {
    dispatch({ type: 'set-user-password-open', payload: true });
  };
  const handlePasswordClose = () => {
    dispatch({ type: 'set-user-password-open', payload: false });
  };

  const onUpdateDetails = useCallback(
    async (...params) => {
      const response = await reduxDispatch(updateDetails(...params));
      if (/fulfilled$/.test(response.type)) {
        showNotification('Successfully updated your information', 'success');
      }
      handleShopifyClose();
      handleWooCommerceClose();
      handleCompanyClose();
      handleUserClose();
    },
    [reduxDispatch, showNotification],
  );

  return (
    <Fragment>
      <DashWrapper>
        {user !== null ? (
          <Fragment>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Typography variant='h4' align='left' gutterBottom>
                  Profile
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={6}
                justify='flex-end'
                spacing={1}
              >
                <Grid item>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={handleShopifyOpen}
                  >
                    Shopify
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={handleWooCommerceOpen}
                  >
                    Woo Commerce
                  </Button>
                </Grid>
              </Grid>
              <Grid container className={classes.root} item xs={12}>
                <Grid item xs={12} sm={7}>
                  <Typography variant='h5' align='left'>
                    User Info
                  </Typography>
                </Grid>
                <Grid container item xs={12} sm={5} alignContent='flex-end'>
                  <Grid item xs={12}>
                    <ProfileCard
                      handleUserOpen={handleUserOpen}
                      handleUserClose={handleUserClose}
                      handlePasswordClose={handlePasswordClose}
                      handlePasswordOpen={handlePasswordOpen}
                      name={user.name}
                      email={user.email}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container className={classes.root} item xs={12}>
                <Grid item xs={12} sm={7}>
                  <Typography variant='h5' align='left'>
                    Company Details
                  </Typography>
                </Grid>
                <Grid container item xs={12} sm={5} alignContent='flex-end'>
                  <Grid item xs={12}>
                    <CompanyCard
                      handleCompanyOpen={handleCompanyOpen}
                      handleCompanyClose={handleCompanyClose}
                      company={user.company}
                      address={user.address}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <CompanyDialog
              companyOpen={state.editCompanyOpen}
              user={user}
              updateDetails={onUpdateDetails}
              handleCompanyClose={handleCompanyClose}
              handleCompanyOpen={handleCompanyOpen}
            />
            <UserDialog
              userOpen={state.editUserOpen}
              user={user}
              updateDetails={onUpdateDetails}
              handleUserClose={handleUserClose}
              handleUserOpen={handleUserOpen}
            />
            <PasswordDialog
              passwordOpen={state.editPasswordOpen}
              user={user}
              handlePasswordClose={handlePasswordClose}
              handlePasswordOpen={handlePasswordOpen}
            />
            <ShopifyDialog
              userOpen={state.editShopifyOpen}
              handleShopifyClose={handleShopifyClose}
              handleShopifyOpen={handleShopifyOpen}
            />
            <WooCommerceDialog
              userOpen={state.editWooOpen}
              handleWooCommerceOpen={handleWooCommerceOpen}
              handleWooCommerceClose={handleWooCommerceClose}
            />
          </Fragment>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress color='primary' />
          </div>
        )}
      </DashWrapper>
    </Fragment>
  );
};

export default Orders;
