import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputBase from '@material-ui/core/InputBase';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const selectOptions = [
  { label: 'SKU', value: 'sku' },
  { label: 'Barcode', value: 'barcode' },
];

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(),
    },
    select: {
      marginLeft: theme.spacing(),
    },
  }),
);

const AddProductAutoComplete = ({
  disabled,
  getOptionLabel,
  getOptionSelected,
  name,
  selectedIds = [],
  onAddProduct,
  products,
}) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState('');
  const [selectValue, setSelectValue] = useState(selectOptions[0].value);

  const [options, setOptions] = useState([]);
  useEffect(() => {
    const newOptions = products.filter(
      (element) => !selectedIds.includes(element._id),
    );
    setOptions(newOptions);
  }, [products, selectedIds]);

  const onChange = (event, value) => {
    onAddProduct(value);
    setInputValue('');
  };

  const onInputChange = (event, value) => {
    setInputValue(value || '');
  };

  const onSelect = (event) => {
    setSelectValue(event.target.value);
  };

  const startAdornment = (
    <InputAdornment position='start'>
      <Select
        autoWidth
        classes={{ root: classes.select }}
        disabled={disabled}
        input={<InputBase />}
        onChange={onSelect}
        value={selectValue}
      >
        {selectOptions.map(({ label, value }) => (
          <MenuItem key={value} dense value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </InputAdornment>
  );

  const renderInput = (params) => {
    return (
      <TextField
        {...params}
        disabled={disabled}
        label='Add Product'
        InputLabelProps={{ shrink: true }}
        fullWidth
        variant='outlined'
        InputProps={{ ...params.InputProps, startAdornment }}
      />
    );
  };

  return (
    <Autocomplete
      autoComplete
      autoHighlight
      classes={{ root: classes.root }}
      disabled={disabled}
      getOptionLabel={getOptionLabel(selectValue)}
      getOptionSelected={getOptionSelected(selectValue)}
      id={`add-product-${name}`}
      inputValue={inputValue}
      onChange={onChange}
      onInputChange={onInputChange}
      options={options}
      renderInput={renderInput}
      size='small'
      value={null}
    />
  );
};

export default AddProductAutoComplete;
