export const image_one =
  'https://fsswebsiteimages.s3-us-west-1.amazonaws.com/landing/warehouse-worker.jpg';
export const image_two =
  'https://fsswebsiteimages.s3-us-west-1.amazonaws.com/landing/pallettes.jpg';
export const image_three =
  'https://fsswebsiteimages.s3-us-west-1.amazonaws.com/landing/shipping.jpg';
export const image_four =
  'https://fsswebsiteimages.s3-us-west-1.amazonaws.com/landing/forklift2.jpg';
export const image_five =
  'https://fsswebsiteimages.s3-us-west-1.amazonaws.com/landing/fulfillment.jpg';
export const image_six =
  'https://fsswebsiteimages.s3-us-west-1.amazonaws.com/landing/storage.jpg';
export const image_seven =
  'https://fsswebsiteimages.s3-us-west-1.amazonaws.com/landing/ship.jpg';
