function parseFloatNumber(num) {
  if (num === null || num === undefined) return NaN;
  if (typeof num === 'string') {
    num =
      (/^\(.+\)$/.test(num) ? -1 : 1) *
      parseFloat(num.trim().replace(/(^\()|(\)$)/g, ''));
  }
  return parseFloat(num.toPrecision(12));
}

export default parseFloatNumber;
