import { useCallback, useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';

const initialState = {
  mode: null,
  userId: null,
  initialValues: {
    sku: '',
    barcode: '',
    description: '',
    loose_qty: '',
    case_qty: '',
    pallet_qty: '',
    total: '',
    soNum: '',
    lotNum: '',
    notes: '',
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'intialize':
      return initialState;
    case 'set-mode':
      return { ...state, mode: action.payload || null };
    case 'set-initial-values':
      return {
        ...state,
        initialValues: action.payload || initialState.initialValues,
      };
    case 'set-user-id':
      return { ...state, userId: action.payload || null };
    default:
      return state;
  }
};

const useUnitDialog = ({ opened, onClose, onSubmit, unit, _userId }) => {
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (opened && !unit) {
      dispatch({ type: 'set-mode', payload: 'create' });
      dispatch({ type: 'set-initial-values' });
      dispatch({ type: 'set-user-id', payload: _userId || null });
    } else if (opened && !!unit) {
      dispatch({ type: 'set-mode', payload: 'update' });
      const {
        sku,
        barcode,
        description,
        loose_qty,
        case_qty,
        pallet_qty,
        total,
        soNum,
        lotNum,
        notes,
      } = unit;
      dispatch({
        type: 'set-initial-values',
        payload: {
          sku,
          barcode,
          description,
          loose_qty,
          case_qty,
          pallet_qty,
          total,
          soNum,
          lotNum,
          notes,
        },
      });
      dispatch({ type: 'set-user-id', payload: unit.user });
    } else {
      dispatch({ type: 'intialize' });
    }
  }, [opened, unit, _userId]);

  const onFormSubmit = useCallback(
    async (values, form) => {
      const success = await onSubmit({ ...values, user: state.userId });
      if (!success) return;
      onClose();
    },
    [state.userId, onClose, onSubmit],
  );

  const onChangeUserId = (userId) => {
    dispatch({ type: 'set-user-id', payload: userId });
    dispatch({ type: 'set-initial-values' });
  };

  return { isAdmin, ...state, onFormSubmit, onChangeUserId };
};

export default useUnitDialog;
