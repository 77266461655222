import React from 'react';

import { Field } from 'react-final-form';

import Grid from '@material-ui/core/Grid';

import Input from '../../../common/fieldComponent/Input';
import CheckBox from '../../../common/fieldComponent/CheckBox';

import useLoadDetailsForm from './useLoadDetailsForm';

const LoadDetailsForm = () => {
  const { list, checkboxList, parse } = useLoadDetailsForm();

  return (
    <Grid container spacing={2}>
      {list.map(({ ...itemProps }) => {
        return (
          <Grid key={itemProps.name} item xs={12} md={4}>
            <Field
              component={Input}
              size='small'
              parse={parse}
              required
              {...itemProps}
            />
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <Grid container justify='space-around' spacing={2}>
          {checkboxList.map((itemProps) => {
            return (
              <Grid key={itemProps.name} item>
                <Field
                  component={CheckBox}
                  name={itemProps.name}
                  label={itemProps.label}
                  type='checkbox'
                  initialValue={false}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LoadDetailsForm;
