import React from 'react';
import Wrapper from '../../wrappers/Wrapper';
import Footer from '../../layout/Footer';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

const text = [
  {
    header: (
      <Typography variant='h5'>
        <b>
          Data Protection Statement and Consent to Use of Data at
          Fillstorship.com
        </b>
      </Typography>
    ),
    body: (
      <Typography variant='caption' paragraph>
        Data protection is a matter of trust and your trust is important to us.
        So that you can feel secure when visiting our websites, we strictly
        observe the statutory regulations in processing your person-specific
        data and we would like to inform you here about our data acquisition and
        data utilization. The following data-protection statement explains to
        you which data is collected from you on our websites, which parts of
        this data we process and use and in which way, and to whom you can turn
        with any concerns or requests.
      </Typography>
    ),
  },
  {
    header: (
      <Typography variant='h5'>
        <b>Customer Account</b>
      </Typography>
    ),
    body: (
      <Typography variant='caption' paragraph>
        Acquisition, Processing and Utilization of Person-Specific Data.
        Person-specific data describes details relating to the actual or
        personal relationships of a specific or identifiable natural person.
        Such details include, for example, your name, your telephone number,
        your address, and all existing data that you disclose to us during
        registration and when opening your customer account.
      </Typography>
    ),
  },
  {
    header: (
      <Typography variant='h5'>
        <b>Setup of the Customer Account</b>
      </Typography>
    ),
    body: (
      <Typography variant='caption' paragraph>
        The following data must be given during the registration for a customer
        account (obligatory disclosures): your e-mail address – your password –
        your forename – your first name – your last name. Your billing and
        delivery address need not be given until you order something. For your
        order we need your correct name, address and payment data. We need your
        e-mail address so that we can confirm receipt of your order and its
        dispatch, and so that we can communicate with you in general. We also
        use your first name and last name to identify you (as a log-in name),
        when you visit your customer account.
      </Typography>
    ),
  },
  {
    header: (
      <Typography variant='h5'>
        <b>
          Acquisition, Processing and Utilization of Your Person-Specific Data
        </b>
      </Typography>
    ),
    body: (
      <Typography variant='caption' paragraph>
        We collect, store, and process your data for the entire processing of
        your purchase, the technical administration, our own marketing purposes
        and to prevent criminal offences and fraud. Your person-specific data is
        only passed on to third parties to the extent that this is legally
        permissible, including the purpose of processing the contract or for
        invoicing, for marketing or if you have previously consented to this.
        When processing orders, for example, the service companies used by us
        (transport companies, logistics companies, Paypal) are sent the data
        they require for processing and concluding orders. The data passed on to
        our service providers in this context may only be used by them for
        fulfilling their duties.
      </Typography>
    ),
  },
  {
    header: (
      <Typography variant='h5'>
        <b>Cookies</b>
      </Typography>
    ),
    body: (
      <Typography variant='caption' paragraph>
        The acceptance of cookies is not a prerequisite for access to our
        websites. We draw your attention, however, to the fact that our website
        and service has only limited functionality, if you do not allow us to
        place cookies.
      </Typography>
    ),
  },
  {
    header: (
      <Typography variant='h5'>
        <b>What are cookies?</b>
      </Typography>
    ),
    body: (
      <Typography variant='caption' paragraph>
        Cookies are small files that are stored on your data carrier and hold
        certain settings and data for exchange with our system via your browser.
        Basically speaking there are 2 different types of cookies: the so-called
        session cookies, that are deleted as soon as you close your browser; and
        temporary/permanent cookies, that are stored on your data carriers for a
        longer period or without limit. This type of storage helps us to design
        our websites and our offers for you and makes it easier for you to use
        them in that, for example, certain input from you can be stored so that
        you are not required to enter this repeatedly.
      </Typography>
    ),
  },
  {
    header: (
      <Typography variant='h5'>
        <b>How we use cookies</b>
      </Typography>
    ),
    body: (
      <Typography variant='caption' paragraph>
        We use traffic log cookies to identify which pages are being used. This
        helps us analyse data about web page traffic and improve our website in
        order to tailor it to customer needs. We only use this information for
        statistical analysis purposes and then the data is removed from the
        system. Overall, cookies help us provide you with a better website, by
        enabling us to monitor which pages you find useful and which you do not.
        A cookie in no way gives us access to your computer or any information
        about you, other than the data you choose to share with us. You can
        choose to accept or decline cookies. Most web browsers automatically
        accept cookies, but you can usually modify your browser setting to
        decline cookies if you prefer. This may prevent you from taking full
        advantage of the website.
      </Typography>
    ),
  },
  {
    header: (
      <Typography variant='h5'>
        <b>Controlling your personal information</b>
      </Typography>
    ),
    body: (
      <Typography variant='caption' paragraph>
        You may choose to restrict the collection or use of your personal
        information in the following ways: Whenever you are asked to fill in a
        form on the website, look for the box that you can click to indicate
        that you do not want the information to be used by anybody for direct
        marketing purposes If you have previously agreed to us using your
        personal information for direct marketing purposes, you may change your
        mind at any time by writing to or emailing us at ship@Fillstorship.com
        We will not sell, distribute or lease your personal information to third
        parties unless we have your permission or are required by law to do so.
        We may use your personal information to send you promotional information
        about third parties which we think you may find interesting if you tell
        us that you wish this to happen. You may request details of personal
        information which we hold about you under the General Data Protection
        Regulation 2018. If you believe that any information we are holding on
        you is incorrect or incomplete, please write to or email us as soon as
        possible, at the above address. We will promptly correct any information
        found to be incorrect.
      </Typography>
    ),
  },
  {
    header: (
      <Typography variant='h5'>
        <b>What data is stored in the cookies?</b>
      </Typography>
    ),
    body: (
      <Typography variant='caption' paragraph>
        In the cookies used by Fillstorship, only pseudonymous data is stored.
        On activation of the cookie this is allocated an identification number;
        an allocation of your person-specific data to this identification number
        is not undertaken. Your name, your IP address and other such data that
        might allow the cookie to be traced to you directly are not placed in
        the cookie. On the basis of the cookie technology we receive only
        pseudonymized information, relating for example to which of our shop
        pages have been visited, which products were viewed, etc. Fillstorship
        makes use of a number of web partners who help to make the Internet
        offer and the websites more interesting for you. These cookies are
        temporary/permanent cookies that are deleted automatically after the
        pre-specified period. These temporary or even permanent cookies
        (lifespan of 14 days to 10 years) are stored on your hard disk and
        delete themselves after the pre-specified period. The cookies of our
        partner companies also contain solely pseudonymous - mostly even
        anonymous - data.
      </Typography>
    ),
  },
  {
    header: (
      <Typography variant='h5'>
        <b>Log Files</b>
      </Typography>
    ),
    body: (
      <Typography variant='caption' paragraph>
        With each access to the pages of Fillstorship usage data is transmitted
        by the respective Internet browser and is stored in record files, the
        so-called server log files. The records stored in this way contain the
        following data: date and time of the call-up, name of the page called
        up, IP address, referrer URL (URL of origin, from which you came to the
        websites), the quantities of data transferred, product information and
        the version of the browser used.These log file data records are
        evaluated by us, to help us further improve our offer and the
        Fillstorship site, as well as to make these more-user-friendly, to
        detect and correct errors more quickly and to control server capacities.
      </Typography>
    ),
  },
  {
    header: (
      <Typography variant='h5'>
        <b>Re-Targeting and Data Acquisition by Third Parties</b>
      </Typography>
    ),
    body: (
      <Typography variant='caption' paragraph>
        Your use of our site means that you accept the practices set forth in
        this policy. We reserve the right to make changes to the policy by
        posting the new version with a new effective date. You continued use
        indicates your agreement to the changes.
      </Typography>
    ),
  },
];

const PrivacyPolicy = () => {
  return (
    <div>
      <Wrapper />
      <Container style={{ marginTop: '3em' }}>
        {text.map((t, i) => (
          <div key={i}>
            {t.header}
            {t.body}
          </div>
        ))}
      </Container>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
