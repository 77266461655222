import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Form } from 'react-final-form';
import UserAutoCompleteTextField from '../../common/UserAutoCompleteTextField';
import UnitForm from '../../forms/UnitForm';
import useUnitDialog from './useUnitDialog';

const UnitDialog = ({
  isLoading: _isLoading,
  opened,
  unit,
  onClose,
  onSubmit,
  userId: _userId,
}) => {
  const {
    isAdmin,
    mode,
    initialValues,
    userId,
    onFormSubmit,
    onChangeUserId,
  } = useUnitDialog({
    opened,
    onClose,
    onSubmit,
    unit,
    _userId,
  });

  const modeString = mode ? mode.charAt(0).toUpperCase() + mode.slice(1) : '';

  const renderForm = ({ handleSubmit, form }) => {
    const { dirty, pristine, submitting } = form.getState();
    return (
      <>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            {isAdmin && (
              <Box marginBottom={2}>
                <UserAutoCompleteTextField
                  autocompleteProps={{ disabled: mode === 'update' }}
                  hideAll
                  label='Customer'
                  margin='dense'
                  onChange={onChangeUserId}
                  value={userId}
                />
              </Box>
            )}
            <UnitForm
              disabled={isAdmin && userId === null}
              mode={mode}
              isAdmin={isAdmin}
            />
          </DialogContent>
          <DialogActions>
            <Button disabled={_isLoading} onClick={onClose} variant='outlined'>
              Close
            </Button>
            <Button
              disabled={_isLoading || !dirty || pristine || submitting}
              color='primary'
              type='submit'
              variant='outlined'
            >
              {modeString}
            </Button>
          </DialogActions>
        </form>
        {(_isLoading || submitting) && <LinearProgress color='primary' />}
      </>
    );
  };

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      open={opened}
      onClose={onClose}
      scroll='paper'
    >
      <DialogTitle>{`${modeString} Unit`}</DialogTitle>
      <Form
        initialValues={initialValues}
        onSubmit={onFormSubmit}
        render={renderForm}
        subscription={{ submitting: true, pristine: true }}
      />
    </Dialog>
  );
};

export default UnitDialog;
