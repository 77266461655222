import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AdminRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  const loading = useSelector((state) => state.auth.isLoading);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated && !isAdmin && loading) {
          return <Redirect to='/home' />;
        } else if (!isAuthenticated && !loading) {
          return <Redirect to='/login' />;
        } else {
          return <Component {...props} />;
        }
      }}
    />
  );
};

export default AdminRoute;
