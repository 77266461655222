import axios from 'axios';

/**
 * @description Void or refund a payment
 *
 *              `param.data.creditCard` - required if refund a settled payment
 * @param {object} param
 * @param {string} param.id
 * @param {string=} param.userId
 * @param {object} param.data
 * @param {import('../../../utils/authorizenet/utils/setCreditCardPaymentType').CreditCardType=} param.data.creditCard
 */
export const deletePayment = async ({ id, userId, data = {} }) => {
  const response = userId
    ? await axios.delete(`/api/v1/users/${userId}/payments/${id}`, {
        data,
      })
    : await axios.delete(`/api/v1/payments/${id}`, { data });
  return response.data;
};

/**
 * @description Get a hosted payment request token
 * @param {object} param
 * @param {string=} param.userId
 * @param {import('../../../server/utils/authorizenet/getHostedPaymentPageRequest').GetHostedPaymentPageRequestParams} param.data
 */
export const getHostedPaymentPageRequestToken = async ({
  userId,
  data = {},
}) => {
  const response = userId
    ? await axios.post(`/api/v1/users/${userId}/payments/token`, data)
    : await axios.post('/api/v1/payments/token', data);

  return response.data;
};

/**
 * @description Get a payment
 * @param {object} param
 * @param {string} param.id
 * @param {string=} param.userId
 */
export const getPayment = async ({ id, userId }) => {
  const response = userId
    ? await axios.get(`/api/v1/users/${userId}/payments/${id}`)
    : await axios.get(`/api/v1/payments/${id}`);
  return response.data;
};

/**
 * @description Get all payments
 * @param {object} param
 * @param {string=} param.userId
 */
export const getPayments = async ({ userId }) => {
  const response = userId
    ? await axios.get(`/api/v1/users/${userId}/payments`)
    : await axios.get('/api/v1/payments');
  return response.data;
};

export const getPublicClientKey = async ({ userId } = {}) => {
  const response = userId
    ? await axios.get(`/api/v1/users/${userId}/payments/clientKey`)
    : await axios.get('/api/v1/payments/clientKey');
  return response.data;
};

export const syncPayment = async ({ transId }) => {
  const response = await axios.post(`/api/v1/payments/syncPayment/${transId}`);
  return response.data;
};

/**
 * @description Synchronize a payment from authorize.net
 * @param {object} param
 * @param {string} param.id
 * @param {string=} param.userId
 */
export const updatePayment = async ({ id, userId }) => {
  const response = userId
    ? await axios.put(`/api/v1/users/${userId}/payments/${id}`)
    : await axios.put(`/api/v1/payments/${id}`);
  return response.data;
};

/**
 * @description Synchronize user payments from authorize.net
 * @param {object} param
 * @param {string=} param.userId
 */
export const updatePayments = async ({ userId }) => {
  const response = userId
    ? await axios.put(`/api/v1/users/${userId}/payments`)
    : await axios.put(`/api/v1/payments`);
  return response.data;
};

/**
 * @description Synchronize authorize.net transactions to payments from firstSettlementDate till 31 days after
 * @param {object} param
 * @param {'all' | 'settled' | 'unsettled'} param.type
 * @param {object} param.data
 * @param {string=} param.data.firstSettlementDate -  ISO string
 */
export const updatePaymentsFromAuthorize = async ({
  type = 'all',
  data = {},
} = {}) => {
  const response = await axios.put(`/api/v1/payments/sync/${type}`, data);
  return response.data;
};

export default {
  deletePayment,
  getHostedPaymentPageRequestToken,
  getPayment,
  getPayments,
  getPublicClientKey,
  syncPayment,
  updatePayment,
  updatePayments,
  updatePaymentsFromAuthorize,
};
