import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const UserDialog = (props) => {
  const [state, setState] = useState({
    name: '',
    email: '',
  });
  const handleClose = () => {
    props.handleUserClose();
  };

  const handleChange = (e) => {
    e.preventDefault();
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.updateDetails(state);
  };

  return (
    <div>
      <Dialog
        open={props.userOpen}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle>Update User Info</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To update your user info, please update your information here.
          </DialogContentText>
          <TextField
            onChange={handleChange}
            margin='dense'
            label='Name'
            name='name'
            type='text'
            fullWidth
          />
          <TextField
            onChange={handleChange}
            margin='dense'
            label='Email Address'
            name='email'
            type='email'
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          <Button type='submit' onClick={handleSubmit} color='primary'>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default UserDialog;
