import React from 'react';
import { Radios } from 'mui-rff';

const Operation = ({ disabled, readOnly = false }) => {
  return (
    <>
      {readOnly === true ? (
        <Radios
          name='operation'
          required={true}
          disabled={true}
          data={[
            { label: 'Incoming', value: 'INCOMING' },
            { label: 'Outgoing', value: 'OUTGOING' },
          ]}
        />
      ) : (
        <Radios
          name='operation'
          required={true}
          disabled={disabled}
          data={[
            { label: 'Incoming', value: 'INCOMING' },
            { label: 'Outgoing', value: 'OUTGOING' },
          ]}
        />
      )}
    </>
  );
};

export default Operation;
