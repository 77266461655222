import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import actions from './transoutordersActions';

const initialState = {
  isLoading: false,
  error: '',

  isUpdating: false,
  updateError: '',
};

export const createTransOutOrder = createAsyncThunk(
  'transoutorders/createTransOutOrder',
  async (transout, thunkAPI) => {
    return actions
      .createTransOutOrder(transout)
      .then((r) => {
        return r.data;
      })
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

export const getTransOutOrders = createAsyncThunk(
  'transoutorders/getTransOutOrder',
  (params = {}, thunkAPI) => {
    const { id, ...rest } = params;
    if (id) {
      return actions.getTransOutOrdersByUserId(id, rest);
    }
    return actions.getTransOutOrders(rest);
  },
);

export const updateTransOutOrder = createAsyncThunk(
  'transoutorders/updateTransOutOrder',
  async (transout, thunkAPI) => {
    return actions
      .updateTransOutOrder(transout)
      .then((r) => r.data)
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

export const deleteTransOutOrder = createAsyncThunk(
  'transoutorders/deleteTransOutOrder',
  (id, thunkAPI) => {
    return actions
      .deleteTransOutOrder(id)
      .then((r) => r.data)
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

const transoutordersAdapter = createEntityAdapter({
  selectId: (entity) => entity._id,
});

const updatePending = (state, action) => {
  state.isUpdating = true;
  state.updateError = '';
};

const updateError = (state, action) => {
  state.isUpdating = false;
  state.updateError = action.payload.error;
};

const slice = createSlice({
  name: 'transoutorders',
  initialState: transoutordersAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: {
    [createTransOutOrder.pending]: updatePending,
    [createTransOutOrder.rejected]: updateError,
    [createTransOutOrder.fulfilled]: (state, action) => {
      state.isUpdating = false;
      const { _id, ...changes } = action.payload;
      transoutordersAdapter.updateOne(state, { id: _id, changes });
    },

    [getTransOutOrders.pending]: (state, action) => {
      state.isLoading = true;
      state.error = '';
    },
    [getTransOutOrders.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    [getTransOutOrders.fulfilled]: (state, action) => {
      state.isLoading = false;
      const { data } = action.payload;
      state.transoutorders = action.payload;
      transoutordersAdapter.removeAll(state);
      transoutordersAdapter.upsertMany(state, data);
    },
    [updateTransOutOrder.pending]: updatePending,
    [updateTransOutOrder.rejected]: updateError,
    [updateTransOutOrder.fulfilled]: (state, action) => {
      state.isUpdating = false;
      const { _id, ...changes } = action.payload;
      transoutordersAdapter.updateOne(state, { id: _id, changes });
    },
    [deleteTransOutOrder.pending]: updatePending,
    [deleteTransOutOrder.rejected]: updateError,
    [deleteTransOutOrder.fulfilled]: (state, action) => {
      state.isUpdating = false;
      transoutordersAdapter.removeOne(state, action.payload.id);
    },
  },
});

export const createTransOutOrdersSelectors = () =>
  transoutordersAdapter.getSelectors((state) => state.transoutorders);

export const {
  selectById: selectTransoutById,
  selectIds: selectTransoutIds,
  selectEntities: selectTransoutEntities,
  selectAll: selectAllTransouts,
  selectTotal: selectTotalTransouts,
} = transoutordersAdapter.getSelectors((state) => state.transoutorders);

export default slice.reducer;
