import { useCallback, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from '../../../redux/users/usersSlice';
import {
  initiatePayments,
  clearError,
  getPayments,
  updatePayment,
  paymentSelector,
  deletePayment,
} from '../../../redux/payments';

import { updatePaymentsFromAuthorize } from '../../../api/payments';

import { useNotification } from '../../layout/Notifier';

const initialState = {
  initial: false,
  isLoading: false,
  userId: null,
  tableData: [],
  tableDataShouldUpdate: false,
  paymentsIds: null,
  isSyncing: false,
  selectedPayment: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'set-initial':
      return { ...state, initial: action.payload };

    case 'set-is-loading':
      return { ...state, isLoading: action.payload };

    case 'set-user-id':
      return { ...state, userId: action.payload };

    case 'set-table-data':
      return { ...state, tableData: action.payload };

    case 'set-table-data-should-update':
      return { ...state, tableDataShouldUpdate: action.payload };

    case 'set-is-syncing':
      return { ...state, isSyncing: action.payload };

    case 'set-selected-payment':
      return { ...state, selectedPayment: action.payload };

    default:
      return state;
  }
};

const selector = (state) => {
  return {
    isAdmin: state.auth.isAdmin,
    userId: state.auth.user ? state.auth.user._id : '',
    paymentsIsLoading: state.payments.isLoading,
    paymentsIds: paymentSelector.selectIds(state),
    paymentsEntities: paymentSelector.selectEntities(state),
    paymentsError: state.payments.error,
  };
};

const usePayments = () => {
  const {
    isAdmin,
    userId,
    paymentsIsLoading,
    paymentsIds,
    paymentsEntities,
    paymentsError,
  } = useSelector(selector);
  const reduxDispatch = useDispatch();

  const [state, dispatch] = useReducer(reducer, initialState);

  const showNotification = useNotification();

  useEffect(() => {
    if (paymentsError) {
      showNotification(paymentsError, 'error');
      reduxDispatch(clearError());
    }
  }, [paymentsError, showNotification, reduxDispatch]);

  const initialProcess = useCallback(async () => {
    if (isAdmin) {
      await reduxDispatch(getUsers());
    } else {
      dispatch({ type: 'set-user-id', payload: userId });
    }
    dispatch({ type: 'set-initial', payload: true });
  }, [isAdmin, reduxDispatch, userId]);

  useEffect(() => {
    if (!state.initial) {
      initialProcess();
    }
  }, [state.initial, initialProcess]);

  const fetchPayments = useCallback(async () => {
    await reduxDispatch(initiatePayments());
    if (isAdmin && state.userId) {
      await reduxDispatch(getPayments({ userId: state.userId }));
    } else {
      await reduxDispatch(getPayments());
    }
    dispatch({ type: 'set-table-data-should-update', payload: true });
  }, [isAdmin, state.userId, reduxDispatch]);

  useEffect(() => {
    if (state.initial) {
      fetchPayments();
    }
  }, [state.initial, fetchPayments]);

  const generateTableData = useCallback(
    (ids) => {
      if (!ids) return [];
      else {
        return ids.reduce((acc, id) => {
          const payment = paymentsEntities[id];
          if (payment) acc.push({ ...payment });
          return acc;
        }, []);
      }
    },
    [paymentsEntities],
  );

  useEffect(() => {
    if (!paymentsIsLoading && state.tableDataShouldUpdate) {
      dispatch({
        type: 'set-table-data',
        payload: generateTableData(paymentsIds),
      });
      dispatch({ type: 'set-table-data-should-update', payload: false });
    }
  }, [
    paymentsIsLoading,
    state.tableDataShouldUpdate,
    generateTableData,
    paymentsIds,
  ]);

  const onUserChange = (newUserId) => {
    dispatch({ type: 'set-user-id', payload: newUserId });
  };

  const onSyncAuthorizeNet = async () => {
    dispatch({ type: 'set-is-syncing', payload: true });
    await updatePaymentsFromAuthorize().catch((error) => {
      showNotification(error.message, 'error');
    });
    await fetchPayments();
    dispatch({ type: 'set-is-syncing', payload: false });
  };

  const onSyncAPayment = useCallback(
    async (event, data) => {
      await reduxDispatch(
        updatePayment({
          id: data._id,
          userId: isAdmin
            ? state.userId
              ? state.userId
              : data.user._id
            : null,
        }),
      );
      dispatch({ type: 'set-table-data-should-update', payload: true });
    },
    [reduxDispatch, isAdmin, state.userId],
  );

  const onRowClick = (event, data) => {
    dispatch({ type: 'set-selected-payment', payload: data });
  };

  const onCleanSelectedPayment = () => {
    dispatch({ type: 'set-selected-payment', payload: null });
  };

  const onVoidPayment = useCallback(async () => {
    if (state.selectedPayment) {
      await reduxDispatch(
        deletePayment({
          id: state.selectedPayment._id,
          userId: isAdmin
            ? state.userId
              ? state.userId
              : state.selectedPayment.user._id
            : null,
        }),
      );
      dispatch({ type: 'set-table-data-should-update', payload: true });
    }
  }, [state.selectedPayment, reduxDispatch, isAdmin, state.userId]);

  const onRefundPayment = useCallback(
    async (data = {}) => {
      if (state.selectedPayment) {
        await reduxDispatch(
          deletePayment({
            id: state.selectedPayment._id,
            userId: isAdmin
              ? state.userId
                ? state.userId
                : state.selectedPayment.user._id
              : null,
            data,
          }),
        );
        dispatch({ type: 'set-table-data-should-update', payload: true });
      }
    },
    [state.selectedPayment, reduxDispatch, isAdmin, state.userId],
  );

  return {
    isAdmin,
    ...state,
    isLoading: !state.initial || paymentsIsLoading || state.isLoading,
    onUserChange,
    onSyncAuthorizeNet,
    onSyncAPayment,
    onRowClick,
    onCleanSelectedPayment,
    onVoidPayment,
    onRefundPayment,
  };
};

export default usePayments;
