import { useCallback, useEffect, useReducer, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNotification } from '../../layout/Notifier';
import useErrorHandler from '../../../hooks/useErrorHandler';
import { useHistory } from 'react-router-dom';

import orderSelector from './orderSelector';

/**
 * @typedef State
 * @property {Boolean} editDialogOpen
 * @property {Boolean} orderDialogOpen
 * @property {Object | null} currentOrder
 */
const initialState = {
  editDialogOpen: false,
  deleteDialogOpen: false,
  completeDialogOpen: false,
  orderDialogOpen: false,
  currentOrder: null,
};

/**
 * @typedef {Object} ActionBoolean
 * @property {'set-edit-dialog-open' | 'set-order-dialog-open'} type
 * @property {Boolean} payload
 */
/**
 * @typedef {Object} ActionCurrentOrder
 * @property {'set-current-order'} type
 * @property {Object | null} payload
 */
/**
 * @typedef {ActionBoolean | ActionCurrentOrder} Action
 */
/**
 * @param {State} state
 * @param {Action} action
 * @returns {State}
 */
const reducer = (state, action) => {
  switch (action.type) {
    case 'set-edit-dialog-open':
      return { ...state, editDialogOpen: action.payload };

    case 'set-order-dialog-open':
      return { ...state, orderDialogOpen: action.payload };

    case 'set-delete-dialog-open':
      return { ...state, deleteDialogOpen: action.payload };

    case 'set-complete-dialog-open':
      return { ...state, completeDialogOpen: action.payload };

    case 'set-current-order':
      return { ...state, currentOrder: action.payload };

    default:
      return state;
  }
};

/**
 * @typedef UseOrderTableParam
 * @property {import('./orderSelector').TableType} tableType
 * @property {String=} userId
 */
/**
 * @param {UseOrderTableParam} param
 */
const useOrderTable = ({ tableType, userId, completed }) => {
  const tableRef = useRef();
  const reduxDispatch = useDispatch();
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const showNotification = useNotification();
  const {
    title,
    orders,
    productName,
    isLoading,
    isUpdating,
    updateErrorSelector,
    errorSelector,
    getOrders,
    updateOrder,
    deleteOrder,
  } = useSelector(orderSelector(tableType));

  useErrorHandler(updateErrorSelector);
  useErrorHandler(errorSelector);

  useEffect(() => {
    if (userId) reduxDispatch(getOrders({ id: userId }));
    else reduxDispatch(getOrders());
  }, [userId, reduxDispatch, getOrders]);

  const handleOrderUpdate = useCallback(
    async (data, { onSuccess = () => {} } = {}) => {
      const response = await reduxDispatch(updateOrder(data));
      if (/fulfilled$/.test(response.type)) {
        if (typeof onSuccess === 'function') onSuccess();
        showNotification(
          `You have successfully updated the ${title} Order`,
          'success',
        );
      }
    },
    [reduxDispatch, updateOrder, showNotification, title],
  );

  const handleOrderDelete = useCallback(
    async (data, { onSuccess = () => {} } = {}) => {
      const response = await reduxDispatch(deleteOrder(data._id));
      if (/fulfilled$/.test(response.type)) {
        if (typeof onSuccess === 'function') onSuccess();
        showNotification(
          `You have successfully deleted the ${title} Order`,
          'success',
        );
        handleDialogClose('delete-dialog')();
      }
    },
    [reduxDispatch, deleteOrder, showNotification, title],
  );

  const handleOrderUpdateStatus = useCallback(
    async (data, newStatus) => {
      await handleOrderUpdate({ ...data, status: newStatus });
    },
    [handleOrderUpdate],
  );

  const handleOrderComplete = useCallback(
    (data) => {
      handleOrderUpdateStatus(data, 'Completed');
      handleDialogClose('complete-dialog')();
    },
    [handleOrderUpdateStatus],
  );

  /**
   * @param {'Approved' | 'Canceled' | 'Completed' | 'Pending' | 'Rejected' } newStatus
   */

  const onClickEdit = async (data) => {
    dispatch({ type: 'set-current-order', payload: data });
    dispatch({ type: 'set-edit-dialog-open', payload: true });
  };

  const onClickPick = async (data) => {
    dispatch({ type: 'set-current-order', payload: data });
    dispatch({ type: 'set-order-dialog-open', payload: true });
  };

  const onClickVerify = async (data) => {
    dispatch({ type: 'set-current-order', payload: data });
    dispatch({ type: 'set-order-dialog-open', payload: true });
  };

  const onClickDelete = async (data) => {
    dispatch({ type: 'set-current-order', payload: data });
    dispatch({ type: 'set-delete-dialog-open', payload: true });
  };

  const onClickComplete = async (data) => {
    dispatch({ type: 'set-current-order', payload: data });
    dispatch({ type: 'set-complete-dialog-open', payload: true });
  };

  /**
   * @param {'edit-dialog' | 'order-dialog'} type
   */
  const handleDialogClose = (type) => () => {
    if (type === 'edit-dialog') {
      dispatch({ type: 'set-edit-dialog-open', payload: false });
    } else if (type === 'order-dialog') {
      dispatch({ type: 'set-order-dialog-open', payload: false });
    } else if (type === 'delete-dialog') {
      dispatch({ type: 'set-delete-dialog-open', payload: false });
    } else if (type === 'complete-dialog') {
      dispatch({ type: 'set-complete-dialog-open', payload: false });
    }
  };

  const handleDialogUpdate = async (data) => {
    await handleOrderUpdate(
      { ...data },
      {
        onSuccess: () => {
          handleDialogClose('edit-dialog')();
        },
      },
    );
  };

  const handlePick = useCallback(
    async (event) => {
      event.preventDefault();
      const pickedOrder = {
        ...state.currentOrder,
        status: tableType === 'transfer_in' ? 'Verified' : 'Picked',
      };
      await handleOrderUpdate(pickedOrder, {
        onSuccess: () => {
          handleDialogClose('order-dialog')();
        },
      });
    },
    [state.currentOrder, tableType, handleOrderUpdate],
  );

  const handleDownloadPDF = (data) => {
    if (!!data.pdfUrl) {
      window.open(data.pdfUrl, '_blank');
    }
  };

  const handleDownloadPS = (data) => {
    if (!!data.packingslip) {
      window.open(data.packingslip, '_blank');
    }
  };

  const onClickShipment = useCallback(
    async (data) => {
      history.push('/shipment/book', data);
    },
    [history],
  );

  const filterData = (data, status) => {
    const orders = data.map((order) => ({ ...order, tableData: {} }));

    const completeOrders = orders.filter(
      (order) => order.status === 'Completed',
    );
    const incompleteOrders = orders.filter(
      (order) => order.status !== 'Completed',
    );

    return status ? completeOrders : incompleteOrders;
  };

  return {
    ...state,
    tableRef,
    title: completed ? `${title} - Completed` : `${title} - In Process`,
    data: filterData(orders, completed),
    isLoading: isLoading || isUpdating,
    productName,
    handleOrderUpdateStatus,
    handleOrderDelete,
    handleOrderComplete,
    onClickEdit,
    onClickPick,
    onClickVerify,
    handleDialogUpdate,
    handleDialogClose,
    handlePick,
    handleDownloadPDF,
    handleDownloadPS,
    onClickShipment,
    onClickDelete,
    onClickComplete,
  };
};

export default useOrderTable;
