import axios from 'axios';

export const DEFAULT_CONFIG = {
  headers: {
    'Content-Type': 'application/json',
  },
};

/**
 * @param {Object} data
 * @param {String} data.api_key
 * @param {String} data.api_pass
 * @param {String} data.api_secret
 * @param {String} data.shopName
 */
export const createMarketplace = async (data) => {
  const response = await axios.post(
    '/api/v1/marketplaces',
    data,
    DEFAULT_CONFIG,
  );
  return response.data;
};

/**
 * @param {String} id
 */
export const deleteMarketplace = async (id) => {
  const response = await axios.delete(`/api/v1/marketplaces/${id}`);
  return response.data;
};

/**
 * @param {String=} userId
 */
export const getMarketplaces = async (userId) => {
  const response = userId
    ? await axios.get(`/api/v1/users/${userId.toString()}/marketplaces`)
    : await axios.get('/api/v1/marketplaces');
  return response.data;
};

export const getAllMarketplaces = async () => {
  const response = await axios.get('/api/v1/marketplaces/all');
  return response.data;
};

/**
 * @param {String} id
 * @param {Object} data
 * @param {String=} data.api_key
 * @param {String=} data.api_pass
 * @param {String=} data.api_secret
 * @param {String=} data.shopName
 */
export const updateMarketplace = async (id, data = {}) => {
  const response = await axios.put(
    `/api/v1/marketplaces/${id}`,
    data,
    DEFAULT_CONFIG,
  );
  return response.data;
};

export default {
  createMarketplace,
  deleteMarketplace,
  getMarketplaces,
  getAllMarketplaces,
  updateMarketplace,
};
