import { useCallback, useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';

import { useNotification } from '../../layout/Notifier';

import { uploadFootage } from '../../../redux/camera/cameraSlice';

/**
 * @typedef {Object} PickMapperItem
 * @property {String} id
 * @property {String} barcode
 * @property {String} description
 * @property {String} sku
 * @property {Number} quantity
 * @property {Number} picked
 */
/**
 * @typedef {Object} State
 * @property {Record<string, PickMapperItem> | null} pickedMapper
 * @property {Number} allPickedCount
 */

/**
 * @type {State}
 */
const initialState = {
  pickedMapper: null,
  allPickedCount: 0,
};

/**
 * @typedef ActionInitialize
 * @property {'initialize'} type
 */
/**
 * @typedef ActionPickMapper
 * @property {'set-pick-mapper'} type
 * @property {Record<string, PickMapperItem>} payload
 */
/**
 * @typedef {ActionInitialize | ActionPickMapper} Action
 */

/**
 * @param {State} state
 * @param {Action} action
 * @returns {State}
 */
const reducer = (state, action) => {
  switch (action.type) {
    case 'initialize':
      return initialState;

    case 'set-pick-mapper':
      return { ...state, pickedMapper: action.payload };

    case 'pick-product':
      const id = action.payload;
      const pickedItem = state.pickedMapper[id];
      pickedItem.picked++;

      return {
        ...state,
        pickedMapper: { ...state.pickedMapper, [id]: pickedItem },
        allPickedCount:
          state.allPickedCount +
          (pickedItem.picked < pickedItem.quantity ? 0 : 1),
      };

    case 'pick-all-products':
      const ids = action.payload;
      const pickedItems = state.pickedMapper[ids];
      pickedItems.picked = pickedItems.quantity;

      return {
        ...state,
        pickedMapper: { ...state.pickedMapper, [ids]: pickedItems },
        allPickedCount:
          state.allPickedCount +
          (pickedItems.picked < pickedItems.quantity ? 0 : 1),
      };

    default:
      return state;
  }
};

const usePickDialog = ({ order, products = [], productName }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const showNotification = useNotification();
  const reduxDispatch = useDispatch();

  useEffect(() => {
    if (!!order) {
      /** @type {Record<string, PickMapperItem>} */
      const pickList = products.reduce((acc, product) => {
        const id = product[productName]?._id;
        if (!id) return acc;
        /** @type {PickMapperItem} */
        const item = {
          id,
          barcode: product[productName].barcode,
          description: product[productName].description || '',
          sku: product[productName].sku,
          quantity: product.quantity,
          picked: 0,
        };
        return { ...acc, [id]: item };
      }, {});
      dispatch({ type: 'set-pick-mapper', payload: pickList });
    } else {
      dispatch({ type: 'initialize' });
    }
  }, [order, products, productName]);

  const onScan = useCallback(
    (barcode) => {
      try {
        const foundPickedProduct = Object.values(state.pickedMapper).find(
          (item) => item.barcode === barcode,
        );

        // Product not found from the list
        if (!foundPickedProduct) {
          throw new Error(
            'Scanned product not found. Please confirm barcodes match or find the correct product and try again.',
          );
        }

        // Check over pick
        if (foundPickedProduct.picked >= foundPickedProduct.quantity) {
          throw new Error('You scanned extra item.');
        }

        // Pick product
        dispatch({ type: 'pick-product', payload: foundPickedProduct.id });
      } catch (error) {
        showNotification(error.message, 'error');
      }
    },
    [state.pickedMapper, showNotification],
  );

  const onProductClick = useCallback(
    (id) => {
      try {
        const foundPickedProduct = Object.values(state.pickedMapper).find(
          (item) => item.id === id,
        );

        // Product not found from the list
        if (!foundPickedProduct) {
          throw new Error('Incorrect product scanned');
        }

        // Check over pick
        if (foundPickedProduct.picked >= foundPickedProduct.quantity) {
          throw new Error('Item already scanned');
        }

        // Pick product
        dispatch({ type: 'pick-all-products', payload: foundPickedProduct.id });
      } catch (error) {
        showNotification(error.message, 'error');
      }
    },
    [state.pickedMapper, showNotification],
  );

  const onScanError = () => {
    showNotification('Error scanning product. Try again', 'error');
  };

  const onCapture = useCallback(
    /**
     * @param {FormData} formData
     */
    (formData) => {
      formData.append('orderId', order?._id.toString());
      reduxDispatch(uploadFootage(formData));
    },
    [order, reduxDispatch],
  );

  return {
    ...state,
    allPicked:
      state.pickedMapper &&
      Object.keys(state.pickedMapper).length === state.allPickedCount,
    onScan,
    onProductClick,
    onScanError,
    onCapture,
  };
};

export default usePickDialog;
