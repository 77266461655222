import React from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => {
  const dotSize = 0.3;
  return createStyles({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    text: {
      marginTop: theme.spacing(),
      color: 'white',
    },
    loadingDotAnimation: {
      display: 'inline-block',
      verticalAlign: 'bottom',
      width: `${dotSize * 3}rem`,
      overflow: 'hidden',

      animation: '3s',
      animationIterationCount: 'infinite',
      animationName: '$dots',
      animationTimingFunction: 'step-start',
    },
    '@keyframes dots': {
      '0%': { width: 0, marginRight: `${dotSize * 3}rem` },
      '16.5%': { width: `${dotSize * 1}rem`, marginRight: `${dotSize * 2}rem` },
      '33%': { width: `${dotSize * 2}rem`, marginRight: `${dotSize * 1}rem` },
      '49.5%': { width: `${dotSize * 3}rem`, marginRight: 0 },
      '66%': { width: `${dotSize * 2}rem`, marginRight: `${dotSize * 1}rem` },
      '82.5%': { width: `${dotSize * 1}rem`, marginRight: `${dotSize * 2}rem` },
      '100%': { width: 0, marginRight: `${dotSize * 3}rem` },
    },
  });
});

const LoadingModal = ({
  className,
  isLoading,
  modalProps = {},
  circularProgressProps = {},
  text = '',
  typographyProps = {},
}) => {
  const classes = useStyles();

  const hasDots = /\.{3}$/.test(text);

  return (
    <Modal open={isLoading} {...modalProps}>
      <div className={clsx(classes.root, className)}>
        <CircularProgress size={48} {...circularProgressProps} />
        {!!text && (
          <Typography
            variant='h6'
            {...typographyProps}
            className={clsx(classes.text, typographyProps.className)}
          >
            {!hasDots ? (
              text
            ) : (
              <>
                {text.replace(/\.{3}$/, '')}
                <span className={classes.loadingDotAnimation}>...</span>
              </>
            )}{' '}
          </Typography>
        )}
      </div>
    </Modal>
  );
};

export default LoadingModal;
