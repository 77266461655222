import { useCallback, useEffect, useReducer } from 'react';

import { uploadFile } from '../../api/upload';

import { initialState, reducer } from './reducer';

/**
 * Hook to upload file to s3
 * @param {Object} param
 * @param {String} param.accessKeyId
 * @param {String} param.secretAccessKey
 * @param {String} param.bucketName
 */
const useUploadS3 = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // Process next data
  useEffect(() => {
    if (state.pending.length > 0 && state.processing === null) {
      dispatch({ type: 'process-next' });
    }
  }, [state.pending.length, state.processing]);

  const uploadProcessingData = useCallback(async () => {
    try {
      const formData = new FormData();
      formData.append('file', state.processing);
      const { url } = await uploadFile(formData);
      dispatch({ type: 'process-completed', url });
    } catch (error) {
      dispatch({ type: 'process-failed', error });
    }
  }, [state.processing]);
  useEffect(() => {
    if (state.processing !== null) {
      uploadProcessingData();
    }
  }, [state.processing, uploadProcessingData]);

  /**
   * Push data to pending queue
   * @param {File} data
   */
  const uploadData = (file) => {
    if (!!file) {
      dispatch({ type: 'push-to-pending', payload: file });
    }
  };

  /**
   * @param {'pending' | 'completed' | 'failed'} type
   */
  const clean = (type) => () => {
    dispatch({ type: `clean-${type}` });
  };

  /**
   * @param {'pending' | 'completed' | 'failed'} type
   * @returns {(index: Number) => void}
   */
  const remove = (type) => (index) => {
    dispatch({ type: `remove-${type}`, payload: index });
  };

  return {
    ...state,
    uploadData,
    cleanPending: clean('pending'),
    cleanCompleted: clean('completed'),
    cleanFailed: clean('failed'),
    removePending: remove('pending'),
    removeCompleted: remove('completed'),
    removeFailed: remove('failed'),
  };
};

export default useUploadS3;
