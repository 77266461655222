import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { image_one } from '../../../../assets/ship';

const useStyles = makeStyles((theme) => ({
  hero: {
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      height: '40vh',
      maxHeight: 1300,
    },
  },
  container_text: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '75%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      height: 'auto',
      textAlign: 'center',
      padding: theme.spacing(2),
    },
  },
  header: {
    borderBottom: 'solid thin white',
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      fontWeight: 'bold',
    },
  },
  img: {
    display: 'block',
    width: '100%',
    height: '40vh',
    objectFit: 'cover',
    filter: 'brightness(50%)',
  },
}));

const Hero = () => {
  const classes = useStyles();

  return (
    <div className={classes.hero}>
      <img className={classes.img} src={image_one} alt='' />
      <div className={classes.container_text}>
        <Typography variant='h4' className={classes.header} gutterBottom>
          Shipping Quote
        </Typography>
        <Typography variant='body1' gutterBottom>
          We offer shipping services to the Southwestern region of the United
          States. Try out our shipping quote generator to find your cost for
          shipping with Fillstorship.
        </Typography>
      </div>
    </div>
  );
};

export default Hero;
