import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

const DeleteDialog = ({ currentOrder, open, handleClose, onClickDelete }) => {
  const deleteOrder = () => {
    onClickDelete(currentOrder);
  };

  return (
    <Dialog open={open}>
      <DialogTitle>{`Delete Order #${currentOrder?.order_no}?`}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          Confirm deletion of the order. This process cannot be reversed.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='secondary' autoFocus>
          Cancel
        </Button>
        <Button onClick={deleteOrder} style={{ color: 'red' }} autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
