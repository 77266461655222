import { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { resetPassword } from '../../../../redux/auth/authSlice';

const useResetForm = ({ token, onSuccess }) => {
  const isUpdating = useSelector(
    /**
     * @param {import('../../../../redux/store').RootState} state
     */
    (state) => state.auth.isUpdating,
  );
  const dispatch = useDispatch();

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const onFormSubmit = useCallback(
    async ({ password }, form) => {
      await dispatch(resetPassword({ password, token })).then((response) => {
        if (/fulfilled$/.test(response.type)) {
          if (typeof onSuccess === 'function') onSuccess();
          else {
            setPasswordVisible(false);
            setConfirmPasswordVisible(false);
            setTimeout(form.restart);
          }
        }
      });
      return null;
    },
    [dispatch, token, onSuccess],
  );

  const handlePasswordVisible = () => {
    setPasswordVisible((prev) => !prev);
  };

  const handleConfirmPasswordVisible = () => {
    setConfirmPasswordVisible((prev) => !prev);
  };

  return {
    isLoading: isUpdating,
    passwordVisible,
    confirmPasswordVisible,
    onFormSubmit,
    handlePasswordVisible,
    handleConfirmPasswordVisible,
  };
};

export default useResetForm;
