/**
 * @typedef {Object} Product
 * @property {String} description
 * @property {String} sku
 * @property {Number} unitQty
 * @property {String} lotNum
 * @property {String} notes
 */

/** @returns {Product} */
export const getDefaultProduct = () => {
  return { description: '', sku: '', unitQty: '', lotNum: '', notes: '' };
};

export const DEFAULT_PRODUCT = getDefaultProduct();

/**
 * @param {Product} product
 */
export const isEqualDefaultProduct = ({
  description = '',
  sku = '',
  unitQty = '',
  lotNum = '',
  notes = '',
}) => {
  return (
    description === DEFAULT_PRODUCT.description &&
    sku === DEFAULT_PRODUCT.sku &&
    unitQty === DEFAULT_PRODUCT.unitQty &&
    lotNum === DEFAULT_PRODUCT.lotNum &&
    notes === DEFAULT_PRODUCT.notes
  );
};
