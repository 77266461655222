import React from 'react';

import ReadOnlyTextField from './ReadOnlyTextField';

const SettlementInformation = ({ transaction }) => {
  return (
    <>
      <ReadOnlyTextField
        label='Settlement Amount'
        value={transaction.settleAmount}
      />
      {transaction.batch && (
        <ReadOnlyTextField
          label='Settlement Date'
          value={transaction.batch.settlementTimeUTC}
          type='date'
        />
      )}
    </>
  );
};

export default SettlementInformation;
