import { numbersOnlyParser } from '../../../../utils/parsers';

export const getFieldList = (type) => [
  {
    type: 'text',
    gridProps: { xs: 12, sm: 4 },
    name: 'carrier2',
    label: 'Carrier',
    required: false,
  },
  {
    type: 'text',
    gridProps: { xs: 12, sm: 4 },
    name: 'service',
    label: 'Service',
  },
  {
    type: 'text',
    gridProps: { xs: 12, sm: 4 },
    name: 'trackingNumber',
    label: 'Tracking Number',
  },
  {
    type: 'text',
    gridProps: { xs: 12, sm: 3 },
    name: 'shipperCost',
    label: 'Shipper Cost',
  },
  {
    type: 'text',
    gridProps: { xs: 12, sm: 3 },
    name: 'shipmentCost',
    label: 'Shipment Cost',
  },
  {
    type: 'text',
    gridProps: { xs: 12, sm: 3 },
    name: 'noOfPallets',
    label: 'No. of Pallets',
    fieldProps: { parse: numbersOnlyParser },
  },
  {
    type: 'text',
    gridProps: { xs: 12, sm: 3 },
    name: 'totalWeight',
    label: 'Total Weight (lbs)',
    fieldProps: { parse: numbersOnlyParser },
  },
  {
    type: 'date',
    gridProps: { xs: 12, sm: 6 },
    name: 'shipDate',
    label: 'Ship Date',
  },
  {
    type: 'date',
    gridProps: { xs: 12, sm: 6 },
    name: 'deliveryDate',
    label: 'Delivery Date',
  },
  {
    type: 'text',
    gridProps: { xs: 12, sm: 6 },
    name: 'instructions',
    label: 'Delivery Instructions',
    multiline: true,
    rows: 1,
  },
  {
    type: 'text',
    gridProps: { xs: 12, sm: 6 },
    name: 'additionalComments',
    label: 'Additional Comments',
    multiline: true,
    rows: 1,
  },
];

export const DEFAULT_ADD_SHIPPING_INFO = {
  carrier2: '',
  service: '',
  trackingNumber: '',
  shipperCost: '',
  shipmentCost: '',
  shipDate: '',
  deliveryDate: '',
  noOfPallets: '',
  totalWeight: '',
  instructions: '',
  additionalComments: '',
};
