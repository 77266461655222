import parseFloatNumber from '../../../../utils/parseFloatNumber';

import { getDefaultProduct, isEqualDefaultProduct } from '../Products';

export const DEFAULT_HANDLING_UNIT_TYPE = [
  'Pallet',
  'Bag',
  'Bale',
  'Box',
  'Bundle',
  'Carton',
  'Case',
  'Crate',
  'Cylinder',
  'Drum',
  'Pail',
  'Piece',
  'Reel',
  'Roll',
  'Skid',
  'Tank',
  'Tote',
  'Trailer',
  'Tube',
];

/**
 * @typedef {Object} HandlingUnit
 * @property {String} type
 * @property {Number} quantity
 * @property {Number} weight
 * @property {Number} totalWeight
 * @property {Number} length
 * @property {Number} width
 * @property {Number} height
 * @property {String} freightClass
 * @property {Boolean} stackable
 * @property {import('../Products').Product[]} products
 */

/** @returns {HandlingUnit} */
export const getDefaultHandlingUnit = () => {
  return {
    type: '',
    quantity: '',
    weight: '',
    totalWeight: '',
    length: '',
    width: '',
    height: '',
    freightClass: '',
    stackable: false,
    products: [getDefaultProduct(), getDefaultProduct()],
  };
};
export const DEFAULT_HANDLING_UNIT = getDefaultHandlingUnit();

/**
 *
 * @param {HandlingUnit} handlingUnit
 */
export const isEqualDefaultHandlingUnitTextField = ({
  type = '',
  quantity = '',
  weight = '',
  length = '',
  width = '',
  height = '',
}) => {
  const checkType = !!type ? type : DEFAULT_HANDLING_UNIT.type;

  return (
    checkType === DEFAULT_HANDLING_UNIT.type &&
    quantity === DEFAULT_HANDLING_UNIT.quantity &&
    weight === DEFAULT_HANDLING_UNIT.weight &&
    length === DEFAULT_HANDLING_UNIT.length &&
    width === DEFAULT_HANDLING_UNIT.width &&
    height === DEFAULT_HANDLING_UNIT.height
  );
};
/**
 *
 * @param {HandlingUnit} handlingUnit
 */
export const isEqualDefaultHandlingUnit = ({
  type = '',
  quantity = '',
  weight = '',
  length = '',
  width = '',
  height = '',
  products = [],
}) => {
  const isProductsDefault = products.reduce((acc, product) => {
    if (!acc) return false;
    return isEqualDefaultProduct(product);
  }, true);

  return (
    isProductsDefault &&
    isEqualDefaultHandlingUnitTextField({
      type,
      quantity,
      weight,
      length,
      width,
      height,
    })
  );
};

export const getFreightClass = ({
  totalWeight,
  quantity,
  length,
  width,
  height,
}) => {
  totalWeight = parseFloatNumber(totalWeight) || 0;
  quantity = parseInt(quantity) || 0;
  length = parseFloatNumber(length) || 0;
  width = parseFloatNumber(width) || 0;
  height = parseFloatNumber(height) || 0;

  const freightDensity =
    totalWeight / quantity / ((length * width * height) / 1728) || 0;

  if (freightDensity < 1) return 500;
  else if (1 <= freightDensity && freightDensity < 2) return 400;
  else if (2 <= freightDensity && freightDensity < 3) return 300;
  else if (3 <= freightDensity && freightDensity < 4) return 250;
  else if (4 <= freightDensity && freightDensity < 5) return 200;
  else if (5 <= freightDensity && freightDensity < 6) return 175;
  else if (6 <= freightDensity && freightDensity < 7) return 150;
  else if (7 <= freightDensity && freightDensity < 8) return 125;
  else if (8 <= freightDensity && freightDensity < 9) return 110;
  else if (9 <= freightDensity && freightDensity < 10.5) return 100;
  else if (10.5 <= freightDensity && freightDensity < 12) return 92.5;
  else if (12 <= freightDensity && freightDensity < 13.5) return 85;
  else if (13.5 <= freightDensity && freightDensity < 15) return 77.5;
  else if (15 <= freightDensity && freightDensity < 22.5) return 70;
  else if (22.5 <= freightDensity && freightDensity < 30) return 65;
  else if (30 <= freightDensity && freightDensity < 35) return 60;
  else if (35 <= freightDensity && freightDensity < 50) return 55;
  else if (50 <= freightDensity) return 50;
};
