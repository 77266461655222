import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import actions from './weeklyReportsActions';

const initialState = {
  isLoading: false,
  error: '',

  isUpdating: false,
  updateError: '',
};

export const getWeeklyReports = createAsyncThunk(
  'weeklyreports/getWeeklyReports',
  (params = {}) => {
    const { id } = params;
    return actions.getWeeklyReports(id);
  },
);

export const deleteReport = createAsyncThunk(
  'weeklyreports/deleteReports',
  (id, thunkAPI) => {
    return actions
      .deleteReport(id)
      .then((r) => r.data)
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

const weeklyReportsAdapter = createEntityAdapter({
  selectId: (entity) => entity._id,
});

const slice = createSlice({
  name: 'weeklyreports',
  initialState: weeklyReportsAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: {
    [getWeeklyReports.pending]: (state) => {
      state.isLoading = true;
      state.error = '';
    },
    [getWeeklyReports.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    [getWeeklyReports.fulfilled]: (state, action) => {
      state.isLoading = false;
      const { data } = action.payload;
      state.reports = action.payload;
      weeklyReportsAdapter.removeAll(state);
      weeklyReportsAdapter.upsertMany(state, data);
    },
    [deleteReport.pending]: (state) => {
      state.isLoading = true;
      state.error = '';
    },
    [deleteReport.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    [deleteReport.fulfilled]: (state, action) => {
      state.isUpdating = false;
      state.isLoading = false;
      weeklyReportsAdapter.removeOne(state, action.payload.id);
    },
  },
});

export const createWeeklyReportsSelectors = () => {
  return weeklyReportsAdapter.getSelectors((state) => state.weeklyreports);
};

export default slice.reducer;
