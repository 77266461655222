import React from 'react';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'mui-rff';

const UnitForm = ({ disabled, mode, isAdmin }) => {
  const setMode = (mode) => {
    switch (mode) {
      case 'create':
        return false;
      case 'update':
        return true;
      default:
        return;
    }
  };

  const readOnly = (isAdmin, mode) => {
    // if Admin and create, edit
    if (isAdmin === true && mode === 'create') return false;
    // if Admin and update, no edit
    else if (isAdmin === true && mode === 'update') return true;
    // if User and create, edit
    else if (isAdmin === false && mode === 'create') return false;
    // if User and update, no edit
    else if (isAdmin === false && mode === 'update') return true;
  };

  const fields = [
    {
      spacing: 4,
      name: 'sku',
      label: 'SKU',
      required: true,
      InputProps: { readOnly: !isAdmin && setMode(mode) },
    },
    {
      spacing: 4,
      name: 'barcode',
      label: 'Barcode',
      required: true,
    },
    {
      spacing: 4,
      name: 'description',
      label: 'Description',
      required: true,
    },
    {
      spacing: 6,
      name: 'soNum',
      label: 'SO Number',
      required: false,
    },
    {
      spacing: 6,
      name: 'lotNum',
      label: 'Lot Number',
      required: false,
    },
    {
      spacing: 3,
      name: 'loose_qty',
      label: 'Loose Quantity',
      required: true,
      InputProps: { readOnly: !isAdmin && setMode(mode) },
    },
    {
      spacing: 3,
      name: 'case_qty',
      label: 'Case Quantity',
      required: true,
      InputProps: { readOnly: readOnly(isAdmin, mode) },
    },
    {
      spacing: 3,
      name: 'pallet_qty',
      label: 'Pallet Quantity',
      required: true,
      InputProps: { readOnly: readOnly(isAdmin, mode) },
    },
    {
      spacing: 3,
      name: 'total',
      label: 'Total',
      InputProps: { readOnly: readOnly(isAdmin, mode) },
    },
  ];

  return (
    <Grid container spacing={2}>
      {fields.map(({ spacing, name, label, required, ...props }, index) => (
        <Grid item xs={spacing} key={index}>
          <TextField
            disabled={disabled}
            label={label}
            name={name}
            variant='outlined'
            InputLabelProps={{ shrink: true }}
            margin='dense'
            required={required}
            {...props}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default UnitForm;
