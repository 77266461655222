import axios from 'axios';

const DEFAULT_CONFIG = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const createTask = (task) => {
  return axios.post('/api/v1/tasks', task, DEFAULT_CONFIG).then((r) => r.data);
};

const getTasks = () => {
  return axios.get('/api/v1/tasks').then((r) => r.data);
};

const deleteTask = (id) => {
  return axios.delete(`/api/v1/tasks/${id}`).then((r) => {
    return r.data;
  });
};

export default {
  createTask,
  getTasks,
  deleteTask,
};
