import React, { useState } from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';

const pagesList = [
  { page: 'Home', route: '/' },
  { page: 'Fill', route: '/fill' },
  { page: 'Stor', route: '/stor' },
  { page: 'Ship', route: '/ship' },
  { page: 'About', route: '/about' },
  { page: 'Contact', route: '/contact' },
];

const MiniDrawer = () => {
  const useStyles = makeStyles((theme) => ({
    list: { width: '30vw' },
    fullList: { width: 'auto' },
  }));

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role='presentation'
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {pagesList.map((page, index) => (
          <ListItem button key={index}>
            <Link
              to={page.route}
              style={{
                color: 'black',
                textDecoration: 'none',
              }}
            >
              <ListItemText primary={page.page} />
            </Link>
          </ListItem>
        ))}
      </List>
    </div>
  );
  const classes = useStyles();
  const [state, setState] = useState({ left: false });

  const toggleDrawer = (anchor, open) => () => {
    setState({ ...state, [anchor]: open });
  };

  return (
    <Box display={{ sm: 'none' }}>
      <Button onClick={toggleDrawer('left', true)}>
        <MenuIcon style={{ color: 'white' }} />
      </Button>
      <Drawer
        anchor={'left'}
        open={state['left']}
        onClose={toggleDrawer('left', false)}
      >
        {list('left')}
      </Drawer>
    </Box>
  );
};

export default MiniDrawer;
