import axios from 'axios';

const DEFAULT_CONFIG = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const getCases = () => {
  return axios.get('/api/v1/cases').then((r) => {
    return r.data;
  });
};

export const getCasesByUserId = (userId) => {
  return axios.get(`/api/v1/users/${userId.toString()}/cases`).then((r) => {
    return r.data;
  });
};

export const createCase = (newCase) => {
  return axios
    .post(`/api/v1/cases`, newCase, DEFAULT_CONFIG)
    .then((r) => r.data);
};

export const updateCase = (newCase) => {
  return axios
    .put(`/api/v1/cases/${newCase._id}`, newCase, DEFAULT_CONFIG)
    .then((r) => r.data);
};

export const deleteCase = (id) => {
  return axios.delete(`/api/v1/cases/${id}`).then((r) => r.data);
};

export default {
  getCases,
  getCasesByUserId,
  createCase,
  updateCase,
  deleteCase,
};
