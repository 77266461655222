import React from 'react';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import moment from 'moment';

import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

import ShipmentForm from '../../forms/ShipmentForm_v2';

const ReadOnlyTextField = ({ label, value = '' }) => {
  return (
    <TextField
      value={value}
      label={label}
      fullWidth
      variant='outlined'
      size='small'
      InputLabelProps={{ shrink: true }}
      InputProps={{ readOnly: true }}
    />
  );
};

const ShipmentsTableDetailPanel = ({ data }) => {
  const renderForm = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ShipmentForm readOnly />
        </Grid>
      </Grid>
    );
  };

  return (
    <Container component={Box} maxWidth='xl' padding={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <ReadOnlyTextField
            label='Created At'
            value={moment(data.createdAt).format('YYYY-MM-DD HH:mm') || ''}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ReadOnlyTextField
            label='Updated At'
            value={moment(data.updatedAt).format('YYYY-MM-DD HH:mm') || ''}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ReadOnlyTextField
            label='Originator'
            value={data?.originator?.name || ''}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ReadOnlyTextField
            label='Bill To'
            value={data?.billTo?.company || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <Form
            initialValues={data || {}}
            onSubmit={() => {}}
            mutators={arrayMutators}
            render={renderForm}
            subscription={{ initialValues: true }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ShipmentsTableDetailPanel;
