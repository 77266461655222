import React, { useState, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Wrapper from '../../../wrappers/Wrapper';
import Footer from '../../../layout/Footer';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hero from './Hero';
import ShipmentQuoteDialog from '../../../dialogs/ShipmentQuoteDialog';
import { getQuote } from '../../../../redux/quote';
import { Form } from 'react-final-form';
import { User, LoadDetails } from './Items';
import { initialValues, validate } from './helpers';
import Typography from '@material-ui/core/Typography';
import LoadDetailsForm from '../../../forms/ShipmentForm/LoadDetailsForm';
import { TextField, Checkboxes } from 'mui-rff';

const useStyles = makeStyles((theme) => ({
  user: {
    marginTop: theme.spacing(3),
    width: '60%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    border: 'solid thin lightgrey',
    borderRadius: '5px',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  container: {
    width: '60%',
    textAlign: 'center',
    borderRadius: '5px',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1),
    border: 'solid thin lightgrey',
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const Ship = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openConfirm, setOpenConfirm] = useState(false);

  const [shipment, setShipment] = useState({
    quote: null,
  });

  const handleOpen = () => setOpenConfirm(true);
  const handleClose = () => setOpenConfirm(false);

  const onSubmit = async (data) => {
    dispatch(getQuote(data))
      .then((data) => setShipment({ ...shipment, quote: data.payload }))
      .catch((error) => console.error(error));
    handleOpen();
  };

  return (
    <Fragment>
      <Wrapper>
        <Hero />
        <Form
          onSubmit={onSubmit}
          validate={validate}
          initialValues={initialValues}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <div className={classes.user}>
                <Grid container item justify='center'>
                  <Grid item xs={12}>
                    <Typography variant='h5' align='center'>
                      Shipper
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      style={{ borderRadius: '5px' }}
                      label='Zip Code'
                      name='shipperZip'
                      variant='outlined'
                      required
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid container item justify='center'>
                    {User.shipper.map((props, index) => {
                      return <Checkboxes key={index} {...props} />;
                    })}
                  </Grid>
                </Grid>
                <Grid container item justify='center'>
                  <Grid item xs={12}>
                    <Typography variant='h5' align='center'>
                      Receiver
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      style={{ borderRadius: '5px' }}
                      label='Zip Code'
                      name='receiverZip'
                      variant='outlined'
                      required
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid container item justify='center'>
                    {User.receiver.map((props, index) => {
                      return <Checkboxes key={index} {...props} />;
                    })}
                  </Grid>
                </Grid>
              </div>
              <Grid container justify='center'>
                <Grid container className={classes.container}>
                  {LoadDetails.map((item, index) => (
                    <Grid item xs={item.size} key={index}>
                      {item.field}
                    </Grid>
                  ))}
                  <Grid item xs={12}>
                    <LoadDetailsForm />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container justify='center'>
                <Button
                  className={classes.button}
                  type='button'
                  variant='contained'
                  onClick={form.reset}
                  disabled={submitting || pristine}
                >
                  Reset
                </Button>
                <Button
                  className={classes.button}
                  color='primary'
                  variant='contained'
                  type='submit'
                  disabled={submitting}
                >
                  Get Quote
                </Button>
              </Grid>
            </form>
          )}
        />
        {shipment.quote !== null && (
          <ShipmentQuoteDialog
            openConfirm={openConfirm}
            handleClose={handleClose}
            handleOpen={handleOpen}
            quote={shipment.quote}
            user='guest'
          />
        )}
        <Footer />
      </Wrapper>
    </Fragment>
  );
};

export default Ship;
