import React from 'react';

import Grid from '@material-ui/core/Grid';

import { renderField } from '../fields';

import { fieldList } from './utils';

const General = ({ disabled, readOnly = false }) => {
  return (
    <Grid container spacing={2} justify='center' alignItems='center'>
      {fieldList.map(({ name, gridProps = {}, ...fieldProps }) => (
        <Grid key={name} item {...gridProps}>
          {renderField({ ...fieldProps, name, disabled, readOnly })}
        </Grid>
      ))}
    </Grid>
  );
};

export default General;
