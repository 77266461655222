import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { selectAllCartonizes } from '../../../redux/cartonizeorders/cartonizeordersSlice';
import { selectAllPalletizes } from '../../../redux/palletizeorders/palletizeordersSlice';
import { selectAllTransins } from '../../../redux/transinorders/transinordersSlice';
import { selectAllTransouts } from '../../../redux/transoutorders/transoutordersSlice';

const OrderSelection = ({
  label,
  onChange,
  autocompleteProps = {},
  ...props
}) => {
  const cartonize = useSelector(selectAllCartonizes);
  const palletize = useSelector(selectAllPalletizes);
  const transin = useSelector(selectAllTransins);
  const transout = useSelector(selectAllTransouts);

  const [value, setValue] = useState(null);
  const ordersArr = [cartonize, palletize, transin, transout];
  let orders = [].concat(...ordersArr).sort((a, b) => b.order_no - a.order_no);

  useEffect(() => {
    setValue(null);
  }, [orders]);

  const onAutoChange = (event, newValue) => {
    setValue(value);
    onChange(newValue);
  };

  return (
    <Autocomplete
      autoHighlight
      disableClearable
      getOptionLabel={(option) =>
        option ? `${option?.order_no} - ${option?.billTo?.company}` : ''
      }
      onChange={onAutoChange}
      options={orders || []}
      value={value}
      {...autocompleteProps}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant='outlined'
          InputLabelProps={{ ...params.InputLabelProps, shrink: true }}
          placeholder='Order #'
          {...props}
        />
      )}
    />
  );
};

export default OrderSelection;
