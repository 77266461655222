import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';

import caseActions from './casesActions';

const initialState = {
  isLoading: false,
  error: '',

  isUpdating: false,
  updateError: '',

  pagination: {
    page: 1,
    totalPages: 1,
    totalCount: 0,
  },
};

export const createCase = createAsyncThunk(
  'cases/createCase',
  async (newCase, thunkAPI) => {
    return caseActions
      .createCase(newCase)
      .then((r) => {
        return r.data;
      })
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

export const getCases = createAsyncThunk(
  'cases/getCases',
  (params = {}, thunkAPI) => {
    const { id, ...rest } = params;
    if (id) {
      return caseActions.getCasesByUserId(id, rest);
    }
    return caseActions.getCases(rest);
  },
);

export const updateCase = createAsyncThunk(
  'cases/updateCase',
  async (newCase, thunkAPI) => {
    return caseActions
      .updateCase(newCase)
      .then((r) => r.data)
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

export const deleteCase = createAsyncThunk(
  'cases/deleteCase',
  async (id, thunkAPI) => {
    return caseActions
      .deleteCase(id)
      .then((r) => r.data)
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

const casesAdapter = createEntityAdapter({
  selectId: (entity) => entity._id,
});

const updatePending = (state, action) => {
  state.isUpdating = true;
  state.updateError = '';
};

const updateError = (state, action) => {
  state.isUpdating = false;
  state.updateError = action.payload.error;
};

export const clearCases = (state, action) => {
  state.ids = [];
  state.entities = [];
};

const slice = createSlice({
  name: 'cases',
  initialState: casesAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: {
    [createCase.pending]: updatePending,
    [createCase.rejected]: updateError,
    [createCase.fulfilled]: (state, action) => {
      state.isUpdating = false;
      state.ids = [action.payload._id, ...state.ids];
      state.entities[action.payload._id] = action.payload;
    },

    [getCases.pending]: (state, action) => {
      state.isLoading = true;
      state.error = '';
    },
    [getCases.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    [getCases.fulfilled]: (state, action) => {
      state.isLoading = false;
      const { data } = action.payload;
      state.cases = action.payload;
      casesAdapter.removeAll(state);
      casesAdapter.upsertMany(state, data);
    },
    [updateCase.pending]: updatePending,
    [updateCase.rejected]: updateError,
    [updateCase.fulfilled]: (state, action) => {
      state.isUpdating = false;
      const { _id, ...changes } = action.payload;
      casesAdapter.updateOne(state, { id: _id, changes });
    },
    [deleteCase.pending]: updatePending,
    [deleteCase.rejected]: updateError,
    [deleteCase.fulfilled]: (state, action) => {
      state.isUpdating = false;
      casesAdapter.removeOne(state, action.payload.id);
    },
  },
});

export const createCasesSelectors = () => {
  return casesAdapter.getSelectors((state) => state.cases);
};

export const {
  selectById: selectCaseById,
  selectIds: selectCaseIds,
  selectEntities: selectCaseEntities,
  selectAll: selectAllCases,
  selectTotal: selectTotalCases,
} = casesAdapter.getSelectors((state) => state.cases);

export default slice.reducer;
