import React from 'react';
import { styled } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { MdRemove } from 'react-icons/md';

import { renderField } from '../fields';

const RedButton = styled(Button)({
  color: '#f44336',
  '&:hover': {
    backgroundColor: 'rgba(244, 67, 54, 0.04)',
  },
});

const Product = ({
  name: parentName,
  productName,
  disabled,
  readOnly,
  onRemove,
}) => {
  const fieldList = [
    {
      type: 'readOnlyText',
      gridProps: {},
      label: 'SKU #',
      name: `${productName}.sku`,
    },
    {
      type: 'text',
      gridProps: {},
      name: 'quantity',
      label: 'Quantity',
      required: true,
    },
    { type: 'text', gridProps: {}, name: 'itemNum', label: 'Item #' },
    { type: 'text', gridProps: {}, name: 'lotNum', label: 'Lot #' },
    { type: 'text', gridProps: {}, name: 'notes', label: 'Notes' },
  ];

  return (
    <Grid container spacing={1}>
      {fieldList.map(({ name, gridProps = {}, ...fieldProps }, index) => (
        <Grid key={`${parentName}.${name}.${index}`} item {...gridProps}>
          {renderField({
            ...fieldProps,
            name: `${parentName}.${name}`,
            disabled,
            readOnly,
          })}
        </Grid>
      ))}
      {!readOnly && (
        <Grid item component={Box} display='flex'>
          <RedButton
            disabled={disabled}
            onClick={onRemove}
            size='small'
            startIcon={<MdRemove />}
          >
            Remove
          </RedButton>
        </Grid>
      )}
    </Grid>
  );
};

export default Product;
