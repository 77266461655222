import axios from 'axios';

const DEFAULT_CONFIG = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const getUsers = () => {
  return axios.get('/api/v1/users').then((r) => {
    return r.data;
  });
};

const createUser = (user) => {
  return axios.post('/api/v1/users', user, DEFAULT_CONFIG).then((r) => {
    return r.data;
  });
};

const updateUser = (user) => {
  return axios
    .put(`/api/v1/users/${user._id}`, user, DEFAULT_CONFIG)
    .then((r) => {
      return r.data;
    });
};

const deleteUser = (id) => {
  return axios.delete(`/api/v1/users/${id}`).then((r) => {
    return r.data;
  });
};

export default {
  getUsers,
  createUser,
  updateUser,
  deleteUser,
};
