import axios from 'axios';

const DEFAULT_CONFIG = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const sendContactEmail = (formData) => {
  return axios
    .post('/api/v1/contactemail', formData, DEFAULT_CONFIG)
    .then((r) => {
      return r.data;
    });
};

export default { sendContactEmail };
