import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

import DashWrapper from '../../wrappers/DashWrapper';

import WooCommerceTable from '../../tables/WooCommerceTable';

import useWooCommerceOrders from './useWooCommerceOrders';

const useStyles = makeStyles((theme) =>
  createStyles({
    circularProgressContainer: {
      width: '100%',
      minHeight: '50vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    paper: {
      minHeight: '30vh',
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    warningIcon: {
      fontSize: 50,
      marginBottom: theme.spacing(2),
    },
  }),
);
const WooCommerceOrders = () => {
  const classes = useStyles();
  const { isLoading, isAdmin, hasEcommerce } = useWooCommerceOrders();

  return (
    <DashWrapper>
      {isLoading ? (
        <div className={classes.circularProgressContainer}>
          <CircularProgress size={48} />
        </div>
      ) : !isAdmin && !hasEcommerce ? (
        <Paper variant='outlined' className={classes.paper}>
          <WarningRoundedIcon className={classes.warningIcon} />
          <Typography variant='h6' align='center' gutterBottom>
            Woo Commerce market place is not connected.
          </Typography>
          <Typography variant='h6' align='center' gutterBottom>
            Please integrate Woo Commerce API keys under Profile settings.
          </Typography>
        </Paper>
      ) : (
        <WooCommerceTable />
      )}
    </DashWrapper>
  );
};

export default WooCommerceOrders;
