import React from 'react';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import moment from 'moment';

const ReadOnlyTextField = ({
  fullWidth = true,
  label,
  margin = 'dense',
  value,
  variant = 'outlined',
  inputProps = {},
  type,
  ...props
}) => {
  if (type === 'date') value = moment(value).format('YYYY-MM-DD, hh:mm A');
  return (
    <TextField
      fullWidth={fullWidth}
      inputProps={{
        readOnly: true,
        ...inputProps,
      }}
      margin={margin}
      value={value}
      variant={variant}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>{label}</InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

export default ReadOnlyTextField;
