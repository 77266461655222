import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import {
  image_one,
  image_two,
  image_three,
  image_four,
  image_five,
  image_six,
  image_seven,
  image_eight,
} from '../../../../assets/services';

const styles = (theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: 'white',
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 55,
  },
  title: {
    marginBottom: theme.spacing(5),
    fontWeight: '300',
  },
});

const services = [
  { src: image_one, alt: 'fulfillment', label: 'Fulfillment' },
  { src: image_two, alt: 'graph', label: 'Storage' },
  { src: image_three, alt: 'shipping', label: 'Shipping' },
  { src: image_four, alt: 'clock', label: 'Kitting + Assembly' },
];

const services2 = [
  { src: image_five, alt: 'fulfillment', label: 'Packaging Solutions' },
  { src: image_six, alt: 'graph', label: 'Ecommerce Processing' },
  { src: image_seven, alt: 'shipping', label: 'Distribution' },
  { src: image_eight, alt: 'clock', label: 'Consulting' },
];

const Services = (props) => {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <Typography variant='h5' marked='center' className={classes.title}>
          Our Services
        </Typography>
        <Grid container spacing={5}>
          {services.map((service, index) => (
            <Grid item xs={12} md={3} key={index}>
              <div className={classes.item}>
                <img
                  className={classes.image}
                  src={service.src}
                  alt={service.alt}
                />
                <Typography variant='subtitle1' className={classes.title}>
                  {service.label}
                </Typography>
              </div>
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={5}>
          {services2.map((service, index) => (
            <Grid item xs={12} md={3} key={index}>
              <div className={classes.item}>
                <img
                  className={classes.image}
                  src={service.src}
                  alt={service.alt}
                />
                <Typography variant='subtitle1' className={classes.title}>
                  {service.label}
                </Typography>
              </div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </section>
  );
};

Services.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Services);
