import React, { Fragment } from 'react';
import Wrapper from '../../wrappers/Wrapper';
import Footer from '../../../components/layout/Footer';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import {
  image_one,
  image_two,
  image_three,
  image_four,
  image_five,
  image_six,
  image_seven,
  image_eight,
  image_nine,
  image_ten,
  image_eleven,
  image_twelve,
  image_thirteen,
} from '../../../assets/about';

const images = [
  [
    { src: image_one, size: 2 },
    { src: image_two, size: 2 },
    { src: image_three, size: 2 },
    { src: image_four, size: 2 },
    { src: image_five, size: 2 },
    { src: image_six, size: 2 },
  ],
  [
    { src: image_seven, size: 2 },
    { src: image_eight, size: 2 },
    { src: image_nine, size: 2 },
    { src: image_ten, size: 2 },
    { src: image_eleven, size: 2 },
    { src: image_twelve, size: 2 },
  ],
];

const info = [
  {
    title: 'About Fillstorship',
    description: `Fillstorship is providing manufacturing experience to create
      turnkey solutions including fulfillment, warehousing, and
      distribution via ecommerce solutions in one place. Our goal is
      to allow companies a place to launch new products and grow their
      business, while staying focused on their product. We will handle
      the fulfillment and distribution. As many big box retailers
      close their doors, more are opening for small businesses. You
      can reach new customers through social media outlets and the new
      storefront is your webpage. Instagram, Facebook, or whatever
      channels are used to get attention to your product can result in
      sales directly from your webpage. Our experience in
      manufacturing has showed us the success and errors of others,
      and we have learned to be efficient, cost effective with
      environmentally cautious practices. It’s the localized feel of
      your farmers market going from farm to fork, and we are now in a
      manufacturer direct to consumer market.`,
    details: [
      { info1: 'Founded:', info2: '2017' },
      { info1: 'Fulfillment Coverage:', info2: 'All 50 U.S states' },
      { info1: 'Shipping Coverage:', info2: 'Southwestern United States' },
      {
        info1: 'Storage Location:',
        info2: `Ontario, California and La Verne,
      California`,
      },
    ],
  },
  {
    title: 'What Makes us Unique?',
    description: `Fillstorship combines our experience in manufacturing and
      warehousing with ecommerce order processing so your products and
      kits can be fulfilled during the ecommerce order process. The
      results are faster turn time to customer, reduced warehousing
      cost, reduced shrinkage loss, and less handling charges.
      Fillstorship also caters to the smaller start-up business. There
      is no minimum monthly requirements, experience in manufacturing
      and distribution, assist in development and processes for your
      product, an extension of your business without the costs of your
      own warehouse and shipping departments.`,
  },
];

const useStyles = makeStyles((theme) => ({
  hero: {
    width: '100%',
    height: '40vh',
    objectFit: 'cover',
    filter: 'brightness(50%)',
    [theme.breakpoints.up('sm')]: {
      height: '40vh',
      maxHeight: 1300,
    },
  },
  images: {
    width: '200px',
    height: '100px',
    border: 'solid thin black',
    backgroundColor: 'white',
    margin: '5px',
  },
  customers: {
    backgroundColor: '#F6F9FB',
    width: '100%',
    padding: theme.spacing(10, 0),
  },
  item: {
    [theme.breakpoints.down('xs')]: {
      order: 1,
    },
  },
  description: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      padding: theme.spacing(2),
    },
  },
  grid: {
    margin: theme.spacing(3, 0),
    width: '100vw',
  },
}));

const About = () => {
  const classes = useStyles();

  const Images = () => {
    return (
      <Fragment>
        <Grid container item justify='center' xs={12}>
          {images[0].map((image, index) => (
            <img
              src={image.src}
              className={classes.images}
              key={index}
              alt=''
            />
          ))}
        </Grid>
        <Grid container item justify='center' xs={12}>
          {images[1].map((image, index) => (
            <img
              src={image.src}
              className={classes.images}
              key={index}
              alt=''
            />
          ))}
        </Grid>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Wrapper>
        <img src={image_thirteen} alt='' className={classes.hero} />
        <Grid container spacing={3} justify='center' className={classes.grid}>
          <Grid item xs={12} sm={5} className={classes.item}>
            <Typography variant='h5' align='center'>
              {info[0].title}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5} className={classes.item}>
            <Typography
              variant='body1'
              align='left'
              className={classes.description}
            >
              {info[0].description}
            </Typography>
            <br />
            <div className={classes.description}>
              {info[0].details.map((detail, index) => (
                <Typography key={index}>
                  <b>{detail.info1}</b> {detail.info2}
                </Typography>
              ))}
            </div>
          </Grid>
          <Grid item xs={12} sm={5} className={classes.item}>
            <Typography variant='h5' align='center'>
              {info[1].title}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={5}
            className={classes.item}
            style={{ width: '75%' }}
          >
            <Typography
              variant='body1'
              align='left'
              className={classes.description}
            >
              {info[1].description}
            </Typography>
          </Grid>
        </Grid>
        <div className={classes.customers}>
          <Typography variant='h4' align='center' paragraph>
            Our Customers
          </Typography>
          <Images />
        </div>
        <Footer />
      </Wrapper>
    </Fragment>
  );
};

export default About;
