import React, { useCallback, useReducer } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';

import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import red from '@material-ui/core/colors/red';

import ReactDraggable from 'react-draggable';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: ({ draggable, scale }) => {
      const common = {
        width: `${50 * scale}%`,
        margin: 'auto',
        borderRadius: theme.spacing(0.75),
        overflow: 'hidden',
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
      };
      return !draggable
        ? { position: 'relative', ...common }
        : {
            position: 'absolute',
            zIndex: 999,
            ...common,
          };
    },
    controller: {
      position: 'absolute',
      top: 0,
      right: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.25)',
      borderRadius: theme.spacing(),
      overflow: 'hidden',
    },
    recordingIcon: {
      color: red[500],
      marginRight: 'auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& > svg': {
        animationDuration: '1.5s',
        animationIterationCount: 'infinite',
        animationName: '$blinker',
        animationTimingFunction: theme.transitions.easing.easeIn,
      },
    },
    '@keyframes blinker': {
      '50%': {
        opacity: 0.25,
      },
    },
  }),
);

const initialState = {
  draggable: false,
  position: { x: 0, y: 0 },
  scale: 1,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'switch-draggable':
      return { ...state, draggable: !state.draggable };

    case 'set-position':
      return { ...state, position: action.payload };

    case 'set-scale':
      if (action.payload) return { ...state, scale: action.payload };
      else {
        const newScale = state.scale === 1 ? 1.5 : state.scale === 1.5 ? 2 : 1;
        return { ...state, scale: newScale };
      }

    default:
      return state;
  }
};
const WebcamDraggableContainer = ({ children }) => {
  const [{ draggable, position, scale }, dispatch] = useReducer(
    reducer,
    initialState,
  );

  const classes = useStyles({ draggable, scale });

  const onDragging = useCallback(
    (e, ui) => {
      const payload = {
        x: position.x + ui.deltaX,
        y: position.y + ui.deltaY,
      };
      dispatch({ type: 'set-position', payload });
    },
    [position.x, position.y],
  );

  const onDraggableSwitch = useCallback(() => {
    if (draggable) {
      dispatch({ type: 'set-position', payload: { x: 0, y: 0 } });
      dispatch({ type: 'set-scale', payload: 1 });
    }
    dispatch({ type: 'switch-draggable' });
  }, [draggable]);

  const onScaleSwitch = useCallback(() => {
    if (draggable) dispatch({ type: 'set-scale' });
  }, [draggable]);

  return (
    <ReactDraggable
      disabled={!draggable}
      position={position}
      onDrag={onDragging}
      cancel='button'
    >
      <div className={classes.container}>
        {children}
        <Grid
          container
          className={classes.controller}
          spacing={1}
          justify='flex-end'
          alignItems='center'
        >
          <Grid item className={classes.recordingIcon}>
            <FiberManualRecordIcon />
          </Grid>
          <Grid item>
            <Tooltip title={`scale ${scale}x`}>
              <IconButton
                color={draggable ? 'primary' : 'secondary'}
                onClick={onScaleSwitch}
                size='small'
              >
                <AspectRatioIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip
              title={`swtich to ${draggable ? 'undraggable' : 'draggable'}`}
            >
              <IconButton
                color={draggable ? 'primary' : 'secondary'}
                onClick={onDraggableSwitch}
                size='small'
              >
                <FilterNoneIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </div>
    </ReactDraggable>
  );
};

export default WebcamDraggableContainer;
