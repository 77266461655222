import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const ListItems = [
  { label: 'Fill', route: '/fill' },
  { label: 'Stor', route: '/stor' },
  { label: 'Ship', route: '/ship' },
  { label: 'About', route: '/about' },
  { label: 'Contact', route: '/contact' },
];

const Pages = () => {
  const useStyles = makeStyles((theme) => ({
    nav: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
      width: '40%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      textTransform: 'uppercase',
    },
  }));

  const classes = useStyles();
  return (
    <div className={classes.nav}>
      {ListItems.map((item, index) => (
        <Link
          key={index}
          to={item.route}
          style={{ color: '#FFF', textDecoration: 'none' }}
        >
          <Typography component='div'>
            <Box fontSize={12} fontWeight={400} letterSpacing={2}>
              {item.label}
            </Box>
          </Typography>
        </Link>
      ))}
    </div>
  );
};

export default Pages;
