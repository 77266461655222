/**
 * @typedef {Object} Product
 * @property {Object=} unit
 * @property {Object=} case
 * @property {String} quantity
 * @property {String} lotNum
 * @property {String} notes
 */

/** @returns {Product} */
export const getDefaultProduct = () => {
  return { quantity: '', itemNum: '', lotNum: '', notes: '' };
};

export const processProducts = (values, name, productName) => {
  values[name] =
    values[name]?.map((product) => ({
      ...product,
      [productName]: product[productName]._id,
    })) || [];
};
