import React from 'react';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import { Select } from 'mui-rff';

export const User = {
  shipper: [
    {
      name: 'shipperDock',
      data: { label: 'Dock', value: true },
    },
    { name: 'shipperForklift', data: { label: 'Forklift', value: true } },
    { name: 'shipperLiftgate', data: { label: 'Liftgate', value: true } },
    {
      name: 'shipperFloorstack',
      data: { label: 'Floorstack', value: true },
    },
  ],
  receiver: [
    {
      name: 'receiverDock',
      data: { label: 'Dock', value: true },
    },
    {
      name: 'receiverForklift',
      data: { label: 'Forklift', value: true },
    },
    {
      name: 'receiverLiftgate',
      data: { label: 'Liftgate', value: true },
    },
  ],
};

export const LoadDetails = [
  {
    size: 12,
    field: (
      <Typography variant='h5' align='center'>
        Load Details
      </Typography>
    ),
  },
  {
    size: 12,
    field: (
      <Select
        name='packType'
        label='Pack Type'
        variant='outlined'
        required
        style={{ marginBottom: '10px' }}
      >
        <MenuItem value='pallet'>Pallet</MenuItem>
        <MenuItem value='bag'>Bag</MenuItem>
        <MenuItem value='bale'>Bale</MenuItem>
        <MenuItem value='box'>Box</MenuItem>
        <MenuItem value='bundle'>Bundle</MenuItem>
        <MenuItem value='carton'>Carton</MenuItem>
      </Select>
    ),
  },
];
