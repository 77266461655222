import { useCallback, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getDefaultShipmentFormValues,
  produceValues,
  produceShipment,
} from '../../forms/ShipmentForm_v2';

import useErrorHandler from '../../../hooks/useErrorHandler';
import { useNotification } from '../../layout/Notifier';

import { initializeQuote } from '../../../redux/quote/quoteSlice';
import { getUsers, selectAllUsers } from '../../../redux/users/usersSlice';

/**
 * @typedef {Object} State
 * @property {ReturnType<getDefaultShipmentFormValues> | null} initialValues
 * @property {String | null} billTo
 * @property {String | null} originator
 * @property {Object | null} shipment
 * @property {boolean} quoteOpen
 * @property {boolean} successOpen
 */
/** @type {State} */
const initialState = {
  initialValues: null,
  billTo: null,
  originator: null,
  shipment: null,
  quoteOpened: false,
  successOpened: false,
};

/**
 * @typedef {Object} ActionInitialize
 * @property {'initialize'} type
 */
/**
 * @typedef {Object} ActionObject
 * @property {'set-initial-values' | 'set-shipment'} type
 * @property {Object} payload
 */
/**
 * @typedef {Object} ActionString
 * @property {'set-bill-to' | 'set-originator'} type
 * @property {String=} payload
 */
/**
 * @typedef {Object} ActionBoolean
 * @property {'set-quote-opened' | 'set-success-opened'} type
 * @property {Boolean} payload
 */
/**
 * @typedef {ActionInitialize | ActionObject | ActionString | ActionBoolean} Action
 */

/**
 * @param {State} state
 * @param {Action} action
 * @returns {State}
 */
const reducer = (state, action) => {
  switch (action.type) {
    case 'initialize':
      return initialState;

    case 'set-initial-values':
      return {
        ...state,
        initialValues: { ...getDefaultShipmentFormValues(), ...action.payload },
      };

    case 'set-bill-to':
      return { ...state, billTo: action.payload };

    case 'set-originator':
      return { ...state, originator: action.payload };

    case 'set-shipment':
      return { ...state, shipment: action.payload };

    case 'set-quote-opened':
      return { ...state, quoteOpened: action.payload };

    case 'set-success-opened':
      return { ...state, successOpened: action.payload };

    default:
      return state;
  }
};

/**
 * @param {import('../../../redux/store').RootState} state
 */
const selector = (state) => {
  return {
    isAdmin: state.auth.isAdmin,
    users: selectAllUsers(state),
    quote: state.quote,
    isLoading:
      state.shipments.isLoading ||
      state.shipments.isUpdating ||
      state.quote.isLoading,
  };
};

const useEditShipmentDialog = ({ open, handleEditShipment, shipment }) => {
  const reduxDispatch = useDispatch();
  const showNotification = useNotification();

  const { isAdmin, users, quote, isLoading } = useSelector(selector);
  const [state, dispatch] = useReducer(reducer, initialState);

  useErrorHandler((state) => state.shipments.error);
  useErrorHandler((state) => state.shipments.updateError);
  useErrorHandler((state) => state.quote.error?.error);

  useEffect(() => {
    if (open && !!shipment && state.initialValues === null) {
      reduxDispatch(initializeQuote());
      const initialValues = produceShipment(shipment);
      const { billTo, originator } = shipment;
      if (billTo) {
        dispatch({ type: 'set-bill-to', payload: billTo });
      }
      if (originator) {
        dispatch({ type: 'set-originator', payload: originator });
      }
      dispatch({ type: 'set-initial-values', payload: initialValues });
    } else if (!open && state.initialValues !== null) {
      dispatch({ type: 'initialize' });
    }
  }, [open, shipment, state.initialValues, reduxDispatch]);

  // Get Users
  useEffect(() => {
    if (isAdmin) reduxDispatch(getUsers());
  }, [isAdmin, reduxDispatch]);

  const onBillToChange = (event, billTo) => {
    dispatch({ type: 'set-bill-to', payload: billTo });
  };

  const onSubmit = useCallback(
    async (values) => {
      try {
        // Producing Values
        const newShipment = {
          ...shipment,
          ...produceValues(values),
          billTo: state.billTo._id,
          originator: state.originator._id,
          user: state.billTo._id,
        };

        // await reduxDispatch(getQuote(newShipment)).then((response) => {});
        // if (/fulfilled$/.test(response.type)) {}
        dispatch({ type: 'set-shipment', payload: newShipment });
        dispatch({ type: 'set-quote-opened', payload: true });
      } catch (error) {
        showNotification(error.message, 'error');
      }

      return null;
    },
    [shipment, state.billTo, state.originator, showNotification],
  );

  const onQuoteDialogClose = () => {
    dispatch({ type: 'set-quote-opened', payload: false });
  };

  const onSuccessDialogClose = () => {
    dispatch({ type: 'set-initial-values', payload: {} });
    dispatch({ type: 'set-success-opened', payload: false });
  };

  const onClickUpdate = useCallback(async () => {
    // if (quote.quote !== null) {}
    dispatch({ type: 'set-quote-opened', payload: false });
    await handleEditShipment({
      ...state.shipment,
      total: shipment?.total,
    });
  }, [handleEditShipment, shipment, state.shipment]);

  return {
    isAdmin,
    users,
    quote: quote.quote,
    isLoading,
    ...state,
    onBillToChange,
    onSubmit,
    onQuoteDialogClose,
    onSuccessDialogClose,
    onClickUpdate,
  };
};

export default useEditShipmentDialog;
