import axios from 'axios';
import qs from 'qs';

const DEFAULT_CONFIG = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const getShopifyOrders = (params) => {
  return axios
    .get('/api/v1/shopifyorders/', {
      params,
      paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' }),
    })
    .then((r) => {
      return r.data;
    });
};

const getShopifyOrderByUserId = (userId, params) => {
  return axios
    .get(`/api/v1/users/${userId}/shopifyorders`, {
      params,
      paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' }),
    })
    .then((r) => {
      return r.data;
    });
};

const updateShopifyOrder = (order) => {
  return axios
    .put(`/api/v1/shopifyorders/${order._id}`, order, DEFAULT_CONFIG)
    .then((r) => r.data);
};

const deleteShopifyOrder = (id) => {
  return axios.delete(`/api/v1/shopifyorders/${id}`).then((r) => r.data);
};

const downloadShopifyOrders = () => {
  return axios.post('/api/v1/shopifyorders/download').then((r) => r.data);
};

const downloadShopifyOrdersByUserId = (id) => {
  return axios
    .post(`/api/v1/users/${id}/shopifyorders/download`)
    .then((r) => r.data);
};

export default {
  getShopifyOrders,
  updateShopifyOrder,
  deleteShopifyOrder,
  getShopifyOrderByUserId,
  downloadShopifyOrders,
  downloadShopifyOrdersByUserId,
};
