import React from 'react';
import MaterialTable from 'material-table';
import useUnitTable from './useUnitTable';
import UnitDialog from '../../dialogs/UnitDialog';
import History from './History';
import { MdAddBox, MdEdit, MdFlipToFront } from 'react-icons/md';

const UnitTable = ({ onGetUnits, userId, isAdmin, reloadAll }) => {
  const {
    units,
    currentUnit,
    isLoading,
    isUpdating,
    onRowDelete,
    onClickAdd,
    onClickUpdate,
    handleClose,
    opened,
    onDialogSubmit,
    handleBreakCase,
  } = useUnitTable({
    userId,
    onGetUnits,
    reloadAll,
  });

  const actions = [
    {
      icon: MdAddBox,
      isFreeAction: true,
      tooltip: 'Create Unit',
      onClick: onClickAdd,
    },
    {
      icon: MdEdit,
      tooltip: 'Update Unit',
      onClick: onClickUpdate,
    },
    {
      icon: MdFlipToFront,
      hidden: !isAdmin,
      tooltip: 'Break Case',
      onClick: handleBreakCase,
    },
  ];

  const columns = [
    { title: 'SKU', field: 'sku' },
    { title: 'Barcode', field: 'barcode' },
    { title: 'Description', field: 'description' },
    { title: 'SO #', field: 'soNum' },
    { title: 'Lot #', field: 'lotNum' },
    { title: 'Loose Quantity', field: 'loose_qty' },
    { title: 'Case Quantity', field: 'case_qty' },
    { title: 'Pallet Quantity', field: 'pallet_qty' },
    { title: 'Total', field: 'total', editable: 'never' },
  ];

  const options = {
    rowStyle: (data, index) => {
      if (index % 2) return { backgroundColor: '#f7f7f7' };
    },
    exportButton: true,
    exportAllData: true,
    pageSize: 10,
    pageSizeOptions: [5, 10, 20, 30, 50, 75, 100, 200, 500],
  };

  const detailPanels = (data) => {
    return <History data={data} />;
  };

  return (
    <>
      <MaterialTable
        title='Units'
        isLoading={isLoading || isUpdating}
        actions={actions}
        options={options}
        columns={columns}
        data={units}
        detailPanel={detailPanels}
        editable={isAdmin ? { onRowDelete } : null}
        localization={{ header: { actions: '' } }}
      />
      <UnitDialog
        opened={opened}
        isLoading={isLoading || isUpdating}
        onClose={handleClose}
        unit={currentUnit}
        userId={userId}
        onSubmit={onDialogSubmit}
      />
    </>
  );
};

export default UnitTable;
