import React from 'react';

import TextField from '@material-ui/core/TextField';

import DetailPanelContainer from './DetailPanelContainer';

const Notes = ({ notes }) => {
  return (
    <DetailPanelContainer title='Notes'>
      <TextField
        fullWidth
        inputProps={{ readOnly: true }}
        multiline
        size='small'
        rows={5}
        value={notes}
        variant='outlined'
      />
    </DetailPanelContainer>
  );
};

export default Notes;
