import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import fss from '../../api/fss';

const initialState = {
  isCreating: false,
  createError: '',

  isLoading: false,
  loadError: '',

  isDetailsLoading: false,
  detailsLoadError: '',

  isImportRunning: false,
  importError: '',
};

const createImport = createAsyncThunk(
  'importSlice/createImport',
  (params, thunkApi) => {
    return fss
      .createImport(params)
      .catch(err => thunkApi.rejectWithValue(err.response.data));
  }
);

export const getImports = createAsyncThunk(
  'importSlice/getImports',
  ({ userId } = {}, thunkApi) => {
    return fss.getImports(userId);
  }
);

export const getImportDetails = createAsyncThunk(
  'importSlice/getImportDetails',
  ({ importId } = {}, thunkApi) => {
    return fss.getImportDetails(importId);
  }
);

export const runImport = createAsyncThunk(
  'importSlice/runImport',
  ({ importId, userId, items }, thunkApi) => {
    return fss.runImport(importId, userId, items);
  }
);

const importsAdapter = createEntityAdapter({ selectId: item => item._id });
export const createSelectors = () =>
  importsAdapter.getSelectors(state => state.importState);

const importSlice = createSlice({
  name: 'importSlice',
  initialState: importsAdapter.getInitialState(initialState),
  reducers: {
    addImport: (state, action) => {
      const data = action.payload;
      state.ids = [data._id, ...state.ids];
      state.entities[data._id] = data;
    },
    updateImport: (state, action) => {
      importsAdapter.upsertOne(state, action.payload);
    },
  },
  extraReducers: {
    [createImport.pending]: (state, action) => {
      state.isCreating = true;
      state.createError = '';
    },
    [createImport.fulfilled]: (state, action) => {
      state.isCreating = false;
      const { data } = action.payload;
      state.ids = [data._id, ...state.ids];
      state.entities[data._id] = data;
    },
    [createImport.rejected]: (state, action) => {
      state.isCreating = false;
      state.createError = action.payload.error;
    },
    [getImports.pending]: (state, action) => {
      state.isLoading = true;
      state.loadError = '';
    },
    [getImports.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.isLoading = false;
      importsAdapter.removeAll(state);
      importsAdapter.upsertMany(state, data);
    },
    [getImports.rejected]: (state, action) => {
      state.isLoading = false;
      state.loadError = action.error?.message;
    },
    [getImportDetails.pending]: (state, action) => {
      state.isDetailsLoading = true;
      state.detailsLoadError = '';
    },
    [getImportDetails.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.isDetailsLoading = false;
      importsAdapter.upsertOne(state, data);
    },
    [getImportDetails.rejected]: (state, action) => {
      state.isDetailsLoading = false;
      state.detailsLoadError = action.error?.message;
    },
    [runImport.pending]: (state, action) => {
      state.isImportRunning = true;
    },
    [runImport.fulfilled]: (state, action) => {
      state.isImportRunning = false;
      const { data } = action.payload.data;
      importsAdapter.upsertOne(state, data);
    },
    [runImport.rejected]: (state, action) => {
      state.isImportRunning = false;
      state.importError = action.error?.message;
    },
  },
});
export const { addImport, updateImport } = importSlice.actions;
export default importSlice.reducer;
