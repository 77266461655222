import { useCallback, useEffect, useReducer, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  createShipmentsSelectors,
  getShipments,
  updateShipment,
  deleteShipment,
} from '../../../redux/shipments/shipmentsSlice';

import { useNotification } from '../../layout/Notifier';
import useErrorHandler from '../../../hooks/useErrorHandler';

/**
 * @typedef State
 * @property {Boolean} editDialogOpen
 * @property {Object | null} currentOrder
 * @property {Object | null} changeRateOrder
 */
const initialState = {
  editDialogOpen: false,
  deleteDialogOpen: false,
  currentOrder: null,
  changeRateOrder: null,
};

/**
 * @typedef {Object} ActionBoolean
 * @property {'set-edit-dialog-open'} type
 * @property {Boolean} payload
 */
/**
 * @typedef {Object} ActionObject
 * @property {'set-current-order' | 'set-change-rate-order'} type
 * @property {Object | null} payload
 */
/**
 * @typedef {ActionBoolean | ActionObject} Action
 */
/**
 * @param {State} state
 * @param {Action} action
 * @returns {State}
 */
const reducer = (state, action) => {
  switch (action.type) {
    case 'set-edit-dialog-open':
      return { ...state, editDialogOpen: action.payload };

    case 'set-delete-dialog-open':
      return { ...state, deleteDialogOpen: action.payload };

    case 'set-current-order':
      return { ...state, currentOrder: action.payload };

    case 'set-change-rate-order':
      return { ...state, changeRateOrder: action.payload };

    default:
      return state;
  }
};

/**
 * @param {import('../../../redux/store').RootState} state
 */
const selector = (state) => {
  return {
    shipments: createShipmentsSelectors().selectAll(state),
    isLoading: state.shipments.isLoading,
    isUpdating: state.shipments.isUpdating,
  };
};

const updateErrorSelector = (state) => state.shipments.updateError;
const errorSelector = (state) => state.shipments.error;

const useShipmentsTable = ({ userId, completed }) => {
  const tableRef = useRef();
  const reduxDispatch = useDispatch();
  const { shipments, isLoading, isUpdating } = useSelector(selector);
  const [state, dispatch] = useReducer(reducer, initialState);

  const showNotification = useNotification();

  useErrorHandler(updateErrorSelector);
  useErrorHandler(errorSelector);

  useEffect(() => {
    if (userId) reduxDispatch(getShipments({ id: userId }));
    else reduxDispatch(getShipments());
  }, [userId, reduxDispatch]);

  const handleShipmentUpdate = useCallback(
    async (data, { onSuccess = () => {} } = {}) => {
      const response = await reduxDispatch(updateShipment(data));
      if (/fulfilled$/.test(response.type)) {
        if (typeof onSuccess === 'function') onSuccess();
        showNotification(
          'You have successfully updated the shipment',
          'success',
        );
      }
    },
    [reduxDispatch, showNotification],
  );

  const handleShipmentDelete = useCallback(
    async (data, { onSuccess = () => {} } = {}) => {
      const response = await reduxDispatch(deleteShipment(data));
      if (/fulfilled$/.test(response.type)) {
        if (typeof onSuccess === 'function') onSuccess();
        showNotification('You have successfully deleted a shipment', 'success');
      }
      handleDeleteDialogClose();
    },
    [reduxDispatch, showNotification],
  );
  /**
   * @param {'Approved' | 'Canceled' | 'Completed' | 'Pending' | 'In Progress' | 'Rejected' } newStatus
   */
  const handleShipmentUpdateStatus = (newStatus) => async (data) => {
    const order = { ...data };
    if (!!newStatus) order.status = newStatus;
    await handleShipmentUpdate(order);
  };

  const handleChangeRateDialog = (data) => {
    dispatch({ type: 'set-change-rate-order', payload: data });
  };

  const handleEditShipment = async (data) => {
    await handleShipmentUpdate(
      { ...data },
      { onSuccess: handleEditDialogClose },
    );
  };

  const handleEditDialogOpen = (data) => {
    dispatch({ type: 'set-current-order', payload: data });
    dispatch({ type: 'set-edit-dialog-open', payload: true });
  };

  const handleEditDialogClose = () => {
    dispatch({ type: 'set-edit-dialog-open', payload: false });
  };

  const handleDeleteDialogOpen = (data) => {
    dispatch({ type: 'set-current-order', payload: data });
    dispatch({ type: 'set-delete-dialog-open', payload: true });
  };

  const handleDeleteDialogClose = () => {
    dispatch({ type: 'set-delete-dialog-open', payload: false });
  };

  const handleDownloadPDF = (data) => {
    if (!!data.bol) {
      window.open(data.bol, '_blank');
    }
  };

  const filterShipment = (data, status) => {
    const shipments = data.map((shipment) => ({ ...shipment, tableData: {} }));

    const completeShipments = shipments.filter(
      (shipment) => shipment.status === 'Completed',
    );
    const incompleteShipments = shipments.filter(
      (shipment) => shipment.status !== 'Completed',
    );
    return status ? completeShipments : incompleteShipments;
  };

  return {
    tableRef,
    isLoading: isLoading || isUpdating,
    data: filterShipment(shipments, completed),
    ...state,
    handleShipmentUpdate,
    handleShipmentUpdateStatus,
    handleChangeRateDialog,
    handleEditShipment,
    handleEditDialogOpen,
    handleEditDialogClose,
    handleDeleteDialogOpen,
    handleDeleteDialogClose,
    handleDownloadPDF,
    handleShipmentDelete,
  };
};

export default useShipmentsTable;
