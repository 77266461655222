import React from 'react';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import UserDashQuickInfo from '../common/UserDashQuickInfo';
import Divider from '@material-ui/core/Divider';

const selector = (state) => {
  return {
    dashboard: state.dashboard,
    isAdmin: state.auth.isAdmin,
  };
};
const DashboardInfo = () => {
  const { dashboard } = useSelector((state) => state.dashboard);
  const { isAdmin } = useSelector(selector);
  const userList = isAdmin
    ? [
        { label: 'Total Users', value: dashboard.userInfo },
        { label: 'New Orders', value: dashboard.newOrdersNumbersCount },
      ]
    : [];

  return (
    <>
      {userList.map((info, index) => {
        return (
          <Grid item xs={12} key={index}>
            <Divider />
            <UserDashQuickInfo label={info.label} value={info.value} />
          </Grid>
        );
      })}
    </>
  );
};

export default DashboardInfo;
