import React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

import LoadingModal from '../../common/LoadingModal';
import ImportDialog from '../../dialogs/ImportDialog';
import OrderForm from '../../forms/OrderForm_v2';

import BillToSection from './BillToSection';
import SyncButton from './SyncButton';

import useCreateOrder from './useCreateOrder';
import OrderSelection from './OrderSelection';

/**
 * @typedef {Object} CreateOrderProps
 * @property {Function} createOrder
 * @property {('hazardous' | 'LTL' | 'FTL' | 'container')[]} enabledCheckboxs
 * @property {Boolean} isLoadingOrder
 * @property {String} title
 */
/**
 * @param {CreateOrderProps} props
 */
const CreateOrder = ({
  createOrder = () => {},
  enabledCheckboxs = ['hazardous', 'LTL', 'FTL', 'container'],
  isLoadingOrder = false,
  title,
  reduxName,
  tableType,
}) => {
  const {
    isAdmin,
    user,
    initialValues,
    units,
    cases,
    pallets,
    userId,
    bulkOrders,
    isLoadingCSV,
    isLoading,
    onFormSubmit,
    onBulkSubmit,
    onUserIdChange,
    onLoadUnitsFromCSV,
    onLoadCasesFromCSV,
    onLoadBulkOrders,
    handleClose,
    onOrderSelect,
    modal,
  } = useCreateOrder({ createOrder, title, reduxName });

  /**
   * @param {import('react-final-form').FormRenderProps<any>} props
   */
  const renderForm = ({ handleSubmit, form }) => {
    const { submitting } = form.getState();
    const disabledSubmit = isLoadingOrder || isLoading || submitting || !userId;

    return (
      <Grid container spacing={2} component='form' onSubmit={handleSubmit}>
        <Grid item xs={6}>
          <BillToSection
            isAdmin={isAdmin}
            userId={userId}
            onUserIdChange={onUserIdChange}
            user={user}
          />
        </Grid>
        <Grid item xs={6}>
          <OrderSelection
            autocompleteProps={{ disabled: !userId }}
            label='Previous Orders'
            margin='dense'
            onChange={onOrderSelect}
          />
        </Grid>
        <Grid item component={Box} display='flex' flexDirection='row'>
          <Box
            component='span'
            display='flex'
            flexDirection='row'
            alignItems='center'
          >
            <Icon fontSize='small' color='primary'>
              help
            </Icon>
            <Link
              href={
                'https://fssuploads.s3-us-west-1.amazonaws.com/import-order-csv.csv'
              }
            >
              <Typography variant='caption' style={{ padding: '5px' }}>
                Single Order CSV
              </Typography>
            </Link>
            <Link
              href={
                'https://fsstemplates.s3-us-west-1.amazonaws.com/OrdersTemplate.csv'
              }
            >
              <Tooltip
                arrow
                title='Note: MM-DD-YYYY not MM/DD/YYYY. Enter TRUE or FALSE values for [Hazardous, LTL, FTL, Container] fields. Verify SKUs exist in the inventory beforehand.'
              >
                <Typography variant='caption' style={{ padding: '5px' }}>
                  Bulk Orders CSV
                </Typography>
              </Tooltip>
            </Link>
          </Box>
          <SyncButton
            disabled={disabledSubmit}
            onClick={onLoadUnitsFromCSV(form)}
          >
            LOAD Units FROM CSV FILE
          </SyncButton>
          <SyncButton
            disabled={disabledSubmit}
            onClick={onLoadCasesFromCSV(form)}
          >
            LOAD Cases FROM CSV FILE
          </SyncButton>
          <SyncButton
            disabled={disabledSubmit}
            onClick={onLoadBulkOrders(form)}
          >
            BULK IMPORT ORDERS
          </SyncButton>
        </Grid>
        <Grid item xs={12}>
          <OrderForm
            disabled={disabledSubmit}
            units={units}
            cases={cases}
            pallets={pallets}
            reduxName={reduxName}
            tableType={tableType}
            enabledCheckboxs={enabledCheckboxs}
            isAdmin={isAdmin}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            disabled={disabledSubmit}
            color='primary'
            variant='contained'
            type='submit'
          >
            submit
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Form
        initialValues={initialValues}
        mutators={arrayMutators}
        onSubmit={onFormSubmit}
        render={renderForm}
        subscription={{ submitting: true, pristine: true, initialValues: true }}
      />
      <ImportDialog
        handleClose={handleClose}
        onBulkSubmit={onBulkSubmit}
        open={modal}
        orders={bulkOrders}
      />
      <LoadingModal
        isLoading={isLoadingOrder || isLoading || !!isLoadingCSV}
        text={
          isLoadingOrder
            ? 'Creating your order...'
            : isLoading
            ? 'Fetching user list...'
            : !!isLoadingCSV
            ? `Loading csv file ${isLoadingCSV?.file?.name} for ${isLoadingCSV?.type}...`
            : ''
        }
      />
    </>
  );
};

export default CreateOrder;
