import axios from 'axios';

/**
 * @param {FormData} data
 */
export const uploadFile = async (data) => {
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  const response = await axios.post('/api/v1/upload/s3', data, config);
  return response.data;
};

export default { uploadFile };
