import React from 'react';

import ReadOnlyTextField from './ReadOnlyTextField';

const OtherInformation = ({ transaction }) => {
  return (
    <>
      {transaction.tax.amount && (
        <ReadOnlyTextField label='Tax' value={transaction.tax.amount} />
      )}
      {transaction.duty.amount && (
        <ReadOnlyTextField label='Duty' value={transaction.duty.amount} />
      )}
      {transaction.shipping.amount && (
        <ReadOnlyTextField
          label='Shipping'
          value={transaction.shipping.amount}
        />
      )}
    </>
  );
};

export default OtherInformation;
