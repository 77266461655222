import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const Copyright = () => {
  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      {'Copyright ©'} Fillstorship {new Date().getFullYear()}
    </Typography>
  );
};

const footers = [
  {
    title: 'Fillstorship',
    description: [],
  },
  {
    title: 'Services',
    description: [
      { label: 'Fulfillment', to: '/fill' },
      { label: 'Storage', to: '/stor' },
      { label: 'Shipping', to: '/ship' },
    ],
  },
  {
    title: 'Support',
    description: [
      { label: 'Terms of Use', to: 'tos' },
      { label: 'Privacy Policy', to: 'privacypolicy' },
      { label: 'Return Policy', to: 'returnpolicy' },
    ],
  },
  {
    title: 'Contact',
    description: [
      { label: '2356 1st Street', to: '' },
      { label: 'La Verne, CA 91750', to: '' },
      { label: 'Phone: (909) 592-9000', to: '' },
      { label: 'Mon - Sat: 6am - 6pm PST', to: '' },
      { label: 'Sunday: Closed', to: '' },
    ],
  },
];

const useStyles = makeStyles((theme) => ({
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <Fragment>
      <Container maxWidth='md' component='footer' className={classes.footer}>
        <Grid container spacing={4} justify='space-evenly'>
          {footers.map((footer, index) => (
            <Grid item xs={6} sm={3} key={index}>
              <Typography variant='h6' color='textPrimary' gutterBottom>
                {footer.title}
              </Typography>
              <ul>
                {footer.description.map((item, index) => (
                  <Link
                    key={index}
                    to={item.to}
                    style={{ textDecoration: 'none', color: 'black' }}
                  >
                    <Typography>{item.label}</Typography>
                  </Link>
                ))}
              </ul>
            </Grid>
          ))}
        </Grid>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
    </Fragment>
  );
};

export default Footer;
