import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';
import { getDashboard } from '../../redux/dashboard/dashboardSlice';
import DashboardCharts from '../charts/DashboardCharts';
import Orders from '../layout/Orders';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import DashboardInfo from '../layout/DashboardInfo';
import Grid from '@material-ui/core/Grid';
import DashWrapper from '../wrappers/DashWrapper';

const Home = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getDashboard());
  }, [dispatch]);

  return (
    <div>
      <DashWrapper>
        {!!user ? (
          <div>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Paper style={{ height: '100%' }}>
                  <Grid container style={{ height: '100%', padding: '16px' }}>
                    <Grid item xs={12}>
                      <Typography variant='h6'>Hello, {user.name}</Typography>
                    </Grid>
                    <DashboardInfo />
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper style={{ height: '100%' }}>
                  <Orders type='inventory' />
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper style={{ height: '100%' }}>
                  <Orders type='ecommerce' />
                </Paper>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <DashboardCharts />
            </Grid>
          </div>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress color='primary' />
          </div>
        )}
      </DashWrapper>
    </div>
  );
};

export default Home;
