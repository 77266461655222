import React from 'react';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import _ from 'lodash';
import { red, amber, orange, blue, green } from '@material-ui/core/colors';

const statusColor = (status, s) => {
  switch (status) {
    case 'Pending':
      return s.Pending ? (
        <Chip
          label={`${s.Pending} Pending`}
          variant='outlined'
          style={{ color: amber[500], borderColor: amber[500] }}
        />
      ) : null;
    case 'Approved':
      return s.Approved ? (
        <Chip
          label={`${s.Approved} Approved`}
          variant='outlined'
          style={{ color: orange[500], borderColor: orange[500] }}
        />
      ) : null;
    case 'Rejected':
      return s.Rejected ? (
        <Chip
          label={`${s.Rejected} Rejected`}
          variant='outlined'
          style={{ color: red[500], borderColor: red[500] }}
        />
      ) : null;
    case 'Verified':
      return s.Verified ? (
        <Chip
          label={`${s.Verified} Verified`}
          variant='outlined'
          style={{ color: blue[500], borderColor: blue[500] }}
        />
      ) : null;
    case 'Picked':
      return s.Picked ? (
        <Chip
          label={`${s.Picked} Picked`}
          variant='outlined'
          style={{ color: blue[500], borderColor: blue[500] }}
        />
      ) : null;
    case 'Completed':
      return s.Completed ? (
        <Chip
          label={`${s.Completed} Completed`}
          variant='outlined'
          style={{ color: green[500], borderColor: green[500] }}
        />
      ) : null;
    default:
      return null;
  }
};

const sortOrders = (orders) => {
  let sorted = {};

  orders.forEach((order) => {
    if (!sorted[order.status]) {
      sorted[order.status] = 1;
    } else {
      sorted[order.status] += 1;
    }
  });

  return sorted;
};

const Quickview = ({ data }) => {
  let orders = sortOrders(data);

  return (
    <>
      {_.size(data) !== 0 ? (
        <Box display='flex' flexDirection='row'>
          {[
            'Pending',
            'Approved',
            'Picked',
            'Verified',
            'Rejected',
            'Completed',
          ].map((type, index) => {
            return (
              <span style={{ margin: 3 }} key={index}>
                {statusColor(type, orders)}
              </span>
            );
          })}
        </Box>
      ) : null}
    </>
  );
};

export default Quickview;
