import { useCallback, useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { getUnitsByUserId, getUnits } from '../../../redux/units/unitsActions';

const initialState = {
  mode: null,
  userId: null,
  units: [],
  initialValues: {
    sku: '',
    barcode: '',
    case_qty: 0,
    case_weight: 0,
    description: '',
    height: 0,
    length: 0,
    width: 0,
    pallet_qty: 0,
    shelf_qty: 0,
    total: 0,
    soNum: '',
    lotNum: '',
    unit: '',
    units: [],
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'intialize':
      return initialState;

    case 'set-mode':
      return { ...state, mode: action.payload || null };

    case 'set-initial-values':
      return {
        ...state,
        initialValues: action.payload || initialState.initialValues,
      };

    case 'set-user-id':
      return { ...state, userId: action.payload || null };

    case 'set-units':
      return { ...state, units: action.payload };

    default:
      return state;
  }
};

const selector = (state) => {
  return {
    isAdmin: state.auth.isAdmin,
  };
};

const useCaseDialog = ({ opened, onClose, onSubmit, pallet, _userId }) => {
  const { isAdmin } = useSelector(selector);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (opened && !pallet) {
      dispatch({ type: 'set-mode', payload: 'create' });
      dispatch({ type: 'set-initial-values' });
      dispatch({ type: 'set-user-id', payload: _userId || null });
    } else if (opened && !!pallet) {
      dispatch({ type: 'set-mode', payload: 'update' });
      const {
        sku,
        units,
        barcode,
        case_qty,
        case_weight,
        description,
        height,
        length,
        width,
        pallet_qty,
        shelf_qty,
        total,
        lotNum,
        soNum,
        unit,
      } = pallet;
      dispatch({
        type: 'set-initial-values',
        payload: {
          sku,
          units,
          barcode,
          case_qty,
          case_weight,
          description,
          height,
          length,
          width,
          pallet_qty,
          shelf_qty,
          total,
          lotNum,
          soNum,
          unit,
        },
      });
      dispatch({ type: 'set-user-id', payload: pallet.user });
    } else {
      dispatch({ type: 'intialize' });
    }
  }, [opened, pallet, _userId]);

  const loadUnits = useCallback(async () => {
    const response =
      isAdmin && state.userId
        ? await getUnitsByUserId(state.userId)
        : await getUnits();
    dispatch({ type: 'set-units', payload: response.data });
  }, [isAdmin, state.userId]);

  useEffect(() => {
    if (!!state.mode) loadUnits();
  }, [state.mode, state.userId, loadUnits]);

  const onFormSubmit = useCallback(
    async (values, form) => {
      const success = await onSubmit({ ...values, user: state.userId });

      if (!success) return;
      if (state.mode === 'create') {
        setTimeout(form.restart);
      } else if (state.mode === 'update') {
        onClose();
      }
      return;
    },
    [state.mode, state.userId, onClose, onSubmit],
  );

  const onChangeUserId = (userId) => {
    dispatch({ type: 'set-user-id', payload: userId });
    dispatch({ type: 'set-initial-values' });
  };

  return { isAdmin, ...state, onFormSubmit, onChangeUserId };
};

export default useCaseDialog;
