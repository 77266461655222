import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import actions from './tasksActions';

const initialState = {
  isLoading: false,
  error: '',

  isUpdating: false,
  updateError: '',
};

export const createTask = createAsyncThunk(
  'tasks/createTask',
  async (task, thunkAPI) => {
    return actions
      .createTask(task)
      .then((r) => r.data)
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

export const getTasks = createAsyncThunk(
  'tasks/getTasks',
  async (params = {}, thunkAPI) => {
    return actions
      .getTasks()
      .then((r) => r)
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

export const deleteTask = createAsyncThunk(
  'tasks/deleteTask',
  (id, thunkAPI) => {
    return actions
      .deleteTask(id)
      .then((r) => r.data)
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

const tasksAdapter = createEntityAdapter({
  selectId: (entity) => entity._id,
});

const updatePending = (state, action) => {
  state.isUpdating = true;
  state.updateError = '';
};

const updateError = (state, action) => {
  state.isUpdating = false;
  state.updateError = action.payload.error;
};

const slice = createSlice({
  name: 'tasks',
  initialState: tasksAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: {
    [createTask.pending]: updatePending,
    [createTask.rejected]: updateError,
    [createTask.fulfilled]: (state, action) => {
      state.isUpdating = false;
      const { _id, ...changes } = action.payload;
      tasksAdapter.updateOne(state, { id: _id, changes });
    },
    [getTasks.pending]: (state, action) => {
      state.isLoading = true;
      state.error = '';
    },
    [getTasks.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    [getTasks.fulfilled]: (state, action) => {
      state.isLoading = false;
      const { data } = action.payload;
      state.tasks = action.payload;
      tasksAdapter.removeAll(state);
      tasksAdapter.upsertMany(state, data);
    },
    [deleteTask.pending]: updatePending,
    [deleteTask.rejected]: updateError,
    [deleteTask.fulfilled]: (state, action) => {
      state.isUpdating = false;
      tasksAdapter.removeOne(state, action.payload.id);
    },
  },
});

export const tasksSelectors = () => {
  return tasksAdapter.getSelectors((state) => state.tasks);
};

export default slice.reducer;
