import { useForm } from 'react-final-form';

import parseFloatNumber from '../../../../utils/parseFloatNumber';
import { parseDecimal, parseInteger } from '../../../../utils/parsers';

import { loadDetailsList, loadDetailsCheckboxList } from '../fieldList';

const useLoadDetailsForm = () => {
  const form = useForm();

  /**
   * @param {string} numPallets
   * @param {string} weightPerPallet
   */
  const onChangeToTalLoadWeight = (numPallets, weightPerPallet) => {
    if (!numPallets || !weightPerPallet) {
      form.change('totalLoadWeight', null);
    } else {
      const num1 = parseInt(numPallets);
      const num2 = parseFloatNumber(weightPerPallet);
      form.change('totalLoadWeight', parseFloatNumber(num1 * num2));
    }
  };

  /**
   * @param {string} value
   * @param {string} name
   * @returns string
   */
  const parse = (value, name) => {
    if (typeof value !== 'string') return '';
    if (name === 'totalLoadWeight') return value;
    value = value.replace(/-/g, '');
    value = name === 'numPallets' ? parseInteger(value) : parseDecimal(value);
    if (name === 'numPallets' || name === 'weightPerPallet') {
      onChangeToTalLoadWeight(
        name === 'numPallets' ? value : form.getState().values['numPallets'],
        name === 'weightPerPallet'
          ? value
          : form.getState().values['weightPerPallet'],
      );
    }
    return value;
  };

  return {
    list: loadDetailsList,
    checkboxList: loadDetailsCheckboxList,
    parse,
  };
};

export default useLoadDetailsForm;
