import React from 'react';

import TextField from '@material-ui/core/TextField';

import UserAutoCompleteTextField from '../../common/UserAutoCompleteTextField';

const BillToSection = ({ isAdmin, userId, onUserIdChange, user }) => {
  return isAdmin ? (
    <UserAutoCompleteTextField
      FormHelperTextProps={{ error: !userId }}
      helperText={
        !userId ? 'Please select a customer before filling out the form' : null
      }
      hideAll
      label='Bill TO'
      margin='dense'
      onChange={onUserIdChange}
      required
      value={userId}
    />
  ) : (
    <TextField
      fullWidth
      label='Bill TO'
      margin='dense'
      size='small'
      value={user?.name || ''}
      variant='outlined'
      InputProps={{ readOnly: true }}
      InputLabelProps={{ shrink: true }}
      required
    />
  );
};

export default BillToSection;
