import React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { Form } from 'react-final-form';
import { TextField } from 'mui-rff';

import { emailValidation } from '../../../../utils/validations';

import LoadingModal from '../../../common/LoadingModal';

import useForgotForm from './useForgotForm';

const validate = (value) =>
  value && !emailValidation(value) ? 'Invalid Email' : null;

const ForgotForm = ({ onSuccess }) => {
  const { isLoading, onFormSubmit } = useForgotForm({ onSuccess });

  const renderForm = ({ handleSubmit, form }) => {
    const { dirty, submitting } = form.getState();

    return (
      <Grid
        container
        justify='center'
        alignItems='center'
        spacing={4}
        component='form'
        onSubmit={handleSubmit}
      >
        <Grid item xs={12} sm={8}>
          <TextField
            label='Email'
            name='email'
            fieldProps={{ validate }}
            type='email'
            required
          />
        </Grid>
        <Grid
          item
          xs={12}
          component={Box}
          display='flex'
          justifyContent='center'
        >
          <Button
            color='primary'
            disabled={isLoading || !dirty || submitting}
            type='submit'
            variant='contained'
          >
            Send Password Reset Link
          </Button>
        </Grid>
      </Grid>
    );
  };
  return (
    <>
      <Form onSubmit={onFormSubmit} render={renderForm} />
      <LoadingModal
        isLoading={isLoading}
        text='Sending reset password email...'
      />
    </>
  );
};

export default ForgotForm;
