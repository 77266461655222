import { useEffect, useCallback, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import useErrorHandler from '../../../hooks/useErrorHandler';
import { useNotification } from '../../layout/Notifier';
import {
  createUnitsSelectors,
  deleteUnit,
  createUnit,
  selectUnitById,
  updateUnit,
  breakCase,
} from '../../../redux/units/unitsSlice';

const selectUnits = createSelector(createUnitsSelectors().selectAll, (rows) =>
  rows.map((row) => ({ ...row, tableData: {} })),
);

const unitSelector = (id) => (state) => {
  const unit = id ? selectUnitById(state, id) || null : null;
  return unit;
};

const initialState = {
  opened: false,
  currentUnitId: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'open-dialog':
      return { ...state, opened: true };
    case 'close-dialog':
      return { ...state, opened: false };
    case 'set-current-unit-id':
      return { ...state, currentUnitId: action.payload || null };
    default:
      return state;
  }
};

const useUnitTable = ({ userId, onGetUnits, reloadAll }) => {
  const reduxDispatch = useDispatch();
  const units = useSelector(selectUnits);
  const isLoading = useSelector((state) => state.units.isLoading);
  const isUpdating = useSelector((state) => state.units.isUpdating);
  const showNotification = useNotification();
  const [{ currentUnitId, ...state }, dispatch] = useReducer(
    reducer,
    initialState,
  );
  const currentUnit = useSelector(unitSelector(currentUnitId));

  const updateErrorSelector = (state) => state.units.updateError;
  const errorSelector = (state) => state.units.error;

  useErrorHandler(updateErrorSelector);
  useErrorHandler(errorSelector);

  useEffect(() => {
    if (typeof onGetUnits === 'function') {
      onGetUnits();
    }
  }, [onGetUnits, userId]);

  const onClickAdd = (event, data) => {
    dispatch({ type: 'open-dialog' });
  };

  const onClickUpdate = (event, data) => {
    dispatch({ type: 'open-dialog' });
    dispatch({ type: 'set-current-unit-id', payload: data._id });
  };

  const onDialogSubmit = useCallback(
    async (data) => {
      let response = null;
      if (!currentUnit) {
        response = await reduxDispatch(createUnit(data));
      } else {
        response = await reduxDispatch(updateUnit({ ...currentUnit, ...data }));
      }
      if (/fulfilled$/.test(response.type)) {
        if (!currentUnit) {
          showNotification('Unit created.', 'success');
        } else {
          showNotification('Unit updated.', 'success');
        }
        return true;
      }
      return false;
    },
    [currentUnit, reduxDispatch, showNotification],
  );

  const onRowDelete = useCallback(
    async (rowData) => {
      rowData.user = userId;
      await reduxDispatch(deleteUnit(rowData._id));
    },
    [reduxDispatch, userId],
  );

  const handleClose = () => {
    dispatch({ type: 'close-dialog' });
    dispatch({ type: 'set-current-unit-id' });
  };

  const handleBreakCase = useCallback(
    async (event, rowData) => {
      await reduxDispatch(breakCase(rowData._id));
      reloadAll({ pallets: false });
    },
    [reduxDispatch, reloadAll],
  );

  return {
    units,
    currentUnit,
    isLoading,
    isUpdating,
    ...state,
    onClickAdd,
    onClickUpdate,
    onRowDelete,
    handleClose,
    onDialogSubmit,
    handleBreakCase,
  };
};

export default useUnitTable;
