import React from 'react';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const DetailPanelContainer = ({ title, children, actions }) => {
  return (
    <Card>
      <CardContent>
        {title && (
          <Typography variant='h6' gutterBottom>
            {typeof title === 'function' ? title() : title}
          </Typography>
        )}
        {children}
      </CardContent>
      {actions && (
        <CardActions>
          {typeof actions === 'function' ? actions() : actions}
        </CardActions>
      )}
    </Card>
  );
};

export default DetailPanelContainer;
