export const getDefaultProduct = () => {
  return { numOfPallets: '', notes: '' };
};

export const processProducts = (values, name, productName) => {
  values[name] =
    values[name]?.map((product) => ({
      ...product,
      [productName]: product[productName]._id,
    })) || [];
};
