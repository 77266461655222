import React from 'react';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';

const formatDate = (date) => {
  return moment(date).format('MMMM Do YYYY, h:mm:ss a');
};

const updateType = (type) => {
  switch (type) {
    case 'createCase':
      return 'by case creation';
    case 'deleteCase':
      return 'by case deletion';
    case 'updateCase':
      return 'by case update';
    case 'updateUnit':
      return 'by unit update';
    case 'shopify':
      return 'by Shopify order';
    case 'transin':
      return 'by Transfer In order';
    case 'transout':
      return 'by Transfer Out order';
    case 'palletize':
      return 'by Palletize order';
    case 'cartonize':
      return 'by Cartonize order';
    case 'manual':
      return 'by Manual order';
    case undefined || null:
      return '';
    default:
      return '';
  }
};

const History = ({ data }) => {
  const renderOrigin = () => {
    const date = formatDate(data.createdAt);
    const originator = data?.history[0]?.originator?.company;
    return (
      <Box padding={1} margin={1}>
        <Typography color='primary' variant='subtitle1'>
          {originator} {originator ? 'c' : 'C'}reated item on {date}
        </Typography>
        <Divider />
      </Box>
    );
  };

  const renderUpdates = () => {
    const updatesArr = data?.history;

    return updatesArr.map((u, index) => {
      return !u.hasOwnProperty('originator') ? (
        <Box padding={1} margin={1} key={index}>
          <Typography color='primary' variant='subtitle1'>
            {u?.userId?.company} updated {formatDate(u?.timestamp)}{' '}
            {updateType(u?.type)} {u?.order}
          </Typography>
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Typography variant='subtitle2'>SKU: {u.sku}</Typography>
            <Typography variant='subtitle2'>
              Description: {u.description}
            </Typography>
            <Typography variant='subtitle2'>Barcode: {u.barcode}</Typography>
            <Typography variant='subtitle2'>SO#: {u.soNum}</Typography>
            <Typography variant='subtitle2'>Lot#: {u.lotNum}</Typography>
            <Typography variant='subtitle2'>Loose: {u.loose_qty}</Typography>
            <Typography variant='subtitle2'>Case: {u.case_qty}</Typography>
            <Typography variant='subtitle2'>Pallet: {u.pallet_qty}</Typography>
            <Typography>Total: {u.total}</Typography>
          </Box>
          <Divider />
        </Box>
      ) : null;
    });
  };

  return (
    <Container>
      <Typography variant='h5' align='center' color='primary'>
        History ({data?.history.length})
      </Typography>
      <>{renderOrigin()}</>
      <>{renderUpdates()}</>
    </Container>
  );
};
export default History;
