import axios from 'axios';

const DEFAULT_CONFIG = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const loadUser = () => {
  return axios.get('/api/v1/auth/me').then((r) => r.data);
};

const register = (formData) => {
  return axios
    .post('/api/v1/auth/register', formData, DEFAULT_CONFIG)
    .then((r) => r.data);
};

const login = (formData) => {
  return axios
    .post('/api/v1/auth/login', formData, DEFAULT_CONFIG)
    .then((r) => r.data);
};

const logout = () => {
  return axios.get('/api/v1/auth/logout', DEFAULT_CONFIG).then((r) => r.data);
};

const forgotPassword = (formData) => {
  return axios
    .post('/api/v1/auth/forgotpassword', formData, DEFAULT_CONFIG)
    .then((r) => r.data);
};

const resetPassword = (formData) => {
  return axios
    .put(
      `/api/v1/auth/resetpassword/${formData.token}`,
      { password: formData.password },
      DEFAULT_CONFIG,
    )
    .then((r) => r.data);
};

const updateDetails = (formData) => {
  return axios
    .put('/api/v1/auth/updatedetails', formData, DEFAULT_CONFIG)
    .then((r) => r.data);
};

const changePassword = (formData) => {
  return axios
    .put(
      `/api/v1/auth/updatepassword`,
      {
        newPassword: formData.newPassword,
        currentPassword: formData.currentPassword,
      },
      DEFAULT_CONFIG,
    )
    .then((r) => r.data);
};

export default {
  register,
  updateDetails,
  loadUser,
  login,
  logout,
  resetPassword,
  forgotPassword,
  changePassword,
};
