import parseFloatNumber from '../../../utils/parseFloatNumber';

/**
 * @param {Object} param
 * @param {Boolean} param.check
 * @param {Boolean} param.isDecimal
 */
export const numberValidate = ({ check, isDecimal = false, name = '' }) =>
  /**
   * @param {String} value
   */
  (value) => {
    if (!value) return;
    const number = (isDecimal ? parseFloatNumber(value) : parseInt(value)) || 0;
    if (check && !(number > 0)) return `${name} has to be larger than 0.`;
    return;
  };
