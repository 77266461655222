import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import clsx from 'clsx';

import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Tooltip from '@material-ui/core/Tooltip';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import ListItem from './ListItem';
import NestedCreate from './Nested/NestedCreate';
import NestedOrders from './Nested/NestedOrders';
import NestedFulfillment from './Nested/NestedFulfillment';
import SmallCreateMenu from './SmallMenu/SmallCreateMenu';
import SmallOrderMenu from './SmallMenu/SmallOrderMenu';
import SmallFulfillmentMenu from './SmallMenu/SmallFulfillmentMenu';

import useListItems from '../useListItems';

const useStyles = makeStyles((theme) =>
  createStyles({
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    drawerPaper: {
      position: 'relative',
      whiteSpace: 'nowrap',
    },
    drawerPaperOpen: ({ drawerWidth }) => {
      return {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      };
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      width: theme.spacing(7),
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },
  }),
);

const MaterialDashDrawer = ({
  drawerWidth,
  open,
  user,
  isAdmin,
  handleDrawerClose,
  onLogout,
}) => {
  const classes = useStyles({ drawerWidth });
  const {
    createItems,
    ordersItems,
    mainListItems,
    mainListItems2,
    adminListItems,
    fulfillmentItems,
    secondaryListItems,
  } = useListItems({
    onLogout,
  });

  return (
    <Drawer
      variant='permanent'
      classes={{
        paper: clsx(classes.drawerPaper, {
          [classes.drawerPaperOpen]: open,
          [classes.drawerPaperClose]: !open,
        }),
      }}
      open={open}
    >
      <div className={classes.toolbarIcon}>
        <Tooltip title='Close Drawer'>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </Tooltip>
      </div>
      <Divider />
      <List>
        {mainListItems.map((itemProps, index) => (
          <ListItem key={`${itemProps.to}-${index}`} {...itemProps} />
        ))}
        {open ? (
          <NestedFulfillment items={fulfillmentItems} />
        ) : (
          <SmallFulfillmentMenu items={fulfillmentItems} />
        )}
        {mainListItems2.map((itemProps, index) => (
          <ListItem key={`${itemProps.to}-${index}`} {...itemProps} />
        ))}
        {open ? (
          <NestedCreate items={createItems} />
        ) : (
          <SmallCreateMenu items={createItems} />
        )}
        {open ? (
          <NestedOrders items={ordersItems} isAdmin={isAdmin} />
        ) : (
          <SmallOrderMenu items={ordersItems} isAdmin={isAdmin} />
        )}
        <Divider />
        {user !== null &&
          user &&
          user.role === 'admin' &&
          adminListItems.map((itemProps, index) => (
            <ListItem key={`${itemProps.to}-${index}`} {...itemProps} />
          ))}
        <Divider />
        {secondaryListItems.map((itemProps, index) => (
          <ListItem key={`${itemProps.to}-${index}`} {...itemProps} />
        ))}
      </List>
    </Drawer>
  );
};

export default MaterialDashDrawer;
