import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import actions from './dashboardActions';

const initialState = {
  dashboard: [],
  isLoading: false,
  isUpdating: false,
  updateError: '',
  error: '',
};

export const getDashboard = createAsyncThunk(
  'dashboard/getDashboard',
  async (params = {}, thunkAPI) => {
    return actions.getDashboard();
  },
);

const slice = createSlice({
  name: 'dashboard',
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getDashboard.pending]: (state, action) => {
      state.isLoading = true;
      state.error = '';
    },
    [getDashboard.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    [getDashboard.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.dashboard = action.payload;
    },
  },
});

export default slice.reducer;
