import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import actions from './transinordersActions';

const initialState = {
  isLoading: false,
  error: '',

  isUpdating: false,
  updateError: '',
};

export const createTransInOrder = createAsyncThunk(
  'transinorders/createTransInOrder',
  async (transIn, thunkAPI) => {
    return actions
      .createTransInOrder(transIn)
      .then((r) => r.data)
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

export const getTransInOrders = createAsyncThunk(
  'transinorders/getTransInsOrders',
  (params = {}, thunkAPI) => {
    const { id, ...rest } = params;
    if (id) {
      return actions.getTransInOrdersByUserId(id, rest);
    }
    return actions.getTransInOrders(rest);
  },
);

export const updateTransInOrder = createAsyncThunk(
  'transinorders/updateTransInOrder',
  async (transIn, thunkApi) => {
    return actions
      .updateTransInOrder(transIn)
      .then((r) => r.data)
      .catch((err) => thunkApi.rejectWithValue(err.response.data));
  },
);

export const deleteTransInOrder = createAsyncThunk(
  'transinorders/deleteTransInOrder',
  (id, thunkApi) => {
    return actions
      .deleteTransInOrder(id)
      .then((r) => r.data)
      .catch((err) => thunkApi.rejectWithValue(err.response.data));
  },
);

const transinordersAdapter = createEntityAdapter({
  selectId: (entity) => entity._id,
});

const updatePending = (state, action) => {
  state.isUpdating = true;
  state.updateError = '';
};

const updateError = (state, action) => {
  state.isUpdating = false;
  state.updateError = action.payload.error;
};

const slice = createSlice({
  name: 'transinorders',
  initialState: transinordersAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: {
    [createTransInOrder.pending]: updatePending,
    [createTransInOrder.rejected]: updateError,
    [createTransInOrder.fulfilled]: (state, action) => {
      state.isUpdating = false;
      transinordersAdapter.addOne(state, action.payload);
    },
    [getTransInOrders.pending]: (state, action) => {
      state.isLoading = true;
      state.error = '';
    },
    [getTransInOrders.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    [getTransInOrders.fulfilled]: (state, action) => {
      state.isLoading = false;
      const { data } = action.payload;
      state.transinorders = action.payload;
      transinordersAdapter.removeAll(state);
      transinordersAdapter.upsertMany(state, data);
    },
    [updateTransInOrder.pending]: updatePending,
    [updateTransInOrder.rejected]: updateError,
    [updateTransInOrder.fulfilled]: (state, action) => {
      state.isUpdating = false;
      const { _id, ...changes } = action.payload;
      transinordersAdapter.updateOne(state, { id: _id, changes });
    },
    [deleteTransInOrder.pending]: updatePending,
    [deleteTransInOrder.rejected]: updateError,
    [deleteTransInOrder.fulfilled]: (state, action) => {
      state.isUpdating = false;
      transinordersAdapter.removeOne(state, action.payload.id);
    },
  },
});

export const createTransInOrdersSelectors = () => {
  return transinordersAdapter.getSelectors((state) => state.transinorders);
};

export const {
  selectById: selectTransinById,
  selectIds: selectTransinIds,
  selectEntities: selectTransinEntities,
  selectAll: selectAllTransins,
  selectTotal: selectTotalTransins,
} = transinordersAdapter.getSelectors((state) => state.transinorders);

export default slice.reducer;
