import React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

import { Form } from 'react-final-form';
import { TextField } from 'mui-rff';

import LoadingModal from '../../../common/LoadingModal';

import useResetForm from './useResetForm';

const passwordValidation = (password = '') =>
  !!password && password.length < 6 ? '6 characters long' : '';

const confirmPasswordValidation = (confirmPassword = '', { password = '' }) =>
  !!confirmPassword && !!password && confirmPassword !== password
    ? 'Please confirm your new password'
    : '';

const ShowPasswordButton = ({ show, onClick }) => {
  return (
    <InputAdornment>
      <Tooltip title='show password'>
        <IconButton size='small' onClick={onClick}>
          {show ? <MdVisibilityOff /> : <MdVisibility />}
        </IconButton>
      </Tooltip>
    </InputAdornment>
  );
};

const ResetForm = ({ token = '' }) => {
  const {
    isLoading,
    passwordVisible,
    confirmPasswordVisible,
    onFormSubmit,
    handlePasswordVisible,
    handleConfirmPasswordVisible,
  } = useResetForm({ token });
  const renderForm = ({ handleSubmit, form }) => {
    const { dirty, submitting } = form.getState();

    return (
      <Grid
        container
        justify='center'
        alignItems='center'
        spacing={4}
        component='form'
        onSubmit={handleSubmit}
      >
        <Grid item xs={12} sm={8}>
          <TextField
            label='New Password'
            name='password'
            fieldProps={{ validate: passwordValidation }}
            InputProps={{
              endAdornment: (
                <ShowPasswordButton
                  show={passwordVisible}
                  onClick={handlePasswordVisible}
                />
              ),
            }}
            type={passwordVisible ? 'text' : 'password'}
            required
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextField
            label='Confirm New Password'
            name='confirmPassword'
            fieldProps={{ validate: confirmPasswordValidation }}
            InputProps={{
              endAdornment: (
                <ShowPasswordButton
                  show={confirmPasswordVisible}
                  onClick={handleConfirmPasswordVisible}
                />
              ),
            }}
            type={confirmPasswordVisible ? 'text' : 'password'}
            required
          />
        </Grid>
        <Grid
          item
          xs={12}
          component={Box}
          display='flex'
          justifyContent='center'
        >
          <Button
            color='primary'
            disabled={isLoading || !dirty || submitting}
            type='submit'
            variant='contained'
          >
            Reset
          </Button>
        </Grid>
      </Grid>
    );
  };
  return (
    <>
      <Form onSubmit={onFormSubmit} render={renderForm} />
      <LoadingModal isLoading={isLoading} text='Reseting your password...' />
    </>
  );
};

export default ResetForm;
