import { configureStore, combineReducers } from '@reduxjs/toolkit';
import authReducer from './auth/authSlice';
import cameraReducer from './camera/cameraSlice';
import cartonizeReducer from './cartonizeorders/cartonizeordersSlice';
import caseReducer from './cases/casesSlice';
import dashboardReducer from './dashboard/dashboardSlice';
import importReducer from './import/importSlice';
import marketplacesReducer from './marketplaces';
import materialDashReducer from './materialDash/materialDashSlice';
import notifyReducer from './notify/notifySlice';
import palletReducer from './pallets/palletsSlice';
import palletizeReducer from './palletizeorders/palletizeordersSlice';
import paymentsReducer from './payments';
import quoteReducer from './quote';
import reportReducer from './reports/reportsSlice';
import weeklyReportsReducer from './weeklyreports/weeklyReportsSlice';
import monthlyReportsReducer from './monthlyreports/monthlyReportsSlice';
import contactemailReducer from './contactemail/contactemailSlice';
import shipmentReducer from './shipments/shipmentsSlice';
import shopifyOrdersReducer from './shopifyorders/shopifyOrdersSlice';
import wooCommerceOrdersReducer from './woocommerceorders/wooCommerceOrdersSlice';
import transinReducer from './transinorders/transinordersSlice';
import transoutReducer from './transoutorders/transoutordersSlice';
import manualReducer from './manualorders/manualordersSlice';
import unitReducer from './units/unitsSlice';
import usersReducer from './users/usersSlice';
import taskReducer from './tasks/tasksSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  camera: cameraReducer,
  contactemail: contactemailReducer,
  cartonizeorders: cartonizeReducer,
  cases: caseReducer,
  dashboard: dashboardReducer,
  importState: importReducer,
  marketplaces: marketplacesReducer,
  materialDash: materialDashReducer,
  notifications: notifyReducer,
  pallets: palletReducer,
  palletizeorders: palletizeReducer,
  payments: paymentsReducer,
  quote: quoteReducer,
  reports: reportReducer,
  weeklyreports: weeklyReportsReducer,
  monthlyreports: monthlyReportsReducer,
  shipments: shipmentReducer,
  shopifyOrders: shopifyOrdersReducer,
  wooCommerceOrders: wooCommerceOrdersReducer,
  transinorders: transinReducer,
  transoutorders: transoutReducer,
  manualorders: manualReducer,
  units: unitReducer,
  users: usersReducer,
  tasks: taskReducer,
});

const store = configureStore({
  reducer: rootReducer,
  devTools: !(process.env.NODE_ENV === 'production'),
});

/**
 * @typedef {ReturnType<typeof rootReducer>} RootState
 */

export default store;
