import React from 'react';

import { Form, Field } from 'react-final-form';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Input from '../../../common/fieldComponent/Input';

const parseCardNumber = (value) => {
  if (!value || typeof value !== 'string') return '';
  return value.replace(/\D/g, '');
};

const parseExpirationDate = (value) => {
  if (!value || typeof value !== 'string') return '';
  value = value.replace(/\D/g, '').replace(/(^\d{0,4})(.*$)/, '$1');
  if (value.length < 2) return value;
  return value.replace(/(^\d{2})(.*$)/, '$1/$2');
};

const parseCardCode = (value) => {
  if (!value || typeof value !== 'string') return '';
  return value.replace(/\D/g, '').replace(/(^\d{3})(.*$)/, '$1');
};

const RefundCreaditCardDialog = ({ open, payment, onClose, onClickRefund }) => {
  const onFormSubmit = (values) => {
    onClickRefund({
      creditCard: {
        ...values,
        expirationDate: values.expirationDate.replace(/\D/g, ''),
      },
    });
  };

  const renderForm = ({ handleSubmit }) => {
    return (
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                name='cardNumber'
                label='Card Number'
                component={Input}
                size='small'
                required
                parse={parseCardNumber}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name='expirationDate'
                label='Expiration Date'
                component={Input}
                size='small'
                required
                parse={parseExpirationDate}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name='cardCode'
                label='CVC'
                component={Input}
                size='small'
                required
                parse={parseCardCode}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color='secondary' onClick={onClose}>
            Close
          </Button>
          <Button color='primary' type='submit'>
            Refund
          </Button>
        </DialogActions>
      </form>
    );
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle disableTypography>
        <Typography variant='h6' display='inline'>{`Refund to `}</Typography>
        <Typography variant='subtitle1' display='inline'>
          {payment._id}
        </Typography>
      </DialogTitle>
      <Form
        onSubmit={onFormSubmit}
        render={renderForm}
        subscription={{ submitting: true, pristine: true }}
      />
    </Dialog>
  );
};

export default RefundCreaditCardDialog;
