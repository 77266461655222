import axios from 'axios';

const DEFAULT_CONFIG = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const getQuote = (quote) => {
  return axios
    .post(`/api/v1/shipments/quote/user`, quote, DEFAULT_CONFIG)
    .then((r) => r.data);
};

export default { getQuote };
