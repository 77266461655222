import React, { useEffect, useState } from 'react';
import {
  Paper,
  Typography,
  Grid,
  Button,
  Box,
  Tooltip,
  Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Papa from 'papaparse';
import { useNotification } from '../../layout/Notifier';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { getImports, createSelectors } from '../../../redux/import/importSlice';
import DataTable, { createColumn } from '../../common/DataTable';
import Admin from '../../wrappers/Admin';
import UserSelect from '../../common/UserSelect';
import moment from 'moment';
import useAdmin from '../../../hooks/useAdmin';
import ConditionalWrapper from '../../common/ConditionalWrapper';
import fss from '../../../api/fss';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(5),
    height: theme.spacing(25),
    marginBottom: theme.spacing(3),
  },
  grid: {
    height: '100%',
  },
}));

const createImportApi = (fields, rows, userId) => {
  return fss.createImport({ fields, rows, type: 'inventory', userId });
};

const ImportForm = ({
  onParseResults,
  onValidateResults,
  isButtonDisabled,
  buttonToolTip,
}) => {
  const classes = useStyles();
  const showNotification = useNotification();
  const onFileChoose = (e) => {
    Papa.parse(e.target.files[0], {
      header: true,
      worker: true,
      complete: (results) => {
        const error = onValidateResults && onValidateResults(results);
        if (error) {
          showNotification(error, 'error');
        } else {
          onParseResults && onParseResults(results);
        }
      },
    });
  };

  return (
    <Paper className={classes.paper}>
      <Grid
        container
        direction='column'
        alignItems='center'
        justify='space-between'
        className={classes.grid}
      >
        <Typography gutterBottom>
          Select .csv file with sku, barcode and description columns.
        </Typography>

        <Box>
          <input
            accept='.csv'
            id='contained-button-file'
            type='file'
            style={{ display: 'none' }}
            onChange={onFileChoose}
            disabled={isButtonDisabled}
          />
          <label htmlFor='contained-button-file'>
            <ConditionalWrapper
              condition={Boolean(buttonToolTip)}
              wrapper={(children) => (
                <Tooltip title={buttonToolTip}>
                  <span>{children}</span>
                </Tooltip>
              )}
            >
              <Button
                component='span'
                color='primary'
                variant='contained'
                disabled={isButtonDisabled}
              >
                Upload
              </Button>
            </ConditionalWrapper>
          </label>
        </Box>

        <Link href='https://fssuploads.s3-us-west-1.amazonaws.com/import-inventory-example.csv'>
          Example CSV
        </Link>
      </Grid>
    </Paper>
  );
};

const selectAllImportsSelector = createSelectors().selectAll;
const initialColumns = [
  createColumn('importType', 'Import Type'),
  createColumn('totalRows', 'Items Count'),
  createColumn('totalSuccess', 'Imported'),
  createColumn('totalNotImported', 'Not Imported'),
  createColumn('totalError', 'Imported with Errors'),
  createColumn('importDate', 'Time', (rowData, data) =>
    moment(data).format('DD/MM/YYYY hh:mm A'),
  ),
];

const InventoryImport = () => {
  const dispatch = useDispatch();
  const showNotification = useNotification();
  const history = useHistory();
  const location = useLocation();
  const isAdmin = useAdmin();
  const importsData = useSelector(selectAllImportsSelector);
  const isLoading = useSelector((state) => state.importState.isLoading);
  const [selectedUser, setSelectedUser] = useState('');
  const [columns] = useState(initialColumns);

  useEffect(() => {
    dispatch(getImports({ userId: selectedUser }));
  }, [dispatch, selectedUser]);

  const onValidateResults = (result) => {
    const importColumns = ['sku', 'barcode', 'description'];
    for (let field of result.meta.fields) {
      if (importColumns.indexOf(field) < 0) {
        return `Unsupported column in CSV file. Supported columns [${importColumns.join(
          ', ',
        )}]`;
      }
    }
  };

  const onParseResults = (result) => {
    createImportApi(
      result.meta.fields,
      result.data,
      isAdmin ? selectedUser : undefined,
    )
      .then((r) => {
        history.push(`${location.pathname}/${r.data.id}`);
      })
      .catch((err) => {
        showNotification(err);
      });
  };

  const onRowClick = (rowData) => {
    history.push(`${location.pathname}/${rowData._id}`);
  };

  return (
    <>
      <Admin>
        <UserSelect value={selectedUser} onChange={setSelectedUser} />
      </Admin>
      <ImportForm
        onValidateResults={onValidateResults}
        onParseResults={onParseResults}
        isButtonDisabled={isAdmin && !selectedUser}
        buttonToolTip={
          isAdmin && !selectedUser ? 'Please select a customer first.' : ''
        }
      />
      <DataTable
        columns={columns}
        data={importsData}
        isLoading={isLoading}
        onRowClick={onRowClick}
      />
    </>
  );
};

export default InventoryImport;
