import React from 'react';

import ReadOnlyTextField from './ReadOnlyTextField';

const AddressInformation = ({ address }) => {
  return (
    <>
      <ReadOnlyTextField
        label='Name'
        value={`${address?.firstName} ${address?.lastName}`}
      />
      <ReadOnlyTextField label='Company' value={address?.company} />
      <ReadOnlyTextField label='Address' value={address?.address} />
      <ReadOnlyTextField label='City' value={address?.city} />
      <ReadOnlyTextField label='State' value={address?.state} />
      <ReadOnlyTextField label='Zip' value={address?.zip} />
      <ReadOnlyTextField label='Country' value={address?.country} />
      {address?.faxNumber && (
        <ReadOnlyTextField label='Phone Number' value={address?.phoneNumber} />
      )}
      {address?.faxNumber && (
        <ReadOnlyTextField label='Fax Number' value={address?.faxNumber} />
      )}
    </>
  );
};

export default AddressInformation;
