import axios from 'axios';

const DEFAULT_CONFIG = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const getPalletizeOrders = () => {
  return axios.get('/api/v1/palletizeorders').then((r) => {
    return r.data;
  });
};

const getUserPalletizeOrders = (userId) => {
  return axios
    .get(`/api/v1/users/${userId.toString()}/palletizeorders`)
    .then((r) => {
      return r.data;
    });
};

const createPalletizeOrder = (palletizeorder) => {
  return axios
    .post('/api/v1/palletizeorders', palletizeorder, DEFAULT_CONFIG)
    .then((r) => {
      return r.data;
    });
};

const updatePalletizeOrder = (palletizeorder) => {
  return axios
    .put(
      `/api/v1/palletizeorders/${palletizeorder._id}`,
      palletizeorder,
      DEFAULT_CONFIG,
    )
    .then((r) => {
      return r.data;
    });
};

const deletePalletizeOrder = (id) => {
  return axios.delete(`/api/v1/palletizeorders/${id}`).then((r) => {
    return r.data;
  });
};

export default {
  getPalletizeOrders,
  getUserPalletizeOrders,
  createPalletizeOrder,
  updatePalletizeOrder,
  deletePalletizeOrder,
};
