import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ThemeProvider } from '@material-ui/core';
import theme from './theme';
import { SnackbarProvider } from 'notistack';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={5000}
    >
      <App />
    </SnackbarProvider>
  </ThemeProvider>,
  document.getElementById('root'),
);
