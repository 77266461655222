import { useCallback, useEffect, useState } from 'react';

import { useForm } from 'react-final-form';

import { getDefaultProduct } from './utils';

/**
 * @param {import('react-final-form-arrays').FieldArrayRenderProps} param
 */
const useProducts = ({ fields, productName = '' }) => {
  const form = useForm();
  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(() => {
    const nextSelectedIds =
      fields.length > 0
        ? fields.value.map(({ [productName]: product }) => product?._id)
        : [];
    setSelectedIds(nextSelectedIds);
  }, [fields.length, fields.value, productName]);

  const handleAddProduct = useCallback(
    (product) => {
      fields.push({ ...getDefaultProduct(), [productName]: product });
    },
    [fields, productName],
  );

  const handleReset = useCallback(() => {
    form.change(fields.name, []);
  }, [form, fields.name]);

  const handleRemove = useCallback(
    (index) => () => {
      fields.remove(index);
    },
    [fields],
  );

  return { selectedIds, handleAddProduct, handleReset, handleRemove };
};

export default useProducts;
