import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import Box from '@material-ui/core/Box';

import RefreshIcon from '@material-ui/icons/Refresh';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import grey from '@material-ui/core/colors/grey';

import MaterialTable, { MTableToolbar } from 'material-table';

import UserAutoCompleteTextField from '../../common/UserAutoCompleteTextField';

import OrderDialog from '../../dialogs/OrderDialog';

import useShopifyTable from './useShopifyTable';

const useStyles = makeStyles((theme) =>
  createStyles({
    userFieldContainer: {
      padding: theme.spacing(1),
    },
    refreshIconInDownload: {
      animationDelay: '0s',
      animationDuration: '1s',
      animationFillMode: 'forwards',
      animationIterationCount: 'infinite',
      animationName: '$rotation',
      transformOrigin: 'center',
      animationTimingFunction: 'linear',
    },
    '@keyframes rotation': {
      '0%': { '-webkit-transform': 'rotate(0deg)' },
      '50%': { '-webkit-transform': 'rotate(180deg)' },
      '100%': { '-webkit-transform': 'rotate(360deg)' },
    },
  }),
);

const ShopifyTable = () => {
  const classes = useStyles();
  const {
    isLoading,
    isDownloading,
    isAdmin,
    columns,
    pagination,
    tableData,
    tableRef,
    currentOrder,
    /* state */
    currentPage,
    pageSize,
    userId,

    onChangePage,
    onChangeRowsPerPage,
    onDialogClose,
    onDownloadShopifyOrders,
    onGetVideo,
    onFilterChange,
    onOrderChange,
    onRowClick,
    onRowDelete,
    onRowUpdate,
    onSubmitFulfill,
    onSubmitPicked,
    onUserChange,
  } = useShopifyTable();

  /** @type {Options} */
  const options = {
    pageSize: pageSize,
    pageSizeOptions: [15, 50, 100],
    rowStyle: (data, index) => ({
      backgroundColor: index % 2 ? 'unset' : grey[100],
    }),
  };

  /** @type {Action[]} */
  const actions = [
    {
      icon: () => (
        <RefreshIcon
          className={clsx({
            [classes.refreshIconInDownload]: isDownloading,
          })}
        />
      ),
      isFreeAction: true,
      tooltip: !!userId ? 'Refresh Shopify Orders' : undefined,
      onClick: onDownloadShopifyOrders,
      disabled: !userId,
    },
    (rowData) => ({
      icon: () => <OndemandVideoIcon />,
      tooltip: 'View scanning footage',
      onClick: onGetVideo,
      disabled: !rowData.footageUrl,
    }),
  ];

  const renderToolBar = (props) => {
    return (
      <>
        <MTableToolbar {...props} />
        {isAdmin && (
          <Box padding={1} paddingLeft={3} paddingRight={3} maxWidth={320}>
            <UserAutoCompleteTextField
              label='Customer'
              value={userId}
              onChange={onUserChange}
              margin='dense'
            />
          </Box>
        )}
      </>
    );
  };

  return (
    <>
      <MaterialTable
        tableRef={tableRef}
        title='Shopify Orders'
        isLoading={isLoading}
        columns={columns}
        components={{ Toolbar: renderToolBar }}
        data={tableData}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onFilterChange={onFilterChange}
        onOrderChange={onOrderChange}
        options={options}
        page={currentPage}
        totalCount={pagination.totalCount}
        editable={{ onRowDelete, onRowUpdate }}
        onRowClick={isAdmin ? onRowClick : undefined}
        actions={actions}
      />
      {isAdmin && (
        <OrderDialog
          isLoading={isLoading}
          onClose={onDialogClose}
          onSubmitFulfill={onSubmitFulfill}
          onSubmitPicked={onSubmitPicked}
          order={currentOrder}
          products={currentOrder?.units}
          productName='_id'
          userId={userId}
        />
      )}
    </>
  );
};

export default ShopifyTable;
