import axios from 'axios';

const DEFAULT_CONFIG = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const getTransInOrders = () => {
  return axios.get('/api/v1/transinorders').then((r) => {
    return r.data;
  });
};

const getTransInOrdersByUserId = (userId) => {
  return axios
    .get(`/api/v1/users/${userId.toString()}/transinorders`)
    .then((r) => {
      return r.data;
    });
};

const createTransInOrder = (transin) => {
  return axios
    .post('/api/v1/transinorders', transin, DEFAULT_CONFIG)
    .then((r) => {
      return r.data;
    });
};

const updateTransInOrder = (transin) => {
  return axios
    .put(`/api/v1/transinorders/${transin._id}`, transin, DEFAULT_CONFIG)
    .then((r) => {
      return r.data;
    });
};

const deleteTransInOrder = (id) => {
  return axios.delete(`/api/v1/transinorders/${id}`).then((r) => {
    return r.data;
  });
};

export default {
  getTransInOrders,
  getTransInOrdersByUserId,
  createTransInOrder,
  updateTransInOrder,
  deleteTransInOrder,
};
