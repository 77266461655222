import React, { useCallback, useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import UnitTable from '../../tables/UnitTable';
import CaseTable from '../../tables/CaseTable';
import PalletTable from '../../tables/PalletTable';
import LinkButton from '../../common/LinkButton';
import { getUnits } from '../../../redux/units/unitsSlice';
import { getCases } from '../../../redux/cases/casesSlice';
import { getPallets } from '../../../redux/pallets/palletsSlice';
import { getUsers } from '../../../redux/users/usersSlice';
import UserAutoCompleteTextField from '../../common/UserAutoCompleteTextField';
import Box from '@material-ui/core/Box';

const selector = (state) => {
  return {
    isAdmin: state.auth.isAdmin,
    isLoading: state.auth.isLoading,
  };
};

const Inventory = () => {
  const dispatch = useDispatch();
  const { isAdmin, isLoading } = useSelector(selector);

  const [userId, setUserId] = useState('');
  const { url } = useRouteMatch();

  useEffect(() => {
    if (!isLoading && isAdmin === true) {
      dispatch(getUsers());
    }
  }, [isLoading, isAdmin, dispatch]);

  const handleChange = (id) => {
    setUserId(id || null);
  };

  const onGetUnits = useCallback(() => {
    dispatch(getUnits({ id: userId }));
  }, [userId, dispatch]);

  const onGetCases = useCallback(() => {
    dispatch(getCases({ id: userId }));
  }, [userId, dispatch]);

  const onGetPallets = useCallback(() => {
    dispatch(getPallets({ id: userId }));
  }, [userId, dispatch]);

  const reloadAll = useCallback(
    ({ units = true, cases = true, pallets = true } = {}) => {
      if (units) onGetUnits();
      if (cases) onGetCases();
      if (pallets) onGetPallets();
    },
    [onGetUnits, onGetCases, onGetPallets],
  );

  return (
    <Grid container spacing={3}>
      {/* If Admin, render UserSelect Component */}
      {!isLoading && isAdmin === true && (
        <Grid item xs={12}>
          <Box maxWidth={320}>
            <UserAutoCompleteTextField
              label='Customer'
              value={userId}
              onChange={handleChange}
              margin='dense'
            />
          </Box>
        </Grid>
      )}
      <Grid item xs={12} container spacing={3}>
        <Grid item xs={6}>
          <LinkButton startIcon={<ImportExportIcon />} to={`${url}/import`}>
            import
          </LinkButton>
        </Grid>
      </Grid>
      {/* Unit Table */}
      <Grid item xs={12}>
        <UnitTable
          onGetUnits={onGetUnits}
          userId={userId}
          isAdmin={isAdmin}
          reloadAll={reloadAll}
        />
      </Grid>
      {/* Case table */}
      <Grid item xs={12}>
        <CaseTable
          onGetCases={onGetCases}
          reloadAll={reloadAll}
          userId={userId}
          isAdmin={isAdmin}
        />
      </Grid>
      {/* Pallet Table */}
      <Grid item xs={12}>
        <PalletTable
          onGetPallets={onGetPallets}
          reloadAll={reloadAll}
          userId={userId}
          isAdmin={isAdmin}
        />
      </Grid>
    </Grid>
  );
};

export default Inventory;
