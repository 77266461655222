import axios from 'axios';

const DEFAULT_CONFIG = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const getManualOrders = () => {
  return axios.get('/api/v1/manualorders').then((r) => r.data);
};

const getUserManualOrders = (userId) => {
  return axios
    .get(`/api/v1/users/${userId.toString()}/manualorders`)
    .then((r) => r.data);
};

const createManualOrder = (manualorder) => {
  return axios
    .post('/api/v1/manualorders', manualorder, DEFAULT_CONFIG)
    .then((r) => r.data);
};

const updateManualOrder = (manualorder) => {
  return axios
    .put(`/api/v1/manualorders/${manualorder._id}`, manualorder, DEFAULT_CONFIG)
    .then((r) => r.data);
};

const deleteManualOrder = (id) => {
  return axios.delete(`/api/v1/manualorders/${id}`).then((r) => r.data);
};

export default {
  getManualOrders,
  getUserManualOrders,
  createManualOrder,
  updateManualOrder,
  deleteManualOrder,
};
