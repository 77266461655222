/**
 * @param {string} value
 * @returns string
 */
export const zipParser = (value) => {
  if (typeof value !== 'string') return '';
  value = value.replace(/[^\d]/g, '').replace(/(\d{0,9})(.*)/, '$1');

  if (value.length <= 5) {
  }
  return value.length <= 5
    ? value.replace(/(\d{0,5})/, '$1')
    : value.replace(/(\d{0,5})(\d{0,4})/, '$1-$2');
};

/**
 * @param {string} value
 * @returns string
 */
export const numbersOnlyParser = (value) => {
  if (typeof value !== 'string') return '';
  return value.replace(/[^\d]/g, '');
};

/**
 * @param {string} value
 * @returns string
 */
export const parseDecimal = (value) => {
  if (typeof value !== 'string') return '';
  const isNegative = value[0] === '-';
  const match = value
    .replace(/[^\d.]/g, '')
    .split('.')
    .reduce(
      (acc, cur, index) => (index === 1 ? acc + '.' + cur : acc + cur),
      '',
    )
    .match(/\d*(\.\d*)?/);
  value = match
    ? match[0]
        .replace(/^(0+)(?=([1-9]\d*(\.\d*)?)|(0(\.\d*)?))/, '')
        .replace(/(^\.\d*$)/, '0$1')
    : '';
  return `${isNegative ? '-' : ''}${value}`;
};

/**
 * @param {string} value
 * @returns string
 */
export const parseInteger = (value) => {
  if (typeof value !== 'string') return '';
  const isNegative = value[0] === '-';
  const match = value.replace(/[^\d]/g, '').match(/\d*(\.\d*)?/);
  value = match
    ? match[0]
        .replace(/^(0+)(?=([1-9]\d*(\.\d*)?)|(0(\.\d*)?))/, '')
        .replace(/(^\.\d*$)/, '0$1')
    : '';
  return `${isNegative ? '-' : ''}${value}`;
};
