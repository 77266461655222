import {
  createTransInOrdersSelectors,
  getTransInOrders,
  updateTransInOrder,
  deleteTransInOrder,
} from '../../../redux/transinorders/transinordersSlice';
import {
  createTransOutOrdersSelectors,
  getTransOutOrders,
  updateTransOutOrder,
  deleteTransOutOrder,
} from '../../../redux/transoutorders/transoutordersSlice';
import {
  createCartonizeOrdersSelectors,
  getCartonizeOrders,
  updateCartonizeOrder,
  deleteCartonizeOrder,
} from '../../../redux/cartonizeorders/cartonizeordersSlice';
import {
  createPalletizeOrdersSelectors,
  getPalletizeOrders,
  updatePalletizeOrder,
  deletePalletizeOrder,
} from '../../../redux/palletizeorders/palletizeordersSlice';
import {
  createManualOrdersSelectors,
  getManualOrders,
  updateManualOrder,
  deleteManualOrder,
} from '../../../redux/manualorders/manualordersSlice';

/**
 * @typedef {'cartonize' | 'palletize' | 'transfer_in' | 'transfer_out'} TableType
 */
/**
 * @param {TableType} tableType
 */
const orderSelector = (tableType) =>
  /** @param {import('../../../redux/store').RootState} state */
  (state) => {
    switch (tableType) {
      case 'cartonize':
        return {
          title: 'Cartonize',
          orders: createCartonizeOrdersSelectors().selectAll(state),
          productName: 'case',
          isLoading: state.cartonizeorders.isLoading,
          isUpdating: state.cartonizeorders.isUpdating,
          updateErrorSelector: (_state) => _state.cartonizeorders.updateError,
          errorSelector: (_state) => _state.cartonizeorders.error,
          getOrders: getCartonizeOrders,
          updateOrder: updateCartonizeOrder,
          deleteOrder: deleteCartonizeOrder,
        };

      case 'palletize':
        return {
          title: 'Palletize',
          orders: createPalletizeOrdersSelectors().selectAll(state),
          productName: 'case',
          isLoading: state.palletizeorders.isLoading,
          isUpdating: state.palletizeorders.isUpdating,
          updateErrorSelector: (_state) => _state.palletizeorders.updateError,
          errorSelector: (_state) => _state.palletizeorders.error,
          getOrders: getPalletizeOrders,
          updateOrder: updatePalletizeOrder,
          deleteOrder: deletePalletizeOrder,
        };

      case 'transfer_in':
        return {
          title: 'Transfer In',
          orders: createTransInOrdersSelectors().selectAll(state),
          productName: 'case',
          isLoading: state.transinorders.isLoading,
          isUpdating: state.transinorders.isUpdating,
          updateErrorSelector: (_state) => _state.transinorders.updateError,
          errorSelector: (_state) => _state.transinorders.error,
          getOrders: getTransInOrders,
          updateOrder: updateTransInOrder,
          deleteOrder: deleteTransInOrder,
        };

      case 'transfer_out':
        return {
          title: 'Transfer Out',
          orders: createTransOutOrdersSelectors().selectAll(state),
          productName: 'case',
          isLoading: state.transoutorders.isLoading,
          isUpdating: state.transoutorders.isUpdating,
          updateErrorSelector: (_state) => _state.transoutorders.updateError,
          errorSelector: (_state) => _state.transoutorders.error,
          getOrders: getTransOutOrders,
          updateOrder: updateTransOutOrder,
          deleteOrder: deleteTransOutOrder,
        };

      case 'manual_order':
        return {
          title: 'Manual',
          orders: createManualOrdersSelectors().selectAll(state),
          productName: 'case',
          isLoading: state.manualorders.isLoading,
          isUpdating: state.manualorders.isUpdating,
          updateErrorSelector: (_state) => _state.manualorders.updateError,
          errorSelector: (_state) => _state.manualorders.error,
          getOrders: getManualOrders,
          updateOrder: updateManualOrder,
          deleteOrder: deleteManualOrder,
        };

      default:
        return {
          title: '',
          orders: [],
          productName: null,
          isLoading: false,
          isUpdating: false,
          updateErrorSelector: () => {},
          errorSelector: () => {},
          getOrders: () => {},
          updateOrder: () => {},
          deleteOrder: () => {},
        };
    }
  };

export default orderSelector;
