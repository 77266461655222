import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import {
  createMarketplace,
  deleteMarketplace,
  getMarketplaces,
  getAllMarketplaces,
  updateMarketplace,
} from './marketplacesActions';

const initialState = {
  isLoading: false,
  error: '',
};

const marketplacesAdapter = createEntityAdapter({
  selectId: (entity) => entity._id,
});

const pendingAction = (state, action) => {
  state.isLoading = true;
  state.error = null;
};

const rejectedAction = (state, action) => {
  debugger;
  state.isLoading = false;
  state.error = action.payload?.error || action?.error?.message;
};

const slice = createSlice({
  name: 'marketplaces',
  initialState: marketplacesAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: {
    [createMarketplace.pending]: pendingAction,
    [createMarketplace.rejected]: rejectedAction,
    [createMarketplace.fulfilled]: (state, action) => {
      marketplacesAdapter.addOne(state, action.payload.data);
      state.isLoading = false;
    },
    [deleteMarketplace.pending]: pendingAction,
    [deleteMarketplace.rejected]: rejectedAction,
    [deleteMarketplace.fulfilled]: (state, action) => {
      marketplacesAdapter.removeOne(state, action.payload);
      state.isLoading = false;
    },
    [getMarketplaces.pending]: pendingAction,
    [getMarketplaces.rejected]: rejectedAction,
    [getMarketplaces.fulfilled]: (state, action) => {
      marketplacesAdapter.upsertMany(state, action.payload.data);
      state.isLoading = false;
    },
    [getAllMarketplaces.pending]: pendingAction,
    [getAllMarketplaces.rejected]: rejectedAction,
    [getAllMarketplaces.fulfilled]: (state, action) => {
      marketplacesAdapter.upsertMany(state, action.payload.data);
      state.isLoading = false;
    },
    [updateMarketplace.pending]: pendingAction,
    [updateMarketplace.rejected]: rejectedAction,
    [updateMarketplace.fulfilled]: (state, action) => {
      marketplacesAdapter.updateOne(state, action.payload.data);
      state.isLoading = false;
    },
  },
});

export const marketplacesSelectors = (() =>
  marketplacesAdapter.getSelectors((state) => state.marketplaces))();

export const marketSelectors = () =>
  marketplacesAdapter.getSelectors((state) => state.marketplaces);

export default slice.reducer;
