import React from 'react';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

const LinkButton = ({ children, to, ...props }) => {
  const toLink = to?.replace('//', '/');
  return (
    <Button component={Link} to={toLink} {...props}>
      {children}
    </Button>
  );
};

export default LinkButton;
