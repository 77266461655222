import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import actions from './contactemailActions';

const initialState = {
  isLoading: false,
  error: '',

  isUpdating: false,
  updateError: '',
};

export const sendContactEmail = createAsyncThunk(
  'contact/sendEmail',
  async (formData, thunkAPI) => {
    return actions
      .sendContactEmail(formData)
      .then((r) => {
        return r.data;
      })
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

const updatePending = (state, action) => {
  state.isUpdating = true;
  state.updateError = '';
};

const updateError = (state, action) => {
  state.isUpdating = false;
  state.updateError = action.payload.error;
};

const slice = createSlice({
  name: 'contactemailSlice',
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [sendContactEmail.pending]: updatePending,
    [sendContactEmail.rejected]: updateError,
    [sendContactEmail.fulfilled]: (state, action) => {
      state.isUpdating = false;
    },
  },
});

export default slice.reducer;
