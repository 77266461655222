import { createAsyncThunk } from '@reduxjs/toolkit';

import paymentAPIs from '../../api/payments';

const errorHandler = (thunkAPI) => (error) => {
  return thunkAPI.rejectWithValue(error.response.data);
};

/**
 * @description Get a payment
 */
export const getPayment = createAsyncThunk(
  'payments/getPayment',
  /**
   * @param {object} param
   * @param {string} param.id
   * @param {string=} param.userId
   */
  async ({ id, userId } = {}, thunkAPI) => {
    const response = await paymentAPIs
      .getPayment({ id, userId })
      .catch(errorHandler(thunkAPI));
    return response;
  },
);

/**
 * @description Get all payments
 */
export const getPayments = createAsyncThunk(
  'payments/getPayments',
  /**
   * @param {object} param
   * @param {string=} param.userId
   */
  async ({ userId } = {}, thunkAPI) => {
    const response = await paymentAPIs
      .getPayments({ userId })
      .catch(errorHandler(thunkAPI));
    return response;
  },
);

/**
 * @description Void or refund a payment
 * @description `param.data.creditCard` - required if refund a settled payment
 */
export const deletePayment = createAsyncThunk(
  'payments/deletePayment',
  /**
   * @param {object} param
   * @param {string} param.id
   * @param {string=} param.userId
   * @param {object} param.data
   * @param {import('../../../../utils/authorizenet/utils/setCreditCardPaymentType').CreditCardType=} param.data.creditCard
   */
  async ({ id, userId, data = {} } = {}, thunkAPI) => {
    const response = await paymentAPIs
      .deletePayment({ id, userId, data })
      .catch(errorHandler(thunkAPI));
    return response;
  },
);

/**
 * @description Synchronize a payment from authorize.net
 */
export const updatePayment = createAsyncThunk(
  'payments/updatePayment',
  /**
   * @param {object} param
   * @param {string} param.id
   * @param {string=} param.userId
   */
  async ({ id, userId } = {}, thunkAPI) => {
    const response = await paymentAPIs
      .updatePayment({ id, userId })
      .catch(errorHandler(thunkAPI));
    return response;
  },
);

/**
 * @description Synchronize user payments from authorize.net
 */
export const updatePayments = createAsyncThunk(
  'payments/updatePayments',
  /**
   * @param {object} param
   * @param {string=} param.userId
   */
  async ({ userId } = {}, thunkAPI) => {
    const response = await paymentAPIs
      .updatePayments({ userId })
      .catch(errorHandler(thunkAPI));
    return response;
  },
);
