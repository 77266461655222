export const fieldList = [
  {
    type: 'text',
    gridProps: { xs: 12, sm: 4 },
    name: 'ponum',
    label: 'PO #',
    required: false,
  },
  {
    type: 'text',
    gridProps: { xs: 12, sm: 4 },
    name: 'carrier',
    label: 'Carrier',
    required: false,
  },
  {
    type: 'text',
    gridProps: { xs: 12, sm: 4 },
    name: 'shipId',
    label: 'Ship Id',
    required: false,
  },
];

export const DEFAULT_GENERAL = {
  ponum: '',
  carrier: '',
  shipId: '',
};
