import React, { useEffect } from 'react';

import {
  KeyboardDateTimePicker as MuiKeyboardDateTimePicker,
  DateTimePicker,
} from '@material-ui/pickers';

import { Field } from 'react-final-form';
import moment from 'moment';

const FieldComponent = ({
  input: { value, onChange, type, ...input },
  meta,
  readOnly = false,
  ...props
}) => {
  useEffect(() => {
    if (value && !moment.isMoment(value)) {
      onChange(moment(value));
    }
  }, [value, onChange]);

  const onDateChange = (date) => {
    onChange(date);
  };

  if (readOnly) {
    return (
      <DateTimePicker
        onChange={onDateChange}
        value={value ? (moment.isMoment(value) ? value : moment(value)) : null}
        {...input}
        {...props}
        InputLabelProps={{ shrink: true, ...props.InputLabelProps }}
        open={false}
      />
    );
  }

  return (
    <MuiKeyboardDateTimePicker
      onChange={onDateChange}
      value={value ? (moment.isMoment(value) ? value : moment(value)) : null}
      {...input}
      {...props}
      InputLabelProps={{ shrink: true, ...props.InputLabelProps }}
    />
  );
};

/**
 * @param {import('@material-ui/pickers').KeyboardDateTimePickerProps & {name: String, fieldProps: import('react-final-form').FieldProps}} props
 */
const KeyboardDateTimePicker = ({ name, fieldProps = {}, ...props }) => {
  return (
    <Field
      name={name}
      render={(params) => <FieldComponent {...params} {...props} />}
      {...fieldProps}
    />
  );
};

export default KeyboardDateTimePicker;
