import { numbersOnlyParser, zipParser } from '../../../utils/parsers';
import { abbreviations, mapper } from '../../../utils/consts/usStates';

import { emailValidation, zipCodeValidation } from '../../../utils/validations';

import moment from 'moment';

export const getList = ({ label = '', name = '' }) => {
  return [
    {
      name: `${name}Name`,
      label: `${label} Name`,
      required: true,
      gridProps: { xs: 12 },
    },
    {
      name: `${name}Address1`,
      label: 'Street Address 1',
      required: true,
      gridProps: { xs: 12, sm: 8 },
    },
    {
      name: `${name}Address2`,
      label: 'Street Address 2',
      gridProps: { xs: 12, sm: 4 },
    },
    {
      name: `${name}City`,
      label: 'City',
      required: true,
      gridProps: { xs: 12, sm: 4 },
    },
    {
      name: `${name}State`,
      label: 'State',
      required: true,
      gridProps: { xs: 12, sm: 4 },
      isAutocomplete: true,
      autocompleteProps: {
        autoComplete: true,
        autoHighlight: true,
        autoSelect: true,
        options: abbreviations,
        getOptionLabel: (option) => mapper[option],
        disableClearable: true,
      },
    },
    {
      name: `${name}Zip`,
      label: 'Zip',
      required: true,
      gridProps: { xs: 12, sm: 4 },
      parse: zipParser,
      validate: (value) => {
        return value && !zipCodeValidation(value) ? 'Invalid Zip Code' : null;
      },
    },
    {
      name: `${name}Contact`,
      label: 'Contact Name',
      // required: true,
      gridProps: { xs: 12, sm: 4 },
    },
    {
      name: `${name}Email`,
      label: 'Contact Email',
      // required: true,
      gridProps: { xs: 12, sm: 4 },
      validate: (value) => {
        return value && !emailValidation(value) ? 'Invalid Email' : null;
      },
    },
    {
      name: `${name}Phone`,
      label: 'Contact Phone',
      // required: true,
      gridProps: { xs: 12, sm: 4 },
      parse: numbersOnlyParser,
    },
    {
      name: `${name}${name === 'shipper' ? 'PickUp' : 'Delivery'}`,
      label: `${name === 'shipper' ? 'Pick Up' : 'Delivery'}`,
      type: 'date',
      required: true,
      gridProps: { xs: 12, sm: 6 },
      validate: (value) => {
        if (!value) return null;
        const date = typeof value === 'string' ? moment.utc(value) : value;
        // Check is valid
        if (!date.isValid()) return date.format();
        // Pass
        return null;
      },
    },
    {
      name: `${name}Location`,
      label: `${label} Location Type`,
      // required: true,
      gridProps: { xs: 12, sm: 6 },
      isAutocomplete: true,
      autocompleteProps: {
        autoComplete: true,
        autoHighlight: true,
        autoSelect: true,
        options: [
          'Commercial',
          'Residential/Non-Commercial',
          'Trade Show',
          'Construction',
          'Limited Access',
          'Carrier Terminal',
          'Container Freight Station',
          'Distribution Center',
        ],
        disableClearable: true,
      },
    },
  ];
};

export const getCheckboxList = ({ name = '' }) => {
  return name === 'shipper'
    ? [
        { name: 'shipperDock', label: 'Dock' },
        { name: 'shipperFork', label: 'Fork Lift' },
        { name: 'shipperFloor', label: 'Floor Stack' },
        { name: 'shipperInside', label: 'Inside Pickup' },
        { name: 'shipperLift', label: 'Lift Gate' },
      ]
    : [
        { name: 'receiverDock', label: 'Dock' },
        { name: 'receiverFork', label: 'Fork Lift' },
        { name: 'receiverLift', label: 'Floor Gate' },
        { name: 'receiverInside', label: 'Inside Delivery' },
        { name: 'receiverApp', label: 'App Required' },
      ];
};

export const loadDetailsList = [
  { name: 'numPallets', label: '# of Pallets' },
  { name: 'weightPerPallet', label: 'Weight Per Pallet (lbs)' },
  {
    name: 'totalLoadWeight',
    label: 'Total Weight',
    InputProps: {
      readOnly: true,
    },
  },
  { name: 'palletWidth', label: 'Width (inches)' },
  { name: 'palletLength', label: 'Length (inches)' },
  { name: 'palletHeight', label: 'Height (inches)' },
];

export const loadDetailsCheckboxList = [
  { name: 'hazardous', label: 'Hazardous' },
  { name: 'stackable', label: 'Stackable' },
  { name: 'loadStrap', label: 'Load Strap' },
  { name: 'loadBlock', label: 'Load Block' },
];
