import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import UserInformation from './UserInformation';
import TransactionDetail from './TransactionDetail';
import SettlementInformation from './SettlementInformation';
import AuthorizationInformation from './AuthorizationInformation';
import PaymentInformation from './PaymentInformation';
import OrderInformation from './OrderInformation';
import AddressInformation from './AddressInformation';
import OtherInformation from './OtherInformation';

import RefundCreaditCardDialog from './RefundCreaditCardDialog';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
      paddingBottom: 0,
    },
  }),
);

const Container = ({ title, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant='h6'>{title}</Typography>
      {children}
    </div>
  );
};

const voidableStatus = [
  'authorizedPendingCapture',
  'capturedPendingSettlement',
  'authorizedPendingRelease',
  'refundPendingSettlement',
];

const DetailDialog = ({ payment, onClose, onVoidPayment, onRefundPayment }) => {
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (payment && !open) {
      setOpen(true);
      setDialogOpen(false);
    }
  }, [payment, open]);

  const onCloseClick = () => {
    setDialogOpen(false);
    setOpen(false);
    onClose();
  };

  const onDialogClose = () => {
    setDialogOpen(false);
  };

  const isRefundable =
    payment?.transaction?.transactionStatus === 'settledSuccessfully';

  const isVoidable = voidableStatus.includes(
    payment?.transaction?.transactionStatus,
  );

  const onClickVoidOrRefund = () => {
    if (isVoidable) {
      onCloseClick();
      onVoidPayment();
    } else if (isRefundable) setDialogOpen(true);
  };

  const onClickRefund = (data = {}) => {
    onCloseClick();
    onRefundPayment(data);
  };

  return (
    payment && (
      <>
        <Dialog open={open} onClose={onCloseClick}>
          <DialogTitle disableTypography>
            <Typography variant='h5' display='inline'>{`Payment `}</Typography>
            <Typography variant='h6' display='inline'>
              {payment._id}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Container title='Transaction Detail'>
              <TransactionDetail transaction={payment.transaction} />
            </Container>
            <Container title='Customer Information'>
              <UserInformation user={payment.user} />
            </Container>
            <Container title='Settlement Information'>
              <SettlementInformation transaction={payment.transaction} />
            </Container>
            <Container title='Authorization Information'>
              <AuthorizationInformation transaction={payment.transaction} />
            </Container>
            {payment.transaction.payment && (
              <Container title='Payment Information'>
                <PaymentInformation transaction={payment.transaction} />
              </Container>
            )}
            {payment.transaction.order && (
              <Container title='Order Information'>
                <OrderInformation order={payment.transaction.order} />
              </Container>
            )}
            {payment.transaction.billTo && (
              <Container title='Billing Information'>
                <AddressInformation address={payment.transaction.billTo} />
              </Container>
            )}
            {payment.transaction.shipTo && (
              <Container title='Shipping Information'>
                <AddressInformation address={payment.transaction.shipTo} />
              </Container>
            )}
            {(payment.transaction.tax ||
              payment.transaction.duty ||
              payment.transaction.shipping) && (
              <Container title='Additional Details - Level 2 Data'>
                <OtherInformation transaction={payment.transaction} />
              </Container>
            )}
          </DialogContent>
          <DialogActions>
            <Button color='secondary' onClick={onCloseClick}>
              Close
            </Button>
            {(isRefundable || isVoidable) && (
              <Button color='primary' onClick={onClickVoidOrRefund}>
                {isRefundable ? 'Refund' : 'Void'}
              </Button>
            )}
          </DialogActions>
        </Dialog>
        <RefundCreaditCardDialog
          payment={payment}
          open={dialogOpen}
          onClose={onDialogClose}
          onClickRefund={onClickRefund}
        />
      </>
    )
  );
};

export default DetailDialog;
