import React from 'react';
import Button from '@material-ui/core/Button';
import { TextField } from 'mui-rff';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import _ from 'lodash';

const Fees = ({ fields, readOnly = false }) => {
  const addFee = () => {
    fields.push({ fee: '', amount: '' });
  };

  const removeFee = (index) => {
    fields.remove(index);
  };

  let total = _.sumBy(fields.value, (o) => Number(o.amount));

  return (
    <>
      {total > 0 && <Typography color='primary'>Total: ${total}</Typography>}
      {fields.map((name, index) => (
        <Grid
          container
          key={index}
          spacing={1}
          direction='row'
          justify='center'
          alignItems='center'
        >
          <Grid item xs={3}>
            <Typography>Fee #{index + 1}</Typography>
          </Grid>
          <Grid item xs={3}>
            <TextField
              placeholder='Fee Description'
              name={`${name}.fee`}
              variant='outlined'
              margin='dense'
              disabled={readOnly ? true : false}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              placeholder='Amount'
              name={`${name}.amount`}
              variant='outlined'
              margin='dense'
              type='number'
              disabled={readOnly ? true : false}
            />
          </Grid>
          <Grid item xs={3}>
            {!readOnly ? (
              <Button onClick={removeFee} style={{ color: 'red' }}>
                Remove
              </Button>
            ) : null}
          </Grid>
        </Grid>
      ))}
      {!readOnly ? (
        <Button color='primary' onClick={addFee}>
          Add
        </Button>
      ) : null}
    </>
  );
};

export default Fees;
