import {
  createReportsSelectors,
  getDailyReports,
} from '../../../redux/reports/reportsSlice';

import {
  createWeeklyReportsSelectors,
  getWeeklyReports,
} from '../../../redux/weeklyreports/weeklyReportsSlice';

import {
  createMonthlyReportsSelectors,
  getMonthlyReports,
} from '../../../redux/monthlyreports/monthlyReportsSlice';

const orderSelector = (tableType) => (state) => {
  switch (tableType) {
    case 'daily':
      return {
        title: 'Daily Reports',
        orders: createReportsSelectors().selectAll(state),
        isLoading: state.reports.isLoading,
        isUpdating: state.reports.isUpdating,
        updateErrorSelector: (_state) => _state.reports.updateError,
        errorSelector: (_state) => _state.reports.error,
        getOrders: getDailyReports,
      };

    case 'weekly':
      return {
        title: 'Weekly Reports',
        orders: createWeeklyReportsSelectors().selectAll(state),
        isLoading: state.weeklyreports.isLoading,
        isUpdating: state.weeklyreports.isUpdating,
        updateErrorSelector: (_state) => _state.weeklyreports.updateError,
        errorSelector: (_state) => _state.weeklyreports.error,
        getOrders: getWeeklyReports,
      };

    case 'monthly':
      return {
        title: 'Monthly Reports',
        orders: createMonthlyReportsSelectors().selectAll(state),
        isLoading: state.monthlyreports.isLoading,
        isUpdating: state.monthlyreports.isUpdating,
        updateErrorSelector: (_state) => _state.monthlyreports.updateError,
        errorSelector: (_state) => _state.monthlyreports.error,
        getOrders: getMonthlyReports,
      };

    default:
      return {
        title: '',
        orders: [],
        isLoading: false,
        isUpdating: false,
        updateErrorSelector: () => {},
        errorSelector: () => {},
        getOrders: () => {},
      };
  }
};

export default orderSelector;
