// eslint-disable-next-line
const emailRegExp = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

/**
 * Validate string is email or not
 * @param {string} value
 * @returns true - value is email
 * @returns false - value is not email || value is empty string || value is not string
 */
export const emailValidation = (value) => {
  return typeof value === 'string' ? emailRegExp.test(value) : false;
};

/**
 * Validate string is zip code or not
 * @param {string} value
 * @returns true - value is zip code
 * @returns false - value is not zip code || value is empty string || value is not string
 */
export const zipCodeValidation = (value) => {
  return typeof value === 'string' ? /^\d{5}(-\d{4})?$/.test(value) : false;
};
