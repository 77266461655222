import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { MdReplay } from 'react-icons/md';

import { CheckboxField } from '../fields';

const WhiteButton = withStyles({
  root: { color: 'rgba(255, 255, 255, 0.87) !important' },
  disabled: { color: 'rgba(255, 255, 255, 0.38) !important' },
})(Button);

const HazardousCheckbox = React.memo(
  withStyles({
    root: { color: 'rgba(255, 255, 255, 0.87) !important' },
    checked: { color: 'rgba(255, 255, 255, 0.87) !important' },
    disabled: { color: 'rgba(255, 255, 255, 0.38) !important' },
  })(({ classes, name, ...props }) => (
    <CheckboxField
      label='HAZ'
      name={name}
      checkboxProps={{
        classes: {
          root: classes.root,
          checked: classes.checked,
          disabled: props.disabled ? classes.disabled : undefined,
        },
      }}
      formControlLabelProps={{
        classes: {
          root: classes.root,
          disabled: props.disabled ? classes.disabled : undefined,
        },
      }}
      {...props}
    />
  )),
);

const CommoditiesTitle = ({ disabled, readOnly, hazName, onReset }) => {
  return (
    <Box display='flex' alignItems='center'>
      <Box component='span' marginRight={2}>
        Commodities
      </Box>
      <HazardousCheckbox
        name={hazName}
        disabled={disabled}
        readOnly={readOnly}
      />
      <Box marginLeft='auto'>
        {!readOnly && (
          <>
            <WhiteButton
              disabled={disabled}
              onClick={onReset}
              startIcon={<MdReplay />}
            >
              Reset
            </WhiteButton>
          </>
        )}
      </Box>
    </Box>
  );
};

export default CommoditiesTitle;
