import { abbreviations, mapper } from '../../../../utils/consts/usStates';
import {
  countriesAbbreviations,
  countriesMapper,
} from '../../../../utils/consts/countries';
import { numbersOnlyParser } from '../../../../utils/parsers';
import { emailValidation } from '../../../../utils/validations';

/**
 * @param {'shipper' | 'receiver'} type
 */
export const getFieldList = (type) => [
  {
    type: 'text',
    gridProps: { xs: 12 },
    name: 'company',
    label: 'Company',
    required: false,
  },
  {
    type: 'text',
    gridProps: { xs: 12, sm: 8 },
    name: 'addressOne',
    label: 'Address 1',
    required: false,
  },
  {
    type: 'text',
    gridProps: { xs: 12, sm: 4 },
    name: 'addressTwo',
    label: 'Address 2',
  },
  {
    type: 'text',
    gridProps: { xs: 12, sm: 8 },
    name: 'city',
    label: 'City',
    required: false,
  },
  {
    type: 'autocomplete',
    gridProps: { xs: 12, sm: 4 },
    name: 'state',
    label: 'State',
    disableClearable: true,
    options: abbreviations,
    getOptionValue: (option) => option || null,
    getOptionLabel: (option) => mapper[option],
    required: false,
  },
  {
    name: 'zip',
    label: 'Zip',
    required: false,
    gridProps: { xs: 12, sm: 6 },
    type: 'text',
  },
  {
    type: 'autocomplete',
    gridProps: { xs: 12, sm: 6 },
    name: 'country',
    label: 'Country',
    disableClearable: true,
    options: countriesAbbreviations,
    getOptionValue: (option) => option || null,
    getOptionLabel: (option) => countriesMapper[option],
    required: false,
  },
  {
    type: 'text',
    gridProps: { xs: 12, sm: 12 },
    name: 'contact',
    label: 'Contact Name',
  },
  {
    type: 'text',
    gridProps: { xs: 12, sm: 6 },
    name: 'email',
    label: 'Email',
    fieldProps: {
      validate: (value) => {
        return value && !emailValidation(value) ? 'Invalid Email' : null;
      },
    },
  },
  {
    type: 'text',
    gridProps: { xs: 12, sm: 6 },
    name: 'telephone',
    label: 'Telephone',
    fieldProps: { parse: numbersOnlyParser },
  },
];

export const DEFAULT_SHIPPER = {
  company: '',
  addressOne: '',
  addressTwo: '',
  city: '',
  state: '',
  zip: '',
  country: '',
  contact: '',
  email: '',
  telephone: '',
};

export const DEFAULT_RECEIVER = {
  company: '',
  addressOne: '',
  addressTwo: '',
  city: '',
  state: '',
  zip: '',
  country: '',
  contact: '',
  email: '',
  telephone: '',
};
