import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import MiniDrawer from './MiniDrawer';
import Pages from './Pages';
import UserLoginRegister from './UserLoginRegister';
import logo from '../../../img/fss-white.svg';

const Navbar = () => {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    home: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position='static' elevation={1}>
        <Toolbar>
          <MiniDrawer />
          <Link to='/'>
            <img
              src={logo}
              alt=''
              className={classes.home}
              width='75px'
              height='auto'
              style={{ verticalAlign: 'middle' }}
            />
          </Link>
          <Pages />
          <div className={classes.root}></div>
          <UserLoginRegister />
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Navbar;
