import React, { useReducer } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useNotification } from '../layout/Notifier';
import { changePassword } from '../../redux/auth/authSlice';

const initialState = {
  newPassword: '',
  currentPassword: '',
};

const reducer = (state, { field, value }) => {
  return { ...state, [field]: value };
};

const PasswordDialog = (props) => {
  const reduxDispatch = useDispatch();
  const showNotification = useNotification();
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleClose = () => {
    props.handlePasswordClose();
  };

  const handleChange = (e) => {
    dispatch({ field: e.target.name, value: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    reduxDispatch(changePassword(state)).then((data) => {
      if (data.payload.success === true) {
        showNotification('Successfully changed password.', 'success');
      } else {
        showNotification('Invalid password. Please try again.', 'error');
      }
    });
    props.handlePasswordClose();
  };

  const passwordList = [
    {
      label: 'Current Password',
      name: 'currentPassword',
      value: state.currentPassword,
    },
    { label: 'New Password', name: 'newPassword', value: state.newPassword },
  ];

  return (
    <div>
      <Dialog
        open={props.passwordOpen}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <DialogContentText>Requires 6 characters minimum</DialogContentText>
          {passwordList.map((props, index) => (
            <TextField
              {...props}
              key={index}
              onChange={handleChange}
              margin='dense'
              type='password'
              fullWidth
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          <Button type='submit' onClick={handleSubmit} color='primary'>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default PasswordDialog;
