import React, { Fragment, useState } from 'react';
import Wrapper from '../../wrappers/Wrapper';
import Footer from '../../layout/Footer';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FillDialog from '../../dialogs/FillDialog';
import { image_one, image_two, image_three } from '../../../assets/fill';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      textAlign: 'center',
    },
  },
  container_text: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '75%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
      textAlign: 'center',
      padding: theme.spacing(2),
    },
  },
  header: {
    paddingBottom: theme.spacing(1),
    borderBottom: 'solid thin white',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      fontWeight: 'bold',
    },
  },
  warehouseImage: {
    width: 'auto',
    height: 300,
  },
  grid: {
    width: '100%',
    backgroundColor: '#F6F9FB',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      padding: theme.spacing(2),
      order: 1,
    },
  },
  img: {
    display: 'block',
    width: '100%',
    height: '40vh',
    objectFit: 'cover',
    filter: 'brightness(50%)',
  },
  container3_img: {
    width: '100%',
    height: 'auto',
    padding: theme.spacing(10),
  },
  container_gray: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '40vh',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
      padding: theme.spacing(2),
    },
  },
}));

const Fill = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Fragment>
      <Wrapper>
        <FillDialog
          open={open}
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
        />
        {/* container 1 */}
        <div className={classes.container}>
          <img src={image_one} className={classes.img} alt='' />
          <div className={classes.container_text}>
            <Typography variant='h4' className={classes.header} gutterBottom>
              Submit your Fulfillment Request
            </Typography>
            <Typography variant='body1' gutterBottom>
              Interested in having Fillstorship fulfill your orders? Submit a
              request below and we will begin the process with you as soon as
              possible.
            </Typography>
            <Button
              variant='contained'
              color='primary'
              type='button'
              onClick={handleClickOpen}
            >
              Fulfillment Request
            </Button>
          </div>
        </div>

        {/* container 2 */}
        <div className={classes.container_gray}>
          <Typography variant='h4' align='center' gutterBottom>
            Have you ever under or over-estimated a product launch?
          </Typography>
          <Typography variant='body1' style={{ width: '75%' }}>
            Going too low and running out is stressful and costly with rush
            deliveries, rushed manufacturing and unhappy customers. Going too
            high would leave you with un-sold inventory, causing unbuilding kits
            or liquidating product at a loss. You can put that scenario behind
            you...
          </Typography>
        </div>

        {/* container 3 */}
        <Grid
          container
          justify='center'
          alignItems='center'
          className={classes.grid}
        >
          <Grid item xs={12} sm={5}>
            <Typography variant='h4' align='center'>
              Our Solution
            </Typography>
            <Typography variant='body1'>
              Fillstorship offers turnkey solutions for your product. It’s in
              our name - Fulfillment, Storage and Shipping. The big box models
              of distribution have changed. More and more small businesses are
              bringing their products to market quicker, more cost effective and
              direct to the consumer. Our streamline process has combined
              Kitting and Assembly with Warehousing and Distribution by
              incorporating Ecommerce processing all in one place.
            </Typography>
          </Grid>
          <Grid container item xs={12} sm={5}>
            <img src={image_two} className={classes.container3_img} alt='' />
          </Grid>
        </Grid>

        {/* container 4 */}
        <div className={classes.container_gray}>
          <Typography variant='h4' align='center' gutterBottom>
            Kitting Assembly
          </Typography>
          <Typography variant='body1' style={{ width: '75%' }}>
            The upfront costs to fulfill and assemble kits can be expensive. Why
            not absorb that cost after the product is sold? Fillstorship
            combines our experience in manufacturing and warehousing with
            ecommerce order processing so your products and kits can be
            fulfilled during the ecommerce order process. The results are faster
            turn time to customer, reduced warehousing cost, reduced shrinkage
            loss, and less handling charges.
          </Typography>
        </div>

        {/* container 5 */}
        <div className={classes.container}>
          <img className={classes.img} src={image_three} alt='' />
          <div className={classes.container_text}>
            <Typography variant='h4' align='center' gutterBottom>
              How it Works
            </Typography>
            <Typography component='p' variant='body1'>
              We warehouse all the components that make up your product. This
              includes packaging, printed material, finished bulk product, and
              items that would be included in the complete package. Orders are
              received through your ecommerce driver. Based on sales volume, we
              fulfill as needed. The cost of fulfillment is billed after your
              products have been shipped. Warehousing, fulfillment, and
              distribution are compiled into detailed reports that track your
              inventory, sales trends, and custom alerts to advise when levels
              are low. We customize and scale to your volumes.
            </Typography>
          </div>
        </div>
        <Footer />
      </Wrapper>
    </Fragment>
  );
};

export default Fill;
