import React, { Fragment, useState } from 'react';
import Wrapper from '../../wrappers/Wrapper';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Footer from '../../../components/layout/Footer';
import Typography from '@material-ui/core/Typography';
import contactsImg from '../../../img/contact.jpg';
import { useNotification } from '../../layout/Notifier';
import { sendContactEmail } from '../../../redux/contactemail/contactemailSlice';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '75%',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  hero: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      height: 'auto',
      padding: theme.spacing(2),
    },
  },
  grid: {
    margin: '3rem 0 3rem',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  container: {
    marginBottom: theme.spacing(14),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    borderBottom: 'solid thin white',
    paddingBottom: theme.spacing(1),
  },
  img: {
    display: 'block',
    width: '100%',
    height: '40vh',
    objectFit: 'cover',
    filter: 'brightness(50%)',
  },
}));

const Contact = () => {
  const dispatch = useDispatch();
  const showNotification = useNotification();
  const classes = useStyles();
  const [state, setState] = useState({
    name: '',
    email: '',
    message: '',
  });

  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (state.email === '' || state.password === '') {
      showNotification('Please fill in all contact form fields.', 'error');
    }
    dispatch(sendContactEmail(state));
    showNotification(
      'You have successfully contacted Fillstorship.',
      'success',
    );
  };

  return (
    <Fragment>
      <Wrapper>
        <div style={{ position: 'relative' }}>
          <img src={contactsImg} className={classes.img} alt='' />
          <div className={classes.hero}>
            <Typography variant='h4' className={classes.header} gutterBottom>
              Contact Fillstorship
            </Typography>
          </div>
        </div>
        <Grid
          className={classes.grid}
          container
          direction='row'
          justify='center'
        >
          <Grid item xs={12} sm={6} align='center'>
            <Typography variant='body1'>
              Feel free to message us if you have any questions, comments or
              concerns.
            </Typography>
            <form className={classes.root}>
              <div>
                <TextField
                  id='standard-basic'
                  label='Name'
                  name='name'
                  onChange={onChange}
                  value={state.name}
                />
                <TextField
                  id='standard-basic'
                  label='E-mail'
                  name='email'
                  onChange={onChange}
                  value={state.email}
                />
                <TextField
                  id='standard-multiline-static'
                  label='Message'
                  multiline
                  rows={5}
                  name='message'
                  onChange={onChange}
                  value={state.message}
                />
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  onClick={onSubmit}
                >
                  Send Message
                </Button>
              </div>
            </form>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            align='justify'
            className={classes.infoContainer}
          >
            <Typography variant='body1'>
              <b>Phone Number:</b> 909-592-9000
            </Typography>
            <Typography>
              <b>Address:</b> 2356 First Street La Verne, CA 91750
            </Typography>
            <Typography>
              <b>E-mail:</b> ship@fillstorship.com
            </Typography>
          </Grid>
        </Grid>
        <Footer />
      </Wrapper>
    </Fragment>
  );
};

export default Contact;
