import React, { Fragment } from 'react';
import Wrapper from '../../../wrappers/Wrapper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Services from './Services';
import WelcomeHero from '../Landing/Hero';
import MediaCard from './MediaCard';
import Footer from '../../../layout/Footer';
import { image_two, image_three, image_four } from '../../../../assets/landing';

const useStyles = makeStyles((theme) => ({
  image: {
    width: '75%',
    height: 'auto',
    borderRadius: '50%',
    border: 'solid thin #039be5',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      width: '50%',
    },
  },
  grid: {
    width: '100%',
    height: '100%',
  },
  item: {
    margin: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
    },
  },
  paper: {
    height: '100%',
    padding: theme.spacing(2),
  },
}));

const Detail = [
  {
    image: image_three,
    header: 'Fulfillment',
    body: `Fillstorship warehouses all the components that make up your
      complete product. Orders are received through your ecommerce
      driver and based on sales volume, we fulfill as needed, “On
      Demand” by pre-determined volumes that you can control.
      Warehousing, fulfillment and distribution are all wrapped up in
      detailed reports tracking inventory, sales trends and custom set
      alerts to advise when levels are low.`,
  },
  {
    image: image_two,
    header: 'Storage',
    body: `Fillstorship provides optimal warehousing spaces that are modern,
      efficient, sanitation-certified, and securely monitored for our
      customer’s inventory.`,
  },
  {
    image: image_four,
    header: 'Shipping',
    body: `Fillstorship enables you to maximize efficiency, maintain
      visibility, and reduce cost. Our shipping coverage extends to the
      entire Southwestern United States. Products we ship for our customers
      include beauty care, wellness and healthcare, industrial supplies,
      pre-packaged food products, limited quantity hazardous materials,
      and more.`,
  },
];

const Landing = () => {
  const classes = useStyles();

  return (
    <Wrapper>
      <Fragment>
        <WelcomeHero />
        <Services />
        <Grid container item justify='center' className={classes.grid}>
          {Detail.map((d, index) => (
            <Grid
              item
              key={index}
              xs={12}
              sm={3}
              align='center'
              className={classes.item}
            >
              <MediaCard image={d.image} header={d.header} body={d.body} />
            </Grid>
          ))}
        </Grid>
        <Footer />
      </Fragment>
    </Wrapper>
  );
};
export default Landing;
