import React from 'react';
import MaterialTable from 'material-table';
import Chip from '@material-ui/core/Chip';
import useTaskTable from './useTaskTable';
import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';

const TaskTable = () => {
  const {
    data,
    users,
    onRowAdd,
    onRowDelete,
    isLoading,
    isUpdating,
  } = useTaskTable();
  let userName = {};

  const columns = [
    {
      title: 'User',
      field: 'user',
      lookup: users !== null ? userName : { na: 'n/a' },
    },
    {
      title: 'Priority Level',
      field: 'priority',
      lookup: { Low: 'Low', Medium: 'Medium', High: 'High' },
      render: (rowData) => {
        return (
          <Chip
            label={rowData.priority}
            style={{
              color: 'white',
              backgroundColor:
                rowData.priority === 'Low'
                  ? green[500]
                  : rowData.priority === 'Medium'
                  ? orange[500]
                  : rowData.priority === 'High'
                  ? red[500]
                  : null,
            }}
          />
        );
      },
    },
    { title: 'Task', field: 'task' },
  ];
  if (users !== null) users.forEach((u) => (userName[u._id] = u.name));

  return (
    <MaterialTable
      title='Tasks'
      columns={columns}
      data={data}
      editable={{ onRowAdd, onRowDelete }}
      isLoading={isLoading || isUpdating}
    />
  );
};

export default TaskTable;
