import React, { useEffect, useState } from 'react';
import { styled } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';

import { useField } from 'react-final-form';

import { parseInteger } from '../../../../utils/parsers';

import { renderField } from '../fields';
import { numberValidate } from '../validations';
import { isEqualDefaultProduct } from './utils';

const GridWithWidth = styled(Grid)(({ theme, width }) =>
  typeof width === 'number'
    ? {
        width: `${width * 19}px !important`,
        maxWidth: `${width * 19}px !important`,
      }
    : {},
);

/**
 * @param {Object} props
 * @param {String} props.parentName
 * @param {Boolean} props.disabled
 * @param {Boolean} props.readonly
 * @param {Boolean} props.required
 */
const Products = ({ parentName, disabled, readOnly, required }) => {
  const field = useField(parentName);

  const [isDefault, setIsDefault] = useState(false);

  useEffect(() => {
    setIsDefault(isEqualDefaultProduct(field.input.value));
  }, [field.input.value]);

  const productFields = [
    {
      type: 'text',
      gridProps: { width: 10 },
      label: 'SKU',
      name: 'sku',
    },
    {
      type: 'text',
      gridProps: { width: 10 },
      label: 'Qty',
      name: 'unitQty',
      fieldProps: {
        parse: parseInteger,
        validate: numberValidate({ check: !isDefault, name: 'unitQty' }),
      },
    },
    {
      type: 'text',
      gridProps: { width: 19 },
      label: 'Product Description',
      name: 'description',
      required: required || !isDefault,
    },
    {
      type: 'text',
      gridProps: { width: 10 },
      label: 'Lot #',
      name: 'lotNum',
    },
    {
      type: 'text',
      gridProps: { width: 19 },
      label: 'Notes',
      name: 'notes',
    },
  ];

  return productFields.map(({ name, gridProps = {}, ...fieldProps }) => (
    <GridWithWidth key={parentName + name} item {...gridProps}>
      {renderField({
        ...fieldProps,
        name: `${parentName}.${name}`,
        disabled,
        readOnly,
      })}
    </GridWithWidth>
  ));
};

export default Products;
