import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getImportDetails,
  updateImport,
  createSelectors,
} from '../../../redux/import/importSlice';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import { useNotification } from '../../layout/Notifier';
import DataTable, { createColumn } from '../../common/DataTable';
import CircularProgress from '../../common/CircularProgress';
import { Toolbar, makeStyles, Tooltip } from '@material-ui/core';
import PrimaryButton from '../../common/PrimaryButton';
import fss from '../../../api/fss';
import Chip from '../../common/Chip';

const importByIdSelector = createSelectors().selectById;
const columns = [
  createColumn('data.sku', 'Sku'),
  createColumn('data.barcode', 'Barcode'),
  createColumn('data.description', 'Description'),
  createColumn('status', 'Status', (rowData, data) => {
    const statusToType = {
      imported: 'success',
      error: 'reject',
    };
    const statusToLabel = {
      imported: 'Imported',
      not_imported: 'Not Imported',
      error: 'Import Error',
    };
    const chip = <Chip label={statusToLabel[data]} type={statusToType[data]} />;
    if (data === 'error') {
      return (
        <Tooltip title={rowData.errorMessage}>
          <span>{chip}</span>
        </Tooltip>
      );
    }
    return chip;
  }),
];

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  toolbarBtn: {
    marginRight: theme.spacing(2),
  },
}));

const ImportDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.importState.isDetailsLoading);

  useErrorHandler((state) => state.importState.detailsLoadError);
  const showNotification = useNotification();

  const importDetails = useSelector((state) => importByIdSelector(state, id));
  const [selected, setSelected] = useState([]);

  const [selectedImporting, setSelectedImporting] = useState(false);
  const [allImporting, setAllImporting] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    if (id) dispatch(getImportDetails({ importId: id }));
  }, [dispatch, id]);

  const runImport = async (importId, userId, items) => {
    try {
      const response = await fss.runImport(importId, userId, items);
      const { data, details } = response.data;
      dispatch(updateImport(data));
      const { imported, skipped, importErrors } = details;
      showNotification(
        `Import finished successfully with ${imported} imported items, ${skipped} skipped, and ${importErrors} errors.`,
        'success',
      );
    } catch (error) {
      showNotification(error?.message || error?.error || error, 'error');
    }
  };

  const importAllClick = () => {
    setAllImporting(true);
    runImport(importDetails._id).finally(() => setAllImporting(false));
  };

  const importSelectedClick = () => {
    if (!selected?.length) return;
    setSelectedImporting(true);
    runImport(importDetails._id, null, selected).finally(() =>
      setSelectedImporting(false),
    );
  };
  if (isLoading) return <CircularProgress isLoading={isLoading} />;

  return (
    <>
      <Toolbar className={classes.toolbar}>
        <PrimaryButton
          className={classes.toolbarBtn}
          isLoading={allImporting}
          onClick={importAllClick}
        >
          Import All
        </PrimaryButton>
        <PrimaryButton
          className={classes.toolbarBtn}
          isLoading={selectedImporting}
          onClick={importSelectedClick}
        >
          Import Selected
        </PrimaryButton>
      </Toolbar>
      <DataTable
        columns={columns}
        data={importDetails?.rows}
        allowSelect
        selected={selected}
        onSelectedChange={setSelected}
      />
    </>
  );
};

export default ImportDetails;
