import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const selector = (state) => state.auth;

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { initialized, isAuthenticated, isLoading } = useSelector(selector);

  const renderProps = (props) => {
    if (!initialized) return <Component {...props} />;
    return !isAuthenticated && !isLoading ? (
      <Redirect to='/login' />
    ) : (
      <Component {...props} />
    );
  };

  return <Route {...rest} render={renderProps} />;
};

export default PrivateRoute;
