import axios from 'axios';

const DEFAULT_CONFIG = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const getUnits = () => {
  return axios.get('/api/v1/units').then((r) => {
    return r.data;
  });
};

export const getUnitsByUserId = (userId) => {
  return axios.get(`/api/v1/users/${userId.toString()}/units`).then((r) => {
    return r.data;
  });
};

export const createUnit = (unit) => {
  return axios.post('/api/v1/units', unit, DEFAULT_CONFIG).then((r) => r.data);
};

export const updateUnit = (unit) => {
  return axios
    .put(`/api/v1/units/${unit._id}`, unit, DEFAULT_CONFIG)
    .then((r) => r.data);
};

export const deleteUnit = (id) => {
  return axios.delete(`/api/v1/units/${id}`).then((r) => r.data);
};

export const breakCase = (id) => {
  return axios.post(`/api/v1/units/${id}/break`).then((r) => r.data);
};

export default {
  getUnits,
  getUnitsByUserId,
  createUnit,
  updateUnit,
  deleteUnit,
  breakCase,
};
