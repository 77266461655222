import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from './CircularProgress';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export const createColumn = (dataKey, label, render) => ({
  dataKey,
  label,
  render,
});

const DataTable = ({
  columns,
  data,
  isLoading,
  onRowClick,
  allowSelect,
  selected = [],
  onSelectedChange = () => {},
}) => {
  const classes = useStyles();
  if (isLoading) return <CircularProgress isLoading={isLoading} />;
  const rowCount = data?.length;

  const renderColumn = column => {
    return <TableCell key={column.dataKey}>{column.label}</TableCell>;
  };

  const renderCell = (rowData, column) => {
    const data = column.dataKey.split('.').reduce((a, b) => a[b], rowData);
    const content = column.render ? column.render(rowData, data) : data;
    return <TableCell key={column.dataKey}>{content}</TableCell>;
  };

  const onSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = data.map(n => n._id);
      onSelectedChange(newSelecteds);
    } else {
      onSelectedChange([]);
    }
  };

  const isRowSelected = id => selected.indexOf(id) !== -1;

  const onRowCheckboxChange = rowData => {
    const { _id: id } = rowData;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    onSelectedChange(newSelected);
  };

  const renderRow = rowData => {
    return (
      <TableRow
        key={rowData._id}
        hover={onRowClick !== undefined}
        onClick={() => onRowClick && onRowClick(rowData)}
      >
        {allowSelect && (
          <TableCell padding="checkbox">
            <Checkbox
              checked={isRowSelected(rowData._id)}
              onChange={() => onRowCheckboxChange(rowData)}
            />
          </TableCell>
        )}
        {columns?.map(column => renderCell(rowData, column))}
      </TableRow>
    );
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {allowSelect && (
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={
                    selected.length > 0 && selected.length < rowCount
                  }
                  checked={rowCount > 0 && selected.length === rowCount}
                  onChange={onSelectAllClick}
                />
              </TableCell>
            )}
            {columns?.map(item => renderColumn(item))}
          </TableRow>
        </TableHead>
        <TableBody>{data?.map(row => renderRow(row))}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default DataTable;
