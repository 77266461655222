import React, { useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { MdPictureAsPdf } from 'react-icons/md';

const StyledToggleButton = withStyles({
  root: { color: 'rgba(0, 0, 0, 0.54)', border: 'none' },
})(ToggleButton);

const ReportTableController = ({
  data: { tableData, ...data },
  disabled,
  onClickPDF = () => {},
}) => {
  const handleClick = useCallback(
    (value) => () => {
      switch (value) {
        case 'PDF':
          onClickPDF(data);
          break;
        default:
          return;
      }
    },
    [data, onClickPDF],
  );

  const actions = [
    {
      title: 'Download PDF',
      value: 'PDF',
      Icon: MdPictureAsPdf,
      buttonProps: {
        disabled: !data?.pdf,
      },
    },
  ].filter((action) => {
    return !!action;
  });

  return actions.map(
    ({ iconProps = {}, title, value, Icon, buttonProps = {} }, index) => (
      <Tooltip key={value} title={title}>
        <StyledToggleButton
          onClick={handleClick(value)}
          value={value}
          {...buttonProps}
          disabled={disabled || buttonProps.disabled}
        >
          <Icon size={16} {...iconProps} />
        </StyledToggleButton>
      </Tooltip>
    ),
  );
};

export default ReportTableController;
