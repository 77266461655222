import React, { useCallback, useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import ClearIcon from '@material-ui/icons/Clear';

import AddProductAutoComplete from './AddProductAutoComplete';

const Controller = ({ disabled, fields, options, subName }) => {
  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(() => {
    setSelectedIds(() => {
      return fields.value
        ? fields.value.map(({ [subName]: product }) => product._id)
        : [];
    });
  }, [fields.value, subName]);

  const getOptionLabel = (key) => (option) => {
    return !option ? '' : option[key];
  };

  const getOptionSelected = (key) => (option, value) => {
    return option[key] === value[key];
  };

  const onAddProduct = useCallback(
    (product) => {
      fields.push({ [subName]: product, quantity: 0 });
    },
    [fields, subName],
  );

  const onCleanProducts = useCallback(() => {
    const removeIndexs = Array.from({ length: fields.length }).map((e, i) => i);
    fields.removeBatch(removeIndexs);
  }, [fields]);

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Button
          disabled={disabled}
          color='primary'
          onClick={onCleanProducts}
          startIcon={<ClearIcon />}
        >
          {fields.value.length} PRODUCTS
        </Button>
      </Grid>
      <Grid item xs={12} md={6}>
        <AddProductAutoComplete
          name={fields.name}
          disabled={disabled}
          getOptionLabel={getOptionLabel}
          getOptionSelected={getOptionSelected}
          onAddProduct={onAddProduct}
          products={options}
          selectedIds={selectedIds}
        />
      </Grid>
    </Grid>
  );
};

export default Controller;
