import React, { Fragment, useState } from 'react';
import Wrapper from '../../wrappers/Wrapper';
import Footer from '../../../components/layout/Footer';
import StorageQuoteDialog from '../../dialogs/StorageQuoteDialog';
import { image_one, image_two, image_three } from '../../../assets/stor';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
  },
  container_bottom: {
    position: 'relative',
  },
  hero: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      height: 'auto',
      padding: theme.spacing(2),
    },
  },
  header: {
    borderBottom: 'solid thin white',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      fontWeight: 'bold',
    },
  },
  container_text: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      width: '90%',
      padding: theme.spacing(2),
    },
  },
  grid: {
    backgroundColor: '#EBECED',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      padding: theme.spacing(2),
      order: 1,
    },
  },
  img: {
    display: 'block',
    width: '100%',
    height: '40vh',
    objectFit: 'cover',
    filter: 'brightness(50%)',
  },
  img2: {
    width: '100%',
    height: 'auto',
    padding: theme.spacing(10),
  },
}));

const Stor = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Fragment>
      <Wrapper>
        <div className={classes.container}>
          <img src={image_one} className={classes.img} alt='' />
          <div className={classes.hero}>
            <Typography variant='h4' className={classes.header} gutterBottom>
              Get your storage quote today
            </Typography>
            <Typography variant='body1' gutterBottom>
              Need to store product at our facility? You can get an instant
              quote by filling out a form here on our site. Try it out today.
              Our price won't be matched.
            </Typography>
            <Button
              variant='contained'
              color='primary'
              type='button'
              onClick={handleOpen}
            >
              Instant Quote
            </Button>
          </div>
        </div>
        <Grid
          container
          justify='center'
          alignItems='center'
          className={classes.grid}
        >
          <Grid item xs={12} sm={5}>
            <Typography variant='h5'>Our Solution</Typography>
            <Typography variant='body1'>
              Fillstorship offers turnkey solutions for your product. It’s in
              our name - Fulfillment, Storage and Shipping. The big box models
              of distribution have changed. More and more small businesses are
              bringing their products to market quicker, more cost effective and
              direct to the consumer. Our streamline process has combined
              Kitting and Assembly with Warehousing and Distribution by
              incorporating Ecommerce processing all in one place.
            </Typography>
          </Grid>
          <Grid container item justify='center' xs={12} sm={5}>
            <img src={image_two} className={classes.img2} alt={''} />
          </Grid>
        </Grid>
        <div className={classes.container_bottom}>
          <img src={image_three} className={classes.img} alt='' />
          <div className={classes.container_text}>
            <Typography variant='h5'>Inventory Pricing</Typography>
            <Typography variant='body1'>
              Fillstorship's pricing is reflective of a total cost for a
              standard, storage request. Standard fees for Fillstorship include
              receiving your inventory and warehousing your products. We also
              offer special pricing for special projects. If you have unique
              needs, Fillstorship can accommodate your business at an additional
              cost. All quotes are customized for each customer. Ask us how we
              can make it work for you!
            </Typography>
          </div>
        </div>
        <StorageQuoteDialog
          open={open}
          handleOpen={handleOpen}
          handleClose={handleClose}
        />
      </Wrapper>
      <Footer />
    </Fragment>
  );
};

export default Stor;
