import React, { PureComponent } from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

class Shipments extends PureComponent {
  render() {
    let { shipments } = this.props;

    let freq = {};
    let data;
    if (shipments) {
      shipments.forEach((shipment) => {
        let date = shipment.createdAt.slice(0, -14);
        freq[date] = freq[date] + 1 || 1;
      });

      let array = [];
      for (let [key, value] of Object.entries(freq)) {
        let obj = {};
        obj.name = moment(key).format('M/D');

        obj.shipments = value;
        array.push(obj);
      }
      array = array.sort((a, b) => new Date(a.name) - new Date(b.name));
      data = array || [];
    }

    return (
      <>
        {shipments ? (
          <Paper style={{ padding: '16px', width: '100%' }}>
            <Typography variant='h6' color='primary' gutterBottom>
              Recent Shipments
            </Typography>

            <LineChart width={500} height={300} data={data}>
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='name' />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type='monotone'
                dataKey='shipments'
                stroke='#82ca9d'
                activeDot={{ r: 2 }}
              />
            </LineChart>
          </Paper>
        ) : null}
      </>
    );
  }
}

export default Shipments;
