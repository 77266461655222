import { createUnitsSelectors } from '../../../redux/units/unitsSlice';
import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';

const selectUnits = createSelector(createUnitsSelectors().selectAll, (rows) =>
  rows.map((row) => ({ ...row, tableData: {} })),
);

const useTransactionTable = () => {
  const units = useSelector(selectUnits);

  return {
    units,
  };
};
export default useTransactionTable;
