import React from 'react';
import { styled } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const TitleBlock = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  color: 'white',
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

/**
 *
 * @param {Object} props
 * @param {'center' | 'inherit' | 'justify' | 'left' | 'right' =} props.align
 * @param {Number=} props.padding
 * @param {Number=} props.disabledTypography
 */
const Container = ({
  children,
  title = '',
  align = 'center',
  disabledTypography = false,
  padding = 2,
}) => {
  const renderTitle = () => {
    return typeof title === 'function' ? title() : title;
  };
  return (
    <Paper variant='outlined' elevation={0}>
      <TitleBlock>
        {disabledTypography ? (
          renderTitle()
        ) : (
          <Typography variant='h6' align={align}>
            {renderTitle()}
          </Typography>
        )}
      </TitleBlock>
      <Box padding={padding}>{children}</Box>
    </Paper>
  );
};

export default Container;
