import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import shopify from '../../api/shopify';

const initialState = {
  isLoading: false,
  error: '',

  isUpdating: false,
  updateError: '',

  isDownloading: false,
  isDataStale: false,
  downloadError: '',

  pagination: {
    page: 1,
    totalPages: 1,
    totalCount: 0,
  },
};

export const getShopifyOrders = createAsyncThunk(
  'shopifyOrder/getShopifyOrder',
  (params = {}, thunkApi) => {
    const { id, ...rest } = params;
    if (id) {
      return shopify.getShopifyOrderByUserId(id, rest);
    }
    return shopify.getShopifyOrders(rest);
  },
);

export const deleteShopifyOrder = createAsyncThunk(
  'shopifyOrder/delete',
  (id, thunkApi) => {
    return shopify
      .deleteShopifyOrder(id)
      .then((r) => r.data)
      .catch((err) => thunkApi.rejectWithValue(err.response.data));
  },
);

export const updateShopifyOrder = createAsyncThunk(
  'shopifyOrder/update',
  async (order, thunkApi) => {
    return shopify
      .updateShopifyOrder(order)
      .then((r) => r.data)
      .catch((err) => thunkApi.rejectWithValue(err.response.data));
  },
);

export const downloadShopifyOrders = createAsyncThunk(
  'shopifyOrder/download',
  (id, thunkApi) => {
    const downloadOrders = id
      ? shopify.downloadShopifyOrdersByUserId(id)
      : shopify.downloadShopifyOrders();

    return downloadOrders
      .then((r) => r.data)
      .catch((err) => thunkApi.rejectWithValue(err.response.data));
  },
);

const shopifyOrdersAdapter = createEntityAdapter({
  selectId: (entity) => entity._id,
});

const updatePending = (state, action) => {
  state.isUpdating = true;
  state.updateError = '';
};

const updateError = (state, action) => {
  state.isUpdating = false;
  state.updateError = action.payload.error;
};

const slice = createSlice({
  name: 'shopifyOrders',
  initialState: shopifyOrdersAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: {
    [getShopifyOrders.pending]: (state, action) => {
      state.isLoading = true;
      state.error = '';
      // state.pagination = initialState.pagination;
    },
    [getShopifyOrders.fulfilled]: (state, action) => {
      state.isLoading = false;
      const { data, count, pagination } = action.payload;
      state.pagination = {
        page: pagination.page || initialState.pagination.page,
        totalPages: pagination.totalPages || initialState.pagination.totalPages,
        totalCount: count,
      };
      state.isDataStale = false;
      shopifyOrdersAdapter.removeAll(state);
      shopifyOrdersAdapter.upsertMany(state, data);
    },
    [getShopifyOrders.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    },
    [deleteShopifyOrder.pending]: updatePending,
    [deleteShopifyOrder.fulfilled]: (state, action) => {
      state.isUpdating = false;
      shopifyOrdersAdapter.removeOne(state, action.payload.id);
    },
    [deleteShopifyOrder.rejected]: updateError,
    [updateShopifyOrder.pending]: updatePending,
    [updateShopifyOrder.fulfilled]: (state, action) => {
      state.isUpdating = false;
      const { _id, ...changes } = action.payload;
      shopifyOrdersAdapter.updateOne(state, { id: _id, changes });
    },
    [updateShopifyOrder.rejected]: updateError,
    [downloadShopifyOrders.pending]: (state, action) => {
      state.isDownloading = true;
      state.downloadError = '';
    },
    [downloadShopifyOrders.rejected]: (state, action) => {
      state.isDownloading = false;
      state.downloadError = action.payload.error;
    },
    [downloadShopifyOrders.fulfilled]: (state, action) => {
      state.isDownloading = false;
      state.isDataStale = true;
    },
  },
});

export const createShopifyOrdersSelectors = () =>
  shopifyOrdersAdapter.getSelectors((state) => state.shopifyOrders);

export default slice.reducer;
