import React from 'react';
import { styled } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';

import { MdAdd, MdRemove } from 'react-icons/md';

import ProductsRow from './ProductsRow';

import useProducts from './useProducts';

const RedIconButton = styled(IconButton)({
  color: '#f44336',
  '&:hover': {
    backgroundColor: 'rgba(244, 67, 54, 0.04)',
  },
});

const StyledTableCell = styled(TableCell)({
  borderBottom: 'unset',
});

/**
 * @param {import('react-final-form-arrays').FieldArrayRenderProps & { disabled: Boolean, readonly: Boolean, required: Boolean}} props
 */
const Products = ({ fields, disabled, readOnly, required }) => {
  const { onAddOne, onRemoveOne } = useProducts({ fields });

  return (
    <>
      <Table size='small'>
        <TableBody>
          {fields.map((fieldName, index) => (
            <TableRow key={fieldName + index}>
              <StyledTableCell padding='none'>
                {!readOnly && index > 1 && (
                  <Tooltip title={`Remove Product ${index}`}>
                    <RedIconButton
                      size='small'
                      disabled={disabled}
                      onClick={onRemoveOne(index)}
                    >
                      <MdRemove />
                    </RedIconButton>
                  </Tooltip>
                )}
              </StyledTableCell>
              <StyledTableCell>
                <Grid container spacing={2} wrap='nowrap'>
                  <ProductsRow
                    parentName={fieldName}
                    disabled={disabled}
                    readOnly={readOnly}
                    required={required && index === 0}
                  />
                </Grid>
              </StyledTableCell>
            </TableRow>
          ))}
          <TableRow>
            <StyledTableCell padding='checkbox' />
            {!readOnly && (
              <StyledTableCell>
                <Button
                  color='primary'
                  disabled={disabled}
                  onClick={onAddOne}
                  size='small'
                  startIcon={<MdAdd />}
                >
                  Add Product
                </Button>
              </StyledTableCell>
            )}
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default Products;
