import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import { Link } from 'react-router-dom';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import AllInboxIcon from '@material-ui/icons/AllInbox';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const SmallOrderMenu = ({ items, isAdmin }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const userView = items.filter((i) => i.to !== '/orders/all');
  items = isAdmin ? items : userView;

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <AllInboxIcon />
        </ListItemIcon>
        <ListItemText primary='Orders' />
      </ListItem>
      <StyledMenu
        id='customized-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {items.map(({ to, title }) => (
          <Link
            key={to}
            to={to}
            style={{ width: '100%', color: 'black', textDecoration: 'none' }}
          >
            <StyledMenuItem>
              <ListItemText primary={title} />
            </StyledMenuItem>
          </Link>
        ))}
      </StyledMenu>
    </>
  );
};

export default SmallOrderMenu;
