import React from 'react';

import Grid from '@material-ui/core/Grid';

import { renderField } from '../fields';

import { fieldList } from './utils';

const General = ({
  disabled,
  readOnly = false,
  enabledCheckboxs = ['hazardous', 'LTL', 'FTL', 'container'],
}) => {
  return (
    <Grid container spacing={2} alignItems='center'>
      {fieldList.map(({ name, gridProps = {}, ...fieldProps }) => {
        if (fieldProps.type === 'checkbox' && !enabledCheckboxs.includes(name))
          return null;
        return (
          <Grid key={name} item {...gridProps}>
            {renderField({ ...fieldProps, name, disabled, readOnly })}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default General;
