import React from 'react';
import MaterialTable, { MTableActions } from 'material-table';
import ReportTableController from './ReportTableController';
import useReportTable from './useReportTable';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

const columns = [
  { title: 'Customer', field: 'company' },
  {
    title: 'Date',
    field: 'range',
    render: (rowData) => {
      if (rowData.range.length === 10) {
        const date = moment(new Date(rowData.range))
          .utc()
          .format('dddd, MMM Do YYYY');
        return <Typography>{date}</Typography>;
      } else {
        const dates = rowData.range.split('-');
        const start = moment(new Date(dates[0]))
          .utc()
          .format('dddd, MMM Do YYYY');
        const end = moment(new Date(dates[1]))
          .utc()
          .format('dddd, MMM Do YYYY');
        return (
          <Typography>
            {start} - {end}
          </Typography>
        );
      }
    },
  },
];

const ReportTable = ({ disabledTitle = false, isAdmin, userId, type }) => {
  const {
    /* state */
    tableRef,
    data,
    title,
    isLoading,
    handleDownloadPDF,
  } = useReportTable({ userId, type });

  const options = {
    showTitle: !disabledTitle,
    rowStyle: (data, index) => {
      return index % 2 ? { backgroundColor: '#f7f7f7' } : {};
    },
    pageSize: 10,
    pageSizeOptions: [5, 10, 20, 30, 50, 75, 100],
    exportButton: true,
    exportAllData: true,
  };

  const renderActions = (props) => {
    return props.data ? (
      <>
        <MTableActions {...props} actions={[]} />
        <ReportTableController
          isAdmin={isAdmin}
          onClickPDF={handleDownloadPDF}
          {...props}
        />
      </>
    ) : (
      <MTableActions {...props} />
    );
  };

  return (
    <MaterialTable
      tableRef={tableRef}
      columns={columns}
      components={{ Actions: renderActions }}
      data={data}
      editable={{ onRowUpdate: () => {} }}
      isLoading={isLoading}
      options={options}
      title={title}
    />
  );
};

export default ReportTable;
