import React, { useCallback, useRef, useEffect, useState } from 'react';
import Webcam from 'react-webcam';

const WebcamCapturer = ({ className, onCapture }) => {
  const webcamRef = useRef();
  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const dataAvailableCallback = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        let fd = new FormData();
        fd.append('data', data);
        onCapture && onCapture(fd);
      }
    },
    [onCapture],
  );

  const startCapture = useCallback(() => {
    if (!webcamRef.current.stream || capturing) return;
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: 'video/webm',
    });

    mediaRecorderRef.current.addEventListener(
      'dataavailable',
      dataAvailableCallback,
    );

    mediaRecorderRef.current.start();
  }, [
    webcamRef,
    capturing,
    mediaRecorderRef,
    setCapturing,
    dataAvailableCallback,
  ]);

  useEffect(() => {
    return () => {
      mediaRecorderRef.current.stop();
    };
  }, [mediaRecorderRef]);

  return (
    <Webcam
      className={className}
      audio={false}
      ref={webcamRef}
      onUserMedia={startCapture}
      style={{ display: 'block' }}
    />
  );
};

export default WebcamCapturer;
