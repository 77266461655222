import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNotification } from '../../layout/Notifier';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  createMarketplace,
  getMarketplaces,
  updateMarketplace,
} from '../../../redux/marketplaces';

const WooCommerceDialog = ({ userOpen, handleWooCommerceClose }) => {
  const showNotification = useNotification();
  const dispatch = useDispatch();
  const [data, setData] = useState({
    shopName: '',
    api_key: '',
    api_secret: '',
    type: 'WooCommerce',
  });
  const { shopName, api_key, api_secret } = data;
  const [submitType, setsubmitType] = useState('');

  const listItems = [
    { label: 'Shop Name', name: 'shopName', value: shopName },
    { label: 'API Key', name: 'api_key', value: api_key },
    { label: 'API Secret', name: 'api_secret', value: api_secret },
  ];

  useEffect(() => {
    dispatch(getMarketplaces())
      .then((data) => {
        const API = data.payload.data;
        let marketplace = API[0]?.marketplace;
        if (API.length > 0 && marketplace === 'WooCommerce') {
          setData(API[0]);
          setsubmitType('UPDATE');
        } else {
          setsubmitType('POST');
        }
      })
      .catch((err) => console.log(err));
  }, [dispatch]);

  const createAPI = useCallback(async () => {
    await dispatch(createMarketplace(data))
      .then((data) => {
        if (data.payload.success === false) {
          showNotification(data.payload.error, 'error');
        } else {
          showNotification('Successful Integration to Woo Commerce', 'success');
        }
      })
      .catch((err) => console.log(err));
  }, [data, dispatch, showNotification]);

  const updateAPI = useCallback(async () => {
    dispatch(updateMarketplace({ id: data._id, data }))
      .then((data) => {
        if (data.payload.success === false) {
          showNotification(data.payload.error, 'error');
        } else {
          showNotification(
            'Successfully updated your Woo Commerce details',
            'success',
          );
        }
      })
      .catch((err) => console.log(err));
  }, [data, dispatch, showNotification]);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitType === 'POST' ? createAPI() : updateAPI();
    handleWooCommerceClose();
  };

  return (
    <Fragment>
      {data && (
        <Dialog open={userOpen} onClose={handleWooCommerceClose}>
          <DialogTitle>Integrate Woo Commerce API</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please integrate/update your Woo Commerce API credentials.
            </DialogContentText>
            {listItems.map((props) => (
              <TextField
                fullWidth
                onChange={handleChange}
                margin='dense'
                type='text'
                key={props.name}
                {...props}
              />
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleWooCommerceClose} color='primary'>
              Cancel
            </Button>
            <Button type='submit' onClick={handleSubmit} color='primary'>
              {submitType === 'POST' ? 'Integrate' : 'Update'}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Fragment>
  );
};
export default WooCommerceDialog;
