import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import DashQuickInfo from '../common/DashQuickInfo';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '90%',
    padding: theme.spacing(1),
    margin: '0 auto',
  },
}));

const Orders = ({ type }, label) => {
  const classes = useStyles();
  const { dashboard } = useSelector((state) => state.dashboard);

  const list =
    type === 'inventory'
      ? [
          { label: 'Shipments', order: dashboard.shipment },
          { label: 'Transfer In', order: dashboard.transIn },
          { label: 'Transfer Out', order: dashboard.transOut },
          { label: 'Cartonize', order: dashboard.cartonize },
          { label: 'Palletize', order: dashboard.palletize },
        ]
      : [
          { label: 'Shopify', order: dashboard.shopifyorders },
          { label: 'WooCommerce', order: dashboard.wcorders },
        ];

  return (
    <div className={classes.root}>
      <Typography variant='h6' align='left' color='primary'>
        {(label = type === 'inventory' ? 'Orders' : 'Ecommerce')}
      </Typography>
      {list.map((info, index) => (
        <DashQuickInfo info={info} key={index} />
      ))}
    </div>
  );
};

export default Orders;
