import { abbreviations, mapper } from '../../../../utils/consts/usStates';
import { numbersOnlyParser, zipParser } from '../../../../utils/parsers';
import {
  emailValidation,
  zipCodeValidation,
} from '../../../../utils/validations';

/**
 * @param {'shipper' | 'receiver'} type
 */
export const getFieldList = (type) => [
  {
    type: 'text',
    gridProps: { xs: 12 },
    name: 'company',
    label: 'Company',
    required: false,
  },
  {
    type: 'text',
    gridProps: { xs: 12, sm: 8 },
    name: 'addressOne',
    label: 'Address 1',
    required: false,
  },
  {
    type: 'text',
    gridProps: { xs: 12, sm: 4 },
    name: 'addressTwo',
    label: 'Address 2',
  },
  {
    type: 'text',
    gridProps: { xs: 12, sm: 4 },
    name: 'city',
    label: 'City',
    required: false,
  },
  {
    type: 'autocomplete',
    gridProps: { xs: 12, sm: 4 },
    name: 'state',
    label: 'State',
    disableClearable: true,
    options: abbreviations,
    getOptionValue: (option) => option || null,
    getOptionLabel: (option) => mapper[option],
    required: false,
  },
  {
    name: 'zip',
    label: 'Zip',
    required: false,
    gridProps: { xs: 12, sm: 4 },
    type: 'text',
    fieldProps: {
      parse: zipParser,
      validate: (value) => {
        return value && !zipCodeValidation(value) ? 'Invalid Zip Code' : null;
      },
    },
  },
  {
    type: 'text',
    gridProps: { xs: 12, sm: 4 },
    name: 'contact',
    label: 'Contact Name',
  },
  {
    type: 'text',
    gridProps: { xs: 12, sm: 4 },
    name: 'email',
    label: 'Email',
    fieldProps: {
      validate: (value) => {
        return value && !emailValidation(value) ? 'Invalid Email' : null;
      },
    },
  },
  {
    type: 'text',
    gridProps: { xs: 12, sm: 4 },
    name: 'telephone',
    label: 'Telephone',
    fieldProps: { parse: numbersOnlyParser },
  },
  {
    type: 'date',
    gridProps: { xs: 12, sm: 4 },
    name: type === 'shipper' ? 'pickup.date' : 'delivery.date',
    label: `${type === 'shipper' ? 'Pickup' : 'Delivery'} Date`,
    placeholder: 'yyyy-mm-dd',
    required: false,
  },
  {
    type: 'text',
    gridProps: { xs: 12, sm: 4 },
    name: type === 'shipper' ? 'pickup.from' : 'delivery.from',
    label: 'From',
    placeholder: 'hh:mm',
    required: false,
  },
  {
    type: 'text',
    gridProps: { xs: 12, sm: 4 },
    name: type === 'shipper' ? 'pickup.to' : 'delivery.to',
    label: 'To',
    placeholder: 'hh:mm',
    required: false,
  },
  { type: 'checkbox', name: 'dock', label: 'Dock' },
  { type: 'checkbox', name: 'fork', label: 'Forklift' },
  {
    type: 'checkbox',
    name: 'floor',
    label: `Floor ${type === 'shipper' ? 'Stack' : 'Unload'}`,
  },
  {
    type: 'checkbox',
    name: 'inside',
    label: `Inside ${type === 'shipper' ? 'Pickup' : 'Delivery'}`,
  },
  { type: 'checkbox', name: 'lift', label: 'Lift Gate' },
  {
    type: 'text',
    gridProps: { xs: 12 },
    name: 'notes',
    label: 'Notes',
    fullWidth: true,
    multiline: true,
    rows: 3,
  },
];

export const DEFAULT_SHIPPER = {
  company: '',
  addressOne: '',
  addressTwo: '',
  city: '',
  state: '',
  zip: '',
  contact: '',
  email: '',
  telephone: '',
  pickup: { date: null, from: null, to: null },
  dock: true,
  fork: true,
  floor: false,
  inside: false,
  lift: false,
  notes: '',
};

export const DEFAULT_RECEIVER = {
  company: '',
  addressOne: '',
  addressTwo: '',
  city: '',
  state: '',
  zip: '',
  contact: '',
  email: '',
  telephone: '',
  delivery: { date: null, from: null, to: null },
  dock: true,
  fork: true,
  floor: false,
  inside: false,
  lift: false,
  notes: '',
};
