import { useCallback, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useNotification } from '../../layout/Notifier';

import { getUnits, selectAllUnits } from '../../../redux/units/unitsSlice';
import { getCases, selectAllCases } from '../../../redux/cases/casesSlice';
import {
  getPallets,
  palletsSelectors,
} from '../../../redux/pallets/palletsSlice';
import { selectAllUsers } from '../../../redux/users/usersSlice';

import { processProducts, processCSV } from '../../forms/OrderForm_v2';

/**
 * @typedef {Object} State
 * @property {Object | null} initialValues
 * @property {String | null} billTo
 * @property {String | null} originator
 * @property {{file: File, type: 'cases' | 'units'} | null} isLoadingCSV
 */
/** @type {State} */
const initialState = {
  initialValues: null,
  billTo: null,
  originator: null,
  isLoadingCSV: null,
};

/**
 * @typedef {Object} ActionInitialize
 * @property {'initialize'} type
 */
/**
 * @typedef {Object} ActionObject
 * @property {'set-initial-values' | 'set-is-loading-csv'} type
 * @property {Object | {file: File, type: 'cases' | 'units'} | null} payload
 */
/**
 * @typedef {Object} ActionString
 * @property {'set-bill-to' | 'set-originator'} type
 * @property {String=} payload
 */
/**
 * @typedef {ActionInitialize | ActionObject | ActionString} Action
 */

/**
 * @param {State} state
 * @param {Action} action
 * @returns {State}
 */
const reducer = (state, action) => {
  switch (action.type) {
    case 'initialize':
      return initialState;

    case 'set-initial-values':
      return {
        ...state,
        initialValues: { ...action.payload },
      };

    case 'set-bill-to':
      return { ...state, billTo: action.payload };

    case 'set-originator':
      return { ...state, originator: action.payload };

    case 'set-is-loading-csv':
      return { ...state, isLoadingCSV: action.payload };

    default:
      return state;
  }
};

/**
 * @param {import('../../../redux/store').RootState} state
 */
const selector = (state) => {
  const { initialized, isAdmin, user } = state.auth;

  return {
    initialized,
    isAdmin,
    user,
    units: selectAllUnits(state),
    cases: selectAllCases(state),
    pallets: palletsSelectors.selectAll(state),
    users: selectAllUsers(state).filter((user) => user.role === 'user'),
  };
};

const useEditOrderDialog = ({ currentOrder, open, updateOrder }) => {
  const reduxDispatch = useDispatch();
  const showNotification = useNotification();

  const { isAdmin, units, cases, pallets, users } = useSelector(selector);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (open && !!currentOrder && state.initialValues === null) {
      const { billTo, originator } = currentOrder;
      if (!!billTo) {
        dispatch({ type: 'set-bill-to', payload: billTo });
      }
      if (!!originator) {
        dispatch({ type: 'set-originator', payload: originator });
      }
      dispatch({ type: 'set-initial-values', payload: currentOrder });
    } else if (!open && state.initialValues !== null) {
      dispatch({ type: 'initialize' });
    }
  }, [open, currentOrder, state.initialValues]);

  // Fetch Units and Cases
  useEffect(() => {
    if (!!state.billTo) {
      reduxDispatch(getUnits({ id: isAdmin ? state.billTo._id : null }));
      reduxDispatch(getCases({ id: isAdmin ? state.billTo._id : null }));
      reduxDispatch(getPallets({ id: isAdmin ? state.billTo._id : null }));
    }
  }, [state.billTo, isAdmin, reduxDispatch]);

  const onFormSubmit = useCallback(
    async (values) => {
      try {
        const order = {
          /** General */ ...currentOrder,
          ...values,
          /** Shipper */
          shipper: { ...currentOrder?.shipper, ...values?.shipper },
          /** Receiver */
          receiver: { ...currentOrder?.receiver, ...values?.receiver },
          billTo: state.billTo._id || currentOrder.billTo._id,
          originator: state.originator._id || currentOrder.originator._id,
          user: state.billTo._id || currentOrder.billTo._id,
        };
        processProducts(order, 'units', 'unit');
        processProducts(order, 'cases', 'case');
        await updateOrder(order);
      } catch (error) {
        showNotification(error.message);
      }
    },
    [
      currentOrder,
      state.billTo,
      state.originator,
      updateOrder,
      showNotification,
    ],
  );

  /**
   *
   * @param {import('final-form').FormApi} form
   */
  const onBillToChange = (form) => async (event, billTo) => {
    await form.change('units', []);
    await form.change('cases', []);
    dispatch({ type: 'set-bill-to', payload: billTo });
  };

  const onLoadCSVSuccess = useCallback(
    (file) => ({ acceptList, start, end }) => {
      const time = ((end - start) / 1000).toFixed(3);
      const message = `Imported ${file.name} success, spent ${time}s, imported ${acceptList.length} items.`;
      showNotification(message, 'success');
      dispatch({ type: 'set-is-loading-csv', payload: null });
    },
    [showNotification],
  );

  const onLoadCSVError = useCallback(
    (error) => {
      showNotification(error.message, 'error');
      dispatch({ type: 'set-is-loading-csv', payload: null });
    },
    [showNotification],
  );

  const handleLoadFromCSV = useCallback(
    ({ name, productName, file, products, form }) => {
      if (!!file && !state.isLoadingCSV) {
        dispatch({ type: 'set-is-loading-csv', payload: { file, type: name } });
        processCSV({
          name,
          productName,
          file,
          products,
          form,
          onSuccess: onLoadCSVSuccess(file),
          onError: onLoadCSVError,
        });
      }
    },
    [state.isLoadingCSV, onLoadCSVSuccess, onLoadCSVError],
  );

  const onLoadUnitsFromCSV = useCallback(
    (form) => (event) => {
      handleLoadFromCSV({
        name: 'units',
        productName: 'unit',
        file: event.target?.files[0],
        products: units,
        form,
      });
    },
    [units, handleLoadFromCSV],
  );

  const onLoadCasesFromCSV = useCallback(
    (form) => (event) => {
      handleLoadFromCSV({
        name: 'cases',
        productName: 'case',
        file: event.target?.files[0],
        products: cases,
        form,
      });
    },
    [cases, handleLoadFromCSV],
  );

  return {
    ...state,
    isAdmin,
    units,
    cases,
    users,
    pallets,
    onFormSubmit,
    onBillToChange,
    onLoadUnitsFromCSV,
    onLoadCasesFromCSV,
  };
};

export default useEditOrderDialog;
