import axios from 'axios';

const DEFAULT_CONFIG = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

const uploadFootage = (fileBlob) => {
  return axios
    .post(`/api/v1/upload`, fileBlob, DEFAULT_CONFIG)
    .then((r) => r.data);
};

export default {
  uploadFootage,
};
