import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';

import actions from './cameraActions';

const initialState = {
  isLoading: false,
  error: '',

  isUpdating: false,
  updateError: '',
};

export const uploadFootage = createAsyncThunk(
  'camera/uploadFootage',
  async (videoBlob, thunkAPI) => {
    return actions
      .uploadFootage(videoBlob)
      .then((r) => r)
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

const cameraAdapter = createEntityAdapter({
  selectId: (entity) => entity._id,
});

const updatePending = (state, action) => {
  state.isUpdating = true;
  state.updateError = '';
};

const updateError = (state, action) => {
  state.isUpdating = false;
  state.updateError = action.payload.error;
};

const slice = createSlice({
  name: 'camera',
  initialState: cameraAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: {
    [uploadFootage.pending]: updatePending,
    [uploadFootage.rejected]: updateError,
    [uploadFootage.fulfilled]: (state, action) => {
      state.isUpdating = false;
    },
  },
});

export default slice.reducer;
