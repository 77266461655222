import React from 'react';

import TextField from '@material-ui/core/TextField';

const Input = ({ input, meta, ...props }) => {
  const errorMessage = meta.error || meta.submitError || null;

  return (
    <TextField
      {...input}
      InputLabelProps={{ shrink: true }}
      error={!!errorMessage}
      helperText={errorMessage || ''}
      fullWidth
      variant='outlined'
      {...props}
    />
  );
};

export default Input;
