import { useForm } from 'react-final-form';

import { getDefaultHandlingUnit } from '../HandlingUnits';

/**
 * @typedef {Object} Commodities
 * @property {Boolean} hazardous
 * @property {import('../HandlingUnits').HandlingUnit[]} handlingUnits
 */

/** @returns {Commodities} */
export const getDefaultCommodities = () => {
  return {
    hazardous: false,
    handlingUnits: [getDefaultHandlingUnit(), getDefaultHandlingUnit()],
  };
};

const useCommodities = ({ name = 'commodities' }) => {
  const form = useForm();

  const onReset = () => {
    form.change(name, getDefaultCommodities());
  };

  return { onReset };
};

export default useCommodities;
