import React from 'react';

import Grid from '@material-ui/core/Grid';

import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import Container from './Container';
import General from './General';
import DetailsForm from './DetailForm';
import Commodities from './Commodities';
import AttachDocuments from './AttachDocuments';

const ShipmentForm = ({ disabled = false, readOnly = false }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Container title='General'>
          <General disabled={disabled} readOnly={readOnly} />
        </Container>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {[
            { name: 'shipper', title: 'Shipper' },
            { name: 'receiver', title: 'Receiver' },
          ].map(({ name, title }) => (
            <Grid key={name} item xs={12} sm={6}>
              <Container title={title}>
                <Field
                  component={DetailsForm}
                  name={name}
                  disabled={disabled}
                  readOnly={readOnly}
                />
              </Container>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Commodities
              name='commodities'
              disabled={disabled}
              readOnly={readOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <FieldArray
              name='documents'
              component={AttachDocuments}
              disabled={disabled}
              readOnly={readOnly}
              subscription={{ value: true }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ShipmentForm;
