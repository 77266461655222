import axios from 'axios';

const DEFAULT_CONFIG = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const getShipments = () => {
  return axios.get('/api/v1/shipments').then((r) => {
    return r.data;
  });
};

const getShipmentsByUserId = (userId) => {
  return axios.get(`/api/v1/users/${userId.toString()}/shipments`).then((r) => {
    return r.data;
  });
};

const createShipment = (shipment) => {
  return axios
    .post('/api/v1/shipments', shipment, DEFAULT_CONFIG)
    .then((r) => r.data);
};

const updateShipment = (shipment) => {
  return axios
    .put(`/api/v1/shipments/${shipment._id}`, shipment, DEFAULT_CONFIG)
    .then((r) => r.data);
};

const deleteShipment = (shipment) => {
  return axios.delete(`/api/v1/shipments/${shipment._id}`).then((r) => r.data);
};

export default {
  getShipments,
  getShipmentsByUserId,
  createShipment,
  updateShipment,
  deleteShipment,
};
