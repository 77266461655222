export const image_one =
  'https://fsswebsiteimages.s3-us-west-1.amazonaws.com/about/colorproof-logo.png';
export const image_two =
  'https://fsswebsiteimages.s3-us-west-1.amazonaws.com/about/easyday-logo.png';
export const image_three =
  'https://fsswebsiteimages.s3-us-west-1.amazonaws.com/about/shebd-logo.png';
export const image_four =
  'https://fsswebsiteimages.s3-us-west-1.amazonaws.com/about/essentialoxygen-logo.png';
export const image_five =
  'https://fsswebsiteimages.s3-us-west-1.amazonaws.com/about/whitemountainimaging-logo.jpg';
export const image_six =
  'https://fsswebsiteimages.s3-us-west-1.amazonaws.com/about/rodanfields-logo.jpeg';
export const image_seven =
  'https://fsswebsiteimages.s3-us-west-1.amazonaws.com/about/algenist-logo.png';
export const image_eight =
  'https://fsswebsiteimages.s3-us-west-1.amazonaws.com/about/slmd-logo.jpg';
export const image_nine =
  'https://fsswebsiteimages.s3-us-west-1.amazonaws.com/about/yoursuperfoods-logo.jpg';
export const image_ten =
  'https://fsswebsiteimages.s3-us-west-1.amazonaws.com/about/avery-logo.png';
export const image_eleven =
  'https://fsswebsiteimages.s3-us-west-1.amazonaws.com/about/cenveo-logo.jpg';
export const image_twelve =
  'https://fsswebsiteimages.s3-us-west-1.amazonaws.com/about/essentialoxygen-logo.png';
export const image_thirteen =
  'https://fsswebsiteimages.s3-us-west-1.amazonaws.com/about/pallettes.jpeg';
