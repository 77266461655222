import React from 'react';
import MaterialTable from 'material-table';
import useUserTable from './useUserTable';

const UserTable = () => {
  const {
    users,
    isLoading,
    isUpdating,
    onRowAdd,
    onRowUpdate,
    onRowDelete,
  } = useUserTable();

  const columns = [
    { title: 'Customer ID', field: 'user_id', editable: 'never' },
    { title: 'Name', field: 'name' },
    { title: 'Company', field: 'company' },
    { title: 'Address', field: 'address' },
    { title: 'Email', field: 'email' },
    { title: 'Password', field: 'password' },
    { title: 'Balance', field: 'balance' },
  ];

  const options = {
    rowStyle: (data, index) =>
      index % 2 ? { backgroundColor: '#f7f7f7' } : {},
    exportButton: true,
    exportAllData: true,
    pageSize: 50,
    pageSizeOptions: [5, 10, 20, 30, 50, 75, 100],
  };

  return (
    <MaterialTable
      title='Users'
      options={options}
      columns={columns}
      data={users}
      isLoading={isLoading || isUpdating}
      editable={{ onRowAdd, onRowUpdate, onRowDelete }}
    />
  );
};

export default UserTable;
