import { useCallback, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  processProducts,
  processCSV,
  processBulkCSV,
  getOrderFormValues,
  DEFAULT_SHIPPER,
  DEFAULT_RECEIVER,
  produceOrder,
} from '../../forms/OrderForm_v2';

import useErrorHandler from '../../../hooks/useErrorHandler';
import { useNotification } from '../../layout/Notifier';

import { getUnits, selectAllUnits } from '../../../redux/units/unitsSlice';
import { getCases, selectAllCases } from '../../../redux/cases/casesSlice';
import {
  getPallets,
  palletsSelectors,
} from '../../../redux/pallets/palletsSlice';
import { getCartonizeOrders } from '../../../redux/cartonizeorders/cartonizeordersSlice';
import { getPalletizeOrders } from '../../../redux/palletizeorders/palletizeordersSlice';
import { getTransInOrders } from '../../../redux/transinorders/transinordersSlice';
import { getTransOutOrders } from '../../../redux/transoutorders/transoutordersSlice';
import { getUsers } from '../../../redux/users/usersSlice';

/**
 * @typedef {Object} State
 * @property {Boolean} initialized
 * @property {String | null} userId
 * @property {{file: File, type: 'cases' | 'units'} | null} isLoadingCSV
 */
/** @type {State} */
const initialState = {
  initialValues: null,
  userId: null,
  isLoadingCSV: null,
  bulkOrders: [],
  modal: false,
};

/**
 * @typedef {Object} ActionString
 * @property {'set-user-id'} type
 * @property {String=} payload
 */
/**
 * @typedef {Object} ActionObject
 * @property {'set-is-loading-csv'} type
 * @property {{file: File, type: 'cases' | 'units'} | null} payload
 */
/**
 * @typedef {ActionString | ActionBoolean | ActionObject} Action
 */

/**
 * @param {State} state
 * @param {Action} action
 * @returns {State}
 */
const reducer = (state, action) => {
  switch (action.type) {
    case 'set-initial-values':
      return {
        ...state,
        initialValues: { ...getOrderFormValues(), ...action.payload },
      };

    case 'set-user-id':
      return { ...state, userId: action.payload || '' };

    case 'set-is-loading-csv':
      return { ...state, isLoadingCSV: action.payload };

    case 'set-modal-open':
      return { ...state, modal: action.payload };

    case 'set-bulk-orders':
      return { ...state, bulkOrders: action.payload || [] };

    default:
      return state;
  }
};

/**
 * @param {import('../../../redux/store').RootState} state
 */
const selector = (state) => {
  const { initialized, isAdmin, user } = state.auth;

  return {
    initialized,
    isAdmin,
    user,
    units: selectAllUnits(state),
    cases: selectAllCases(state),
    pallets: palletsSelectors.selectAll(state),
    isLoading:
      state.users.isLoading ||
      state.cartonizeorders.isLoading ||
      state.cartonizeorders.isUpdating ||
      state.palletizeorders.isLoading ||
      state.palletizeorders.isUpdating ||
      state.transinorders.isLoading ||
      state.transinorders.isUpdating ||
      state.transoutorders.isLoading ||
      state.transoutorders.isUpdating,
  };
};
const useCreateOrder = ({ createOrder = () => {}, title, reduxName = '' }) => {
  const reduxDispatch = useDispatch();
  const showNotification = useNotification();

  const {
    initialized,
    isAdmin,
    user,
    units,
    cases,
    pallets,
    isLoading,
  } = useSelector(selector);
  const [state, dispatch] = useReducer(reducer, initialState);

  useErrorHandler((state) => state?.[reduxName]?.error || null);
  useErrorHandler((state) => state?.[reduxName]?.updateError || null);

  // Get Users
  const initializeProcess = useCallback(async () => {
    if (isAdmin) await reduxDispatch(getUsers());
    dispatch({ type: 'set-initial-values', payload: {} });
    dispatch({ type: 'set-user-id', payload: isAdmin ? '' : user._id });
  }, [isAdmin, reduxDispatch, user]);

  useEffect(() => {
    if (initialized && state.initialValues === null) {
      initializeProcess();
    }
  }, [initialized, state.initialValues, initializeProcess]);
  // Get Orders

  const loadOrders = useCallback(() => {
    reduxDispatch(getCartonizeOrders());
    reduxDispatch(getPalletizeOrders());
    reduxDispatch(getTransInOrders());
    reduxDispatch(getTransOutOrders());
  }, [reduxDispatch]);

  useEffect(() => {
    if (state.initialValues !== null) {
      loadOrders();
    }
  }, [state.initialValues, loadOrders]);

  // Fetch Units and Cases
  useEffect(() => {
    if (!!state.userId) {
      reduxDispatch(getUnits({ id: isAdmin ? state.userId : null }));
      reduxDispatch(getCases({ id: isAdmin ? state.userId : null }));
      reduxDispatch(getPallets({ id: isAdmin ? state.userId : null }));
    }
  }, [state.userId, isAdmin, reduxDispatch]);

  const onFormSubmit = useCallback(
    async (values, form) => {
      try {
        const order = {
          /** General */
          ...getOrderFormValues(),
          ...values,
          /** Shipper */
          shipper: { ...DEFAULT_SHIPPER, ...values?.shipper },
          /** Receiver */
          receiver: { ...DEFAULT_RECEIVER, ...values?.receiver },
          billTo: state.userId,
          originator: user?._id,
          user: state.userId,
        };
        processProducts(order, 'units', 'unit');
        processProducts(order, 'cases', 'case');
        processProducts(order, 'pallets', 'pallet');
        await reduxDispatch(createOrder(order)).then((response) => {
          if (/fulfilled$/.test(response.type)) {
            showNotification(
              `You have successfully created a ${title || ''} Order`,
              'success',
            );
            setTimeout(form.restart);
          }
        });
      } catch (error) {
        showNotification(error.message);
      }
      return null;
    },
    [state.userId, user, reduxDispatch, createOrder, title, showNotification],
  );

  const onBulkSubmit = useCallback(
    async (values, form) => {
      try {
        dispatch({ type: 'set-modal-open', payload: false });
        let order = {
          // shipper: { ...DEFAULT_SHIPPER, ...values?.shipper },
          // receiver: { ...DEFAULT_RECEIVER, ...values?.receiver },
          billTo: state.userId,
          originator: user?._id,
          user: state.userId,
          pallets: [],
        };

        let _orders = state.bulkOrders.map((o) => {
          order = { ...o, ...order };
          return order;
        });

        await _orders.map(async (o) => {
          await reduxDispatch(createOrder(o)).then((response) => {
            if (/fulfilled$/.test(response.type)) {
              showNotification(
                `You have successfully imported an order`,
                'success',
              );
            }
          });
        });
      } catch (error) {
        showNotification(error.message);
      }
      return null;
    },
    [
      state.userId,
      state.bulkOrders,
      user,
      reduxDispatch,
      createOrder,
      showNotification,
    ],
  );

  const onUserIdChange = useCallback(
    (userId) => {
      if (userId !== state.userId) {
        dispatch({ type: 'set-user-id', payload: userId });
      }
    },
    [state.userId],
  );

  const onOrderSelect = (order) => {
    const newInitialValues = produceOrder(order);
    dispatch({ type: 'set-initial-values', payload: newInitialValues });
  };

  const onLoadCSVSuccess = useCallback(
    (file) => ({ acceptList, start, end }) => {
      // const time = ((end - start) / 1000).toFixed(3);
      // const message = `Imported ${file.name} success, spent ${time}s. Imported ${acceptList.length} items.`;
      // showNotification(message, 'success');
      dispatch({ type: 'set-bulk-orders', payload: acceptList });
      dispatch({ type: 'set-is-loading-csv', payload: null });
      dispatch({ type: 'set-modal-open', payload: true });
    },
    [],
  );

  const onLoadCSVError = useCallback(
    (error) => {
      showNotification(error.message, 'error');
      dispatch({ type: 'set-is-loading-csv', payload: null });
    },
    [showNotification],
  );

  const handleLoadFromCSV = useCallback(
    ({ name, productName, file, products, form }) => {
      if (!!file && !state.isLoadingCSV) {
        dispatch({ type: 'set-is-loading-csv', payload: { file, type: name } });
        processCSV({
          name,
          productName,
          file,
          products,
          form,
          onSuccess: onLoadCSVSuccess(file),
          onError: onLoadCSVError,
        });
      }
    },
    [state.isLoadingCSV, onLoadCSVSuccess, onLoadCSVError],
  );

  const onLoadUnitsFromCSV = useCallback(
    (form) => (event) => {
      handleLoadFromCSV({
        name: 'units',
        productName: 'unit',
        file: event.target?.files[0],
        products: units,
        form,
      });
    },
    [units, handleLoadFromCSV],
  );

  const onLoadCasesFromCSV = useCallback(
    (form) => (event) => {
      handleLoadFromCSV({
        name: 'cases',
        productName: 'case',
        file: event.target?.files[0],
        products: cases,
        form,
      });
    },
    [cases, handleLoadFromCSV],
  );

  const handleLoadFromBulkCSV = useCallback(
    ({ _units, _cases, file, form }) => {
      if (!!file && !state.isLoadingCSV) {
        dispatch({ type: 'set-is-loading-csv', payload: { file } });
        processBulkCSV({
          _units,
          _cases,
          file,
          form,
          onSuccess: onLoadCSVSuccess(file),
          onError: onLoadCSVError,
        });
      }
    },
    [state.isLoadingCSV, onLoadCSVSuccess, onLoadCSVError],
  );

  const onLoadBulkOrders = useCallback(
    (form) => (event) => {
      handleLoadFromBulkCSV({
        _units: units,
        _cases: cases,
        file: event.target?.files[0],
        form,
      });
    },
    [units, cases, handleLoadFromBulkCSV],
  );

  const handleClose = () => {
    dispatch({ type: 'set-modal-open', payload: false });
  };

  return {
    isAdmin,
    user,
    units,
    cases,
    pallets,
    isLoading,
    ...state,
    onFormSubmit,
    onBulkSubmit,
    onUserIdChange,
    onLoadUnitsFromCSV,
    onLoadCasesFromCSV,
    onLoadBulkOrders,
    handleClose,
    onOrderSelect,
  };
};

export default useCreateOrder;
