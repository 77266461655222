import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

const ImportDialog = ({ open, handleClose, onBulkSubmit, orders }) => {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth='md'>
      <DialogTitle>Import {orders.length} orders?</DialogTitle>
      <DialogActions>
        <Button onClick={handleClose} color='secondary'>
          Cancel
        </Button>
        <Button onClick={onBulkSubmit} color='primary'>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImportDialog;
