import React, { Fragment } from 'react';
import Navbar from '../layout/Navbar';

const Wrapper = ({ children }) => {
  return (
    <Fragment>
      <Navbar />
      <div>{children}</div>
    </Fragment>
  );
};

export default Wrapper;
