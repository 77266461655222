import React from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

import LoadingModal from '../../common/LoadingModal';
import UserAutoCompleteTextField from '../../common/UserAutoCompleteTextField';
import ShipmentForm from '../../forms/ShipmentForm_v2';
import DashWrapper from '../../wrappers/DashWrapper';

import QuoteDialog from './QuoteDialog';
import ShipmentSelection from './ShipmentSelection';
import SuccessDialog from './SuccessDialog';

import useBookShipment from './useBookShipment';

const BookShipment = () => {
  const {
    isAdmin,
    quote,
    user,
    initialValues,
    userId,
    quoteOpened,
    successOpened,
    isLoading,
    onFormSubmit,
    onShipmentSelect,
    onUserIdChange,
    onQuoteDialogClose,
    onSuccessDialogClose,
    onClickBook,
  } = useBookShipment();

  const renderForm = ({ handleSubmit, form }) => {
    const { submitting } = form.getState();
    const disabledSubmit = isLoading || submitting || !userId;

    return (
      <Grid container spacing={2} component='form' onSubmit={handleSubmit}>
        <Grid item xs={12} md={6}>
          {isAdmin ? (
            <UserAutoCompleteTextField
              FormHelperTextProps={{ error: !userId }}
              helperText={
                !userId
                  ? 'Please select a customer before filling out the form'
                  : null
              }
              hideAll
              label='Bill TO'
              margin='dense'
              onChange={onUserIdChange}
              required
              value={userId}
            />
          ) : (
            <TextField
              fullWidth
              label='Bill TO'
              margin='dense'
              size='small'
              value={user?.name || ''}
              variant='outlined'
              InputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
              required
            />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <ShipmentSelection
            autocompleteProps={{ disabled: !userId }}
            label='Previous Shipments'
            margin='dense'
            onChange={onShipmentSelect}
          />
        </Grid>
        <Grid item xs={12}>
          <ShipmentForm disabled={!userId} />
        </Grid>
        <Grid item xs={12}>
          <Button
            disabled={disabledSubmit}
            color='primary'
            variant='contained'
            type='submit'
          >
            submit
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <DashWrapper>
      <Form
        initialValues={initialValues}
        mutators={arrayMutators}
        onSubmit={onFormSubmit}
        render={renderForm}
        subscription={{
          submitting: true,
          pristine: true,
          initialValues: false,
        }}
      />
      <QuoteDialog
        open={quoteOpened}
        onClose={onQuoteDialogClose}
        quote={quote}
        onClickBook={onClickBook}
      />
      <SuccessDialog open={successOpened} onClose={onSuccessDialogClose} />
      <LoadingModal isLoading={isLoading} text='' />
    </DashWrapper>
  );
};

export default BookShipment;
