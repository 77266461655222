import axios from 'axios';

const createImport = ({ fields, rows, type, userId }) => {
  return axios
    .post('/api/v1/imports', { fields, rows, type, userId })
    .then((r) => r.data);
};

const getImports = (userId) => {
  return axios
    .get('/api/v1/imports', { params: { userId } })
    .then((r) => r.data);
};

const getImportDetails = (importId) => {
  return axios
    .get(`/api/v1/imports/${importId}`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response.data;
    });
};

const runImport = (importId, userId, items) => {
  return axios
    .post(`/api/v1/imports/run/${importId}`, {
      userId,
      items,
    })
    .then((r) => r.data)
    .catch((error) => {
      throw error.response.data;
    });
};

export default {
  createImport,
  getImports,
  getImportDetails,
  runImport,
};
