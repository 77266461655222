export const fieldList = [
  {
    type: 'text',
    gridProps: { xs: 12, sm: 4 },
    name: 'ponum',
    label: 'PO #',
    required: false,
  },
  {
    type: 'text',
    gridProps: { xs: 12, sm: 4 },
    name: 'carrier',
    label: 'Carrier',
    required: false,
  },
  {
    type: 'text',
    gridProps: { xs: 12, sm: 4 },
    name: 'shipId',
    label: 'Ship Id',
    required: false,
  },
  {
    type: 'text',
    gridProps: { xs: 12 },
    name: 'notes',
    label: 'Notes',
    fullWidth: true,
    multiline: true,
    rows: 3,
  },
  { type: 'checkbox', gridProps: {}, name: 'hazardous', label: 'Hazardous' },
  { type: 'checkbox', gridProps: {}, name: 'LTL', label: 'LTL' },
  { type: 'checkbox', gridProps: {}, name: 'FTL', label: 'FTL' },
  { type: 'checkbox', gridProps: {}, name: 'container', label: 'Container' },
];

export const DEFAULT_GENERAL = {
  ponum: '',
  carrier: '',
  shipId: '',
  notes: '',
  hazardous: false,
  LTL: false,
  FTL: false,
  container: false,
};
