/**
 * @typedef {Object} State
 * @property {File[]} pending
 * @property {File | null} processing
 * @property {{file: File, url: String}[]} completed
 * @property {{file: File, error: any}[]} failed
 */
/** @type {State} */
export const initialState = {
  pending: [],
  processing: null,
  completed: [],
  failed: [],
};

/**
 * @typedef {Object} PushToPending
 * @property {'push-to-pending'} type
 * @property {File} payload
 */
/**
 * @typedef {Object} ProcessNext
 * @property {'process-next'} type
 */
/**
 * @typedef {Object} ProcessCompleted
 * @property {'process-completed'} type
 * @property {String} url
 */
/**
 * @typedef {Object} ProcessFailed
 * @property {'process-failed'} type
 * @property {any} error
 */
/**
 * @typedef {Object} CleanAction
 * @property {'clean-pending' | 'clean-completed' | 'clean-failed'} type
 */
/**
 * @typedef {Object} RemoveAction
 * @property {'remove-pending' | 'remove-completed' | 'remove-failed'} type
 * @property {Number} payload
 */

/**
 *
 * @param {State} state
 * @param {PushToPending | ProcessNext | ProcessCompleted | ProcessFailed | CleanAction | RemoveAction} action
 * @returns {State}
 */
export const reducer = (state, action) => {
  switch (action.type) {
    case 'push-to-pending':
      return { ...state, pending: [...state.pending, action.payload] };

    case 'process-next':
      const pending = [...state.pending];
      const processing = pending.shift();
      return { ...state, processing, pending };

    case 'process-completed':
      return {
        ...state,
        processing: null,
        completed: [
          ...state.completed,
          { file: state.processing, url: action.url || '' },
        ],
      };

    case 'process-failed':
      return {
        ...state,
        processing: null,
        failed: [
          ...state.failed,
          { file: state.processing, error: action.error || null },
        ],
      };

    case 'clean-pending':
      return { ...state, pending: [] };

    case 'clean-completed':
      return { ...state, completed: [] };

    case 'clean-failed':
      return { ...state, failed: [] };

    case 'remove-pending':
      return {
        ...state,
        pending: state.pending.filter((v, i) => i !== action.payload),
      };

    case 'remove-completed':
      return {
        ...state,
        completed: state.completed.filter((v, i) => i !== action.payload),
      };

    case 'remove-failed':
      return {
        ...state,
        failed: state.failed.filter((v, i) => i !== action.payload),
      };

    default:
      return state;
  }
};
