import React from 'react';
import MaterialTable from 'material-table';
import useMarketTable from './useMarketTable';

const MarketTable = () => {
  const {
    marketplaces,
    isLoading,
    onRowUpdate,
    onRowDelete,
  } = useMarketTable();

  const columns = [
    { title: 'Customer', field: 'user.company' },
    { title: 'Platform', field: 'marketplace' },
    { title: 'Shop Name', field: 'shopName' },
    { title: 'Key', field: 'api_key' },
    { title: 'Pass', field: 'api_pass' },
    { title: 'Secret', field: 'api_secret' },
  ];

  const options = {
    rowStyle: (data, index) =>
      index % 2 ? { backgroundColor: '#f7f7f7' } : {},
    pageSize: 10,
    pageSizeOptions: [5, 10, 20, 30, 50, 75, 100],
  };

  return (
    <>
      <MaterialTable
        isLoading={isLoading}
        columns={columns}
        data={marketplaces}
        title='Ecommerce API'
        options={options}
        editable={{
          onRowUpdate,
          onRowDelete,
        }}
      />
    </>
  );
};
export default MarketTable;
