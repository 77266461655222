import axios from 'axios';

const getWeeklyReports = (id) => {
  return axios
    .get('/api/v1/reports/weekly', { params: { userId: id } })
    .then((r) => r.data);
};

const deleteReport = (id) => {
  return axios.delete(`/api/v1/reports/${id}`).then((r) => {
    return r.data;
  });
};

export default {
  deleteReport,
  getWeeklyReports,
};
