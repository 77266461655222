export const initialValues = {
  shipperDock: true,
  shipperForklift: true,
  shipperLiftgate: true,
  shipperFloorstack: true,
  receiverDock: true,
  receiverForklift: true,
  receiverLiftgate: true,
  packType: 'pallet',
  hazardous: true,
  stackable: true,
  loadStrap: true,
  loadBlock: true,
};

export const validate = (values) => {
  const errors = {};
  if (!values.shipperZip) {
    errors.shipperZip = 'Required';
  }
  if (!values.receiverZip) {
    errors.receiverZip = 'Required';
  }
  return errors;
};
