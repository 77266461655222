import React, { PureComponent, Fragment } from 'react';
import Paper from '@material-ui/core/Paper';
import { sortUnits } from '../../utils/sortData';
import Typography from '@material-ui/core/Typography';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
} from 'recharts';

class UnitsChart extends PureComponent {
  render() {
    const { data } = this.props;
    return (
      <Fragment>
        {data !== null && data !== undefined ? (
          <Paper style={{ padding: '16px' }}>
            <Typography variant='h6' color='primary' gutterBottom>
              Units
            </Typography>
            <BarChart
              width={500}
              height={300}
              data={sortUnits(data)}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='name' />
              <YAxis />
              <Tooltip />
              <Legend />
              <ReferenceLine y={0} stroke='#000' />
              <Bar dataKey='Quantity' fill='#82ca9d' />
            </BarChart>
          </Paper>
        ) : null}
      </Fragment>
    );
  }
}

export default UnitsChart;
