import React from 'react';
import { styled } from '@material-ui/core/styles';

import Chip from '@material-ui/core/Chip';

import {
  green,
  red,
  amber,
  deepOrange,
  blue,
  orange,
} from '@material-ui/core/colors';

const StyledChip = styled(({ status, ...props }) => {
  return <Chip label={status} {...props} />;
})(({ status = '', theme }) => {
  let backgroundColor;
  switch (status.toUpperCase()) {
    case 'PENDING':
      backgroundColor = amber[500];
      break;

    case 'APPROVED':
      backgroundColor = orange[500];
      break;
    case 'VERIFIED':
    case 'PICKED':
    case 'IN PROGRESS':
      backgroundColor = blue[500];
      break;

    case 'COMPLETED':
      backgroundColor = green[500];
      break;

    case 'REJECTED':
      backgroundColor = red[500];
      break;

    case 'CANCELED':
      backgroundColor = deepOrange[500];
      break;

    default:
      break;
  }
  return {
    width: '100%',
    color: 'white',
    backgroundColor,
  };
});

export default React.memo(StyledChip);
