import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { MdFileUpload } from 'react-icons/md';

const WhiteButton = withStyles({
  root: { color: 'rgba(255, 255, 255, 0.87) !important' },
  disabled: { color: 'rgba(255, 255, 255, 0.38) !important' },
})(Button);

const Title = ({ disabled = false, readOnly = false, onLoadFile }) => {
  return (
    <Box display='flex' alignItems='center'>
      <Box component='span' marginRight={2}>
        Attached Documents
      </Box>
      <Box marginLeft='auto'>
        {!readOnly && (
          <WhiteButton
            component='label'
            disabled={disabled}
            startIcon={<MdFileUpload />}
          >
            Select File
            <Box
              component='input'
              disabled={disabled}
              onChange={onLoadFile}
              type='file'
              display='none'
              multiple
            />
          </WhiteButton>
        )}
      </Box>
    </Box>
  );
};

export default Title;
