/**
 * @typedef {Object} DialogStatesItem
 * @property {String} title
 * @property {String} content
 * @property {String} closeLabel
 * @property {Boolean=} pickable
 * @property {Boolean=} submitable
 */
/**
 * @typedef {Object} DialogStates
 * @property {DialogStatesItem} COMPLETED
 * @property {DialogStatesItem} FULFILLED
 * @property {DialogStatesItem} PENDING
 * @property {DialogStatesItem} PICKED
 * @property {DialogStatesItem} UNFULFILLED
 */
/**
 * @type {Record<string, DialogStatesItem>}
 */
export const dialogStates = {
  COMPLETED: {
    title: 'This order has been fulfilled successfully',
    content: 'Please continue onto the next fulfillment order. Thank you.',
  },
  FULFILLED: {
    title: 'This order has been fulfilled successfully',
    content: 'Please continue onto the next fulfillment order. Thank you.',
  },
  PENDING: {
    title: 'Pick Products',
    content:
      'Please pick the product for this order. Scan all barcodes to continue fulfillment process.',
    closeLabel: 'Cancel',
    pickable: true,
  },
  PROCESSING: {
    title: 'Processing Products',
    content: 'Please continue onto the next fulfillment order. Thank you.',
  },
  PICKED: {
    title: 'Finalize Fulfillment',
    content:
      'Please confirm that the shipping details for this order is correct. Also, confirm that shipping label has been printed out and placed on packaging.',
    closeLabel: 'Cancel',
    submitable: true,
  },
  UNFULFILLED: {
    title: 'Pick Products',
    content:
      'Please pick the product for this order. Scan all barcodes to continue fulfillment process.',
    closeLabel: 'Cancel',
    pickable: true,
  },
  FAILED: {
    title: 'Order failed',
    content: 'Unable to process this order. Please try again.',
    closeLabel: 'Cancel',
    pickable: true,
  },
};

export default dialogStates;
