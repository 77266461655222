import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import UserAutoCompleteTextField from '../../common/UserAutoCompleteTextField';
import { getUsers } from '../../../redux/users/usersSlice';
import OrderTable from '../../tables/OrderTable';
import DashWrapper from '../../wrappers/DashWrapper';

/**
 * @param {import('../../../redux/store').RootState} state
 */
const selector = (state) => {
  return {
    isAdmin: state.auth.isAdmin,
    isLoading: state.auth.isLoading,
  };
};

const AllOrders = () => {
  const dispatch = useDispatch();
  const { isAdmin, isLoading } = useSelector(selector);

  const [userId, setUserId] = useState(null);

  useEffect(() => {
    if (!isLoading && isAdmin === true) {
      dispatch(getUsers());
    }
  }, [isLoading, isAdmin, dispatch]);

  const handleChange = (id) => {
    setUserId(id || null);
  };

  const tableTypes = [
    'transfer_in',
    'transfer_out',
    'cartonize',
    'palletize',
    'manual_order',
  ];

  return (
    <>
      <DashWrapper>
        <Grid container spacing={3}>
          {/* If Admin, render UserSelect Component */}
          {!isLoading && isAdmin === true && (
            <Grid item xs={12} sm={4}>
              <UserAutoCompleteTextField
                label='Customer'
                value={userId}
                onChange={handleChange}
                margin='dense'
              />
            </Grid>
          )}
          {tableTypes.map((tableType, index) => (
            <Grid key={tableType + index} item xs={12}>
              <OrderTable
                isAdmin={isAdmin}
                tableType={tableType}
                userId={userId}
                completed={false}
              />
            </Grid>
          ))}
        </Grid>
      </DashWrapper>
    </>
  );
};

export default AllOrders;
