import React, { useEffect, useState } from 'react';
import { styled, makeStyles, createStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';

import { useField } from 'react-final-form';

import { parseDecimal, parseInteger } from '../../../../utils/parsers';
import parseFloatNumber from '../../../../utils/parseFloatNumber';

import { renderField } from '../fields';

import {
  DEFAULT_HANDLING_UNIT_TYPE,
  getFreightClass,
  isEqualDefaultHandlingUnit,
} from './utils';

import { numberValidate } from '../validations';

const GridWithWidth = styled(Grid)(({ theme, width }) =>
  typeof width === 'number'
    ? {
        width: `${width * 19}px !important`,
        maxWidth: `${width * 19}px !important`,
      }
    : {},
);

const useStyle = makeStyles((theme) =>
  createStyles({
    checkboxFormLabel: ({ disabled }) => ({
      margin: 0,
      '& span': {
        fontSize: '0.78rem',
        padding: 0,
        color: !disabled ? 'rgba(0, 0, 0, 0.54)' : undefined,
      },
    }),
  }),
);

const HandlingUnitRow = ({ parentName, disabled, readOnly, required }) => {
  const classes = useStyle({ disabled });
  const [isDefault, setIsDefault] = useState(false);

  const field = useField(parentName);

  useEffect(() => {
    setIsDefault(isEqualDefaultHandlingUnit(field.input.value));
  }, [field.input.value]);

  const quantityField = useField(`${parentName}.quantity`);
  const weightField = useField(`${parentName}.weight`);
  const totalWeightField = useField(`${parentName}.totalWeight`);
  const lengthField = useField(`${parentName}.length`);
  const widthField = useField(`${parentName}.width`);
  const heightField = useField(`${parentName}.height`);
  const freightClassField = useField(`${parentName}.freightClass`);

  // Handle Total Weight Update
  useEffect(() => {
    const quantity = parseInt(quantityField.input.value) || 0;
    const weight = parseFloatNumber(weightField.input.value) || 0;
    const totalWeight = quantity * weight;
    if (totalWeightField.input.value !== totalWeight.toString()) {
      totalWeightField.input.onChange(totalWeight.toString());
    }
  }, [
    quantityField.input.value,
    weightField.input.value,
    totalWeightField.input,
  ]);

  // Handle Freight Class Update
  useEffect(() => {
    const freightClass = getFreightClass({
      quantity: quantityField.input.value,
      totalWeight: totalWeightField.input.value,
      length: lengthField.input.value,
      width: widthField.input.value,
      height: heightField.input.value,
    });
    if (freightClassField.input.value !== freightClass.toString()) {
      freightClassField.input.onChange(freightClass.toString());
    }
  }, [
    quantityField.input.value,
    totalWeightField.input.value,
    lengthField.input.value,
    widthField.input.value,
    heightField.input.value,
    freightClassField.input,
  ]);

  const handlingUnitFields = [
    {
      type: 'autocomplete',
      gridProps: { width: 8 },
      label: 'Packaging Type',
      name: 'type',
      options: DEFAULT_HANDLING_UNIT_TYPE,
      getOptionLabel: (option) => option || '',
      getOptionValue: (option) => option || '',
      required: required || !isDefault,
    },
    {
      type: 'text',
      gridProps: { width: 5 },
      label: 'HU Qty',
      name: 'quantity',
      required: required || !isDefault,
      fieldProps: {
        parse: parseInteger,
        validate: numberValidate({ check: !isDefault, name: 'Quantity' }),
      },
    },
    {
      type: 'text',
      gridProps: { width: 6 },
      label: 'HU Wt',
      name: 'weight',
      required: required || !isDefault,
      fieldProps: {
        parse: parseDecimal,
        validate: numberValidate({ check: !isDefault, name: 'Quantity' }),
      },
    },
    {
      type: 'readOnlyText',
      gridProps: { width: 6 },
      label: 'Tot Wt(Lbs)',
      name: 'totalWeight',
    },
    {
      type: 'text',
      gridProps: { width: 3.75 },
      label: 'L(In)',
      name: 'length',
      required: required || !isDefault,
      fieldProps: {
        parse: parseDecimal,
        validate: numberValidate({ check: !isDefault, name: 'Quantity' }),
      },
    },
    {
      type: 'text',
      gridProps: { width: 3.75 },
      label: 'W(In)',
      name: 'width',
      required: required || !isDefault,
      fieldProps: {
        parse: parseDecimal,
        validate: numberValidate({ check: !isDefault, name: 'Quantity' }),
      },
    },
    {
      type: 'text',
      gridProps: { width: 3.75 },
      label: 'H(In)',
      name: 'height',
      required: required || !isDefault,
      fieldProps: {
        parse: parseDecimal,
        validate: numberValidate({ check: !isDefault, name: 'Quantity' }),
      },
    },
    {
      type: 'readOnlyText',
      gridProps: { width: 6.25 },
      label: 'Freight Class',
      name: 'freightClass',
    },
    {
      type: 'checkbox',
      label: 'Stackable',
      name: 'stackable',
      formControlLabelProps: {
        labelPlacement: 'top',
        classes: { root: classes.checkboxFormLabel },
      },
    },
  ];
  return handlingUnitFields.map(({ gridProps, name, ...fieldProps }) => (
    <GridWithWidth key={parentName + name} item {...gridProps}>
      {renderField({
        ...fieldProps,
        name: `${parentName}.${name}`,
        disabled,
        readOnly,
      })}
    </GridWithWidth>
  ));
};

export default HandlingUnitRow;
