import React, { useEffect } from 'react';
import DashWrapper from '../wrappers/DashWrapper';
import UserTable from '../tables/UserTable';
import MarketTable from '../tables/MarketTable';
import { useDispatch } from 'react-redux';
import { getUsers } from '../../redux/users/usersSlice';
import { getAllMarketplaces } from '../../redux/marketplaces/marketplacesActions';

const Users = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getAllMarketplaces());
  }, [dispatch]);

  return (
    <DashWrapper>
      <UserTable />
      <MarketTable />
    </DashWrapper>
  );
};

export default Users;
