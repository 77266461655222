import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { MdReplay } from 'react-icons/md';

import PalletsSelection from './PalletsSelection';

const WhiteButton = withStyles({
  root: { color: 'rgba(255, 255, 255, 0.87) !important' },
  disabled: { color: 'rgba(255, 255, 255, 0.38) !important' },
})(Button);

const Title = ({
  title,
  products = [],
  selectedIds = [],
  disabled = false,
  readOnly = false,
  onAddProduct = () => {},
  onReset = () => {},
}) => {
  return (
    <Box display='flex' alignItems='center'>
      <Box component='span' marginRight={2}>
        {title}
      </Box>
      <Box marginLeft='auto'>
        {!readOnly && (
          <Box display='flex'>
            <PalletsSelection
              disabled={disabled}
              title={title}
              products={products}
              selectedIds={selectedIds}
              onAddProduct={onAddProduct}
            />
            <WhiteButton
              disabled={disabled}
              onClick={onReset}
              startIcon={<MdReplay />}
            >
              Reset
            </WhiteButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Title;
