import React from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const PrimaryButton = ({ children, isLoading, ...props }) => {
  const classes = useStyles();
  return (
    <Button variant="contained" color="primary" disabled={isLoading} {...props}>
      {children}
      {isLoading && (
        <CircularProgress
          className={classes.progress}
          size={24}
          color="primary"
        />
      )}
    </Button>
  );
};

export default PrimaryButton;
