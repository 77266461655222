import React from 'react';
import { useSelector } from 'react-redux';

import Container from '@material-ui/core/Container';

import CreateOrder from '../../layout/CreateOrder';

import DashWrapper from '../../wrappers/DashWrapper';

import { createCartonizeOrder } from '../../../redux/cartonizeorders/cartonizeordersSlice';

/**
 * @param {String} name
 */
const selector = (name) => /**
 * @param {import('../../redux/store').RootState} state
 * @returns {{isLoadingOrder: Boolean}}
 */ (state) => {
  return {
    isLoadingOrder: state?.[name]?.isLoading || state?.[name]?.isUpdating,
  };
};

const Cartonize = () => {
  const reduxName = 'cartonizeorders';
  const { isLoadingOrder } = useSelector(selector(reduxName));

  return (
    <DashWrapper>
      <Container component='main' maxWidth='lg'>
        <CreateOrder
          createOrder={createCartonizeOrder}
          enabledCheckboxs={['hazardous']}
          isLoadingOrder={isLoadingOrder}
          title='Cartonize'
          reduxName={reduxName}
        />
      </Container>
    </DashWrapper>
  );
};

export default Cartonize;
