import React from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { Link } from 'react-router-dom';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      color: 'black',
      textDecoration: 'none',
    },
  }),
);

const MaterialDashListItem = ({
  to = '',
  title,
  Icon,
  onClick,
  listItemProps = {},
}) => {
  const classes = useStyles();
  const location = useLocation();

  return (
    <Link className={classes.root} to={to} onClick={onClick}>
      <ListItem
        button
        selected={typeof to === 'string' && location.pathname === to}
        {...listItemProps}
      >
        {Icon && (
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
        )}
        <ListItemText primary={title} />
      </ListItem>
    </Link>
  );
};

export default MaterialDashListItem;
