import moment from 'moment';
import { DEFAULT_GENERAL } from './General';
import { DEFAULT_SHIPPER, DEFAULT_RECEIVER } from './DetailForm';
import { getDefaultCommodities } from './Commodities';
import {
  getDefaultHandlingUnit,
  isEqualDefaultHandlingUnitTextField,
} from './HandlingUnits';
import { getDefaultProduct, isEqualDefaultProduct } from './Products';

export const getDefaultShipmentFormValues = () => {
  return {
    /* General */ ...DEFAULT_GENERAL,
    /* Shipper */ shipper: { ...DEFAULT_SHIPPER },
    /* Receiver */ receiver: { ...DEFAULT_RECEIVER },
    /* Commodities */ commodities: getDefaultCommodities(),
    /* Documents */ documents: [],
  };
};

/**
 *
 * @param {import('./Commodities').Commodities} commodities
 * @returns {import('./Commodities').Commodities}
 */
const produceCommodities = ({ hazardous, handlingUnits }) => {
  const newHandlingUnits = handlingUnits.reduce((acc, handlingUnit) => {
    const products = handlingUnit.products.reduce((products, product) => {
      if (!isEqualDefaultProduct(product)) {
        products.push(product);
      }
      return products;
    }, []);

    if (
      products.length > 0 &&
      !isEqualDefaultHandlingUnitTextField(handlingUnit)
    ) {
      acc.push({ ...handlingUnit, products });
    }

    return acc;
  }, []);

  return { hazardous, handlingUnits: newHandlingUnits };
};

/**
 * @param {ReturnType<typeof getDefaultShipmentFormValues>} values
 * @return {ReturnType<typeof getDefaultShipmentFormValues>}
 */
export const produceValues = ({
  shipper: { pickup, ...shipper },
  receiver: { delivery, ...receiver },
  commodities,
  documents = [],
  ...general
}) => {
  /** @type {ReturnType<typeof getDefaultShipmentFormValues>} */
  /* General */
  const shipment = { ...DEFAULT_GENERAL, ...general };

  /* Shipper */
  shipment.shipper = { ...DEFAULT_SHIPPER, ...shipper };
  shipment.shipper.pickup.date = moment(pickup.date).format('YYYY-MM-DD');
  shipment.shipper.pickup.from = pickup.from;
  shipment.shipper.pickup.to = pickup.to;

  /* Receiver */
  shipment.receiver = { ...DEFAULT_RECEIVER, ...receiver };
  shipment.receiver.delivery.date = moment(delivery.date).format('YYYY-MM-DD');
  shipment.receiver.delivery.from = delivery.from;
  shipment.receiver.delivery.to = delivery.to;

  /* Commodities */
  shipment.commodities = produceCommodities(commodities);

  /* Documents */
  shipment.documents = [...documents];

  return shipment;
};

/**
 * @param {Object} shipment
 * @return {ReturnType<typeof getDefaultShipmentFormValues>}
 */
export const produceShipment = ({
  shipper = {},
  receiver = {},
  commodities,
  documents = [],
  ...shipment
}) => {
  /* General */
  const values = Object.keys(DEFAULT_GENERAL).reduce(
    (acc, key) => ({
      ...acc,
      [key]: !!shipment[key] ? shipment[key] : DEFAULT_GENERAL[key],
    }),
    {},
  );

  /* Shipper */
  values.shipper = Object.keys(DEFAULT_SHIPPER).reduce((acc, key) => {
    let value = DEFAULT_SHIPPER[key];
    if (!!shipper[key]) {
      if (key === 'pickup') {
        const pickup = shipper.pickup;
        if (!!shipper) {
          value.date = typeof pickup === 'string' ? pickup : pickup.date;
          value.from = typeof pickup === 'string' ? pickup : pickup.from;
          value.to = typeof pickup === 'string' ? pickup : pickup.to;
        }
      } else if (typeof value === 'boolean') {
        value = !!shipper[key];
      } else {
        value = shipper[key];
      }
    }
    return { ...acc, [key]: value };
  }, {});

  /* Receiver */
  values.receiver = Object.keys(DEFAULT_RECEIVER).reduce((acc, key) => {
    let value = DEFAULT_RECEIVER[key];
    if (!!receiver[key]) {
      if (key === 'delivery') {
        const delivery = receiver.delivery;
        if (!!receiver) {
          value.date = typeof delivery === 'string' ? delivery : delivery.date;
          value.from = typeof delivery === 'string' ? delivery : delivery.from;
          value.to = typeof delivery === 'string' ? delivery : delivery.to;
        }
      } else if (typeof value === 'boolean') {
        value = !!receiver[key];
      } else {
        value = receiver[key];
      }
    }
    return { ...acc, [key]: value };
  }, {});

  /* Commodities */
  values.commodities = !!commodities ? commodities : getDefaultCommodities();

  /* Documents */
  values.documents = [...documents];
  return values;
};

export { getDefaultCommodities, getDefaultHandlingUnit, getDefaultProduct };
