import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import {
  getPayment,
  getPayments,
  deletePayment,
  updatePayment,
  updatePayments,
} from './paymentsActions';

const initialState = {
  isLoading: false,
  error: null,
};

const paymentsAdapter = createEntityAdapter({
  selectId: (entity) => entity._id,
});

const pendingAction = (state, action) => {
  state.isLoading = true;
  state.error = null;
};

const rejectedAction = (state, action) => {
  state.isLoading = false;
  state.error = action.payload.error;
};

const fulfilledAddOne = (state, action) => {
  if (state.ids.includes(action.payload.data._id)) {
    paymentsAdapter.updateOne(state, action.payload.data);
  } else {
    paymentsAdapter.addOne(state, action.payload.data);
  }
  state.isLoading = false;
};

const fulfilledAddMany = (state, action) => {
  const ids = state.ids;
  const entities = state.entities;
  action.payload.data.forEach((payment) => {
    const id = payment._id;
    if (!ids.includes(id)) {
      ids.push(id);
    }
    entities[id] = payment;
  });
  state.ids = [...ids];
  state.entities = { ...entities };
  state.isLoading = false;
};

const slice = createSlice({
  name: 'payments',
  initialState: paymentsAdapter.getInitialState(initialState),
  reducers: {
    initiatePayments: () => paymentsAdapter.getInitialState(initialState),
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: {
    // payments/getPayment
    [getPayment.pending]: pendingAction,
    [getPayment.rejected]: rejectedAction,
    [getPayment.fulfilled]: fulfilledAddOne,
    // payments/getPayments
    [getPayments.pending]: pendingAction,
    [getPayments.rejected]: rejectedAction,
    [getPayments.fulfilled]: fulfilledAddMany,
    // payments/deletePayment
    [deletePayment.pending]: pendingAction,
    [deletePayment.rejected]: rejectedAction,
    [deletePayment.fulfilled]: fulfilledAddMany,
    // payments/updatePayment
    [updatePayment.pending]: pendingAction,
    [updatePayment.rejected]: rejectedAction,
    [updatePayment.fulfilled]: fulfilledAddOne,
    // payments/updatePayments
    [updatePayments.pending]: pendingAction,
    [updatePayments.rejected]: rejectedAction,
    [updatePayments.fulfilled]: fulfilledAddMany,
  },
});

export const { initiatePayments, clearError } = slice.actions;

export const paymentSelector = paymentsAdapter.getSelectors(
  (state) => state.payments,
);

export default slice.reducer;
