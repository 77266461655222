import React from 'react';

import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const QuoteDialog = ({ open, onClose, onClickBook }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id='alert-dialog-title'>
        Confirm shipment booking
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Shipment will be reviewed by Fillstorship and a rate will be given
          upon approval.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Close
        </Button>
        <Button onClick={onClickBook} color='primary' autoFocus>
          Book
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QuoteDialog;
