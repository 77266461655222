import { useCallback, useEffect, useReducer } from 'react';

import { useSelector } from 'react-redux';

import { getCasesByUserId, getCases } from '../../../redux/cases/casesActions';
import { getUnitsByUserId, getUnits } from '../../../redux/units/unitsActions';

/**
 * @typedef State
 * @property {'create' | 'update' | null=} mode
 * @property {Object} initialValues
 * @property {String} userId
 * @property {Object[]} cases
 * @property {Object[]} units
 */
/** @type {State} */
const initialState = {
  mode: null,
  initialValues: {
    sku: '',
    numOfPallets: null,
    notes: '',
    soNum: '',
    lotNum: '',
    cases: [],
    units: [],
  },
  numOfPallets: null,
  userId: null,
  cases: [],
  units: [],
  soNum: '',
  lotNum: '',
};
/**
 * @typedef ActionIntialize
 * @property {'intialize'} type
 */
/**
 * @typedef ActionMode
 * @property {'set-mode'} type
 * @property {'create' | 'update'=} payload
 */
/**
 * @typedef ActionInitialValues
 * @property {'set-initial-values'} type
 * @property {Object} payload
 */
/**
 * @typedef ActionUserId
 * @property {'set-user-id'} type
 * @property {String} payload
 */
/**
 * @typedef ActionObjectArray
 * @property {'set-cases' | 'set-units'} type
 * @property {Object[]} payload
 */
/** @typedef {ActionIntialize | ActionMode | ActionInitialValues | ActionUserId | ActionObjectArray} Action */
/**
 *
 * @param {State} state
 * @param {Action} action
 * @returns {State}
 */

const reducer = (state, action) => {
  switch (action.type) {
    case 'intialize':
      return initialState;

    case 'set-mode':
      return { ...state, mode: action.payload || null };

    case 'set-initial-values':
      return {
        ...state,
        initialValues: action.payload || initialState.initialValues,
      };

    case 'set-user-id':
      return { ...state, userId: action.payload || null };

    case 'set-cases':
      return { ...state, cases: action.payload };

    case 'set-units':
      return { ...state, units: action.payload };

    default:
      return state;
  }
};

const selector = (state) => {
  return {
    isAdmin: state.auth.isAdmin,
  };
};

/**
 *
 * @param {Object} param
 * @param {Boolean} param.opened
 * @param {Function} param.onClose
 * @param {Function} param.onSumbit
 * @param {Object | null} param.pallet
 */
const usePalletDialog = ({ opened, onClose, onSubmit, pallet, _userId }) => {
  const { isAdmin } = useSelector(selector);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (opened && !pallet) {
      dispatch({ type: 'set-mode', payload: 'create' });
      dispatch({ type: 'set-initial-values' });
      dispatch({ type: 'set-user-id', payload: _userId || null });
    } else if (opened && !!pallet) {
      dispatch({ type: 'set-mode', payload: 'update' });
      const { sku, numOfPallets, notes, cases, units, soNum, lotNum } = pallet;
      dispatch({
        type: 'set-initial-values',
        payload: { sku, numOfPallets, notes, cases, units, soNum, lotNum },
      });
      dispatch({ type: 'set-user-id', payload: pallet.user._id });
    } else {
      dispatch({ type: 'intialize' });
    }
  }, [opened, pallet, _userId]);

  const loadCases = useCallback(async () => {
    const response =
      isAdmin && state.userId
        ? await getCasesByUserId(state.userId)
        : await getCases();
    dispatch({ type: 'set-cases', payload: response.data });
  }, [isAdmin, state.userId]);
  const loadUnits = useCallback(async () => {
    const response =
      isAdmin && state.userId
        ? await getUnitsByUserId(state.userId)
        : await getUnits();
    dispatch({ type: 'set-units', payload: response.data });
  }, [isAdmin, state.userId]);

  useEffect(() => {
    if (!!state.mode) {
      loadCases();
      loadUnits();
    }
  }, [state.mode, state.userId, loadCases, loadUnits]);

  const onFormSubmit = useCallback(
    async (values, form) => {
      const success = await onSubmit({ ...values, user: state.userId });
      if (!success) return;
      if (state.mode === 'create') {
        setTimeout(form.restart);
      } else if (state.mode === 'update') {
        onClose();
      }
      return;
    },
    [state.mode, state.userId, onClose, onSubmit],
  );

  const onChangeUserId = (userId) => {
    dispatch({ type: 'set-user-id', payload: userId });
    dispatch({ type: 'set-initial-values' });
  };

  return { isAdmin, ...state, onFormSubmit, onChangeUserId };
};

export default usePalletDialog;
