import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import actions from './quoteActions';

const initialState = {
  quote: null,
  isLoading: false,
  error: null,
};

export const getQuote = createAsyncThunk(
  'quote/getQuote',
  async (quote, thunkAPI) => {
    return actions
      .getQuote(quote)
      .then((r) => r.data)
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

const slice = createSlice({
  name: 'quote',
  initialState: initialState,
  reducers: {
    initializeQuote: () => initialState,
  },
  extraReducers: {
    [getQuote.pending]: () => {
      return { ...initialState, isLoading: true };
    },
    [getQuote.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [getQuote.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.quote = action.payload;
    },
  },
});

export const { initializeQuote } = slice.actions;

export default slice.reducer;
