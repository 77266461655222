import React from 'react';

import ReadOnlyTextField from './ReadOnlyTextField';

const PaymentInformation = ({ transaction }) => {
  return (
    <>
      {transaction?.payment?.creditCard && (
        <>
          <ReadOnlyTextField
            label='Card Type'
            value={transaction?.payment?.creditCard?.cardType}
          />
          <ReadOnlyTextField
            label='Card Number'
            value={transaction?.payment?.creditCard?.cardNumber}
          />
          <ReadOnlyTextField
            label='Expiration Date'
            value={transaction?.payment?.creditCard?.expirationDate}
          />
        </>
      )}
      {transaction?.payment?.bankAccount && (
        <>
          <ReadOnlyTextField
            label='Routing Number'
            value={transaction?.payment?.bankAccount?.routingNumber}
          />
          <ReadOnlyTextField
            label='Account Number'
            value={transaction?.payment?.bankAccount?.accountNumber}
          />
          <ReadOnlyTextField
            label='Name On Account'
            value={transaction?.payment?.bankAccount?.nameOnAccount}
          />
          <ReadOnlyTextField
            label='E-Check Type'
            value={transaction?.payment?.bankAccount?.echeckType}
          />
        </>
      )}
    </>
  );
};

export default PaymentInformation;
