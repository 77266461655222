import React from 'react';
import { useSelector } from 'react-redux';
import Container from '@material-ui/core/Container';
import CreateOrder from '../../layout/CreateOrder';
import DashWrapper from '../../wrappers/DashWrapper';
import { createManualOrder } from '../../../redux/manualorders/manualordersSlice';

/**
 * @param {String} name
 */
const selector = (name) => /**
 * @param {import('../../redux/store').RootState} state
 * @returns {{isLoadingOrder: Boolean}}
 */ (state) => {
  return {
    isLoadingOrder: state?.[name]?.isLoading || state?.[name]?.isUpdating,
  };
};

const Manual = () => {
  const reduxName = 'manualorders';
  const { isLoadingOrder } = useSelector(selector(reduxName));

  return (
    <DashWrapper>
      <Container component='main' maxWidth='lg'>
        <CreateOrder
          createOrder={createManualOrder}
          isLoadingOrder={isLoadingOrder}
          enabledCheckboxs={[]}
          title='Manual Order'
          tableType='manual_order'
          reduxName={reduxName}
        />
      </Container>
    </DashWrapper>
  );
};

export default Manual;
