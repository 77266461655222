import papaparse from 'papaparse';
import { DEFAULT_GENERAL } from './General';
import { DEFAULT_SHIPPER, DEFAULT_RECEIVER } from './DetailForm';
import { DEFAULT_ADD_SHIPPING_INFO } from './ShippingInformation';
import { getDefaultProduct } from './Products';
import _ from 'lodash';

export const getOrderFormValues = () => {
  return {
    /* General */ ...DEFAULT_GENERAL,
    /* Shipper */ shipper: { ...DEFAULT_SHIPPER },
    /* Receiver */ receiver: { ...DEFAULT_RECEIVER },
    /* Additional Shipping */ shipping: { ...DEFAULT_ADD_SHIPPING_INFO },
    /* Units */ units: [],
    /* Cases */ cases: [],
    /* Pallets */ pallets: [],
    /* Documents */ documents: [],
  };
};

/**
 * @param {any[]} products
 */
const processListMapper = (products) => {
  return products.reduce(
    (acc, product) => {
      if (!!product.sku) {
        acc.list.push(product.sku);
        acc.mapper[product.sku] = { ...product };
      }
      return acc;
    },
    { list: [], mapper: {} },
  );
};

/**
 * @param {Object} params
 * @param {File} params.file
 * @param {import('final-form').FormApi} params.form
 * @param {String} params.name
 * @param {String} params.productName
 * @param {any[]} params.products
 * @param {({acceptCount: Number, start: Number, end: Number}) => void} params.onSuccess
 * @param {(error: Error) => void} params.onError
 */
export const processCSV = ({
  file,
  form,
  name,
  productName,
  products,
  onSuccess = () => {},
  onError = () => {},
}) => {
  const start = Date.now();
  const { list: skus, mapper: skuMapper } = processListMapper(products);
  let row = 0,
    skuRowMapper = new Map(),
    acceptList = [],
    parseError = null,
    headerMapper = new Map();

  papaparse.parse(file, {
    worker: true,
    step: ({ data, errors }, parser) => {
      row++;

      try {
        if (errors.length > 0) throw new Error(errors[0].message);

        if (row === 1) {
          // Get header index
          data.forEach((header, index) => {
            headerMapper.set(header.toLowerCase(), index);
          });

          if (!headerMapper.has('sku')) {
            throw new Error(
              'Unsupported column in CSV file. Supported columns [sku, quantity, lotNum, notes]',
            );
          }
        } else {
          const sku = data[headerMapper.get('sku')];
          // Reject if not match record
          if (!skus.includes(sku)) {
            throw new Error(
              `ROW ${row}: ${sku} does not match our ${name} record.`,
            );
          }

          // Reject if repeat
          if (skuRowMapper.has(sku)) {
            throw new Error(
              `ROW ${skuRowMapper.get(sku)} and ${row}: ${sku} is duplicate.`,
            );
          }

          const newProduct = getDefaultProduct();
          Object.keys(newProduct).forEach((key) => {
            if (headerMapper.has(key.toLowerCase())) {
              newProduct[key] = data[headerMapper.get(key.toLowerCase())];
            }
          });

          // Success
          skuRowMapper.set(sku, row);
          acceptList.push({ ...newProduct, [productName]: skuMapper[sku] });
        }
      } catch (error) {
        parseError = error;
        parser.abort();
      }
    },
    complete: async () => {
      if (parseError === null) {
        if (row > 0) {
          form.change(name, [...acceptList]);
          if (typeof onSuccess === 'function')
            onSuccess({
              start,
              end: Date.now(),
              acceptList,
            });
        }
      } else {
        if (typeof onError === 'function') onError(parseError);
      }
    },
    error: (error) => {
      if (typeof onError === 'function') onError(error);
    },
  });
};

export const processBulkCSV = async ({
  _units,
  _cases,
  cases,
  file,
  form,
  onSuccess = () => {},
  onError = () => {},
}) => {
  const start = Date.now();
  let allResults = [];
  let parseError = null;

  _units = await cleanData(_units);
  _cases = await cleanData(_cases);
  let row = 0;

  papaparse.parse(file, {
    header: true,
    step: async ({ data, errors }, parser) => {
      if (data.shipper__company && data.receiver__company) row++;

      let newData = formatData(row, data, _units, _cases);

      allResults.push(newData);
    },
    complete: (results) => {
      if (parseError === null) {
        let result = allResults.reduce(mergeEquallyLabeledTypes, {
          store: {},
          list: [],
        }).list;

        result.forEach((r) => {
          if (r.cases) r.cases = r.cases.filter((c) => c.case !== undefined);
          if (r.units) r.units = r.units.filter((u) => u.unit !== undefined);
        });

        onSuccess({
          start,
          end: Date.now(),
          acceptList: result,
          open: true,
        });
      } else {
        if (typeof onError === 'function') onError(parseError);
      }
    },
    error: (error) => {
      if (typeof onError === 'function') onError(error);
    },
  });
};

export const produceOrder = ({
  shipper = {},
  receiver = {},
  shipping = {},
  documents = [],
  units = [],
  cases = [],
  pallets = [],
  ...order
}) => {
  /* General */
  const values = Object.keys(DEFAULT_GENERAL).reduce(
    (acc, key) => ({
      ...acc,
      [key]: !!order[key] ? order[key] : DEFAULT_GENERAL[key],
    }),
    {},
  );

  /* Shipper */
  values.shipper = Object.keys(DEFAULT_SHIPPER).reduce((acc, key) => {
    let value = DEFAULT_SHIPPER[key];
    if (!!shipper[key]) {
      if (typeof value === 'boolean') {
        value = !!shipper[key];
      } else {
        value = shipper[key];
      }
    }
    return { ...acc, [key]: value };
  }, {});

  /* Receiver */
  values.receiver = Object.keys(DEFAULT_RECEIVER).reduce((acc, key) => {
    let value = DEFAULT_RECEIVER[key];

    if (!!receiver[key]) {
      if (typeof value === 'boolean') {
        value = !!receiver[key];
      } else {
        value = receiver[key];
      }
    }
    return { ...acc, [key]: value };
  }, {});

  /* Shipping */
  values.shipping = Object.keys(DEFAULT_ADD_SHIPPING_INFO).reduce(
    (acc, key) => {
      let value = DEFAULT_ADD_SHIPPING_INFO[key];

      if (!!shipping[key]) {
        if (typeof value === 'boolean') {
          value = !!shipping[key];
        } else {
          value = shipping[key];
        }
      }
      return { ...acc, [key]: value };
    },
    {},
  );

  /* Products */
  values.units = !!units ? units : getDefaultProduct();
  values.cases = !!cases ? cases : getDefaultProduct();
  values.pallets = !!pallets ? pallets : getDefaultProduct();
  /* Documents */
  values.documents = [...documents];

  return values;
};

const cleanData = async (products) => {
  const filters = ['_id', 'sku'];

  let data = await products.map((unit) => _.pick(unit, filters));
  data = await data.reduce((map, obj) => {
    map[obj.sku] = obj._id;
    return map;
  }, {});

  return data;
};

const formatData = (row, data, _units, _cases) => {
  let obj = {};
  obj.row = row;

  let units = formatUnits(
    data.units__sku,
    data.units__quantity,
    data.units__notes,
    data.units__lotNum,
    data.units__itemNum,
    _units,
  );

  let cases = formatCases(
    data.cases__sku,
    data.cases__quantity,
    data.cases__notes,
    data.cases__lotNum,
    data.cases__itemNum,
    _cases,
  );

  let receiver = formatLocation(
    data.receiver__addressOne,
    data.receiver__addressTwo,
    data.receiver__city,
    data.receiver__company,
    data.receiver__contact,
    data.receiver__country,
    data.receiver__email,
    data.receiver__state,
    data.receiver__telephone,
    data.receiver__zip,
  );

  let shipper = formatLocation(
    data.shipper__addressOne,
    data.shipper__addressTwo,
    data.shipper__city,
    data.shipper__company,
    data.shipper__contact,
    data.shipper__country,
    data.shipper__email,
    data.shipper__state,
    data.shipper__telephone,
    data.shipper__zip,
  );

  let shipping = formatShipping(
    data.shipping__additionalComments,
    data.shipping__carrier2,
    data.shipping__deliveryDate,
    data.shipping__instructions,
    data.shipping__noOfPallets,
    data.shipping__service,
    data.shipping__shipDate,
    data.shipping__shipmentCost,
    data.shipping__shipperCost,
    data.shipping__totalWeight,
    data.shipping__trackingNumber,
  );

  obj.units = [units];
  obj.cases = [cases];
  obj.receiver = receiver;
  obj.shipper = shipper;
  obj.shipping = shipping;

  obj.shipId = data.shipId;
  obj.ponum = data.ponum;
  obj.notes = data.notes;
  obj.carrier = data.carrier;
  obj.hazardous = setBoolean(data.hazardous);
  obj.container = setBoolean(data.container);
  obj.LTL = setBoolean(data.LTL);
  obj.FTL = setBoolean(data.FTL);

  return obj;
};

const setBoolean = (boolean) => {
  switch (boolean) {
    case 'FAlSE':
      return false;
    case 'TRUE':
      return true;
    default:
      return false;
  }
};

const formatUnits = (sku, quantity, notes, lotNum, itemNum, products) => {
  return { unit: products[sku], quantity, notes, lotNum, itemNum };
};

const formatCases = (sku, quantity, notes, lotNum, itemNum, products) => {
  return { case: products[sku], quantity, notes, lotNum, itemNum };
};

const formatLocation = (
  addressOne,
  addressTwo,
  city,
  company,
  contact,
  country,
  email,
  state,
  telephone,
  zip,
) => {
  return {
    addressOne,
    addressTwo,
    city,
    company,
    contact,
    country,
    email,
    state,
    telephone,
    zip,
  };
};

const formatShipping = (
  carrier2,
  service,
  trackingNumber,
  shipperCost,
  shipmentCost,
  shipDate,
  deliveryDate,
  noOfPallets,
  totalWeight,
  instructions,
  additionalComments,
) => {
  return {
    carrier2,
    service,
    trackingNumber,
    shipperCost,
    shipmentCost,
    shipDate,
    deliveryDate,
    noOfPallets,
    totalWeight,
    instructions,
    additionalComments,
  };
};

const mergeEquallyLabeledTypes = (collector, type) => {
  let key = type.row;
  let store = collector.store;
  let storedType = store[key];
  if (storedType) {
    storedType.units = storedType.units.concat(type.units);
    storedType.cases = storedType.cases.concat(type.cases);
  } else {
    store[key] = type;
    collector.list.push(type);
  }
  return collector;
};
