import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Inventory from '../pages/Inventory/Inventory';
import InventoryImport from '../pages/Inventory/InventoryImport';
import ImportDetails from '../pages/Inventory/ImportDetails';
import DashWrapper from '../wrappers/DashWrapper';

export const InventoryRoute = () => {
  const { path } = useRouteMatch();
  return (
    <DashWrapper>
      <Switch>
        <Route exact path={path}>
          <Inventory />
        </Route>
        <Route exact path={`${path}/import`}>
          <InventoryImport />
        </Route>
        <Route exact path={`${path}/import/:id`}>
          <ImportDetails />
        </Route>
      </Switch>
    </DashWrapper>
  );
};
