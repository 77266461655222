import HomeIcon from '@material-ui/icons/Home';
import ListAltIcon from '@material-ui/icons/ListAlt';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PaymentIcon from '@material-ui/icons/Payment';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import TimelineIcon from '@material-ui/icons/Timeline';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import EqualizerIcon from '@material-ui/icons/Equalizer';

const useListItems = ({ onLogout } = { onLogout: () => {} }) => {
  const createItems = [
    { to: '/shipment/book', title: 'Shipment' },
    {
      to: '/transin/create',
      title: 'Transfer In',
      appBarTitle: 'Create Transfer In Order',
    },
    {
      to: '/transout/create',
      title: 'Transfer Out',
      appBarTitle: 'Create Transfer Out Order',
    },
    {
      to: '/cartonize/create',
      title: 'Cartonize',
      appBarTitle: 'Create Cartonize Order',
    },
    {
      to: '/palletize/create',
      title: 'Palletize',
      appBarTitle: 'Create Palletize Order',
    },
    {
      to: '/manual/create',
      title: 'Manual',
      appBarTitle: 'Create Manual Order',
    },
  ];

  const ordersItems = [
    { to: '/orders/all', title: 'All', appBarTitle: 'All Orders' },
    {
      to: '/orders/unprocessed',
      title: 'Open',
      appBarTitle: 'Open Orders',
    },
    {
      to: '/shipments',
      title: 'Shipments',
      appBarTitle: 'Shipments',
    },
    {
      to: '/orders/transin',
      title: 'Transfer In',
      appBarTitle: 'Transfer In Orders',
    },
    {
      to: '/orders/transout',
      title: 'Transfer Out',
      appBarTitle: 'Transfer Out Orders',
    },
    {
      to: '/orders/cartonize',
      title: 'Cartonize',
      appBarTitle: 'Cartonize Orders',
    },
    {
      to: '/orders/palletize',
      title: 'Palletize',
      appBarTitle: 'Palletize Orders',
    },
    {
      to: '/orders/manual',
      title: 'Manual',
      appBarTitle: 'Manual Orders',
    },
  ];

  const fulfillmentItems = [
    {
      to: '/shopify',
      title: 'Shopify',
      appBarTitle: 'Shopify',
    },
    {
      to: '/woocommerce',
      title: 'WooCommerce',
      appBarTitle: 'WooCommerce',
    },
  ];

  const mainListItems = [{ to: '/home', title: 'Home', Icon: HomeIcon }];

  const mainListItems2 = [
    { to: '/inventory', title: 'Inventory', Icon: ListAltIcon },
  ];

  const adminListItems = [
    { to: '/users', title: 'Users', Icon: SupervisedUserCircleIcon },
    { to: '/tasks', title: 'Tasks', Icon: AccountTreeIcon },
    { to: '/transactions', title: 'Transactions', Icon: EqualizerIcon },
  ];

  const secondaryListItems = [
    { to: '/profile', title: 'Profile', Icon: AccountCircleIcon },
    { to: '/reports', title: 'Reports', Icon: TimelineIcon },
    { to: '/payments', title: 'Payments', Icon: PaymentIcon },
    { title: 'Logout', Icon: ExitToAppIcon, onClick: onLogout },
  ];

  const mappingItems = () => {
    return [
      ...createItems,
      ...ordersItems,
      ...fulfillmentItems,
      ...mainListItems,
      ...adminListItems,
      ...secondaryListItems,
    ].reduce(
      (acc, cur) => {
        const { to, ...rest } = cur;
        if (to === undefined) return acc;

        acc.paths.push(to);
        acc.pathToTitle[to] = rest;
        return acc;
      },
      { paths: [], pathToTitle: {} },
    );
  };

  return {
    createItems,
    ordersItems,
    fulfillmentItems,
    mainListItems,
    mainListItems2,
    adminListItems,
    secondaryListItems,
    ...mappingItems(),
  };
};

export default useListItems;
