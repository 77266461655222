import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';

import MaterialDash from '../layout/MaterialDash';

const useStyles = makeStyles((theme) => ({
  root: { display: 'flex' },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    backgroundColor: 'white',
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

const DashWrapper = ({ className, children }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)}>
      <MaterialDash />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth='xl' className={classes.container}>
          <div>{children}</div>
        </Container>
      </main>
    </div>
  );
};

export default DashWrapper;
