import React from 'react';
import { useTheme, makeStyles, createStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import LinearProgress from '@material-ui/core/LinearProgress';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import green from '@material-ui/core/colors/green';

import BarcodeReader from '../../common/BarcodeReader';
import WebcamCapturer from '../../common/WebcamCapturer';
import WebcamDraggableContainer from '../../layout/WebcamDraggableContainer';

import usePickDialog from './usePickDialog';

const useStyles = makeStyles((theme) =>
  createStyles({
    pickedCell: {
      backgroundColor: green[500],
      color: 'white',
    },
    webcam: {
      width: '100%',
    },
  }),
);

const PickDialog = ({
  disabledWebcam = false,
  isLoading,
  onClose,
  onPick,
  order,
  products = [],
  productName,
  title = 'Pick Order',
  orderNo = '',
  subTitle = '',
  pickBtnLabel = 'Pick',
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const {
    pickedMapper,
    allPicked,
    onScan,
    onProductClick,
    onScanError,
    onCapture,
  } = usePickDialog({
    order,
    products,
    productName,
  });

  const renderTableRow = ({
    id,
    sku,
    barcode,
    description,
    quantity,
    picked,
  }) => {
    const cellStyle = picked === quantity ? classes.pickedCell : undefined;
    return (
      <TableRow key={id} onClick={() => onProductClick(id)}>
        <TableCell className={cellStyle}>{sku}</TableCell>
        <TableCell className={cellStyle}>{barcode}</TableCell>
        <TableCell className={cellStyle}>{description}</TableCell>
        <TableCell className={cellStyle} align='right'>
          {quantity}
        </TableCell>
        <TableCell className={cellStyle} align='right'>
          {picked}
        </TableCell>
      </TableRow>
    );
  };

  const renderPickList = () => {
    return (
      <>
        {!disabledWebcam && (
          <WebcamDraggableContainer>
            <WebcamCapturer className={classes.webcam} onCapture={onCapture} />
          </WebcamDraggableContainer>
        )}
        <BarcodeReader onError={onScanError} onScan={onScan} />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>SKU</TableCell>
              <TableCell>Barcode</TableCell>
              <TableCell>Description</TableCell>
              <TableCell align='right'>Quantity</TableCell>
              <TableCell align='right'>Picked</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!!pickedMapper && Object.values(pickedMapper).map(renderTableRow)}
          </TableBody>
        </Table>
      </>
    );
  };

  return (
    <Dialog fullScreen={fullScreen} open={!!pickedMapper}>
      {(!!title || !!subTitle) && (
        <DialogTitle disableTypography>
          {!!title && (
            <Typography variant='h5' gutterBottom>
              {title}
            </Typography>
          )}
          {!!orderNo && (
            <Typography variant='caption' gutterBottom>
              Order#: {orderNo}
            </Typography>
          )}
          {!!subTitle && (
            <Typography variant='subtitle2'>{subTitle}</Typography>
          )}
        </DialogTitle>
      )}
      <DialogContent>{renderPickList()}</DialogContent>
      <DialogActions>
        <Button color='primary' disabled={isLoading} onClick={onClose}>
          Close
        </Button>
        <Button
          color='primary'
          disabled={!allPicked || isLoading}
          onClick={onPick}
        >
          {pickBtnLabel}
        </Button>
      </DialogActions>
      {isLoading && <LinearProgress aria-describedby='Loading' aria-busy />}
    </Dialog>
  );
};

export default PickDialog;
