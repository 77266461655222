import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import actions from './reportsActions';

const initialState = {
  isLoading: false,
  error: '',

  isUpdating: false,
  updateError: '',
};

export const getDailyReports = createAsyncThunk(
  'reports/getDailyReports',
  (params = {}) => {
    const { id } = params;
    return actions.getDailyReports(id);
  },
);

export const deleteReport = createAsyncThunk(
  'reports/deleteReports',
  (id, thunkAPI) => {
    return actions
      .deleteReport(id)
      .then((r) => r.data)
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

const reportsAdapter = createEntityAdapter({
  selectId: (entity) => entity._id,
});

const slice = createSlice({
  name: 'reports',
  initialState: reportsAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: {
    [getDailyReports.pending]: (state) => {
      state.isLoading = true;
      state.error = '';
    },
    [getDailyReports.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    [getDailyReports.fulfilled]: (state, action) => {
      state.isLoading = false;
      const { data } = action.payload;
      state.reports = action.payload;
      reportsAdapter.removeAll(state);
      reportsAdapter.upsertMany(state, data);
    },
    [deleteReport.pending]: (state) => {
      state.isLoading = true;
      state.error = '';
    },
    [deleteReport.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    [deleteReport.fulfilled]: (state, action) => {
      state.isUpdating = false;
      state.isLoading = false;
      reportsAdapter.removeOne(state, action.payload.id);
    },
  },
});

export const createReportsSelectors = () => {
  return reportsAdapter.getSelectors((state) => state.reports);
};

export default slice.reducer;
