import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import useErrorHandler from '../../../hooks/useErrorHandler';

const useResetPassword = ({ history }) => {
  const isAuthenticated = useSelector(
    /**
     * @param {import('../../../redux/store').RootState} state
     */
    (state) => state.auth.isAuthenticated,
  );
  useEffect(() => {
    if (isAuthenticated && typeof history?.push === 'function') {
      history.push('/home');
    }
  }, [isAuthenticated, history]);

  useErrorHandler(
    /**
     * @param {import('../../redux/store').RootState} state
     */
    (state) => state.auth.updateError,
  );

  const goToLogin = useCallback(() => {
    if (typeof history?.push === 'function') history.push('/login');
  }, [history]);

  return { goToLogin };
};

export default useResetPassword;
