import React, { PureComponent, Fragment } from 'react';
import { sortCases } from '../../utils/sortData';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

class CasesChart extends PureComponent {
  render() {
    const { data } = this.props;
    return (
      <Fragment>
        {data !== null && data !== undefined ? (
          <Paper style={{ padding: '16px' }}>
            <Typography variant='h6' color='primary' gutterBottom>
              Cases
            </Typography>
            <BarChart width={500} height={300} data={sortCases(data)}>
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='name' />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey='Pallet' stackId='a' fill='#8884d8' />
              <Bar dataKey='Shelf' stackId='a' fill='#ffc658' />
            </BarChart>
          </Paper>
        ) : null}
      </Fragment>
    );
  }
}

export default CasesChart;
