import React from 'react';

import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const QuoteDialog = ({ open, onClose, onClickUpdate }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id='alert-dialog-title'>
        Update shipment information
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Confirm the changes made to your shipment.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Close
        </Button>
        <Button onClick={onClickUpdate} color='primary' autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QuoteDialog;
