import React, { Fragment } from 'react';

import { styled, makeStyles, createStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';

import { FieldArray } from 'react-final-form-arrays';

import { MdAdd, MdRemove } from 'react-icons/md';

import Products from '../Products';

import HandlingUnitRow from './HandlingUnitRow';

import useHandlingUnits from './useHandlingUnits';

const RedButton = styled(Button)({
  color: '#f44336',
  '&:hover': {
    backgroundColor: 'rgba(244, 67, 54, 0.04)',
  },
});

const StyledTableCell = styled(TableCell)({ borderBottom: 'unset' });

const useStyle = makeStyles((theme) =>
  createStyles({
    root: {
      overflowX: 'auto',
      overflowY: 'hidden',
    },
    checkboxFormLabel: ({ disabled }) => ({
      margin: 0,
      '& span': {
        fontSize: '0.78rem',
        padding: 0,
        color: !disabled ? 'rgba(0, 0, 0, 0.54)' : undefined,
      },
    }),
    tableBody: {
      overflowX: 'auto',
      overflowY: 'hidden',
    },
  }),
);

/**
 * @param {import('react-final-form-arrays').FieldArrayRenderProps & { disabled: Boolean, readonly: Boolean}} props
 */
const HandlingUnits = ({ fields, disabled, readOnly }) => {
  const classes = useStyle({ disabled });
  const { onAddOne, onRemoveOne } = useHandlingUnits({ fields });

  return (
    <div className={classes.root}>
      <Table size='small'>
        <TableBody>
          {fields.map((fieldName, index) => (
            <Fragment key={fieldName + index}>
              <TableRow>
                <StyledTableCell style={{ paddingLeft: 0, paddingRight: 0 }}>
                  <Typography variant='body2' color='primary' noWrap>
                    Handling Unit(s) - {index + 1}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Grid container spacing={2} wrap='nowrap'>
                    <HandlingUnitRow
                      parentName={fieldName}
                      disabled={disabled}
                      readOnly={readOnly}
                      required={index === 0}
                    />
                  </Grid>
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell style={{ verticalAlign: 'top', padding: 0 }}>
                  {!readOnly && index > 1 && (
                    <RedButton
                      startIcon={<MdRemove />}
                      onClick={onRemoveOne(index)}
                    >
                      Remove
                    </RedButton>
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  <FieldArray
                    name={`${fieldName}.products`}
                    component={Products}
                    disabled={disabled}
                    readOnly={readOnly}
                    subscription={{ value: true }}
                    required={index === 0}
                  />
                </StyledTableCell>
              </TableRow>
            </Fragment>
          ))}
          <TableRow>
            <StyledTableCell />
            {!readOnly && (
              <StyledTableCell>
                <Button
                  color='primary'
                  disabled={disabled}
                  onClick={onAddOne}
                  size='small'
                  startIcon={<MdAdd />}
                >
                  Add Commodity
                </Button>
              </StyledTableCell>
            )}
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default HandlingUnits;
