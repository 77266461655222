import React from 'react';

import { useSelector } from 'react-redux';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import { usersSelectors } from '../../redux/users/usersSlice';

const usersSelectorsObj = usersSelectors();

const selector = (state) => {
  return {
    users: usersSelectorsObj
      .selectAll(state)
      .sort((a, b) => a.company.localeCompare(b.company))
      .filter((a) => a.role !== 'admin'),
    entities: usersSelectorsObj.selectEntities(state),
  };
};

const UserAutoCompleteTextField = ({
  id,
  value,
  onChange,
  autocompleteProps = {},
  hideAll,
  ...props
}) => {
  const { users, entities } = useSelector(selector);

  const onAutoChange = (event, newValue) => {
    onChange(newValue ? newValue._id : null);
  };

  const options = hideAll ? users : [null, ...users];

  return (
    <Autocomplete
      autoHighlight
      disableClearable
      getOptionLabel={(option) => (option ? option.company : 'All')}
      id={id || 'user-auto-complete-field'}
      value={(value && entities[value]) || null}
      onChange={onAutoChange}
      options={options || []}
      {...autocompleteProps}
      renderInput={(params) => (
        <TextField
          {...params}
          variant='outlined'
          {...props}
          InputLabelProps={{
            ...params.InputLabelProps,
            shrink: true,
            ...props.InputLabelProps,
          }}
          InputProps={{ ...params.InputProps, ...props.InputProps }}
        />
      )}
    />
  );
};

export default UserAutoCompleteTextField;
