import React, { useCallback } from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Tooltip from '@material-ui/core/Tooltip';

import {
  MdCheck,
  MdCheckCircle,
  MdClose,
  MdEdit,
  MdPictureAsPdf,
  MdAttachMoney,
  MdDeleteForever,
  MdUndo,
  MdTimer,
} from 'react-icons/md';

/**`
 * @typedef ShipmentsTableControllerProps
 * @property {Boolean} disabled
 * @property {Boolean} isAdmin
 * @property {Function} onClickPending
 * @property {Function} onClickApprove
 * @property {Function} onClickCancel
 * @property {Function} onClickChangeRate
 * @property {Function} onClickComplete
 * @property {Function} onClickEdit
 * @property {Function} onClickInProgress
 * @property {Function} onClickRejected
 */
/**
 * @param {ShipmentsTableControllerProps} props
 */
const ShipmentsTableController = ({
  data: { tableData, ...data },
  disabled,
  isAdmin,
  onClickPending = () => {},
  onClickApprove = () => {},
  onClickCancel = () => {},
  onClickChangeRate = () => {},
  onClickComplete = () => {},
  onClickEdit = () => {},
  onClickInProgress = () => {},
  onClickRejected = () => {},
  onClickPDF = () => {},
  onClickDelete = () => {},
}) => {
  const status = data.status.toUpperCase();

  const handleClick = useCallback(
    (value) => () => {
      switch (value) {
        case 'EDIT':
          onClickEdit(data);
          break;
        case 'CHANGE_RATE':
          onClickChangeRate(data);
          break;
        case 'PENDING':
          onClickPending(data);
          break;
        case 'APPROVE':
          onClickApprove(data);
          break;
        case 'IN PROGRESS':
          onClickInProgress(data);
          break;
        case 'COMPLETE':
          onClickComplete(data);
          break;
        case 'REJECT':
          onClickRejected(data);
          break;
        case 'CANCEL':
          onClickCancel(data);
          break;
        case 'PDF':
          onClickPDF(data);
          break;
        case 'DELETE':
          onClickDelete(data);
          break;
        default:
          break;
      }
    },
    [
      data,
      onClickEdit,
      onClickChangeRate,
      onClickPending,
      onClickApprove,
      onClickInProgress,
      onClickComplete,
      onClickRejected,
      onClickCancel,
      onClickPDF,
      onClickDelete,
    ],
  );

  const actions = [
    { title: 'Download PDF', type: 'PDF', Icon: MdPictureAsPdf },
    /** Edit */
    isAdmin || (!isAdmin && status === 'PENDING')
      ? {
          title: 'Edit',
          type: 'EDIT',
          Icon: MdEdit,
        }
      : null,
    /** Change Rate */
    isAdmin
      ? {
          title: 'Change Rate',
          type: 'CHANGE_RATE',
          Icon: MdAttachMoney,
        }
      : null,
    /** Pending */
    isAdmin && status !== 'PENDING'
      ? {
          iconProps: { color: 'orange' },
          title: 'Pending',
          type: 'PENDING',
          Icon: MdUndo,
        }
      : null,
    /** Approve */
    (isAdmin && status !== 'APPROVED') || (isAdmin && status === 'REJECTED')
      ? {
          iconProps: { color: 'green' },
          title: 'Approve',
          type: 'APPROVE',
          Icon: MdCheck,
        }
      : null,
    /** In Progress */
    isAdmin && status !== 'IN PROGRESS'
      ? {
          iconProps: { color: 'blue' },
          title: 'In Progress',
          type: 'IN PROGRESS',
          Icon: MdTimer,
        }
      : null,
    /** Complete */
    isAdmin && status !== 'COMPLETED'
      ? {
          iconProps: { color: 'green' },
          title: 'Complete',
          type: 'COMPLETE',
          Icon: MdCheckCircle,
        }
      : null,
    /** Reject */
    isAdmin && status !== 'REJECTED'
      ? {
          iconProps: { color: 'red' },
          title: 'Reject',
          type: 'REJECT',
          Icon: MdClose,
        }
      : null,
    /** Cancel */
    !isAdmin
      ? {
          iconProps: { color: 'red' },
          title: 'Cancel',
          type: 'CANCEL',
          Icon: MdClose,
        }
      : null,
    isAdmin
      ? {
          iconProps: { color: 'red' },
          title: 'Delete Shipment',
          type: 'DELETE',
          Icon: MdDeleteForever,
        }
      : null,
  ].filter((action) => !!action);

  const renderActions = () => {
    return actions.map(({ iconProps = {}, title, type, Icon }, index) => {
      return (
        <Tooltip key={type + index} title={title}>
          <ToggleButton
            style={{ border: 'none' }}
            disabled={disabled}
            onClick={handleClick(type)}
            value={type}
          >
            <Icon color='black' size={16} {...iconProps} />
          </ToggleButton>
        </Tooltip>
      );
    });
  };

  return <>{renderActions()}</>;
};

export default ShipmentsTableController;
