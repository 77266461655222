import React from 'react';
import CircularProgressMUI from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: { display: 'flex', justifyContent: 'center' },
});

const CircularProgress = ({ isLoading }) => {
  const classes = useStyles();

  if (!isLoading) return null;
  return (
    <div className={classes.container}>
      <CircularProgressMUI color="primary" />
    </div>
  );
};
export default CircularProgress;
