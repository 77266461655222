import React from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { AutoSizer, List } from 'react-virtualized';

import { TextField } from 'mui-rff';

import { FiDelete } from 'react-icons/fi';

import { numbersOnlyParser } from '../../../utils/parsers';

const FieldList = ({ disabled, displayFields, fields }) => {
  const sm = 12 / (displayFields.length + 1);

  const onClickRemove = (index) => () => {
    fields.remove(index);
  };

  const rowRenderer = ({ index, style }) => {
    const name = `${fields.name}[${index}]`;
    return (
      <Grid
        key={name}
        container
        spacing={2}
        alignItems='center'
        style={{ ...style, marginTop: 4 }}
      >
        {displayFields.map((displayField) => {
          const fieldName = `${name}.${displayField.name}`;
          return (
            <Grid key={fieldName} item xs={12} sm={sm}>
              <TextField
                disabled={disabled}
                fullWidth
                label={displayField.label}
                InputLabelProps={{ shrink: true }}
                InputProps={{ readOnly: true }}
                name={fieldName}
                size='small'
                variant='outlined'
              />
            </Grid>
          );
        })}
        <Grid item xs>
          <TextField
            disabled={disabled}
            fieldProps={{ parse: numbersOnlyParser }}
            fullWidth
            label='Quantity'
            InputLabelProps={{ shrink: true }}
            name={`${name}.quantity`}
            required
            size='small'
            variant='outlined'
          />
        </Grid>
        <Grid item>
          <Tooltip title='Remove'>
            <IconButton disabled={disabled} onClick={onClickRemove(index)}>
              <FiDelete />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };
  return (
    <AutoSizer disableHeight>
      {({ width }) => {
        return (
          <List
            height={fields.length <= 10 ? fields.length * (56 + 4) + 4 : 648}
            rowCount={fields.length}
            rowHeight={56}
            width={width}
            rowRenderer={rowRenderer}
          />
        );
      }}
    </AutoSizer>
  );
};

export default FieldList;
