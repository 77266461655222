import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableContainer from '@material-ui/core/TableContainer';

import DetailPanelContainer from './DetailPanelContainer';

import objectDeepFind from '../../../utils/objectDeepFind';

const useStyle = makeStyles((theme) =>
  createStyles({
    container: { maxHeight: 33 * 10 + 37 },
  }),
);

const List = ({ title, data = [], columns }) => {
  const classes = useStyle();

  return (
    <DetailPanelContainer title={`${title} (${data.length})`}>
      <TableContainer className={classes.container} component={Paper}>
        <Table stickyHeader size='small'>
          <TableHead>
            <TableRow>
              {columns.map(({ title, field, ...columnProps }, index) => (
                <TableCell key={title + field + index} {...columnProps}>
                  {title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <TableRow key={index}>
                {columns.map(({ title, field, ...columnProps }, index) => (
                  <TableCell key={title + field + index} {...columnProps}>
                    {objectDeepFind(item, field)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </DetailPanelContainer>
  );
};

export default List;
