import React from 'react';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import UnitsChart from './UnitsChart';
import Shipments from './Shipments';
import Shopify from './Shopify';
import CasesChart from './CasesChart';

const DashboardCharts = () => {
  const dash = useSelector((state) => state.dashboard);
  const { units, cases, shipments, shopify } = dash.dashboard;

  return (
    <>
      <Grid item xs={6}>
        <UnitsChart data={units} />
      </Grid>
      <Grid item xs={6}>
        <CasesChart data={cases} />
      </Grid>
      <Grid item xs={6}>
        <Shipments shipments={shipments} />
      </Grid>
      <Grid item xs={6}>
        <Shopify shopify={shopify} />
      </Grid>
    </>
  );
};

export default DashboardCharts;
