import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import DashWrapper from '../../wrappers/DashWrapper';
import TransactionTable from '../../tables/TransactionTable';
import { getUnits } from '../../../redux/units/unitsSlice';

const Transactions = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUnits());
  }, [dispatch]);

  return (
    <DashWrapper>
      <TransactionTable />
    </DashWrapper>
  );
};

export default Transactions;
