import axios from 'axios';

const DEFAULT_CONFIG = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const getPallets = () => {
  return axios.get('/api/v1/pallets').then((r) => r.data);
};

const getPalletsByUserId = (userId) => {
  return axios
    .get(`/api/v1/users/${userId.toString()}/pallets`)
    .then((r) => r.data);
};

const createPallet = (pallet) => {
  return axios
    .post('/api/v1/pallets', pallet, DEFAULT_CONFIG)
    .then((r) => r.data);
};

const updatePallet = (pallet) => {
  return axios
    .put(`/api/v1/pallets/${pallet._id}`, pallet, DEFAULT_CONFIG)
    .then((r) => r.data);
};

const deletePallet = (id) => {
  return axios.delete(`/api/v1/pallets/${id}`).then((r) => r.data);
};

export default {
  getPallets,
  getPalletsByUserId,
  createPallet,
  updatePallet,
  deletePallet,
};
