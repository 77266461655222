import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import UserAutoCompleteTextField from '../../common/UserAutoCompleteTextField';
import CaseForm from '../../forms/CaseForm';
import useCaseDialog from './useCaseDialog';

const useStyle = makeStyles((theme) =>
  createStyles({
    root: { position: 'relative' },
    progress: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
    },
  }),
);

const CaseDialog = ({
  isLoading: _isLoading,
  opened,
  onClose,
  onSubmit,
  pallet,
  userId: _userId,
}) => {
  const classes = useStyle();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const {
    isAdmin,
    mode,
    initialValues,
    userId,
    units,
    onFormSubmit,
    onChangeUserId,
  } = useCaseDialog({
    opened,
    onClose,
    onSubmit,
    pallet,
    _userId,
  });

  const modeString = mode ? mode.charAt(0).toUpperCase() + mode.slice(1) : '';

  /**
   * @param {FormRenderProps} props
   */
  const renderForm = ({ handleSubmit, form }) => {
    const { dirty, pristine, submitting } = form.getState();
    return (
      <>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            {isAdmin && (
              <Box marginBottom={2}>
                <UserAutoCompleteTextField
                  autocompleteProps={{ disabled: mode === 'update' }}
                  hideAll
                  label='Customer'
                  margin='dense'
                  onChange={onChangeUserId}
                  value={userId}
                />
              </Box>
            )}
            <CaseForm
              disabled={isAdmin && userId === null}
              units={units}
              mode={mode}
              isAdmin={isAdmin}
            />
          </DialogContent>
          <DialogActions>
            <Button disabled={_isLoading} onClick={onClose} variant='outlined'>
              Close
            </Button>
            <Button
              disabled={_isLoading || !dirty || pristine || submitting}
              color='primary'
              type='submit'
              variant='outlined'
            >
              {modeString}
            </Button>
          </DialogActions>
        </form>
        {(_isLoading || submitting) && (
          <LinearProgress className={classes.progress} color='primary' />
        )}
      </>
    );
  };

  return (
    <Dialog
      className={classes.root}
      fullScreen={fullScreen}
      fullWidth
      maxWidth='md'
      open={!!mode}
      onClose={onClose}
      scroll='paper'
    >
      <DialogTitle>{`${modeString} Case`}</DialogTitle>
      <Form
        initialValues={initialValues}
        mutators={arrayMutators}
        onSubmit={onFormSubmit}
        render={renderForm}
        subscription={{ submitting: true, pristine: true }}
      />
    </Dialog>
  );
};

export default CaseDialog;
