import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

const CompleteDialog = ({
  currentOrder,
  open,
  handleClose,
  onClickComplete,
  count = 0,
}) => {
  const completeOrder = () => {
    onClickComplete(currentOrder);
  };

  return (
    <Dialog open={open}>
      <DialogTitle>{`Complete Order #${currentOrder?.order_no}?`}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          Confirm completion of the order. After an order is completed,
          inventory quantities will be changed.
        </DialogContentText>

        <Grid container>
          <Grid item xs={12}>
            <Typography color='primary'>Warning: Low Inventory</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='overline'>Units</Typography>

            <br />
            {currentOrder.units.map((u, index) => {
              if (u.unit.total <= 10) {
                count++;
                return (
                  <Typography key={index} variant='overline' color='error'>
                    {u.unit.sku} ({u.unit.total})
                  </Typography>
                );
              } else return null;
            })}
          </Grid>
          <Grid item xs={6}>
            <Typography variant='overline'>Cases</Typography>
            <br />
            {currentOrder.cases.map((c, index) => {
              if (c.case.total <= 10) {
                count++;
                return (
                  <Typography key={index} variant='overline' color='error'>
                    {c.case.sku} ({c.case.total})
                  </Typography>
                );
              } else return null;
            })}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='secondary' autoFocus>
          Cancel
        </Button>
        <Button onClick={completeOrder} color='primary' autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CompleteDialog;
