import React from 'react';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import _ from 'lodash';
import { red, amber, orange, blue } from '@material-ui/core/colors';

const statusColor = (status, s) => {
  switch (status) {
    case 'Pending':
      return s.Pending ? (
        <Chip
          label={`${s.Pending} Pending`}
          variant='outlined'
          style={{ color: amber[500], borderColor: amber[500] }}
        />
      ) : null;
    case 'Approved':
      return s.Approved ? (
        <Chip
          label={`${s.Approved} Approved`}
          variant='outlined'
          style={{ color: orange[500], borderColor: orange[500] }}
        />
      ) : null;
    case 'Rejected':
      return s.Rejected ? (
        <Chip
          label={`${s.Rejected} Rejected`}
          variant='outlined'
          style={{ color: red[500], borderColor: red[500] }}
        />
      ) : null;
    case 'In Progress':
      return s['In Progress'] ? (
        <Chip
          label={`${s['In Progress']} In Progress`}
          variant='outlined'
          style={{ color: blue[500], borderColor: blue[500] }}
        />
      ) : null;
    default:
      return null;
  }
};

const sortShipments = (shipments) => {
  let shortedShipments = {};

  shipments.forEach((shipment) => {
    if (!shortedShipments[shipment.status]) {
      shortedShipments[shipment.status] = 1;
    } else {
      shortedShipments[shipment.status] += 1;
    }
  });

  return shortedShipments;
};

const Quickview = ({ data }) => {
  let shipments = sortShipments(data);

  return (
    <>
      {_.size(data) !== 0 ? (
        <Box display='flex' flexDirection='row' marginBottom='10px'>
          {['Pending', 'In Progress', 'Approved', 'Rejected'].map(
            (type, index) => {
              return (
                <span style={{ margin: 2 }} key={index}>
                  {statusColor(type, shipments)}
                </span>
              );
            },
          )}
        </Box>
      ) : null}
    </>
  );
};

export default Quickview;
