import React from 'react';

import MuiCheckbox from '@material-ui/core/Checkbox';
import MuiFormControl from '@material-ui/core/FormControl';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import MuiFormHelperText from '@material-ui/core/FormHelperText';

const Checkbox = ({
  input,
  meta,
  label,
  checkboxProps,
  formControlProps,
  formControlLabelProps,
  formHelperTextProps,
}) => {
  const errorMessage = meta.error || meta.submitError || null;
  return (
    <MuiFormControl {...formControlProps} error={!!errorMessage}>
      <MuiFormControlLabel
        control={
          <MuiCheckbox
            id={input.name}
            checked={input.checked}
            onChange={input.onChange}
            {...checkboxProps}
          />
        }
        label={label}
        {...formControlLabelProps}
      />
      {errorMessage && (
        <MuiFormHelperText {...formHelperTextProps}>
          {errorMessage}
        </MuiFormHelperText>
      )}
    </MuiFormControl>
  );
};
export default Checkbox;
