import { useSelector, useDispatch } from 'react-redux';
import { forgotPassword } from '../../../../redux/auth/authSlice';

const useForgotForm = ({ onSuccess }) => {
  const isUpdating = useSelector(
    /**
     * @param {import('../../../../redux/store').RootState} state
     */
    (state) => state.auth.isUpdating,
  );
  const dispatch = useDispatch();

  const onFormSubmit = async ({ email }, form) => {
    await dispatch(forgotPassword({ email })).then((response) => {
      if (/fulfilled$/.test(response.type)) {
        if (typeof onSuccess === 'function') onSuccess();
        else setTimeout(form.restart);
      }
    });
    return null;
  };

  return {
    isLoading: isUpdating,
    onFormSubmit,
  };
};

export default useForgotForm;
