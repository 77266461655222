import { useCallback, useEffect, useReducer, useRef } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { CsvBuilder } from 'filefy';

import {
  palletsSelectors,
  createPallet,
  deletePallet,
  updatePallet,
} from '../../../redux/pallets/palletsSlice';

import { useErrorHandler } from '../../../hooks/useErrorHandler';
import { useNotification } from '../../layout/Notifier';

/**
 * @typedef {Object} State
 * @property {Boolean} opened
 * @property {String} currentPalletId
 */
/** @type {State} */
const initialState = {
  opened: false,
  currentPalletId: null,
};

/**
 * @typedef ActionNonPayload
 * @property {'open-dialog' | 'close-dialog'} type
 */
/**
 * @typedef ActionString
 * @property {'set-current-pallet-id'} type
 * @property {String} payload
 */
/**
 * @typedef {ActionNonPayload | ActionString} Action
 */
/**
 * @param {State} state
 * @param {Action} action
 * @returns {State}
 */
const reducer = (state, action) => {
  switch (action.type) {
    case 'open-dialog':
      return { ...state, opened: true };

    case 'close-dialog':
      return { ...state, opened: false };

    case 'set-current-pallet-id':
      return { ...state, currentPalletId: action.payload || null };

    default:
      return state;
  }
};

const selector = (state) => {
  const pallets = palletsSelectors
    .selectAll(state)
    .map((pallet) => ({ ...pallet, tableData: {} }));
  return {
    isLoading: state.pallets.isLoading,
    isUpdating: state.pallets.isUpdating,
    pallets,
  };
};

const palletSelector = (id) => (state) => {
  const pallet = id ? palletsSelectors.selectById(state, id) || null : null;
  return pallet;
};

const usePalletTable = ({ onGetPallets, reloadAll, userId }) => {
  const { isLoading, isUpdating, pallets } = useSelector(selector);
  const reduxDispatch = useDispatch();
  const tableRef = useRef();
  const [{ currentPalletId, ...state }, dispatch] = useReducer(
    reducer,
    initialState,
  );
  const currentPallet = useSelector(palletSelector(currentPalletId));
  const showNotification = useNotification();

  useErrorHandler((state) => state.pallets.error);
  useErrorHandler((state) => state.pallets.updateError);

  useEffect(() => {
    if (typeof onGetPallets === 'function') {
      onGetPallets();
    }
  }, [onGetPallets, userId]);

  const onRowDelete = useCallback(
    async (rowData) => {
      const response = await reduxDispatch(deletePallet(rowData._id));
      if (/fulfilled$/.test(response.type)) {
        reloadAll({ pallets: false });
      }
    },
    [reduxDispatch, reloadAll],
  );

  const onClickAdd = (event, data) => {
    dispatch({ type: 'open-dialog' });
  };

  const onClickUpdate = (event, data) => {
    dispatch({ type: 'open-dialog' });
    dispatch({ type: 'set-current-pallet-id', payload: data._id });
  };

  const onDialogClose = () => {
    dispatch({ type: 'close-dialog' });
    dispatch({ type: 'set-current-pallet-id' });
  };

  const onDialogSubmit = useCallback(
    async (data) => {
      data.cases = data.cases.map(({ quantity, ...rest }) => ({
        quantity: parseInt(quantity),
        ...rest,
      }));
      data.units = data.units.map(({ quantity, ...rest }) => ({
        quantity: parseInt(quantity),
        ...rest,
      }));
      let response = null;
      if (!currentPallet) {
        response = await reduxDispatch(createPallet(data));
      } else {
        response = await reduxDispatch(
          updatePallet({ ...currentPallet, ...data }),
        );
      }
      if (/fulfilled$/.test(response.type)) {
        if (!currentPallet) {
          showNotification('Pallet created.', 'success');
        } else {
          showNotification('Pallet updated.', 'success');
        }
        reloadAll({ pallets: false });
        return true;
      }
      return false;
    },
    [currentPallet, reduxDispatch, reloadAll, showNotification],
  );

  const onExportCsv = (columns, data) => {
    const csvBuilder = new CsvBuilder('Pallets.csv');
    csvBuilder.setColumns([
      'ID',
      'User',
      'Unit SKU',
      'Unit Barcode',
      'Unit Description',
      'Unit Quantity',
      'Case SKU',
      'Case Barcode',
      'Case Description',
      'Case Quantity',
      'Updated',
      'Created',
    ]);

    data.forEach((pallet) => {
      for (
        let i = 0;
        i < 1 || i < pallet.units.length || i < pallet.cases.length;
        i++
      ) {
        const row = [];
        // ID
        row.push(pallet._id);
        // User
        row.push(pallet.user.name);
        // Unit SKU
        row.push(pallet.units[i]?.unit.sku || '');
        // Unit Barcode
        row.push(pallet.units[i]?.unit.barcode || '');
        // Unit Description
        row.push(pallet.units[i]?.unit.description || '');
        // Unit Quantity
        row.push(pallet.units[i]?.quantity || '');
        // Case SKU
        row.push(pallet.cases[i]?.case.sku || '');
        // Case Barcode
        row.push(pallet.cases[i]?.case.barcode || '');
        // Case Description
        row.push(pallet.cases[i]?.case.description || '');
        // Case Quantity
        row.push(pallet.cases[i]?.quantity || '');
        // Updated Date Time
        row.push(pallet.updatedAt);
        // Created Date Time
        row.push(pallet.createdAt);

        csvBuilder.addRow(row);
      }
    });
    csvBuilder.exportFile();
  };

  return {
    tableRef,
    isLoading,
    isUpdating,
    pallets,
    currentPallet,
    ...state,
    onRowDelete,
    onClickAdd,
    onClickUpdate,
    onDialogClose,
    onDialogSubmit,
    onExportCsv,
  };
};

export default usePalletTable;
