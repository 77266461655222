import React, { useEffect, useReducer } from 'react';
import Wrapper from '../wrappers/Wrapper';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { register } from '../../redux/auth/authSlice';
import { useSelector, useDispatch } from 'react-redux';

import { useNotification } from '../layout/Notifier';

const initialState = {
  name: '',
  email: '',
  company: '',
  address: '',
  password: '',
  password2: '',
};

const reducer = (state, { field, value }) => {
  return { ...state, [field]: value };
};

const Register = (props) => {
  const reduxDispatch = useDispatch();
  const showNotification = useNotification();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (isAuthenticated) {
      props.history.push('/home');
    }
  }, [isAuthenticated, props.history]);

  const { name, email, company, address, password, password2 } = state;
  const onChange = (e) => {
    let { name, value } = e.target;
    dispatch({ field: name, value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (name === '' || email === '' || password === '') {
      showNotification('Please enter all fields.', 'error');
    } else if (password !== password2) {
      showNotification('Passwords do not match.', 'error');
    } else {
      reduxDispatch(
        register({
          name,
          email,
          company,
          address,
          password,
        }),
      );
    }
  };

  const Fields = [
    { type: 'text', name: 'name', value: name, label: 'Name' },
    { type: 'email', name: 'email', value: email, label: 'Email' },
    { type: 'text', name: 'company', value: company, label: 'Company Name' },
    { type: 'text', name: 'address', value: address, label: 'Company Address' },
    {
      type: 'password',
      name: 'password',
      value: password,
      label: 'Password',
      minLength: 6,
    },
    {
      type: 'password',
      name: 'password2',
      value: password2,
      minLength: 6,
      label: 'Confirm Password',
    },
  ];

  return (
    <Wrapper>
      <div className='form-container'>
        <Paper style={{ padding: '1rem', margin: '1rem' }} elevation={1}>
          <Typography
            variant='h5'
            align='center'
            gutterBottom
            style={{ color: '#039be5' }}
          >
            Create your account
          </Typography>
          <form onSubmit={onSubmit}>
            {Fields.map((props, index) => {
              return (
                <TextField
                  {...props}
                  key={index}
                  size='small'
                  fullWidth
                  onChange={onChange}
                />
              );
            })}
            <div style={{ textAlign: 'center', padding: '1rem' }}>
              <Button
                type='submit'
                variant='contained'
                color='primary'
                onClick={onSubmit}
              >
                Create
              </Button>
            </div>
            <Typography variant='caption'>
              By signing up, I agree to Fillstorship's{' '}
              <Link to='/tos' style={{ color: '#039be5' }}>
                Terms of Service
              </Link>
            </Typography>
            <br />
            <Typography variant='caption'>
              <b>Already have an account?</b>{' '}
              <Link to='/login' style={{ textDecoration: 'none' }}>
                <span style={{ color: '#039be5' }}>Log In</span>
              </Link>
            </Typography>
          </form>
        </Paper>
      </div>
    </Wrapper>
  );
};

export default Register;
