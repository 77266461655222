import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles({
  title: {
    fontSize: 14,
  },
});

const CompanyCard = ({ handleCompanyOpen, company, address }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <div className='py-1'>
          <Typography
            className={classes.title}
            color='textSecondary'
            gutterBottom
          >
            Company
          </Typography>

          <TextField
            value={company}
            inputProps={{ style: { padding: '8px 16px' } }}
            variant='outlined'
            fullWidth
            disabled
          />
        </div>
        <Typography
          className={classes.title}
          color='textSecondary'
          gutterBottom
        >
          Company Address
        </Typography>
        <TextField
          value={address}
          inputProps={{ style: { padding: '8px 16px' } }}
          variant='outlined'
          fullWidth
          disabled
        />
      </CardContent>
      <CardActions>
        <Button onClick={handleCompanyOpen} size='small' color='primary'>
          Edit
        </Button>
      </CardActions>
    </Card>
  );
};
export default CompanyCard;
