import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import actions from './usersActions';

const initialState = {
  isLoading: false,
  error: '',

  isUpdating: false,
  updateError: '',
};

export const createUser = createAsyncThunk(
  'users/createUser',
  async (user, thunkAPI) => {
    return actions
      .createUser(user)
      .then((r) => r.data)
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

export const getUsers = createAsyncThunk('users/getUsers', () => {
  return actions.getUsers().then((r) => r.data);
});

export const updateUser = createAsyncThunk(
  'users/updateUser',
  async (user, thunkAPI) => {
    return actions
      .updateUser(user)
      .then((r) => r.data)
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

export const deleteUser = createAsyncThunk(
  'users/deleteUser',
  (id, thunkAPI) => {
    return actions
      .deleteUser(id)
      .then((r) => r.data)
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

const usersAdapter = createEntityAdapter({
  selectId: (entity) => entity._id,
});

const updatePending = (state, action) => {
  state.isUpdating = true;
  state.updateError = '';
};

const updateError = (state, action) => {
  state.isUpdating = false;
  state.updateError = action.payload.error;
};

const slice = createSlice({
  name: 'users',
  initialState: usersAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: {
    [createUser.pending]: updatePending,
    [createUser.rejected]: updateError,
    [createUser.fulfilled]: (state, action) => {
      state.isUpdating = false;
      state.ids = [action.payload._id, ...state.ids];
      state.entities[action.payload._id] = action.payload;
    },
    [getUsers.pending]: (state, action) => {
      state.isLoading = true;
      state.error = '';
    },
    [getUsers.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    [getUsers.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.users = action.payload;
      usersAdapter.removeAll(state);
      usersAdapter.upsertMany(state, action.payload);
    },
    [updateUser.pending]: updatePending,
    [updateUser.rejected]: updateError,
    [updateUser.fulfilled]: (state, action) => {
      state.isUpdating = false;
      const { _id, ...changes } = action.payload;
      usersAdapter.updateOne(state, { id: _id, changes });
    },
    [deleteUser.pending]: updatePending,
    [deleteUser.rejected]: updateError,
    [deleteUser.fulfilled]: (state, action) => {
      state.isUpdating = false;
      usersAdapter.removeOne(state, action.payload.id);
    },
  },
});

export const usersSelectors = () => {
  return usersAdapter.getSelectors((state) => state.users);
};

export const {
  selectById: selectUserById,
  selectIds: selectUserIds,
  selectEntities: selectUserEntities,
  selectAll: selectAllUsers,
  selectTotal: selectTotalUsers,
} = usersAdapter.getSelectors((state) => state.users);

export default slice.reducer;
