import { getDefaultProduct } from './utils';

/**
 * @param {Pick<import('react-final-form-arrays').FieldArrayRenderProps, 'fields'>} param
 */
const useProducts = ({ fields }) => {
  const onAddOne = () => {
    fields.push(getDefaultProduct());
  };

  const onRemoveOne = (index) => () => {
    fields.remove(index);
  };

  return { onAddOne, onRemoveOne };
};

export default useProducts;
