import React from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { Field } from 'react-final-form';

import {
  Autocomplete,
  TextField,
  KeyboardDatePicker,
  DatePicker,
  KeyboardTimePicker,
  TimePicker,
  Checkboxes,
} from 'mui-rff';

import KeyboardDateTimePicker from '../../common/fieldComponent/KeyboardDateTimePicker';

export const AutocompleteField = ({ readOnly, ...props }) => {
  const textFieldProps = {
    variant: 'outlined',
    size: 'small',
    ...props.textFieldProps,
    InputLabelProps: {
      shrink: true,
      ...props?.textFieldProps?.InputLabelProps,
    },
  };

  if (readOnly) {
    textFieldProps.InputProps = { readOnly: true };
  }

  return <Autocomplete {...props} textFieldProps={textFieldProps} />;
};

export const CheckboxField = ({
  disabled,
  label,
  name,
  readOnly,
  checkboxProps = {},
  formControlLabelProps = {},
  fieldProps = {},
}) => {
  return !readOnly ? (
    <Checkboxes
      data={[{ label }]}
      name={name}
      size='small'
      {...checkboxProps}
      disabled={disabled}
      formControlLabelProps={{ disabled, ...formControlLabelProps }}
    />
  ) : (
    <Field
      name={name}
      {...fieldProps}
      render={({ input }) => (
        <FormControlLabel
          control={
            <Checkbox
              id={name}
              checked={!!input.value}
              size='small'
              disabled={disabled}
              {...checkboxProps}
            />
          }
          label={label}
          disabled={disabled}
          {...formControlLabelProps}
        />
      )}
    />
  );
};

export const KeyboardDatePickerField = ({ readOnly, ...props }) => {
  if (readOnly) {
    return (
      <DatePicker
        format='YYYY-MM-DD'
        fullWidth
        inputVariant='outlined'
        size='small'
        variant='inline'
        {...props}
        InputLabelProps={{ shrink: true, ...props.InputLabelProps }}
        open={false}
      />
    );
  }
  return (
    <KeyboardDatePicker
      autoOk
      disableToolbar
      format='YYYY-MM-DD'
      fullWidth
      inputVariant='outlined'
      size='small'
      variant='inline'
      {...props}
      InputLabelProps={{ shrink: true, ...props.InputLabelProps }}
      InputProps={{ readOnly, ...props.InputProps }}
    />
  );
};

export const KeyboardDateTimePickerField = (props) => {
  return (
    <KeyboardDateTimePicker
      autoOk
      format='YYYY-MM-DD HH:mm'
      inputVariant='outlined'
      variant='inline'
      fullWidth
      size='small'
      {...props}
    />
  );
};

export const KeyboardTimePickerField = ({ readOnly, ...props }) => {
  if (readOnly) {
    return (
      <TimePicker
        format='HH:mm'
        fullWidth
        inputVariant='outlined'
        size='small'
        variant='inline'
        {...props}
        InputLabelProps={{ shrink: true, ...props.InputLabelProps }}
        open={false}
      />
    );
  }
  return (
    <KeyboardTimePicker
      autoOk
      disableToolbar
      format='HH:mm'
      fullWidth
      inputVariant='outlined'
      size='small'
      variant='inline'
      {...props}
      InputLabelProps={{ shrink: true, ...props.InputLabelProps }}
    />
  );
};

export const TextFieldField = ({ readOnly, ...props }) => {
  return (
    <TextField
      {...props}
      variant='outlined'
      size='small'
      InputLabelProps={{
        shrink: true,
        ...props?.InputLabelProps,
      }}
      InputProps={{
        readOnly,
        ...props?.InputProps,
      }}
    />
  );
};

export const renderField = ({ type, name, disabled, readOnly, ...props }) => {
  return type === 'autocomplete' ? (
    <AutocompleteField
      name={name}
      autoComplete
      autoHighlight
      autoSelect
      {...props}
      disabled={disabled}
      readOnly={readOnly}
    />
  ) : type === 'checkbox' ? (
    <CheckboxField
      name={name}
      {...props}
      disabled={disabled}
      readOnly={readOnly}
    />
  ) : type === 'date' ? (
    <KeyboardDatePickerField
      name={name}
      {...props}
      disabled={disabled}
      readOnly={readOnly}
    />
  ) : type === 'time' ? (
    <KeyboardTimePickerField
      name={name}
      {...props}
      disabled={disabled}
      readOnly={readOnly}
    />
  ) : type === 'datetime' ? (
    <KeyboardDateTimePickerField
      name={name}
      {...props}
      disabled={disabled}
      readOnly={readOnly}
    />
  ) : type === 'text' ? (
    <TextFieldField
      name={name}
      {...props}
      disabled={disabled}
      readOnly={readOnly}
    />
  ) : type === 'readOnlyText' ? (
    <TextFieldField name={name} {...props} disabled={disabled} readOnly />
  ) : null;
};
