import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import DashWrapper from '../../wrappers/DashWrapper';
import TaskTable from '../../tables/TaskTable.js';
import { getTasks } from '../../../redux/tasks/tasksSlice';
import { getUsers } from '../../../redux/users/usersSlice';

const Tasks = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUsers());
    dispatch(getTasks());
  }, [dispatch]);

  return (
    <DashWrapper>
      <TaskTable />
    </DashWrapper>
  );
};

export default Tasks;
