import React from 'react';
import Wrapper from '../../wrappers/Wrapper';
import Footer from '../../layout/Footer';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

const text = [
  {
    header: (
      <Typography variant='h5'>
        <b>Terms of Service</b>
      </Typography>
    ),
    body: (
      <Typography variant='caption' paragraph>
        Please read the following Terms of Service in full when using
        Fillstorship.com. This agreement documents the legally binding terms and
        conditions attached to the use of the site Fillstorship.com. By using or
        accessing the site in any way, viewing or browsing the site, or adding
        your own content to this site, you are agreeing to be bound by the
        following Terms of Service.
      </Typography>
    ),
  },
  {
    header: (
      <Typography variant='h5'>
        <b>Intellectual Property</b>
      </Typography>
    ),
    body: (
      <Typography variant='caption' paragraph>
        The site and all of its original content are the sole property of
        Fillstorship and are, as such, fully protected by the appropriate
        international copyright and other intellectual property rights laws.
      </Typography>
    ),
  },
  {
    header: (
      <Typography variant='h5'>
        <b>Termination</b>
      </Typography>
    ),
    body: (
      <Typography variant='caption' paragraph>
        Fillstorship reserves the right to terminate any individual/s access to
        Fillstorship.com without providing advanced notice or explanation.
      </Typography>
    ),
  },
  {
    header: (
      <Typography variant='h5'>
        <b>Links to Other Websites</b>
      </Typography>
    ),
    body: (
      <Typography variant='caption' paragraph>
        Fillstorship.com contains a number of links to other websites and online
        resources that are not owned or controlled by Fillstorship. Fillstorship
        has no control over, and is therefore not responsible or liable for the
        content or practices of, any third party sites and/or services.
        Therefore, we strongly advise you to read the entire terms and
        conditions, and privacy policies of any site that you visit as a result
        of following a link that is posted on our site.
      </Typography>
    ),
  },
  {
    header: (
      <Typography variant='h5'>
        <b>Changes to This Agreement</b>
      </Typography>
    ),

    body: (
      <Typography variant='caption' paragraph>
        Fillstorship reserves the right to modify these Terms of Service at any
        time. We will do so by posting on and drawing attention to the updated
        terms on the site. Your decision to continue to visit and use the site
        after changes have been made constitute your formal acceptance of the
        new Terms of Service. Therefore, we ask that you regularly check and
        review this agreement for changes. Should you not agree to any
        provisions found in this agreement or any subsequent changes we make to
        this agreement, we ask and advise that you discontinue your use of
        Fillstorship.com.
      </Typography>
    ),
  },
  {
    header: (
      <Typography variant='h5'>
        <b>Contact Us</b>
      </Typography>
    ),
    body: (
      <Typography variant='caption' paragraph>
        If you have any questions about this agreement, please feel free to
        contact us at ship@Fillstorship.com
      </Typography>
    ),
  },
];

const TermsOfUse = () => {
  return (
    <>
      <Wrapper>
        <Container style={{ marginTop: '3rem' }}>
          {text.map((t, i) => (
            <div key={i}>
              {t.header}
              {t.body}
            </div>
          ))}
        </Container>
      </Wrapper>
      <Footer />
    </>
  );
};

export default TermsOfUse;
