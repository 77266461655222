import React from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

import ShipmentForm from '../../forms/ShipmentForm_v2';

import LoadingModal from '../../common/LoadingModal';

import QuoteDialog from './QuoteDialog';
import SuccessDialog from './SuccessDialog';

import useEditShipmentDialog from './useEditShipmentDialog';

const ReadOnlyTextField = ({ label, value = '' }) => {
  return (
    <TextField
      value={value}
      label={label}
      fullWidth
      variant='outlined'
      size='small'
      InputLabelProps={{ shrink: true }}
      InputProps={{ readOnly: true }}
    />
  );
};

const EditShipmentDialog = ({
  open,
  handleClose,
  handleEditShipment,
  shipment,
}) => {
  const {
    isAdmin,
    users,
    quote,
    isLoading,
    initialValues,
    billTo,
    originator,
    quoteOpened,
    successOpened,
    onBillToChange,
    onSubmit,
    onQuoteDialogClose,
    onSuccessDialogClose,
    onClickUpdate,
  } = useEditShipmentDialog({
    open,
    handleEditShipment,
    shipment,
  });

  const renderForm = ({ handleSubmit, form }) => {
    const formState = form.getState();
    const { dirty } = formState;

    return (
      <>
        <DialogContent>
          <Grid container spacing={2} component='form'>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <ReadOnlyTextField
                    value={originator?.name}
                    label='Originator'
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {isAdmin ? (
                    <Autocomplete
                      disableClearable
                      getOptionLabel={(option) => option.company}
                      getOptionSelected={(option, value) =>
                        option._id === value._id
                      }
                      onChange={onBillToChange}
                      options={users}
                      value={billTo}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label='Bill To'
                          size='small'
                          variant='outlined'
                          InputLabelProps={{
                            shrink: true,
                            ...params.InputLabelProps,
                          }}
                        />
                      )}
                    />
                  ) : (
                    <ReadOnlyTextField
                      value={billTo?.company}
                      label='Bill To'
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ShipmentForm />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color='primary' onClick={handleClose}>
            Cancel
          </Button>
          <Button color='primary' disabled={!dirty} onClick={handleSubmit}>
            {dirty ? 'Update' : 'No Change'}
          </Button>
        </DialogActions>
      </>
    );
  };

  return (
    <>
      <Dialog fullWidth maxWidth='xl' onClose={handleClose} open={open}>
        <DialogTitle align='center'>{`Update Shipment #${shipment?.order_no}`}</DialogTitle>
        {!!initialValues && (
          <Form
            initialValues={initialValues}
            mutators={arrayMutators}
            onSubmit={onSubmit}
            render={renderForm}
            subscription={{
              submitting: true,
              pristine: true,
              initialValues: true,
            }}
          />
        )}
      </Dialog>
      <QuoteDialog
        open={quoteOpened}
        onClose={onQuoteDialogClose}
        quote={quote}
        onClickUpdate={onClickUpdate}
      />
      <SuccessDialog open={successOpened} onClose={onSuccessDialogClose} />
      {open && <LoadingModal isLoading={isLoading} text='' />}
    </>
  );
};

export default EditShipmentDialog;
