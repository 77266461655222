import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import wc from '../../api/woocommerce';

const initialState = {
  isLoading: false,
  error: '',

  isUpdating: false,
  updateError: '',

  isDownloading: false,
  isDataStale: false,
  downloadError: '',

  pagination: {
    page: 1,
    totalPages: 1,
    totalCount: 0,
  },
};

export const getWooCommerceOrders = createAsyncThunk(
  'wooCommerceOrder/getWooCommerceOrder',
  (params = {}) => {
    const { id, ...rest } = params;
    if (id) {
      return wc.getWooCommerceOrderByUserId(id, rest);
    }
    return wc.getWooCommerceOrders(rest);
  },
);

export const deleteWooCommerceOrder = createAsyncThunk(
  'wooCommerceOrder/delete',
  (id, thunkAPI) => {
    return wc
      .deleteWooCommerceOrder(id)
      .then((r) => r.data)
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

export const updateWooCommerceOrder = createAsyncThunk(
  'wooCommerceOrder/update',
  async (order, thunkAPI) => {
    return wc
      .updateWooCommerceOrder(order)
      .then((r) => r.data)
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

export const downloadWooCommerceOrders = createAsyncThunk(
  'wooCommerceOrder/download',
  async (id, thunkAPI) => {
    const downloadOrders = id
      ? wc.downloadWooCommerceOrdersByUserId(id)
      : wc.downloadWooCommerceOrders();

    return downloadOrders
      .then((r) => r.data)
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

const wooCommerceOrdersAdapter = createEntityAdapter({
  selectId: (entity) => entity._id,
});

const updatePending = (state, action) => {
  state.isUpdating = true;
  state.updateError = '';
};

const updateError = (state, action) => {
  state.isUpdating = false;
  state.updateError = action.payload.error;
};

const slice = createSlice({
  name: 'wooCommerceOrder',
  initialState: wooCommerceOrdersAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: {
    [getWooCommerceOrders.pending]: (state, action) => {
      state.isLoading = true;
      state.error = '';
    },
    [getWooCommerceOrders.fulfilled]: (state, action) => {
      state.isLoading = false;
      const { data, count, pagination } = action.payload;
      state.pagination = {
        page: pagination.page || initialState.pagination.page,
        totalPages: pagination.totalPages || initialState.pagination.totalPages,
        totalCount: count,
      };
      state.isDataStale = false;
      wooCommerceOrdersAdapter.removeAll(state);
      wooCommerceOrdersAdapter.upsertMany(state, data);
    },
    [getWooCommerceOrders.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    },
    [deleteWooCommerceOrder.pending]: updatePending,
    [deleteWooCommerceOrder.fulfilled]: (state, action) => {
      state.isUpdating = false;
      wooCommerceOrdersAdapter.removeOne(state, action.payload.id);
    },
    [deleteWooCommerceOrder.rejected]: updateError,
    [updateWooCommerceOrder.pending]: updatePending,
    [updateWooCommerceOrder.fulfilled]: (state, action) => {
      state.isUpdating = false;
      const { _id, ...changes } = action.payload;
      wooCommerceOrdersAdapter.updateOne(state, { id: _id, changes });
    },
    [updateWooCommerceOrder.rejected]: updateError,
    [downloadWooCommerceOrders.pending]: (state, action) => {
      state.isDownloading = true;
      state.downloadError = '';
    },
    [downloadWooCommerceOrders.rejected]: (state, action) => {
      state.isDownloading = false;
      state.downloadError = action.payload.error;
    },
    [downloadWooCommerceOrders.fulfilled]: (state, action) => {
      state.isDownloading = false;
      state.isDataStale = true;
    },
  },
});

export const createWooCommerceOrdersSelectors = () =>
  wooCommerceOrdersAdapter.getSelectors((state) => state.wooCommerceOrders);

export default slice.reducer;
