import React, { useEffect, Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { getUsers } from '../../redux/users/usersSlice';
import UserAutoCompleteTextField from '../common/UserAutoCompleteTextField';
import DashWrapper from '../wrappers/DashWrapper';
import ShipmentsTable from '../tables/ShipmentsTable';

/**
 * @param {import('../../redux/store').RootState} state
 */
const selector = (state) => {
  return {
    isAdmin: state.auth.isAdmin,
    isLoading: state.auth.isLoading,
  };
};

const Shipments = () => {
  const dispatch = useDispatch();
  const { isAdmin, isLoading } = useSelector(selector);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    if (!isLoading && isAdmin === true) {
      dispatch(getUsers());
    }
  }, [isLoading, isAdmin, dispatch]);

  const handleChange = (id) => {
    setUserId(id || null);
  };

  return (
    <Fragment>
      <DashWrapper>
        <Grid container spacing={3}>
          {/* If Admin, render UserSelect Component */}
          {!isLoading && isAdmin === true && (
            <Grid item xs={12} component={Box} maxWidth={320}>
              <UserAutoCompleteTextField
                label='Customer'
                value={userId}
                onChange={handleChange}
                margin='dense'
              />
            </Grid>
          )}
          <Grid item xs={12} component={Box} maxWidth='100%'>
            <ShipmentsTable
              isAdmin={isAdmin}
              userId={userId}
              completed={false}
            />
          </Grid>
          <Grid item xs={12} component={Box} maxWidth='100%'>
            <ShipmentsTable
              isAdmin={isAdmin}
              userId={userId}
              completed={true}
            />
          </Grid>
        </Grid>
      </DashWrapper>
    </Fragment>
  );
};

export default Shipments;
