import React, { useEffect, useReducer } from 'react';
import Wrapper from '../wrappers/Wrapper';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { login } from '../../redux/auth/authSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useNotification } from '../layout/Notifier';

const initialState = {
  email: '',
  password: '',
};

const reducer = (state, { field, value }) => {
  return { ...state, [field]: value };
};

const Login = (props) => {
  const reduxDispatch = useDispatch();
  const showNotification = useNotification();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (isAuthenticated) {
      props.history.push('/home');
    }
    /** TO DO
     * Show Notification for errors in login attempt
     */
  }, [isAuthenticated, props.history]);

  const { email, password } = state;
  const onChange = (e) => {
    let { name, value } = e.target;
    dispatch({ field: name, value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (email === '' || password === '') {
      showNotification('Please fill in all fields.', 'error');
    } else {
      reduxDispatch(login({ email, password }))
        .then((data) => {
          if (data.payload?.success === false) {
            showNotification(data.payload.error, 'error');
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <Wrapper>
      <div className='form-container'>
        <Paper style={{ padding: '1rem', margin: '1rem' }} elevation={1}>
          <Typography
            variant='h5'
            align='center'
            gutterBottom
            style={{ color: '#039be5' }}
          >
            Login
          </Typography>

          <form onSubmit={onSubmit}>
            <TextField
              fullWidth
              type='email'
              name='email'
              value={email}
              onChange={onChange}
              label='Email'
            />

            <div className='form-group'>
              <TextField
                fullWidth
                type='password'
                name='password'
                value={password}
                onChange={onChange}
                label='Password'
              />
              <div style={{ marginTop: '3%' }}>
                <Link
                  to='/resetpassword'
                  style={{
                    width: '100%',
                    color: '#039be5',
                    textDecoration: 'none',
                  }}
                >
                  Forgot Password?
                </Link>
              </div>
            </div>

            <div style={{ textAlign: 'center' }}>
              <Button
                type='submit'
                variant='contained'
                color='primary'
                onClick={onSubmit}
              >
                Login
              </Button>
            </div>
          </form>
        </Paper>
      </div>
    </Wrapper>
  );
};

export default Login;
