/**
 * Object Deep Find by path
 * @param {Record<String, any>} obj
 * @param {String} path split by "."
 */
export const objectDeepFind = (obj, path) => {
  const paths = path.split('.');
  let current = obj;
  for (const p of paths) {
    current = current[p];
    if (current === undefined) return undefined;
  }
  return current;
};

export default objectDeepFind;
