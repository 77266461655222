export const image_one =
  'https://fsswebsiteimages.s3-us-west-1.amazonaws.com/landing/services/picking.svg';
export const image_two =
  'https://fsswebsiteimages.s3-us-west-1.amazonaws.com/landing/services/warehouse.svg';
export const image_three =
  'https://fsswebsiteimages.s3-us-west-1.amazonaws.com/landing/services/shipping.svg';
export const image_four =
  'https://fsswebsiteimages.s3-us-west-1.amazonaws.com/landing/services/package.svg';
export const image_five =
  'https://fsswebsiteimages.s3-us-west-1.amazonaws.com/landing/services/lightbulb.svg';
export const image_six =
  'https://fsswebsiteimages.s3-us-west-1.amazonaws.com/landing/services/global.svg';
export const image_seven =
  'https://fsswebsiteimages.s3-us-west-1.amazonaws.com/landing/services/package-moving.svg';
export const image_eight =
  'https://fsswebsiteimages.s3-us-west-1.amazonaws.com/landing/services/partner.svg';
