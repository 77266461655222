import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useErrorHandler from '../../../hooks/useErrorHandler';
import orderSelector from './orderSelector';

const useReportTable = ({ userId, type }) => {
  const tableRef = useRef();
  const reduxDispatch = useDispatch();
  const {
    orders,
    title,
    isLoading,
    isUpdating,
    updateErrorSelector,
    errorSelector,
    getOrders,
  } = useSelector(orderSelector(type));

  useErrorHandler(updateErrorSelector);
  useErrorHandler(errorSelector);

  useEffect(() => {
    if (userId) reduxDispatch(getOrders({ id: userId }));
    else reduxDispatch(getOrders());
  }, [userId, reduxDispatch, getOrders]);

  const handleDownloadPDF = (data) => {
    if (!!data.pdf) {
      window.open(data.pdf, '_blank');
    }
  };

  return {
    tableRef,
    title,
    data: orders.map((order) => ({ ...order, tableData: {} })),
    isLoading: isLoading || isUpdating,
    handleDownloadPDF,
  };
};

export default useReportTable;
