import React from 'react';
import ChipMui from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import amber from '@material-ui/core/colors/amber';

const useStyles = makeStyles(theme => ({
  chipSuccess: {
    backgroundColor: green[500],
    color: 'white',
  },

  chipReject: {
    backgroundColor: red[500],
    color: 'white',
  },

  chipPending: {
    backgroundColor: amber[700],
    color: 'white',
  },
  chipPrimary: {
    backgroundColor: theme.palette.primary.light,
    color: 'white',
  },
}));

const Chip = ({ type, ...props }) => {
  const classes = useStyles();
  const typeToClassMap = {
    success: classes.chipSuccess,
    reject: classes.chipReject,
    pending: classes.chipPending,
  };
  return (
    <ChipMui
      className={typeToClassMap[type] || classes.chipPrimary}
      {...props}
    />
  );
};

export default Chip;
