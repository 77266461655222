import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import Grid from '@material-ui/core/Grid';

import SyncIcon from '@material-ui/icons/Sync';
import UpdateIcon from '@material-ui/icons/Update';

import grey from '@material-ui/core/colors/grey';

import MaterialTable, { MTableToolbar } from 'material-table';

import moment from 'moment';

import DashWrapper from '../../wrappers/DashWrapper';

import UserAutoCompleteTextField from '../../common/UserAutoCompleteTextField';

import usePayments from './usePayments';

import DetailDialog from './DetailDialog';

const renderStatus = (data) =>
  data.status.replace(/(^[a-z]|[A-Z])/g, (g) => ` ${g.toUpperCase()}`).trim();

const renderDate = (field) => (data) =>
  moment(data[field]).format('YYYY-MM-DD, hh:mm A');

const columns = [
  { title: 'Transaction Id', field: 'transId' },
  { title: 'User', field: 'user.name' },
  {
    title: 'Status',
    field: 'status',
    render: renderStatus,
  },
  { title: 'Amount', field: 'amount', type: 'numeric' },
  {
    title: 'Submitted At',
    field: 'submittedAt',
    render: renderDate('submittedAt'),
    defaultSort: 'desc',
  },
  {
    title: 'Updated At',
    field: 'updatedAt',
    render: renderDate('updatedAt'),
  },
];

const options = {
  rowStyle: (data, index) => ({
    backgroundColor: index % 2 ? 'unset' : grey[100],
  }),
  showTitle: false,
  tableLayout: 'fixd',
  pageSize: 10,
  pageSizeOptions: [10, 25, 50, 100],
};

const useStyles = makeStyles((theme) =>
  createStyles({
    userFieldContainer: {
      padding: theme.spacing(1),
    },
    syncIconInSync: {
      animationDelay: '0s',
      animationDirection: 'reverse',
      animationDuration: '1.5s',
      animationFillMode: 'forwards',
      animationIterationCount: 'infinite',
      animationName: '$rotation',
      transformOrigin: 'center',
      animationTimingFunction: 'linear',
    },
    '@keyframes rotation': {
      '0%': { '-webkit-transform': 'rotate(0deg)' },
      '50%': { '-webkit-transform': 'rotate(180deg)' },
      '100%': { '-webkit-transform': 'rotate(360deg)' },
    },
    icon: {
      color: 'rgba(0, 0, 0, 0.54)',
    },
  }),
);

const Payments = () => {
  const classes = useStyles();

  const {
    isAdmin,
    userId,
    isSyncing,
    tableData,
    selectedPayment,
    isLoading,
    onUserChange,
    onSyncAuthorizeNet,
    onSyncAPayment,
    onRowClick,
    onCleanSelectedPayment,
    onVoidPayment,
    onRefundPayment,
  } = usePayments();

  const renderToolBar = (props) => {
    return isAdmin ? (
      <Grid
        className={classes.userFieldContainer}
        container
        justify='space-between'
      >
        <Grid item xs={12} sm={4}>
          <UserAutoCompleteTextField
            label='Customer'
            value={userId}
            onChange={onUserChange}
            margin='dense'
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <MTableToolbar {...props} />
        </Grid>
      </Grid>
    ) : (
      <MTableToolbar {...props} />
    );
  };

  return (
    <>
      <DashWrapper>
        <MaterialTable
          columns={columns}
          components={{
            Toolbar: renderToolBar,
          }}
          data={tableData}
          // detailPanel={detailPanel}
          isLoading={isLoading}
          options={options}
          onRowClick={onRowClick}
          actions={[
            {
              icon: () => (
                <SyncIcon
                  className={clsx(classes.icon, {
                    [classes.syncIconInSync]: isSyncing,
                  })}
                />
              ),
              tooltip: 'Sync all payments from authorize.net',
              isFreeAction: true,
              onClick: onSyncAuthorizeNet,
              disabled: isSyncing,
              hidden: !isAdmin,
            },
            {
              icon: () => (
                <UpdateIcon className={classes.icon} fontSize='small' />
              ),
              tooltip: 'Synchronize this payment from authorize.net',
              onClick: onSyncAPayment,
              disabled: isLoading,
            },
          ]}
        />
      </DashWrapper>
      <DetailDialog
        payment={selectedPayment}
        onClose={onCleanSelectedPayment}
        onVoidPayment={onVoidPayment}
        onRefundPayment={onRefundPayment}
      />
    </>
  );
};

export default Payments;
