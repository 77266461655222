import axios from 'axios';

const DEFAULT_CONFIG = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const getTransOutOrders = () => {
  return axios.get('/api/v1/transoutorders').then((r) => {
    return r.data;
  });
};

const getTransOutOrdersByUserId = (userId) => {
  return axios
    .get(`/api/v1/users/${userId.toString()}/transoutorders`)
    .then((r) => {
      return r.data;
    });
};

const createTransOutOrder = (transout) => {
  return axios
    .post('/api/v1/transoutorders', transout, DEFAULT_CONFIG)
    .then((r) => r.data);
};

const updateTransOutOrder = (transout) => {
  return axios
    .put(`/api/v1/transoutorders/${transout._id}`, transout, DEFAULT_CONFIG)
    .then((r) => r.data);
};

const deleteTransOutOrder = (id) => {
  return axios.delete(`/api/v1/transoutorders/${id}`).then((r) => r.data);
};

export default {
  getTransOutOrders,
  getTransOutOrdersByUserId,
  createTransOutOrder,
  updateTransOutOrder,
  deleteTransOutOrder,
};
