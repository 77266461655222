import React from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';

import AppBar from './AppBar';
import Drawer from './Drawer';

import useMaterialDash from './useMaterialDash';

const drawerWidth = 240;

const MaterialDash = () => {
  const {
    open,
    user,
    isAdmin,
    handleDrawerOpen,
    handleDrawerClose,
    onLogout,
    title,
  } = useMaterialDash();

  return (
    <>
      <CssBaseline />
      <AppBar
        drawerWidth={drawerWidth}
        open={open}
        title={title}
        isAdmin={isAdmin}
        handleDrawerOpen={handleDrawerOpen}
      />
      <Drawer
        drawerWidth={drawerWidth}
        open={open}
        user={user}
        isAdmin={isAdmin}
        handleDrawerClose={handleDrawerClose}
        onLogout={onLogout}
      />
    </>
  );
};

export default MaterialDash;
