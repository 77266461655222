import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles({
  title: {
    fontSize: 14,
  },
});

const ProfileCard = ({ handleUserOpen, handlePasswordOpen, name, email }) => {
  const classes = useStyles();

  return (
    <Card>
      <CardContent>
        <div className='py-1'>
          <Typography
            className={classes.title}
            color='textSecondary'
            gutterBottom
          >
            Name
          </Typography>
          <TextField
            value={name}
            inputProps={{ style: { padding: '8px 16px' } }}
            variant='outlined'
            fullWidth
            disabled
          />
        </div>
        <Typography
          className={classes.title}
          color='textSecondary'
          gutterBottom
        >
          Email
        </Typography>
        <TextField
          value={email}
          inputProps={{ style: { padding: '8px 16px' } }}
          variant='outlined'
          fullWidth
          disabled
        />
      </CardContent>
      <CardActions>
        <Button size='small' onClick={handleUserOpen} color='primary'>
          Edit
        </Button>
        <Button size='small' onClick={handlePasswordOpen} color='primary'>
          Change Password
        </Button>
      </CardActions>
    </Card>
  );
};
export default ProfileCard;
