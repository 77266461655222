import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';

import actions from './authActions';

const initialState = {
  initialized: false,

  isLoading: false,
  error: '',

  isUpdating: false,
  updateError: '',

  isAuthenticated: false,
  user: null,
  isAdmin: false,
};

export const loadUser = createAsyncThunk(
  'auth/loadUser',
  (params = {}, thunkAPI) => {
    return actions
      .loadUser()
      .then((r) => {
        return r.data;
      })
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

export const register = createAsyncThunk(
  'auth/register',
  async (formData, thunkAPI) => {
    return actions
      .register(formData)
      .then((r) => {
        thunkAPI.dispatch(loadUser());
        return r.data;
      })
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

export const login = createAsyncThunk(
  'auth/login',
  async (formData, thunkAPI) => {
    return actions
      .login(formData)
      .then((r) => {
        thunkAPI.dispatch(loadUser());
        return r.data;
      })
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

export const logout = createAsyncThunk('auth/logout', async (id, thunkAPI) => {
  return actions
    .logout(id)
    .then((r) => r.data)
    .catch((err) => thunkAPI.rejectWithValue(err.response.data));
});

export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (formData, thunkAPI) => {
    return actions
      .forgotPassword(formData)
      .then((r) => r.data)
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (formData, thunkAPI) => {
    return actions
      .resetPassword(formData)
      .then((r) => {
        thunkAPI.dispatch(loadUser());
        return r.data;
      })
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

export const updateDetails = createAsyncThunk(
  'auth/updateDetails',
  async (formData, thunkAPI) => {
    return actions
      .updateDetails(formData)
      .then((r) => {
        thunkAPI.dispatch(loadUser());
        return r.data;
      })
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

export const changePassword = createAsyncThunk(
  'auth/changePassword',
  async (formData, thunkAPI) => {
    return actions
      .changePassword(formData)
      .then((r) => {
        thunkAPI.dispatch(loadUser());
        return r;
      })
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

const authAdapter = createEntityAdapter({
  selectId: (entity) => entity._id,
});

const updatePending = (state, action) => {
  state.isUpdating = true;
  state.updateError = '';
};

const updateError = (state, action) => {
  state.isUpdating = false;
  state.isAuthenticated = false;
  state.loading = false;
  state.user = null;
  state.updateError = action.payload.error;
};

const slice = createSlice({
  name: 'auth',
  initialState: authAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: {
    [register.pending]: updatePending,
    [register.rejected]: updateError,
    [register.fulfilled]: (state, action) => {
      state.isUpdating = false;
      state.isAuthenticated = true;
    },
    [login.pending]: updatePending,
    [login.rejected]: updateError,
    [login.fulfilled]: (state, action) => {
      state.isUpdating = false;
      state.isAuthenticated = true;
    },
    [logout.pending]: updatePending,
    [logout.rejected]: updateError,
    [logout.fulfilled]: (state, action) => {
      window.location.href = '/';
    },
    [loadUser.pending]: (state, action) => {
      state.isLoading = true;
      state.error = '';
    },
    [loadUser.rejected]: (state, action) => {
      if (!state.initialized) state.initialized = true;
      state.isLoading = false;
      state.error = action.error.message;
    },
    [loadUser.fulfilled]: (state, action) => {
      if (!state.initialized) state.initialized = true;
      state.isLoading = false;
      state.isAuthenticated = true;
      state.user = action.payload;
      state.isAdmin = action.payload.role === 'admin' ? true : false;
    },
    [updateDetails.pending]: updatePending,
    [updateDetails.rejected]: updateError,
    [updateDetails.fulfilled]: (state, action) => {
      state.isUpdating = false;
      state.user = action.payload;
    },
    [forgotPassword.pending]: updatePending,
    [forgotPassword.rejected]: updateError,
    [forgotPassword.fulfilled]: (state, action) => {
      state.isUpdating = false;
    },
    [resetPassword.pending]: updatePending,
    [resetPassword.rejected]: updateError,
    [resetPassword.fulfilled]: (state, action) => {
      state.isUpdating = false;
      state.isAuthenticated = true;
    },
    [changePassword.pending]: updatePending,
    [changePassword.rejected]: updateError,
    [changePassword.fulfilled]: (state, action) => {
      state.isUpdating = false;
      state.isAuthenticated = true;
    },
  },
});

export const createAuthSelectors = () => {
  return authAdapter.getSelectors((state) => state.auth);
};

export default slice.reducer;
