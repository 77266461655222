import React from 'react';

import ReadOnlyTextField from './ReadOnlyTextField';

const AVSResponseMapper = {
  A: 'The street address matched, but the postal code did not.',
  B: 'No address information was provided.',
  E: 'The AVS check returned an error.',
  G: 'The card was issued by a bank outside the U.S. and does not support AVS.',
  N: 'Neither the street address nor postal code matched.',
  P: 'AVS is not applicable for this transaction.',
  R: 'Retry — AVS was unavailable or timed out.',
  S: 'AVS is not supported by card issuer.',
  U: 'Address information is unavailable.',
  W: 'The US ZIP+4 code matches, but the street address does not.',
  X: 'Both the street address and the US ZIP+4 code matched.',
  Y: 'The street address and postal code matched.',
  Z: 'The postal code matched, but the street address did not.',
};

const cardCodeResponseMapper = {
  M: 'CVV matched.',
  N: 'CVV did not match.',
  P: 'CVV was not processed.',
  S: 'CVV should have been present but was not indicated.',
  U: 'The issuer was unable to process the CVV check.',
};

const CAVVResponseMapper = {
  0: 'CAVV was not validated because erroneous data was submitted.',
  1: 'CAVV failed validation.',
  2: 'CAVV passed validation.',
  3: 'CAVV validation could not be performed; issuer attempt incomplete.',
  4: 'CAVV validation could not be performed; issuer system error.',
  5: 'Reserved for future use.',
  6: 'Reserved for future use.',
  7: 'CAVV failed validation, but the issuer is available. Valid for U.S.-issued card submitted to non-U.S acquirer.',
  8: 'CAVV passed validation and the issuer is available. Valid for U.S.-issued card submitted to non-U.S. acquirer.',
  9: 'CAVV failed validation and the issuer is unavailable. Valid for U.S.-issued card submitted to non-U.S acquirer.',
  A:
    'CAVV passed validation but the issuer unavailable. Valid for U.S.-issued card submitted to non-U.S acquirer.',
  B: 'CAVV passed validation, information only, no liability shift.',
};

const AuthorizationInformation = ({ transaction }) => {
  return (
    <>
      <ReadOnlyTextField
        label='Authorization Amount'
        value={transaction.authAmount}
      />
      <ReadOnlyTextField
        label='Submit Date'
        value={transaction.submitTimeUTC}
        type='date'
      />
      <ReadOnlyTextField
        label='Authorization Code'
        value={transaction.authCode}
      />
      <ReadOnlyTextField
        label='Reference Transaction ID'
        value={transaction.refTransId}
      />
      <ReadOnlyTextField
        label='Transaction Type'
        value={transaction.transactionType
          .replace(/(^[a-z]|[A-Z])/g, (g) => ` ${g.toUpperCase()}`)
          .trim()}
      />
      <ReadOnlyTextField label='Market Type' value={transaction.marketType} />
      <ReadOnlyTextField label='Product' value={transaction.product} />
      <ReadOnlyTextField
        label='Address Verification Status'
        value={
          transaction.AVSResponse
            ? AVSResponseMapper[transaction.AVSResponse]
            : 'AVS Not Applicable (P)'
        }
      />
      <ReadOnlyTextField
        label='Card Code Status'
        value={
          transaction.cardCodeResponse
            ? cardCodeResponseMapper[transaction.cardCodeResponse]
            : 'Not Applicable'
        }
      />
      <ReadOnlyTextField
        label='CAVV Result Code'
        value={
          transaction.CAVVResponse
            ? CAVVResponseMapper[transaction.CAVVResponse]
            : 'Not Applicable'
        }
      />
      <ReadOnlyTextField label='Customer IP' value={transaction.customerIP} />
    </>
  );
};

export default AuthorizationInformation;
