import axios from 'axios';

const DEFAULT_CONFIG = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const getCartonizeOrders = () => {
  return axios.get('/api/v1/cartonizeorders').then((r) => {
    return r.data;
  });
};

const getUserCartonizeOrders = (userId) => {
  return axios
    .get(`/api/v1/users/${userId.toString()}/cartonizeorders`)
    .then((r) => {
      return r.data;
    });
};

const createCartonizeOrder = (cartonizeorder) => {
  return axios
    .post('/api/v1/cartonizeorders', cartonizeorder, DEFAULT_CONFIG)
    .then((r) => {
      return r.data;
    });
};

const deleteCartonizeOrder = (id) => {
  return axios.delete(`/api/v1/cartonizeorders/${id}`).then((r) => {
    return r.data;
  });
};

const updateCartonizeOrder = (cartonizeorder) => {
  return axios
    .put(
      `/api/v1/cartonizeorders/${cartonizeorder._id}`,
      cartonizeorder,
      DEFAULT_CONFIG,
    )
    .then((r) => r.data);
};

export default {
  getCartonizeOrders,
  getUserCartonizeOrders,
  createCartonizeOrder,
  deleteCartonizeOrder,
  updateCartonizeOrder,
};
