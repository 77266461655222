import React from 'react';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import Wrapper from '../../wrappers/Wrapper';

import ForgotForm from './ForgotForm';
import ResetForm from './ResetForm';

import useResetPassword from './useResetPassword';

const ResetPassword = ({
  history,
  match: {
    params: { resetToken },
  },
}) => {
  const { goToLogin } = useResetPassword({ history });

  return (
    <Wrapper>
      <Container maxWidth='sm'>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          paddingTop={2}
          paddingBottom={2}
          paddingLeft={1}
          paddingRight={1}
        >
          <Typography variant='h5' align='center' gutterBottom>
            Reset Password
          </Typography>
          {resetToken ? (
            <ResetForm token={resetToken} onSuccess={goToLogin} />
          ) : (
            <ForgotForm onSuccess={goToLogin} />
          )}
        </Box>
      </Container>
    </Wrapper>
  );
};
export default ResetPassword;
