import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';

const DashQuickInfo = ({ info }) => {
  return (
    <>
      {info.order !== undefined ? (
        <Grid container>
          <Grid item xs={12}>
            <Divider />
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='space-between'
              padding='8px 0px'
            >
              <Typography>{info.label}</Typography>
              <Typography>
                {info.order[0]}/{info.order[1]} Completed
              </Typography>
            </Box>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};

export default DashQuickInfo;
