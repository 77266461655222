import React from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const ReadOnlyTextField = ({ label, value = '' }) => {
  return (
    <TextField
      value={value}
      label={label}
      fullWidth
      variant='outlined'
      size='small'
      InputLabelProps={{ shrink: true }}
      InputProps={{ readOnly: true }}
    />
  );
};

const BillToSection = ({ isAdmin, options, value, onChange }) => {
  return isAdmin ? (
    <Autocomplete
      disableClearable
      getOptionLabel={(option) => option.company}
      getOptionSelected={(option, value) => option._id === value._id}
      onChange={onChange}
      options={options}
      value={value}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Bill To'
          size='small'
          variant='outlined'
          InputLabelProps={{
            shrink: true,
            ...params.InputLabelProps,
          }}
        />
      )}
    />
  ) : (
    <ReadOnlyTextField value={value?.name} label='Bill To' />
  );
};

export default BillToSection;
