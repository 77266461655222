const findRate = (numPallets) => {
  let rate = 0;
  if (numPallets >= 0 && numPallets <= 10) {
    rate = 15 / 13.32;
  } else if (numPallets >= 11 && numPallets <= 20) {
    rate = 14 / 13.32;
  } else if (numPallets >= 21 && numPallets <= 30) {
    rate = 13 / 13.32;
  } else if (numPallets >= 31 && numPallets <= 40) {
    rate = 12 / 13.32;
  } else if (numPallets >= 41 && numPallets <= 50) {
    rate = 11 / 13.32;
  } else if (numPallets >= 51 && numPallets <= 60) {
    rate = 10 / 13.32;
  } else if (numPallets >= 61 && numPallets <= 70) {
    rate = 9 / 13.32;
  } else if (numPallets >= 71 && numPallets <= 80) {
    rate = 8 / 13.32;
  } else if (numPallets >= 81 && numPallets <= 90) {
    rate = 7 / 13.32;
  } else if (numPallets >= 91 && numPallets <= 99) {
    rate = 6 / 13.32;
  } else {
    rate = 5 / 13.31;
  }
  return rate;
};

const roundToHundredth = (x) => {
  return Number.parseFloat(x).toFixed(2);
};

const genStorageQuote = (pallet) => {
  let numPallets = pallet.numPallets;
  let length = pallet.length / 12;
  let width = pallet.width / 12;
  let duration = pallet.duration;
  let sqft = length * width;
  let sqftTotal = sqft * numPallets;
  let ratePerPallet = findRate(numPallets);
  let storageTotal = sqftTotal * ratePerPallet;
  if (duration >= 1) {
    storageTotal = (storageTotal * duration) / 1;
    storageTotal = roundToHundredth(storageTotal);
  }
  return storageTotal;
};

module.exports = genStorageQuote;
