import React, { useCallback, useEffect, useReducer, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StatusChip from './StatusChip';
import { useNotification } from '../../layout/Notifier';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import {
  createWooCommerceOrdersSelectors,
  deleteWooCommerceOrder,
  getWooCommerceOrders,
  updateWooCommerceOrder,
  downloadWooCommerceOrders,
} from '../../../redux/woocommerceorders/wooCommerceOrdersSlice';

const columns = [
  {
    title: 'Order Id',
    field: 'order_number',
  },
  {
    title: 'Customer',
    field: 'customer',
  },
  {
    title: 'Email',
    field: 'contact_email',
  },
  {
    title: 'Status',
    field: 'status',
    lookup: {
      pending: 'Pending',
      fulfilled: 'Fulfilled',
      picked: 'Picked',
      'in progress': 'In progress',
      unfulfilled: 'Unfulfilled',
      rejected: 'Rejected',
      failed: 'Failed',
      processing: 'Processing',
    },
    render: (rowData) => <StatusChip status={rowData.status} />,
  },
];

const initialState = {
  initialized: false,
  userId: null,
  pageSize: 15,
  currentPage: 0,
  filter: {},
  currentOrderId: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'set-initialized':
      return { ...state, initialized: action.payload };

    case 'set-user-id':
      return { ...state, userId: action.payload };

    case 'set-page-size':
      return { ...state, pageSize: action.payload };

    case 'set-current-page':
      return { ...state, currentPage: action.payload };

    case 'set-filter':
      return { ...state, filter: action.payload };

    case 'set-current-order-id':
      return { ...state, currentOrderId: action.payload };

    default:
      return state;
  }
};

const selector = (state) => {
  return {
    auth: state.auth,
    tableData: createWooCommerceOrdersSelectors()
      .selectAll(state)
      .map((row, index) => ({ ...row, tableData: { id: index } })),
    wooCommerceOrders: state.wooCommerceOrders,
  };
};

const currentOrderSelector = (id) => (state) => {
  return {
    currentOrder: id
      ? createWooCommerceOrdersSelectors().selectById(state, id)
      : null,
  };
};

const useWooCommerceTable = () => {
  const showNotification = useNotification();
  const { auth, tableData, wooCommerceOrders } = useSelector(selector);
  const reduxDispatch = useDispatch();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { currentOrder } = useSelector(
    currentOrderSelector(state.currentOrderId),
  );

  useErrorHandler((state) => state.wooCommerceOrders.updateError);

  const tableRef = useRef();

  // Initialize
  useEffect(() => {
    if (auth.initialized) {
      if (!auth.isAdmin) {
        dispatch({ type: 'set-user-id', payload: auth.user._id });
      }
      dispatch({ type: 'set-initialized', payload: true });
    }
  }, [auth.initialized, auth.isAdmin, auth.user._id]);

  const loadWooCommerceOrders = useCallback(() => {
    const rest = {
      page: state.currentPage + 1,
      limit: state.pageSize,
      ...state.filter,
    };
    if (auth.isAdmin && state.userId) {
      reduxDispatch(getWooCommerceOrders({ id: state.userId, ...rest }));
    } else {
      reduxDispatch(getWooCommerceOrders({ ...rest }));
    }
  }, [
    auth.isAdmin,
    state.userId,
    state.currentPage,
    state.pageSize,
    state.filter,
    reduxDispatch,
  ]);
  useEffect(() => {
    if (state.initialized || wooCommerceOrders.isDataStale) {
      loadWooCommerceOrders();
    }
  }, [state.initialized, wooCommerceOrders.isDataStale, loadWooCommerceOrders]);

  const onChangePage = (page) => {
    dispatch({ type: 'set-current-page', payload: page });
  };

  const onChangeRowsPerPage = (pageSize) => {
    dispatch({ type: 'set-page-size', payload: pageSize });
  };

  const onFilterChange = useCallback(
    (e) => {
      const payload = {};
      for (let { column, value } of e) {
        if (column.lookup) {
          payload[column.field] = value;
        } else {
          payload[column.field] = {
            regex: '.*' + value + '.*',
            options: 'i',
          };
        }
      }
      dispatch({
        type: 'set-filter',
        payload: { ...payload, sort: state.filter.sort },
      });
    },
    [state.filter],
  );

  const onOrderChange = useCallback(
    (columnIndex, order) => {
      let payload = {};
      if (columnIndex === -1) {
        const { sort, ...rest } = state.filter;
        payload = { ...rest };
      } else {
        payload = {
          ...state.filter,
          sort: { [columns[columnIndex].field]: order },
        };
      }
      dispatch({ type: 'set-filter', payload });
    },
    [state.filter],
  );

  const onRowDelete = useCallback(
    async (rowData) => {
      await reduxDispatch(deleteWooCommerceOrder(rowData._id));
    },
    [reduxDispatch],
  );

  const onRowUpdate = useCallback(
    async (newData, oldData) => {
      await reduxDispatch(updateWooCommerceOrder(newData));
    },
    [reduxDispatch],
  );

  const onDownloadWooCommerceOrders = useCallback(() => {
    if (state.userId) reduxDispatch(downloadWooCommerceOrders(state.userId));
  }, [state.userId, reduxDispatch]);

  const onGetVideo = useCallback(
    (event, rowData) => {
      if (!rowData.footageUrl) {
        showNotification('Video footage unavailable', 'error');
      } else {
        window.open(rowData.footageUrl, '_blank');
      }
    },
    [showNotification],
  );

  const onUserChange = (newUserId) => {
    onChangePage(0);
    dispatch({ type: 'set-user-id', payload: newUserId });
  };

  const onRowClick = (event, rowData) => {
    if (tableRef.current?.state?.lastEditingRow === undefined) {
      const { tableData, ...order } = rowData;
      dispatch({ type: 'set-current-order-id', payload: order._id });
    }
  };

  const onDialogClose = () => {
    dispatch({ type: 'set-current-order-id', payload: null });
  };

  const onSubmitFulfill = useCallback(
    (event) => {
      event.preventDefault();
      if (currentOrder) {
        reduxDispatch(
          updateWooCommerceOrder({
            _id: currentOrder._id,
            status: 'fulfilled',
          }),
        );
      }
    },
    [reduxDispatch, currentOrder],
  );

  const onSubmitPicked = useCallback(
    (event) => {
      event.preventDefault();
      if (currentOrder) {
        reduxDispatch(
          updateWooCommerceOrder({ _id: currentOrder._id, status: 'picked' }),
        );
      }
    },
    [reduxDispatch, currentOrder],
  );

  return {
    isLoading: wooCommerceOrders.isLoading || wooCommerceOrders.isUpdating,
    isDownloading: wooCommerceOrders.isDownloading,
    isAdmin: auth.isAdmin,
    columns,
    pagination: wooCommerceOrders.pagination,
    tableData,
    tableRef,
    currentOrder,
    ...state,
    onChangePage,
    onChangeRowsPerPage,
    onDialogClose,
    onDownloadWooCommerceOrders,
    onGetVideo,
    onFilterChange,
    onOrderChange,
    onRowClick,
    onRowDelete,
    onRowUpdate,
    onSubmitFulfill,
    onSubmitPicked,
    onUserChange,
  };
};

export default useWooCommerceTable;
