import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import palletActions from './palletsActions';

const initialState = {
  isLoading: false,
  error: '',

  isUpdating: false,
  updateError: '',
};

export const createPallet = createAsyncThunk(
  'pallets/createPallet',
  async (pallet, thunkAPI) => {
    return palletActions
      .createPallet(pallet)
      .then((r) => {
        return r.data;
      })
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

export const getPallets = createAsyncThunk(
  'pallets/getPallets',
  (params = {}, thunkAPI) => {
    const { id, ...rest } = params;
    if (id) {
      return palletActions.getPalletsByUserId(id, rest);
    }
    return palletActions.getPallets(rest);
  },
);

export const updatePallet = createAsyncThunk(
  'pallets/updatePallet',
  async (pallet, thunkAPI) => {
    return palletActions
      .updatePallet(pallet)
      .then((r) => r.data)
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

export const deletePallet = createAsyncThunk(
  'pallets/deletePallet',
  (id, thunkAPI) => {
    return palletActions
      .deletePallet(id)
      .then((r) => r.data)
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

const palletsAdapter = createEntityAdapter({
  selectId: (entity) => entity._id,
});

const updatePending = (state, action) => {
  state.isUpdating = true;
  state.updateError = '';
};

const updateError = (state, action) => {
  state.isUpdating = false;
  state.updateError = action.payload.error;
};

const slice = createSlice({
  name: 'pallets',
  initialState: palletsAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: {
    [createPallet.pending]: updatePending,
    [createPallet.rejected]: updateError,
    [createPallet.fulfilled]: (state, action) => {
      state.isUpdating = false;
      state.ids = [action.payload._id, ...state.ids];
      state.entities[action.payload._id] = action.payload;
    },

    [getPallets.pending]: (state, action) => {
      state.isLoading = true;
      state.error = '';
    },
    [getPallets.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    [getPallets.fulfilled]: (state, action) => {
      state.isLoading = false;
      const { data } = action.payload;
      state.pallets = action.payload;
      palletsAdapter.removeAll(state);
      palletsAdapter.upsertMany(state, data);
    },
    [updatePallet.pending]: updatePending,
    [updatePallet.rejected]: updateError,
    [updatePallet.fulfilled]: (state, action) => {
      state.isUpdating = false;
      const { _id, ...changes } = action.payload;
      palletsAdapter.updateOne(state, { id: _id, changes });
    },
    [deletePallet.pending]: updatePending,
    [deletePallet.rejected]: updateError,
    [deletePallet.fulfilled]: (state, action) => {
      state.isUpdating = false;
      palletsAdapter.removeOne(state, action.payload._id);
    },
  },
});

export const createPalletsSelectors = () =>
  palletsAdapter.getSelectors((state) => state.pallets);

export const palletsSelectors = palletsAdapter.getSelectors(
  (state) => state.pallets,
);

export default slice.reducer;
