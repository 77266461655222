import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider, useSelector, useDispatch } from 'react-redux';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import Home from './components/pages/Home';
import Fill from './components/pages/LandingPages/Fill';
import Stor from './components/pages/LandingPages/Stor';
import Ship from './components/pages/LandingPages/Ship';
import About from './components/pages/LandingPages/About';
import Contact from './components/pages/LandingPages/Contact';
import Landing from './components/pages/LandingPages/Landing';
import ShopifyOrders from './components/pages/ShopifyOrders_v2';
import WooCommerceOrders from './components/pages/WooCommerceOrders';
import Users from './components/pages/Users';
import Profile from './components/pages/Profile';
import UnprocessedOrders from './components/pages/Orders/UnprocessedOrders';
import ManualOrder from './components/pages/Orders/ManualOrder';
import TransferIn from './components/pages/CreateOrders/TransferIn';
import TransferOut from './components/pages/CreateOrders/TransferOut';
import Cartonize from './components/pages/CreateOrders/Cartonize';
import Palletize from './components/pages/CreateOrders/Palletize';
import Manual from './components/pages/CreateOrders/Manual';
import Tasks from './components/pages/Tasks';
import TransferInOrder from './components/pages/Orders/TransferInOrder';
import TransferOutOrder from './components/pages/Orders/TransferOutOrder';
import CartonizeOrder from './components/pages/Orders/CartonizeOrder';
import PalletizeOrder from './components/pages/Orders/PalletizeOrder';
import AllOrders from './components/pages/Orders/AllOrders';
import BookShipment from './components/pages/BookShipment';
import Shipments from './components/pages/Shipments';
import ResetPassword from './components/pages/ResetPassword';
import Payments from './components/pages/Payments';
import TermsOfUse from './components/pages/FooterPages/TermsOfUse';
import PrivacyPolicy from './components/pages/FooterPages/PrivacyPolicy';
import ReturnPolicy from './components/pages/FooterPages/ReturnPolicy';
import Reports from './components/pages/Reports';
import Register from './components/auth/Register';
import Login from './components/auth/Login';
import Transactions from './components/pages/Transactions';
import PrivateRoute from './components/routing/PrivateRoute';
import AdminRoute from './components/routing/AdminRoute';

import store from './redux/store';

import './App.css';
import Notifier from './components/layout/Notifier';
import { InventoryRoute } from './components/routing/InventoryRoute';
import { loadUser } from './redux/auth/authSlice';

const selector = (state) => {
  return {
    initialized: state.auth.initialized,
    isAdmin: state.auth.isAdmin,
    isAuthenticated: state.auth.isAuthenticated,
  };
};

const App = () => {
  const { initialized, isAuthenticated } = useSelector(selector);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!initialized && !isAuthenticated) dispatch(loadUser());
  }, [initialized, isAuthenticated, dispatch]);

  return (
    <Switch>
      <PrivateRoute exact path='/home' component={Home} />
      <PrivateRoute path='/inventory' component={InventoryRoute} />
      <PrivateRoute exact path='/shopify' component={ShopifyOrders} />
      <PrivateRoute exact path='/woocommerce' component={WooCommerceOrders} />
      <PrivateRoute exact path='/shipments' component={Shipments} />
      <PrivateRoute exact path='/profile' component={Profile} />
      <PrivateRoute exact path='/transin/create' component={TransferIn} />
      <PrivateRoute exact path='/transout/create' component={TransferOut} />
      <PrivateRoute exact path='/cartonize/create' component={Cartonize} />
      <PrivateRoute exact path='/palletize/create' component={Palletize} />
      <PrivateRoute exact path='/shipment/book' component={BookShipment} />
      <PrivateRoute exact path='/manual/create' component={Manual} />
      <PrivateRoute
        exact
        path='/orders/unprocessed'
        component={UnprocessedOrders}
      />
      <PrivateRoute exact path='/orders/transin' component={TransferInOrder} />
      <PrivateRoute
        exact
        path='/orders/transout'
        component={TransferOutOrder}
      />
      <PrivateRoute exact path='/tasks' component={Tasks} />
      <PrivateRoute exact path='/transactions' component={Transactions} />
      <PrivateRoute exact path='/orders/all' component={AllOrders} />
      <PrivateRoute exact path='/orders/cartonize' component={CartonizeOrder} />
      <PrivateRoute exact path='/orders/palletize' component={PalletizeOrder} />
      <PrivateRoute exact path='/orders/manual' component={ManualOrder} />
      <PrivateRoute exact path='/reports' component={Reports} />
      <PrivateRoute path='/payments' component={Payments} />
      <AdminRoute exact path='/users' component={Users} />
      <Route exact path='/' component={Landing} />
      <Route exact path='/fill' component={Fill} />
      <Route exact path='/stor' component={Stor} />
      <Route exact path='/ship' component={Ship} />
      <Route exact path='/about' component={About} />
      <Route exact path='/contact' component={Contact} />
      <Route exact path='/register' component={Register} />
      <Route exact path='/login' component={Login} />
      <Route exact path='/tos' component={TermsOfUse} />
      <Route exact path='/privacypolicy' component={PrivacyPolicy} />
      <Route exact path='/returnpolicy' component={ReturnPolicy} />
      <Route exact path='/resetpassword' component={ResetPassword} />
      <Route
        exact
        path='/resetpassword/:resetToken'
        component={ResetPassword}
      />
    </Switch>
  );
};

const AppWithProvider = (props) => {
  return (
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Router>
          <Notifier />
          <App {...props} />
        </Router>
      </MuiPickersUtilsProvider>
    </Provider>
  );
};

export default AppWithProvider;
