import { useCallback, useEffect } from 'react';

import useUploadS3 from '../../../../hooks/useUploadS3';

/**
 * @param {File} file
 * @returns {File}
 */
const processFile = (file) => {
  // const name = `${new Date().getTime()}-${file.name.replace(' ', '+')}`;
  const name = `${file.name.replaceAll(' ', '+')}`;

  return new File([file], name, { type: file.type });
};

/**
 * @param {Pick<import('react-final-form-arrays').FieldArrayRenderProps, 'fields'} param
 */
const useAttachDocuments = ({ fields }) => {
  const {
    pending,
    processing,
    completed,
    failed,
    uploadData,
    removeCompleted,
    removePending,
    removeFailed,
  } = useUploadS3();

  useEffect(() => {
    if (completed.length > 0) {
      fields.push(completed[0].url);
      removeCompleted(0);
    }
  }, [completed, fields, removeCompleted]);

  const handleLoadFile = (event) => {
    /** @type {FileList} */
    const files = event?.target?.files;
    for (let i = 0; i < files.length; i++) {
      uploadData(processFile(files[i]));
    }
  };

  const handleDownload = (url) => () => {
    if (!!url) window.open(url, '_blank');
  };

  const handleRemove = useCallback(
    (index) => () => {
      if (0 <= index && index < fields.length) fields.remove(index);
    },
    [fields],
  );

  const handleRemovePending = useCallback(
    (index) => () => {
      if (0 <= index && index < pending.length) removePending(index);
    },
    [pending, removePending],
  );

  const handleRemoveFailed = useCallback(
    (index) => () => {
      if (0 <= index && index < failed.length) removeFailed(index);
    },
    [failed, removeFailed],
  );

  return {
    failed,
    pending,
    processing,
    handleLoadFile,
    handleDownload,
    handleRemove,
    handleRemovePending,
    handleRemoveFailed,
  };
};

export default useAttachDocuments;
