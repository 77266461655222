import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const CompanyDialog = (props) => {
  const [state, setState] = useState({
    company: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
  });
  const handleClose = () => {
    props.handleCompanyClose();
  };

  const handleChange = (e) => {
    e.preventDefault();
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.updateDetails(state);
  };
  return (
    <div>
      <Dialog
        open={props.companyOpen}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>Update Company Details</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To update your company details, please update your company name and
            address here.
          </DialogContentText>
          <TextField
            onChange={handleChange}
            margin='dense'
            name='company'
            label='Company'
            fullWidth
          />
          <TextField
            onChange={handleChange}
            margin='dense'
            name='address'
            label='Address'
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          <Button type='submit' onClick={handleSubmit} color='primary'>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CompanyDialog;
