import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import AddBoxIcon from '@material-ui/icons/AddBox';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import Container from './Container';
import General from './General';
import DetailsForm from './DetailForm';
import Products from './Products';
import Pallets from './Pallets';
import AttachDocuments from './AttachDocuments';
import ShippingInformation from './ShippingInformation';
import Operation from './Operation';
import Fees from './Fees';

/**
 * @param {Object} props
 * @param {Boolean} props.disabled
 * @param {Boolean} props.readOnly
 * @param {('hazardous' | 'LTL' | 'FTL' | 'container')[]} props.enabledCheckboxs
 */
const OrderForm = ({
  units = [],
  cases = [],
  pallets = [],
  disabled = false,
  readOnly = false,
  tableType,
  enabledCheckboxs = ['hazardous', 'LTL', 'FTL', 'container'],
  isAdmin,
}) => {
  const detailsList = [
    { name: 'shipper', title: 'Shipper' },
    { name: 'receiver', title: 'Receiver' },
  ];
  const productsList = [
    { name: 'units', title: 'Units', products: units, productName: 'unit' },
    { name: 'cases', title: 'Cases', products: cases, productName: 'case' },
    {
      name: 'pallets',
      title: 'Pallets',
      products: pallets,
      productName: 'pallet',
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Container title='General'>
          <General
            disabled={disabled}
            readOnly={readOnly}
            enabledCheckboxs={enabledCheckboxs}
          />
        </Container>
      </Grid>
      {tableType === 'manual_order' ? (
        <Grid item xs={12}>
          <Field
            component={Operation}
            name='Operation'
            disabled={disabled}
            readOnly={readOnly}
          />
        </Grid>
      ) : null}
      {detailsList.map(({ name, title }, index) => (
        <Grid key={name + index} item xs={12} sm={6}>
          <Container title={title}>
            <Field
              component={DetailsForm}
              name={name}
              disabled={disabled}
              readOnly={readOnly}
            />
          </Container>
        </Grid>
      ))}
      <Grid item xs={12}>
        <Container title='Shipment Details'>
          <Field
            component={ShippingInformation}
            name='shipping'
            disabled={disabled}
            readOnly={readOnly}
          />
        </Container>
      </Grid>
      {isAdmin && (
        <Grid item xs={12}>
          <Container title='Fees'>
            <FieldArray
              component={Fees}
              name='fees'
              disabled={disabled}
              readOnly={readOnly}
            />
          </Container>
        </Grid>
      )}
      <Grid item xs={12}>
        <Box display='flex' flexDirection='row' alignItems='center'>
          <ErrorOutlineIcon
            fontSize='small'
            color='primary'
            style={{ margin: '0px 5px' }}
          />
          <Typography color='primary'>
            To select items below, please make sure they exist in the inventory.
            To add products, click
            <AddBoxIcon
              fontSize='small'
              color='secondary'
              style={{ margin: '0px 5px' }}
            />
            on the appropriate table in the Inventory section.
          </Typography>
        </Box>
      </Grid>
      {productsList.map((props, index) =>
        props.name === 'pallets' ? (
          <Grid key={props.name + index} item xs={12}>
            <FieldArray
              {...props}
              component={Pallets}
              disabled={disabled}
              readOnly={readOnly}
              subscription={{ value: true }}
            />
          </Grid>
        ) : (
          <Grid key={props.name + index} item xs={12}>
            <FieldArray
              {...props}
              component={Products}
              disabled={disabled}
              readOnly={readOnly}
              subscription={{ value: true }}
            />
          </Grid>
        ),
      )}
      <Grid item xs={12}>
        <FieldArray
          name='documents'
          component={AttachDocuments}
          disabled={disabled}
          readOnly={readOnly}
          subscription={{ value: true }}
        />
      </Grid>
    </Grid>
  );
};

export default OrderForm;
