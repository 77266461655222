import React, { useEffect, useState } from 'react';

import ReactBarcodeReader from 'react-barcode-reader';

const BarcodeReader = (props) => {
  const [testCode, setTestCode] = useState(null);
  useEffect(() => {
    if (testCode !== null) {
      setTestCode(null);
    }
  }, [testCode]);

  /**
   *
   * @param {KeyboardEvent} event
   */
  const keypressListener = (event) => {
    event.preventDefault();
    if (event.ctrlKey && event.keyCode === 20) {
      const code = window.prompt('enter code:');
      setTestCode(code);
    }
  };

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      window.addEventListener('keypress', keypressListener);
    }
    return () => {
      if (process.env.NODE_ENV === 'development') {
        window.removeEventListener('keypress', keypressListener);
      }
    };
  }, []);

  return <ReactBarcodeReader testCode={testCode} {...props} />;
};

export default BarcodeReader;
