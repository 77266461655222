import React from 'react';
import MaterialTable from 'material-table';
import useTransactionTable from './useTransactionTable';
import moment from 'moment';

const TransactionTable = () => {
  const { units } = useTransactionTable();

  const columns = [{ title: 'SKU', field: 'sku' }];

  const options = {
    rowStyle: (data, index) => {
      return index % 2 ? { backgroundColor: '#EFEFEF' } : {};
    },
    exportButton: true,
    exportAllData: true,
    pageSize: 100,
    pageSizeOptions: [5, 10, 20, 30, 50, 75, 100],
  };

  const detailPanel = (data) => {
    const columns = [
      { title: 'SKU', field: 'sku' },
      { title: 'Total', field: 'total' },
      { title: 'Type', field: 'type' },
      {
        title: 'Timestamp',
        field: 'timestamp',
        render: (rowData) => (
          <>{moment(rowData.timestamp).format('MMMM Do YYYY, h:mm:ss a')}</>
        ),
      },
    ];

    return (
      <MaterialTable
        title=''
        columns={columns}
        data={data.history.map((row) => ({ ...row, tableData: {} }))}
        options={{
          search: false,
        }}
      />
    );
  };

  return (
    <MaterialTable
      title='Transactions'
      columns={columns}
      data={units}
      detailPanel={detailPanel}
      options={options}
    />
  );
};
export default TransactionTable;
