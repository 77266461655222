import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import {
  marketSelectors,
  deleteMarketplace,
  updateMarketplace,
} from '../../../redux/marketplaces';

const selectMarkets = createSelector(marketSelectors().selectAll, (rows) =>
  rows.map((row) => ({ ...row, tableData: {} })),
);

const useMarketTable = () => {
  const dispatch = useDispatch();
  const marketplaces = useSelector(selectMarkets);
  const { isLoading } = useSelector((state) => state.marketplaces.isLoading);

  const onRowUpdate = useCallback(
    async (data) => {
      await dispatch(updateMarketplace({ id: data._id, data }));
    },
    [dispatch],
  );

  const onRowDelete = async (data) => {
    await dispatch(deleteMarketplace(data._id));
  };

  return { marketplaces, isLoading, onRowUpdate, onRowDelete };
};

export default useMarketTable;
