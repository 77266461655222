import axios from 'axios';
import qs from 'qs';

const DEFAULT_CONFIG = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const getWooCommerceOrders = (params) => {
  return axios
    .get('/api/v1/woocommerce/', {
      params,
      paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' }),
    })
    .then((r) => {
      return r.data;
    });
};

const getWooCommerceOrderByUserId = (userId, params) => {
  return axios
    .get(`/api/v1/users/${userId}/woocommerce`, {
      params,
      paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' }),
    })
    .then((r) => {
      return r.data;
    });
};

const updateWooCommerceOrder = (order) => {
  return axios
    .put(`/api/v1/woocommerce/${order._id}`, order, DEFAULT_CONFIG)
    .then((r) => r.data);
};

const deleteWooCommerceOrder = (id) => {
  return axios.delete(`/api/v1/woocommerce/${id}`).then((r) => r.data);
};

const downloadWooCommerceOrders = () => {
  return axios.get('/api/v1/woocommerce/download').then((r) => r.data);
};

const downloadWooCommerceOrdersByUserId = (id) => {
  return axios
    .get(`/api/v1/users/${id}/woocommerce/download`)
    .then((r) => r.data);
};

export default {
  getWooCommerceOrders,
  updateWooCommerceOrder,
  deleteWooCommerceOrder,
  getWooCommerceOrderByUserId,
  downloadWooCommerceOrders,
  downloadWooCommerceOrdersByUserId,
};
