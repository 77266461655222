import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DrawerListItem from '../ListItem';
import { setNestedCreateOpen } from '../../../../../redux/materialDash/materialDashSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(9),
  },
}));

const NestedCreate = ({ items = [] }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const createOpen = useSelector((state) => state.materialDash.createOpen);

  const handleToggle = () => {
    dispatch(setNestedCreateOpen(!createOpen));
  };

  return (
    items.length > 0 && (
      <List
        component='nav'
        aria-labelledby='nested-list-subheader'
        className={classes.root}
      >
        <ListItem button onClick={handleToggle}>
          <ListItemIcon>
            <AddCircleIcon />
          </ListItemIcon>
          <ListItemText primary='Create' />
          {createOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={createOpen} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            {items.map((itemProps) => {
              return (
                <DrawerListItem
                  key={itemProps.to}
                  {...itemProps}
                  listItemProps={{ className: classes.nested }}
                />
              );
            })}
          </List>
        </Collapse>
      </List>
    )
  );
};
export default NestedCreate;
