import axios from 'axios';

const getDailyReports = (id) => {
  return axios
    .get('/api/v1/reports/daily', { params: { userId: id } })
    .then((r) => r.data);
};

const deleteReport = (id) => {
  return axios.delete(`/api/v1/reports/${id}`).then((r) => {
    return r.data;
  });
};

export default {
  getDailyReports,
  deleteReport,
};
