import React from 'react';
import MaterialTable, { MTableActions, MTableToolbar } from 'material-table';
import moment from 'moment';
import Quickview from './Quickview';
import StatusChip from '../../common/StatusChip';
import Chip from '@material-ui/core/Chip';
import EditShipmentDialog from '../../dialogs/EditShipmentDialog';
import ShipmentsTableController from './ShipmentsTableController';
import ShipmentsTableDetailPanel from './ShipmentsTableDetailPanel';
import ChangeRateDialog from './ChangeRateDialog';
import DeleteDialog from '../../dialogs/DeleteDialog';
import useShipmentsTable from './useShipmentsTable';

/** @type {import('material-table').Column[]} */
const columns = [
  {
    title: 'Status',
    field: 'status',
    render: (rowData) => <StatusChip status={rowData.status} />,
  },
  {
    title: 'Order #',
    field: 'order_no',
  },
  {
    title: 'Bill To',
    field: 'billTo.company',
  },
  {
    title: 'Shipper',
    field: 'shipper.company',
  },
  {
    title: 'Receiver',
    field: 'receiver.company',
  },
  {
    title: 'Pickup Date',
    field: 'shipper?.pickup?.from',
    render: (rowData) => {
      const pickup = rowData?.shipper?.pickup?.date;
      if (!!pickup) {
        if (typeof pickup === 'string') {
          return moment(pickup).format('MMM Do YY');
        } else {
          return moment(pickup.from).format('MMM Do YY');
        }
      }
      return '';
    },
  },
  {
    title: 'Delivery Date',
    field: 'receiver?.delivery?.from',
    render: (rowData) => {
      const delivery = rowData?.receiver?.delivery?.date;
      if (!!delivery) {
        if (typeof delivery === 'string') {
          return moment(delivery).format('MMM Do YY');
        } else {
          return moment(delivery.from).format('MMM Do YY');
        }
      }
      return '';
    },
  },
  {
    title: 'Documents',
    field: 'documents',
    render: (rowData) => (
      <Chip
        clickable
        onClick={() => rowData?.documents.forEach((pdf) => window.open(pdf))}
        label={rowData?.documents.length}
        color={rowData?.documents.length === 0 ? 'secondary' : 'primary'}
        variant='outlined'
      />
    ),
  },
];

const ShipmentsTable = ({ isAdmin, userId, completed }) => {
  const {
    tableRef,
    isLoading,
    data,
    /** State */
    editDialogOpen,
    deleteDialogOpen,
    currentOrder,
    changeRateOrder,
    handleShipmentUpdate,
    handleShipmentUpdateStatus,
    handleChangeRateDialog,
    handleEditShipment,
    handleEditDialogOpen,
    handleEditDialogClose,
    handleDeleteDialogOpen,
    handleDeleteDialogClose,
    handleDownloadPDF,
    handleShipmentDelete,
  } = useShipmentsTable({ userId, completed });

  /** @type {import('material-table').DetailPanel[]} */
  const detailPanels = [
    {
      tooltip: 'Show Details',
      render: (rowData) => <ShipmentsTableDetailPanel data={rowData} />,
    },
  ];

  /** @type {import('material-table').Options} */
  const options = {
    rowStyle: (data, index) => {
      return index % 2 ? { backgroundColor: '#EFEFEF' } : {};
    },
    exportButton: true,
    exportAllData: true,
    pageSize: 10,
    pageSizeOptions: [5, 10, 20, 30, 50, 75, 100],
    actionsColumnIndex: -1,
  };

  const renderActions = (props) => {
    return !props.data ? (
      <MTableActions {...props} />
    ) : (
      <>
        <MTableActions {...props} actions={[]} />
        <ShipmentsTableController
          isAdmin={isAdmin}
          onClickPending={handleShipmentUpdateStatus('Pending')}
          onClickApprove={handleShipmentUpdateStatus('Approved')}
          onClickCancel={handleShipmentUpdateStatus('Canceled')}
          onClickComplete={handleShipmentUpdateStatus('Completed')}
          onClickInProgress={handleShipmentUpdateStatus('In Progress')}
          onClickRejected={handleShipmentUpdateStatus('Rejected')}
          onClickChangeRate={handleChangeRateDialog}
          onClickEdit={handleEditDialogOpen}
          onClickPDF={handleDownloadPDF}
          onClickDelete={handleDeleteDialogOpen}
          {...props}
        />
      </>
    );
  };

  const renderToolbar = (props) => (
    <>
      <MTableToolbar {...props} />
      <Quickview data={data} />
    </>
  );

  return (
    <>
      <MaterialTable
        title={completed ? 'Shipments - Completed' : 'Shipments - In Process'}
        tableRef={tableRef}
        isLoading={isLoading}
        columns={columns}
        components={{ Actions: renderActions, Toolbar: renderToolbar }}
        editable={{ onRowUpdate: () => {} }}
        data={data}
        detailPanel={detailPanels}
        options={options}
        localization={{ header: { actions: '' } }}
      />
      <EditShipmentDialog
        open={editDialogOpen}
        handleClose={handleEditDialogClose}
        handleEditShipment={handleEditShipment}
        shipment={currentOrder}
      />
      <ChangeRateDialog
        shipment={changeRateOrder}
        updateShipment={handleShipmentUpdate}
        onClose={handleChangeRateDialog}
      />
      <DeleteDialog
        open={deleteDialogOpen}
        handleClose={handleDeleteDialogClose}
        currentOrder={currentOrder}
        onClickDelete={handleShipmentDelete}
      />
    </>
  );
};

export default ShipmentsTable;
