import React from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import Chip from '@material-ui/core/Chip';
import StatusChip from '../../common/StatusChip';
import EditOrderDialog from '../../dialogs/EditOrderDialog';
import PickDialog from '../../dialogs/PickDialog';
import QuickView from './QuickView';
import OrderTableDetailPanel from './OrderTableDetailPanel';
import DeleteDialog from '../../dialogs/DeleteDialog';
import CompleteDialog from '../../dialogs/CompleteDialog';
import useOrderTable from './useOrderTable';
import _ from 'lodash';
import moment from 'moment';
import { FaTruckLoading } from 'react-icons/fa';
import { SiMinutemailer } from 'react-icons/si';
import { GoVerified, GoPackage } from 'react-icons/go';
import {
  MdCheck,
  MdCheckCircle,
  MdClose,
  MdEdit,
  MdPictureAsPdf,
  MdDeleteForever,
  MdUndo,
} from 'react-icons/md';

const OrderTable = ({
  disabledTitle = false,
  isAdmin,
  tableType,
  userId,
  completed,
}) => {
  const {
    /* state */
    currentOrder,
    editDialogOpen,
    deleteDialogOpen,
    completeDialogOpen,
    orderDialogOpen,
    tableRef,
    title,
    data,
    isLoading,
    productName,
    handleOrderUpdateStatus,
    handleOrderDelete,
    handleOrderComplete,
    onClickEdit,
    onClickPick,
    onClickVerify,
    handleDialogClose,
    handleDialogUpdate,
    handlePick,
    handleDownloadPDF,
    handleDownloadPS,
    onClickShipment,
    onClickDelete,
    onClickComplete,
  } = useOrderTable({
    tableType,
    userId,
    completed,
  });

  const columns = [
    {
      title: 'Status',
      field: 'status',
      render: (rowData) => <StatusChip status={rowData.status} />,
    },
    { title: 'Order#', field: 'order_no' },
    {
      title:
        tableType === 'cartonize' ? 'Number of Cases' : 'Number of Pallets',
      render: (rowData) =>
        tableType === 'cartonize'
          ? _.sumBy(rowData.cases, (o) => o.quantity)
          : rowData?.shipping?.noOfPallets
          ? rowData?.shipping?.noOfPallets
          : 0,
    },
    { title: 'Bill To', field: 'billTo.company' },
    { title: 'PO#', field: 'ponum' },
    { title: 'Ship Id', field: 'shipId' },
    {
      title: 'Shipper',
      field: 'shipper.company',
    },
    {
      title: 'Receiver',
      field: 'receiver.company',
    },
    { title: 'Shipment Cost', field: 'shipping.shipmentCost' },
    {
      title: 'Shipper Cost',
      field: 'shipping.shipperCost',
    },
    {
      title: 'Completed',
      field: 'completedDate',
      render: (rowData) => {
        return !rowData.completedDate
          ? ''
          : moment(rowData.completedDate.slice(0, 15)).format('MMM Do YY');
      },
    },
    {
      title: 'Documents',
      field: 'documents',
      render: (rowData) => (
        <Chip
          clickable
          onClick={() => rowData?.documents.forEach((pdf) => window.open(pdf))}
          label={rowData?.documents.length}
          color={rowData?.documents.length === 0 ? 'secondary' : 'primary'}
          variant='outlined'
        />
      ),
    },
  ];

  const detailPanels = [
    {
      tooltip: 'Show Details',
      render: (rowData) => (
        <OrderTableDetailPanel
          data={rowData}
          tableType={tableType}
          isAdmin={isAdmin}
        />
      ),
    },
  ];

  const options = {
    rowStyle: (data, index) => {
      return index % 2 ? { backgroundColor: '#EFEFEF' } : {};
    },
    exportButton: true,
    exportAllData: true,
    pageSize: 10,
    pageSizeOptions: [5, 10, 20, 30, 50, 75, 100, 200, 500],
    showTitle: !disabledTitle,
    actionsColumnIndex: -1,
  };

  const actions = [
    /* Edit */
    {
      icon: MdEdit,
      tooltip: 'Edit Order',
      iconProps: { fontSize: 'small' },
      onClick: (event, data) => onClickEdit(data),
    },
    /* PDF */
    {
      icon: MdPictureAsPdf,
      tooltip: 'Download PDF',
      iconProps: { fontSize: 'small' },
      onClick: (event, data) => handleDownloadPDF(data),
    },
    /* Packing Slip */
    {
      icon: SiMinutemailer,
      tooltip: 'Download Packing Slip',
      iconProps: { fontSize: 'small' },
      onClick: (event, data) => handleDownloadPS(data),
    },
    /* Convert to Shipment */
    {
      icon: FaTruckLoading,
      tooltip: 'Convert Order to Shipment',
      iconProps: { fontSize: 'small' },
      onClick: (event, data) => onClickShipment(data),
    },
    /* Reset to Pending */
    ({ status }) => ({
      icon: MdUndo,
      tooltip: 'Reset to Pending',
      hidden: !((isAdmin && status === 'Completed') || status === 'Rejected'),
      iconProps: { fontSize: 'small', color: 'orange' },
      onClick: (event, rowData) => handleOrderUpdateStatus(rowData, 'Pending'),
    }),
    /* Approve */
    ({ status }) => ({
      icon: MdCheck,
      tooltip: 'Approve Order',
      hidden: !(isAdmin && status === 'Pending'),
      iconProps: { fontSize: 'small', color: 'green' },
      onClick: (event, rowData) => handleOrderUpdateStatus(rowData, 'Approved'),
    }),
    /* Reject */
    ({ status }) => ({
      icon: MdClose,
      tooltip: 'Reject Order',
      hidden: !(isAdmin && status === 'Pending'),
      iconProps: { fontSize: 'small', color: 'red' },
      onClick: (event, rowData) => handleOrderUpdateStatus(rowData, 'Rejected'),
    }),
    /* Verify */
    ({ status }) => ({
      icon: GoVerified,
      tooltip: 'Verify Order',
      hidden: !(
        isAdmin &&
        status === 'Approved' &&
        tableType === 'transfer_in'
      ),
      iconProps: { fontSize: 'small', color: 'blue' },
      onClick: (event, rowData) => onClickVerify(rowData, 'Verified'),
    }),
    /* Pick */
    ({ status }) => ({
      icon: GoPackage,
      tooltip: 'Pick Order',
      hidden: !(
        isAdmin &&
        status === 'Approved' &&
        tableType !== 'transfer_in'
      ),
      iconProps: { fontSize: 'small', color: 'green' },
      onClick: (event, rowData) => onClickPick(rowData),
    }),
    /* Complete */
    ({ status }) => ({
      icon: MdCheckCircle,
      tooltip: 'Complete Order',
      hidden: !(isAdmin && status !== 'Completed' && status !== 'Rejected'),
      iconProps: { fontSize: 'small', color: 'green' },
      onClick: (event, rowData) => onClickComplete(rowData),
    }),
    /* Delete */
    {
      icon: MdDeleteForever,
      tooltip: 'Delete Order',
      hidden: !isAdmin,
      iconProps: { fontSize: 'small', color: 'red' },
      onClick: (event, rowData) => onClickDelete(rowData),
    },
  ];

  const renderToolbar = (props) => (
    <>
      <MTableToolbar {...props} />
      <QuickView data={data} />
    </>
  );

  return (
    <>
      <MaterialTable
        tableRef={tableRef}
        columns={columns}
        components={{ Toolbar: renderToolbar }}
        data={data}
        detailPanel={detailPanels}
        actions={actions}
        isLoading={isLoading}
        options={options}
        title={title}
        localization={{ header: { actions: '' } }}
      />
      {currentOrder !== null && editDialogOpen && (
        <EditOrderDialog
          isLoading={isLoading}
          title={title}
          currentOrder={currentOrder}
          handleClose={handleDialogClose('edit-dialog')}
          open={editDialogOpen}
          updateOrder={handleDialogUpdate}
          tableType={tableType}
          enabledCheckboxs={
            tableType === 'transfer_in' || tableType === 'transfer_out'
              ? ['hazardous', 'LTL', 'FTL', 'container']
              : tableType === 'manual_order'
              ? []
              : ['hazardous']
          }
        />
      )}
      {currentOrder !== null && deleteDialogOpen && (
        <DeleteDialog
          currentOrder={currentOrder}
          handleClose={handleDialogClose('delete-dialog')}
          open={deleteDialogOpen}
          onClickDelete={handleOrderDelete}
        />
      )}
      {currentOrder !== null && completeDialogOpen && (
        <CompleteDialog
          currentOrder={currentOrder}
          handleClose={handleDialogClose('complete-dialog')}
          open={completeDialogOpen}
          onClickComplete={handleOrderComplete}
        />
      )}
      {typeof productName === 'string' && !!currentOrder && orderDialogOpen && (
        <PickDialog
          disabledWebcam
          isLoading={isLoading}
          onClose={handleDialogClose('order-dialog')}
          onPick={handlePick}
          order={orderDialogOpen ? currentOrder : null}
          products={currentOrder[`${productName}s`]}
          productName={productName}
          title={`${
            tableType === 'transfer_in' ? 'Verify' : 'Pick'
          } ${title} Order`}
          orderNo={currentOrder.order_no}
          subTitle={`${
            tableType === 'transfer_in' ? 'Verify' : 'Pick'
          } the items in this order. Scan items to continue fulfillment.`}
          pickBtnLabel={tableType === 'transfer_in' ? 'Verify' : 'Pick'}
        />
      )}
    </>
  );
};

export default OrderTable;
