import { useEffect, useCallback, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { createUnitsSelectors } from '../../../redux/units/unitsSlice';
import useErrorHandler from '../../../hooks/useErrorHandler';
import { useNotification } from '../../layout/Notifier';
import {
  createCasesSelectors,
  deleteCase,
  updateCase,
  createCase,
  selectCaseById,
} from '../../../redux/cases/casesSlice';

const selector = (state) => {
  return {
    isLoading: state.cases.isLoading,
    isUpdating: state.cases.isUpdating,
  };
};

const initialState = {
  opened: false,
  currentCaseId: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'open-dialog':
      return { ...state, opened: true };
    case 'close-dialog':
      return { ...state, opened: false };
    case 'set-current-case-id':
      return { ...state, currentCaseId: action.payload || null };
    default:
      return state;
  }
};

const caseSelector = (id) => (state) => {
  const _case = id ? selectCaseById(state, id) || null : null;
  return _case;
};

const useCaseTable = ({ user, reloadAll, onGetCases }) => {
  const reduxDispatch = useDispatch();
  const showNotification = useNotification();
  const { isLoading, isUpdating } = useSelector(selector);
  const [{ currentCaseId, ...state }, dispatch] = useReducer(
    reducer,
    initialState,
  );

  const currentCase = useSelector(caseSelector(currentCaseId));

  const updateErrorSelector = (state) => state.cases.updateError;
  const errorSelector = (state) => state.cases.error;

  useErrorHandler(updateErrorSelector);
  useErrorHandler(errorSelector);

  useEffect(() => {
    if (typeof onGetCases === 'function') {
      onGetCases();
    }
  }, [onGetCases]);

  const selectUnits = createSelector(createUnitsSelectors().selectAll, (rows) =>
    rows.map((row) => ({ ...row })),
  );
  const selectCases = createSelector(createCasesSelectors().selectAll, (rows) =>
    rows.map((row) => ({ ...row, tableData: {} })),
  );

  const onRowAdd = useCallback(
    async (newData) => {
      newData.user = user;
      await reduxDispatch(createCase(newData));
      await reloadAll({ cases: false });
    },
    [reduxDispatch, reloadAll, user],
  );

  const onDialogSubmit = useCallback(
    async (data) => {
      let response = null;
      if (!currentCase) {
        response = await reduxDispatch(createCase(data));
      } else {
        response = await reduxDispatch(
          updateCase({ ...data, _id: currentCaseId }),
        );
      }
      if (/fulfilled$/.test(response.type)) {
        if (!currentCase) {
          showNotification('Case created.', 'success');
        } else {
          showNotification('Case updated.', 'success');
        }
        await reloadAll({ cases: false });
        return true;
      }
      return false;
    },
    [reduxDispatch, showNotification, reloadAll, currentCase, currentCaseId],
  );

  const onClickAdd = (event, data) => {
    dispatch({ type: 'open-dialog' });
  };

  const onClickUpdate = (event, data) => {
    dispatch({ type: 'open-dialog' });
    dispatch({ type: 'set-current-case-id', payload: data._id });
  };

  const handleClose = () => {
    dispatch({ type: 'close-dialog' });
    dispatch({ type: 'set-current-case-id' });
  };

  const onRowDelete = useCallback(
    async (rowData) => {
      rowData.user = user;
      await reduxDispatch(deleteCase(rowData._id));
      await reloadAll({ cases: false });
    },
    [reduxDispatch, reloadAll, user],
  );

  return {
    ...state,
    units: useSelector(selectUnits),
    cases: useSelector(selectCases),
    selectCases,
    selectUnits,
    onRowAdd,
    onDialogSubmit,
    onRowDelete,
    handleClose,
    isLoading,
    isUpdating,
    onClickAdd,
    onClickUpdate,
    currentCase,
  };
};

export default useCaseTable;
