import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    height: '100%',
  },
  media: {
    height: 300,
  },
});

const MediaCard = (props) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardMedia className={classes.media} image={props.image} />
      <CardContent>
        <Typography gutterBottom variant='h5' component='h2'>
          {props.header}
        </Typography>
        <Typography
          variant='body2'
          color='textSecondary'
          component='p'
          align='left'
        >
          {props.body}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default MediaCard;
