import React from 'react';

import ReadOnlyTextField from './ReadOnlyTextField';

const TransactionDetail = ({ transaction }) => {
  return (
    <>
      <ReadOnlyTextField label='Transaction ID' value={transaction.transId} />
      <ReadOnlyTextField
        label='Transaction Status'
        value={transaction.transactionStatus
          .replace(/(^[a-z]|[A-Z])/g, (g) => ` ${g.toUpperCase()}`)
          .trim()}
      />
    </>
  );
};

export default TransactionDetail;
