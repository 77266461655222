import { useCallback, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  getDefaultShipmentFormValues,
  produceValues,
  produceShipment,
} from '../../forms/ShipmentForm_v2';

import useErrorHandler from '../../../hooks/useErrorHandler';
import { useNotification } from '../../layout/Notifier';

import { initializeQuote } from '../../../redux/quote/quoteSlice';
import {
  createShipment,
  getShipments,
} from '../../../redux/shipments/shipmentsSlice';
import { getUsers } from '../../../redux/users/usersSlice';

/**
 * @typedef {Object} State
 * @property {ReturnType<getDefaultShipmentFormValues> | null} initialValues
 * @property {String | null} userId
 * @property {Object | null} shipment
 * @property {boolean} quoteOpened
 * @property {boolean} successOpened
 */
/** @type {State} */
const initialState = {
  initialValues: null,
  userId: null,
  shipment: null,
  quoteOpened: false,
  successOpened: false,
};

/**
 * @typedef {Object} ActionObject
 * @property {'set-initial-values' | 'set-shipment'} type
 * @property {Object} payload
 */
/**
 * @typedef {Object} ActionUserId
 * @property {'set-user-id'} type
 * @property {String=} payload
 */
/**
 * @typedef {Object} ActionBoolean
 * @property {'set-quote-opened' | 'set-success-opened'} type
 * @property {Boolean} payload
 */
/**
 * @typedef {ActionObject | ActionUserId | ActionBoolean} Action
 */

/**
 * @param {State} state
 * @param {Action} action
 * @returns {State}
 */
const reducer = (state, action) => {
  switch (action.type) {
    case 'set-initial-values':
      return {
        ...state,
        initialValues: { ...getDefaultShipmentFormValues(), ...action.payload },
      };

    case 'set-user-id':
      return { ...state, userId: action.payload || '' };

    case 'set-shipment':
      return { ...state, shipment: action.payload };

    case 'set-quote-opened':
      return { ...state, quoteOpened: action.payload };

    case 'set-success-opened':
      return { ...state, successOpened: action.payload };

    default:
      return state;
  }
};

/**
 * @param {import('../../../redux/store').RootState} state
 */
const selector = (state) => {
  const { initialized, isAdmin, user } = state.auth;
  return {
    initialized,
    isAdmin,
    user,
    quote: state.quote,
    isLoading:
      state.shipments.isLoading ||
      state.shipments.isUpdating ||
      state.quote.isLoading,
  };
};

const useBookShipment = () => {
  const reduxDispatch = useDispatch();
  const showNotification = useNotification();
  const location = useLocation();

  const { initialized, isAdmin, user, quote, isLoading } = useSelector(
    selector,
  );
  const [state, dispatch] = useReducer(reducer, initialState);

  useErrorHandler((state) => state.shipments.error);
  useErrorHandler((state) => state.shipments.updateError);
  useErrorHandler((state) => state.quote.error?.error);

  // Get Users
  const initializeProcess = useCallback(
    async (order) => {
      reduxDispatch(initializeQuote());
      if (isAdmin) await reduxDispatch(getUsers());

      dispatch({
        type: 'set-initial-values',
        payload: order ? produceShipment(location.state) : {},
      });
      dispatch({ type: 'set-user-id', payload: isAdmin ? '' : user._id });
    },
    [isAdmin, reduxDispatch, user, location.state],
  );

  useEffect(() => {
    if (
      initialized &&
      state.initialValues === null &&
      location.state !== undefined
    ) {
      initializeProcess(true);
    } else if (initialized && state.initialValues === null) {
      initializeProcess(false);
    }
  }, [initialized, state.initialValues, location.state, initializeProcess]);

  // Get Shipments
  useEffect(() => {
    if (state.initialValues !== null) {
      reduxDispatch(getShipments());
    }
  }, [state.initialValues, reduxDispatch]);

  // Clears history so it doesn't populate from orders after refreshing
  useEffect(() => {
    if (location.state !== undefined) {
      window.history.replaceState(null, '');
    }
  });

  const onFormSubmit = useCallback(
    /**
     * @param {ReturnType<typeof getDefaultShipmentFormValues>} values
     */
    async (values) => {
      try {
        // Producing Values
        const shipment = {
          ...produceValues(values),
          user: state.userId,
          originator: user?._id,
          billTo: state.userId,
        };
        dispatch({ type: 'set-shipment', payload: shipment });
        dispatch({ type: 'set-quote-opened', payload: true });
      } catch (error) {
        showNotification(error.message, 'error');
      }

      return null;
    },
    [state.userId, user, showNotification],
  );

  /**
   * @param {import('final-form').FormApi} form
   */
  const onShipmentSelect = (shipment) => {
    const newInitialValues = produceShipment(shipment);
    dispatch({ type: 'set-initial-values', payload: newInitialValues });
  };

  const onUserIdChange = useCallback(
    (userId) => {
      if (userId !== state.userId) {
        dispatch({ type: 'set-user-id', payload: userId });
      }
    },
    [state.userId],
  );

  const onQuoteDialogClose = () => {
    dispatch({ type: 'set-quote-opened', payload: false });
  };

  const onSuccessDialogClose = () => {
    dispatch({ type: 'set-success-opened', payload: false });
  };

  const onClickBook = useCallback(async () => {
    dispatch({ type: 'set-quote-opened', payload: false });
    const newShipment = {
      ...state.shipment,
      total: quote.quote ? quote.quote : 0,
    };
    await reduxDispatch(createShipment(newShipment)).then((response) => {
      if (/fulfilled$/.test(response.type)) {
        dispatch({ type: 'set-shipment', payload: newShipment });
        dispatch({ type: 'set-success-opened', payload: true });
        dispatch({ type: 'set-initial-values', payload: null });
      }
    });
  }, [quote.quote, state.shipment, reduxDispatch]);

  return {
    isAdmin,
    quote: quote.quote,
    user,
    ...state,
    isLoading: !state.initialValues || isLoading,
    onFormSubmit,
    onShipmentSelect,
    onUserIdChange,
    onQuoteDialogClose,
    onSuccessDialogClose,
    onClickBook,
  };
};

export default useBookShipment;
