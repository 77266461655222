import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { useNotification } from '../../layout/Notifier';
import useErrorHandler from '../../../hooks/useErrorHandler';
import {
  usersSelectors,
  createUser,
  deleteUser,
  updateUser,
} from '../../../redux/users/usersSlice';

const selector = (state) => {
  return {
    isLoading: state.users.isLoading,
    isUpdating: state.users.isUpdating,
  };
};

const updateErrorSelector = (state) => state.users.updateError;
const errorSelector = (state) => state.users.error;

const selectUsers = createSelector(usersSelectors().selectAll, (rows) =>
  rows.map((row) => ({ ...row, tableData: {} })),
);

const useUserTable = () => {
  const dispatch = useDispatch();
  const showNotification = useNotification();
  const users = useSelector(selectUsers);
  const { isLoading, isUpdating } = useSelector(selector);

  useErrorHandler(updateErrorSelector);
  useErrorHandler(errorSelector);

  const onRowAdd = useCallback(
    async (newData) => {
      await dispatch(createUser(newData));
    },
    [dispatch],
  );

  const onRowUpdate = useCallback(
    async (newData) => {
      const response = await dispatch(updateUser(newData));
      if (/fulfilled$/.test(response.type)) {
        showNotification('Updated customer information', 'success');
      }
    },
    [dispatch, showNotification],
  );

  const onRowDelete = useCallback(
    async (rowData) => {
      await dispatch(deleteUser(rowData._id));
    },
    [dispatch],
  );

  return {
    users,
    isLoading,
    isUpdating,
    onRowAdd,
    onRowUpdate,
    onRowDelete,
  };
};

export default useUserTable;
