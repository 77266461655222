import React, { useCallback, useState } from 'react';

import { styled } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import Popover from '@material-ui/core/Popover';

import { MdMoreVert } from 'react-icons/md';

const OpenMdMoreVert = styled(MdMoreVert)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const StyledToggleButton = styled(ToggleButton)(({ selected, theme }) =>
  selected
    ? {
        '&.MuiToggleButton-root.Mui-selected': {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
        },
      }
    : {},
);

const DetailPanelsController = ({
  disabled,
  onToggleDetailPanel,
  tableDataId,
  detailPanels = [],
}) => {
  const [showedIndex, setShowedIndex] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleToggle = (event) => {
    setAnchorEl((prevAnchorEl) => (prevAnchorEl ? null : event.currentTarget));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      handleClose();
    }
  };

  const handleItemClick = useCallback(
    /**  @param {Number} index */
    (index) =>
      /** @param {event: React.MouseEvent<HTMLLIElement, MouseEvent>} event */
      (event) => {
        if (typeof onToggleDetailPanel === 'function') {
          event.preventDefault();
          handleClose();
          setShowedIndex(
            anchorEl ? (showedIndex === index ? null : index) : index,
          );
          onToggleDetailPanel([tableDataId], detailPanels[index].render);
        }
      },
    [onToggleDetailPanel, anchorEl, showedIndex, detailPanels, tableDataId],
  );

  return (
    <>
      <Tooltip title='Detail Panels Controller'>
        <IconButton
          aria-describedby='detail-panels-controller'
          disabled={disabled}
          onClick={handleToggle}
        >
          {showedIndex === null ? <MdMoreVert /> : <OpenMdMoreVert />}
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={handleClose}
        open={!!anchorEl}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <ToggleButtonGroup
          id='detail-panels-controller'
          exclusive
          onKeyDown={handleListKeyDown}
          value={showedIndex}
        >
          {detailPanels.map(({ icon: Icon, tooltip }, index) => (
            <Tooltip key={index} title={tooltip}>
              <StyledToggleButton
                disabled={disabled}
                onClick={handleItemClick(index)}
                selected={showedIndex === index}
                value={index}
              >
                <Icon />
              </StyledToggleButton>
            </Tooltip>
          ))}
        </ToggleButtonGroup>
      </Popover>
    </>
  );
};

export default DetailPanelsController;
