import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import NumberFormatCustom from '../common/NumberFormatCustom';
import TextField from '@material-ui/core/TextField';

const FillDialog = (props) => {
  const [state, setState] = useState({
    name: '',
    company: '',
    phoneNumber: '',
    email: '',
    kit: '',
    productType: '',
    quantity: 0,
    description: '',
  });

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    console.log(state);
  };

  return (
    <div>
      <Dialog open={props.open} onClose={props.handleClose}>
        <DialogTitle align='center'>Fulfillment Request</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography align='center'>Name</Typography>
              <TextField
                name='name'
                value={state.name}
                onChange={handleChange}
                variant='outlined'
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Typography align='center'>Company</Typography>
              <TextField
                name='company'
                value={state.company}
                onChange={handleChange}
                variant='outlined'
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Typography align='center'>Phone Number</Typography>
              <TextField
                name='phoneNumber'
                value={state.phoneNumber}
                onChange={handleChange}
                variant='outlined'
                fullWidth
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography align='center'>Email</Typography>
              <TextField
                name='email'
                value={state.email}
                onChange={handleChange}
                variant='outlined'
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Typography align='center'>Kit or Product Name</Typography>
              <TextField
                name='kit'
                value={state.kit}
                onChange={handleChange}
                variant='outlined'
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Typography align='center'>Product Type</Typography>
              <TextField
                name='productType'
                value={state.productType}
                onChange={handleChange}
                variant='outlined'
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Typography align='center'>Quantity</Typography>
              <TextField
                name='quantity'
                value={state.quantity}
                onChange={handleChange}
                variant='outlined'
                fullWidth
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography align='center'>Description</Typography>
              <TextField
                name='description'
                value={state.description}
                onChange={handleChange}
                variant='outlined'
                multiline
                rows={3}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleSubmit} color='primary'>
            Submit Request
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FillDialog;
