import React from 'react';
import MaterialTable from 'material-table';
import useCaseTable from './useCaseTable';
import CaseDialog from '../../dialogs/CaseDialog';
import { MdAddBox, MdEdit } from 'react-icons/md';
import History from './History';

const CaseTable = ({ onGetCases, userId, reloadAll, skus = {}, isAdmin }) => {
  const {
    units,
    cases,
    onDialogSubmit,
    onRowDelete,
    isLoading,
    isUpdating,
    currentCase,
    opened,
    handleClose,
    onClickAdd,
    onClickUpdate,
  } = useCaseTable({
    userId,
    reloadAll,
    onGetCases,
    isAdmin,
  });

  if (units !== null) units.forEach((unit) => (skus[unit._id] = unit.sku));

  const columns = [
    { title: 'SKU', field: 'sku' },
    { title: 'Barcode', field: 'barcode' },
    { title: 'Description', field: 'description' },
    {
      title: 'Unit SKU',
      field: 'unit',
      lookup: units !== null ? skus : { na: 'n/a' },
    },
    { title: 'Case Quantity', field: 'shelf_qty' },
    { title: 'Pallet Quantity', field: 'pallet_qty', editable: 'never' },
    { title: 'Total', field: 'total', editable: 'never' },
    { title: 'Unit Quantity per Case', field: 'case_qty' },
    { title: 'SO #', field: 'soNum' },
    { title: 'Lot #', field: 'lotNum' },
  ];

  const options = {
    rowStyle: (data, index) => {
      if (index % 2) return { backgroundColor: '#f7f7f7' };
    },
    exportButton: true,
    exportAllData: true,
    pageSize: 10,
    pageSizeOptions: [5, 10, 20, 30, 50, 75, 100, 200, 500],
  };

  const actions = [
    {
      icon: MdAddBox,
      isFreeAction: true,
      tooltip: 'Create Case',
      onClick: onClickAdd,
    },
    {
      icon: MdEdit,
      tooltip: 'Update Case',
      onClick: onClickUpdate,
    },
  ];

  const detailPanels = (data) => {
    return <History data={data} />;
  };

  return (
    <>
      <MaterialTable
        title='Cases'
        isLoading={isLoading || isUpdating}
        actions={actions}
        options={options}
        columns={columns}
        data={cases}
        editable={isAdmin ? { onRowDelete } : null}
        localization={{ header: { actions: '' } }}
        detailPanel={detailPanels}
      />
      <CaseDialog
        isLoading={isLoading || isUpdating}
        opened={opened}
        onClose={handleClose}
        onSubmit={onDialogSubmit}
        pallet={currentCase}
        userId={userId}
      />
    </>
  );
};

export default CaseTable;
