import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

const notifyAdapter = createEntityAdapter();

const notifySlice = createSlice({
  name: 'notifySlice',
  initialState: notifyAdapter.getInitialState(),
  reducers: {
    enqueueSnackbar: notifyAdapter.addOne,
    closeSnackbar: (state, action) => {
      notifyAdapter.updateOne(state, {
        id: action.payload,
        changes: { dismissed: true },
      });
    },
    closeAll: (state, action) => {
      notifyAdapter.updateMany(
        state.ids.map((id) => ({ id, changes: { dismissed: true } })),
      );
    },
    removeSnackbar: notifyAdapter.removeOne,
  },
});
export const createNotifySelectors = () =>
  notifyAdapter.getSelectors((state) => state.notifications);
export const {
  enqueueSnackbar,
  closeSnackbar,
  closeAll,
  removeSnackbar,
} = notifySlice.actions;
export default notifySlice.reducer;
