import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const usePalletsSelection = ({
  products = [],
  selectedIds = [],
  onAddProduct,
}) => {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    const newOptions = products.filter(
      (element) => !selectedIds.includes(element._id),
    );
    setOptions(newOptions);
  }, [products, selectedIds]);

  const onChange = (event, value) => {
    onAddProduct(value);
    setInputValue('');
  };

  const onInputChange = (event, value) => {
    setInputValue(value || '');
  };

  const getOptionLabel = (option) => (!option ? '' : option?.sku || '');

  const getOptionSelected = (option, value) => option?.sku === value?.sku;

  return {
    options,
    inputValue,
    onChange,
    onInputChange,
    getOptionLabel,
    getOptionSelected,
  };
};

const PalletsSelection = ({
  classes,
  title = '',
  products = [],
  selectedIds = [],
  disabled = false,
  onAddProduct = () => {},
}) => {
  const {
    options,
    inputValue,
    onChange,
    onInputChange,
    getOptionLabel,
    getOptionSelected,
  } = usePalletsSelection({ products, selectedIds, onAddProduct });

  const startAdornment = (
    <InputAdornment position='start'>
      <Typography variant='subtitle1' component={Box}>
        Add {title} by SKU#
      </Typography>
    </InputAdornment>
  );

  const renderInput = (params) => {
    return (
      <TextField
        {...params}
        fullWidth
        variant='outlined'
        InputLabelProps={{ shrink: true }}
        InputProps={{ ...params.InputProps, startAdornment }}
      />
    );
  };

  return (
    <Autocomplete
      autoComplete
      autoHighlight
      classes={classes}
      disabled={disabled}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      inputValue={inputValue}
      onChange={onChange}
      onInputChange={onInputChange}
      options={options}
      renderInput={renderInput}
      size='small'
      value={null}
    />
  );
};

export default withStyles(({ theme }) => ({
  inputRoot: {
    backgroundColor: 'rgba(255, 255, 255, 0.87)',
  },
}))(PalletsSelection);
