import React from 'react';
import Wrapper from '../../wrappers/Wrapper';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Footer from '../../layout/Footer';

const text = [
  {
    header: (
      <Typography variant='h5'>
        <b>Return Policy</b>
      </Typography>
    ),
  },
  {
    header: (
      <Typography variant='h5'>
        <b>For quality-related issues:</b>
      </Typography>
    ),
    body: (
      <Typography variant='caption' paragraph>
        30 day money-back guarantee for quality-related issues. Return your
        intact and undamaged Fillstorship product and receive a full refund.
        <br />
        31 days - 6 months: We provide a new item or full refund.
        <br />6 months - 1 year: We provide a new item with partial fee or
        partial refund.
        <br />
        Reminder: Resending shipping fee is covered by us if any. Be sure to use
        your product as directed.
      </Typography>
    ),
  },
  {
    header: (
      <Typography variant='h5'>
        <b>For Non quality-related issues:</b>
      </Typography>
    ),
    body: (
      <Typography variant='caption' paragraph>
        7 day money-back guarantee for non quality-related issues. Return your
        intact and undamaged Fillstorship product and receive a full refund.
        <br />8 days - 1 year: Return for replacement or refund are not
        supported.
      </Typography>
    ),
  },
  {
    header: (
      <Typography variant='h5'>
        <b>Limitation</b>
      </Typography>
    ),
    body: (
      <Typography variant='caption' paragraph>
        Warranty-related shipping costs are only covered in the US. If outside
        the US, you can either return the item and pay the return shipping cost,
        or receive a 50% refund of the item's purchase price if not returning
        the item.
      </Typography>
    ),
  },
];

const ReturnPolicy = () => {
  return (
    <>
      <Wrapper />
      <Container style={{ marginTop: '3rem' }}>
        {text.map((t, i) => (
          <div key={i}>
            {t.header}
            {t.body}
          </div>
        ))}
      </Container>
      <Footer />
    </>
  );
};

export default ReturnPolicy;
