const countriesMapper = {
  US: 'United States',
  CA: 'Canada',
  CN: 'China',
  FR: 'France',
  IT: 'Italy',
  MX: 'Mexico',
  AU: 'Australia',
  NZ: 'New Zealand',
  KR: 'South Korea',
  JP: 'Japan',
};

const countriesAbbreviations = Object.keys(countriesMapper);
const countriesNames = Object.values(countriesMapper);

export { countriesAbbreviations, countriesMapper, countriesNames };
