import React from 'react';
import { styled } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

import { MdSync } from 'react-icons/md';

const SyncButton = styled(
  ({
    children,
    tooltip = '',
    disabled = false,
    onClick = () => {},
    ...props
  }) => {
    return (
      <Tooltip title='Please upload csv file with [sku, quantity, lotNum, notes] columns'>
        <Button
          disabled={disabled}
          component='label'
          color='primary'
          size='small'
          startIcon={<MdSync />}
          {...props}
        >
          {children}
          <Box
            component='input'
            accept='.csv'
            disabled={disabled}
            onChange={onClick}
            type='file'
            display='none'
          />
        </Button>
      </Tooltip>
    );
  },
)({
  padding: 5,
});

export default SyncButton;
