import React from 'react';

import Grid from '@material-ui/core/Grid';

import Controller from './Controller';
import FieldList from './FieldList';

const ProductFieldArray = (props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Controller {...props} />
      </Grid>
      <Grid item xs={12}>
        <FieldList {...props} />
      </Grid>
    </Grid>
  );
};

export default ProductFieldArray;
