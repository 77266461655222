import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import Chip from '@material-ui/core/Chip';

import { green, red, amber } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) =>
  createStyles({
    chip: ({ status }) => ({
      color: 'white',
      textTransform: 'capitalize',
      backgroundColor:
        status === 'COMPLETED'
          ? green[500]
          : status === 'IN PROGRESS' ||
            status === 'PENDING' ||
            status === 'UNFULFILLED'
          ? amber[700]
          : status === 'REJECTED'
          ? red[500]
          : theme.palette.primary.light,
    }),
  }),
);

const StatusChip = ({ status }) => {
  const classes = useStyles({ status: status.toUpperCase() });
  return <Chip size='small' className={classes.chip} label={status} />;
};

export default React.memo(StatusChip);
