import React from 'react';

import ReadOnlyTextField from './ReadOnlyTextField';

const OrderInformation = ({ order }) => {
  return (
    <>
      <ReadOnlyTextField label='Invoice Number' value={order?.invoiceNumber} />
      <ReadOnlyTextField label='Description' value={order?.description} />
      {order?.purchaseOrderNumber && (
        <ReadOnlyTextField
          label='Purchase Order Number'
          value={order?.purchaseOrderNumber}
        />
      )}
    </>
  );
};

export default OrderInformation;
