import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import ExpandMore from '@material-ui/icons/ExpandMore';

import DrawerListItem from '../ListItem';
import { setNestedOrdersOpen } from '../../../../../redux/materialDash/materialDashSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(9),
  },
}));

const NestedOrders = ({ items = [], isAdmin }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const ordersOpen = useSelector((state) => state.materialDash.ordersOpen);

  const handleToggle = () => {
    dispatch(setNestedOrdersOpen(!ordersOpen));
  };

  const userView = items.filter((i) => i.to !== '/orders/all');
  items = isAdmin ? items : userView;

  return (
    items.length > 0 && (
      <List
        component='nav'
        aria-labelledby='nested-list-subheader'
        className={classes.root}
      >
        <ListItem button onClick={handleToggle}>
          <ListItemIcon>
            <AllInboxIcon />
          </ListItemIcon>
          <ListItemText primary='Orders' />
          {ordersOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={ordersOpen} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            {items.map((itemProps) => {
              return (
                <DrawerListItem
                  key={itemProps.to}
                  {...itemProps}
                  listItemProps={{ className: classes.nested }}
                />
              );
            })}
          </List>
        </Collapse>
      </List>
    )
  );
};
export default NestedOrders;
