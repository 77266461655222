import React, { Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { usersSelectors, getUsers } from '../../redux/users/usersSlice';

import { useSelector, useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const UserSelect = ({ value, onChange, hideAll, ...props }) => {
  const dispatch = useDispatch();
  const users = useSelector(usersSelectors().selectAll);
  const classes = useStyles();

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const handleUserChange = (e) => {
    e.preventDefault();
    const userId = e.target.value;
    onChange && onChange(userId);
  };

  return (
    <Fragment>
      <FormControl size='small' className={classes.formControl}>
        <InputLabel>User</InputLabel>
        <Select
          value={value}
          name='user'
          onChange={handleUserChange}
          label='Select User'
          defaultValue='All'
        >
          {!hideAll && (
            <MenuItem key='All' value=''>
              <em>All</em>
            </MenuItem>
          )}
          {users?.map((user) => (
            <MenuItem key={user._id} value={user._id}>
              {user.company}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Fragment>
  );
};

export default UserSelect;
