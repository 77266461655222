import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { FieldArray } from 'react-final-form-arrays';

import ProductFieldArray from './ProductFieldArray';

import { TextField } from 'mui-rff';

const PalletForm = ({ disabled, cases }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const tabs = [
    {
      name: 'cases',
      subName: 'case',
      label: 'Cases',
      displayFields: [
        { name: 'case.sku', label: 'SKU #' },
        { name: 'case.barcode', label: 'Barcode' },
      ],
      options: cases,
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          disabled={disabled}
          label='SKU'
          name='sku'
          variant='outlined'
          InputLabelProps={{ shrink: true }}
          margin='dense'
          required
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          type='number'
          disabled={disabled}
          label='# of Pallets'
          name='numOfPallets'
          variant='outlined'
          InputLabelProps={{ shrink: true }}
          margin='dense'
          required
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          disabled={disabled}
          label='SO #'
          name='soNum'
          variant='outlined'
          InputLabelProps={{ shrink: true }}
          margin='dense'
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          disabled={disabled}
          label='Lot #'
          name='lotNum'
          variant='outlined'
          InputLabelProps={{ shrink: true }}
          margin='dense'
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          disabled={disabled}
          label='Notes'
          multiline
          name='notes'
          rows={3}
          variant='outlined'
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={12}>
        <Card>
          <Tabs
            aria-label='pallet form tabs'
            indicatorColor='primary'
            onChange={handleTabChange}
            textColor='primary'
            value={tabIndex}
            variant='fullWidth'
          >
            {tabs.map(({ name, label }) => (
              <Tab
                aria-controls={`pallet-form-tab-${name}`}
                disabled={disabled}
                id={`pallet-form-tab-${name}`}
                key={name}
                label={label}
              />
            ))}
          </Tabs>
          <CardContent>
            {tabs.map((tab, index) => (
              <div key={tab.name} hidden={tabIndex !== index}>
                <FieldArray
                  disabled={disabled}
                  {...tab}
                  component={ProductFieldArray}
                />
              </div>
            ))}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default PalletForm;
