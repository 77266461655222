import React from 'react';
import Grid from '@material-ui/core/Grid';
import MuiTextField from '@material-ui/core/TextField';
import { TextField, Autocomplete } from 'mui-rff';

const CaseForm = ({ disabled, units, mode, isAdmin }) => {
  const setMode = (mode) => {
    switch (mode) {
      case 'create':
        return false;
      case 'update':
        return true;
      default:
        return;
    }
  };

  const readOnly = (isAdmin, mode) => {
    // if Admin and create, edit
    if (isAdmin === true && mode === 'create') return false;
    // if Admin and update, no edit
    else if (isAdmin === true && mode === 'update') return true;
    // if User and create, edit
    else if (isAdmin === false && mode === 'create') return false;
    // if User and update, no edit
    else if (isAdmin === false && mode === 'update') return true;
  };

  const fields = [
    {
      label: 'Case SKU',
      name: 'sku',
      spacing: 4,
      required: true,
      InputProps: { readOnly: !isAdmin && setMode(mode) },
    },
    { label: 'Barcode', name: 'barcode', spacing: 4, required: true },
    { label: 'Description', name: 'description', spacing: 4, required: true },
    { label: 'Length', name: 'length', spacing: 4, required: true },
    { label: 'Width', name: 'width', spacing: 4, required: true },
    { label: 'Height', name: 'height', spacing: 4, required: true },
    {
      label: 'Unit SKU',
      name: 'unit',
      spacing: 6,
      required: true,
    },
    {
      label: 'Quantity per Case',
      name: 'case_qty',
      spacing: 6,
      required: true,
      type: 'number',
      InputProps: { readOnly: !isAdmin && setMode(mode) },
    },
    {
      label: 'Shelf Quantity',
      name: 'shelf_qty',
      spacing: 4,
      required: true,
      InputProps: { readOnly: !isAdmin && setMode(mode) },
    },
    {
      label: 'Pallet Quantity',
      name: 'pallet_qty',
      spacing: 4,
      required: true,
      InputProps: { readOnly: readOnly(isAdmin, mode) },
    },
    {
      label: 'Total',
      name: 'total',
      spacing: 4,
      required: true,
      InputProps: { readOnly: readOnly(isAdmin, mode) },
    },
    { label: 'SO #', name: 'soNum', spacing: 6, required: false },
    { label: 'Lot #', name: 'lotNum', spacing: 6, required: false },
  ];

  return (
    <Grid container spacing={2}>
      {fields.map(({ label, name, spacing, required, ...props }, index) => {
        return name === 'unit' ? (
          <Grid item xs={spacing} key={index}>
            <Autocomplete
              name='unit'
              options={units}
              getOptionValue={(option) => option._id}
              getOptionLabel={(option) => option.sku}
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  label='Select Unit SKU'
                  margin='dense'
                  variant='outlined'
                  required
                />
              )}
            />
          </Grid>
        ) : (
          <Grid item xs={spacing} key={index}>
            <TextField
              disabled={disabled}
              label={label}
              name={name}
              variant='outlined'
              InputLabelProps={{ shrink: true }}
              margin='dense'
              required={required}
              {...props}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default CaseForm;
