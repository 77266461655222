import React from 'react';
import { styled } from '@material-ui/core/styles';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

import LoadingModal from '../../common/LoadingModal';
import OrderForm from '../../forms/OrderForm_v2';

import BillToSection from './BillToSection';
import SyncButton from './SyncButton';
import useEditOrderDialog from './useEditOrderDialog';

const ReadOnlyTextField = ({ label, value = '' }) => {
  return (
    <TextField
      value={value}
      label={label}
      fullWidth
      variant='outlined'
      size='small'
      InputLabelProps={{ shrink: true }}
      InputProps={{ readOnly: true }}
    />
  );
};

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  flexWrap: 'wrap',
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'space-between',
  },
}));

const EditOrderDialog = ({
  isLoading,
  title,
  currentOrder,
  handleClose,
  open,
  updateOrder,
  enabledCheckboxs,
  tableType,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const {
    initialValues,
    billTo,
    originator,
    isLoadingCSV,
    isAdmin,
    cases,
    units,
    pallets,
    users,
    onFormSubmit,
    onBillToChange,
    onLoadUnitsFromCSV,
    onLoadCasesFromCSV,
  } = useEditOrderDialog({
    currentOrder,
    open,
    updateOrder,
  });

  /**
   * @param {import('react-final-form').FormRenderProps} param0
   */
  const renderForm = ({ handleSubmit, form }) => {
    const { dirty, submitting } = form.getState();
    const disabledSubmit = submitting || !dirty;
    return (
      <>
        <DialogContent>
          <Grid container spacing={2} component='form' onSubmit={handleSubmit}>
            <Grid item xs={12} sm={6}>
              <ReadOnlyTextField value={originator?.name} label='Originator' />
            </Grid>
            <Grid item xs={12} sm={6}>
              <BillToSection
                isAdmin={isAdmin}
                onChange={onBillToChange(form)}
                options={users}
                value={billTo}
              />
            </Grid>
            <Grid item xs={12}>
              <OrderForm
                units={units}
                cases={cases}
                pallets={pallets}
                tableType={tableType}
                enabledCheckboxs={enabledCheckboxs}
                isAdmin={isAdmin}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <StyledDialogActions>
          <div>
            <SyncButton onClick={onLoadUnitsFromCSV(form)}>
              LOAD Units FROM CSV FILE
            </SyncButton>
            <SyncButton onClick={onLoadCasesFromCSV(form)}>
              LOAD Cases FROM CSV FILE
            </SyncButton>
          </div>
          <div>
            <Button disabled={isLoading} onClick={handleClose} color='primary'>
              Cancel
            </Button>
            <Button
              color='primary'
              disabled={disabledSubmit}
              onClick={handleSubmit}
            >
              Update
            </Button>
          </div>
        </StyledDialogActions>
      </>
    );
  };
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      fullWidth
      maxWidth='md'
      onClose={handleClose}
      disableBackdropClick={true}
    >
      <DialogTitle align='center'>{`${title} Order #${currentOrder.order_no} Update`}</DialogTitle>
      {!!initialValues && (
        <Form
          initialValues={initialValues || {}}
          mutators={arrayMutators}
          onSubmit={onFormSubmit}
          render={renderForm}
          subscription={{ submitting: true, pristine: true }}
        />
      )}
      <LoadingModal
        isLoading={!!isLoadingCSV || isLoading}
        text={
          !!isLoadingCSV
            ? `Loading csv file ${isLoadingCSV?.file?.name} for ${isLoadingCSV?.type}...`
            : isLoading
            ? `Updating your ${title?.toLowerCase()} order...`
            : ''
        }
      />
    </Dialog>
  );
};

export default EditOrderDialog;
