import { createAsyncThunk } from '@reduxjs/toolkit';

import marketplacesAPIs from '../../api/marketplaces';

const errorHandler = (thunkAPI) => (error) => {
  return thunkAPI.rejectWithValue(error.response.data);
};

export const createMarketplace = createAsyncThunk(
  'marketplaces/createMarketplace',
  /**
   * @param {Object} data
   * @param {string} data.api_key
   * @param {string} data.api_pass
   * @param {string} data.api_secret
   * @param {string} data.shopName
   */
  async (data, thunkAPI) => {
    const response = await marketplacesAPIs
      .createMarketplace(data)
      .catch(errorHandler(thunkAPI));
    return response;
  },
);

export const deleteMarketplace = createAsyncThunk(
  'marketplaces/deleteMarketplace',
  /**
   * @param {String} id
   */
  async (id, thunkAPI) => {
    const response = await marketplacesAPIs
      .deleteMarketplace(id)
      .then(() => id)
      .catch(errorHandler(thunkAPI));
    return response;
  },
);

export const getMarketplaces = createAsyncThunk(
  'marketplaces/getMarketplaces',
  /**
   * @param {String=} userId
   */
  async (userId, thunkAPI) => {
    const response = await marketplacesAPIs
      .getMarketplaces(userId)
      .catch(errorHandler(thunkAPI));
    return response;
  },
);

export const getAllMarketplaces = createAsyncThunk(
  'marketplaces/getAllMarketplaces',
  async (userId, thunkAPI) => {
    const response = await marketplacesAPIs
      .getAllMarketplaces()
      .catch(errorHandler(thunkAPI));
    return response;
  },
);

export const updateMarketplace = createAsyncThunk(
  'marketplaces/updateMarketplace',
  /**
   * @param {Object} param
   * @param {String} param.id
   * @param {Object} param.data
   * @param {String=} param.data.api_key
   * @param {String=} param.data.api_pass
   * @param {String=} param.data.api_secret
   * @param {String=} param.data.shopName
   */
  async ({ id, data = {} } = {}, thunkAPI) => {
    const response = await marketplacesAPIs
      .updateMarketplace(id, data)
      .catch(errorHandler(thunkAPI));
    return response;
  },
);

export default {};
