import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import PeopleOutlineSharpIcon from '@material-ui/icons/PeopleOutlineSharp';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ViewListIcon from '@material-ui/icons/ViewList';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
  root: { width: '90%' },
});

const UserDashQuickInfo = ({ label, value }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Box display='flex' flexDirection='row' alignItems='center'>
              <Typography variant='h6'>{label}</Typography>
              {label === 'Total Users' ? (
                <PeopleOutlineSharpIcon style={{ fontSize: '25px' }} />
              ) : label === 'New Orders' ? (
                <ViewListIcon style={{ fontSize: '25px' }} />
              ) : (
                <MonetizationOnIcon style={{ fontSize: '25px' }} />
              )}
            </Box>
            <Typography variant='body1'>{value}</Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default UserDashQuickInfo;
