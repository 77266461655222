import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getMarketplaces,
  marketplacesSelectors,
} from '../../../redux/marketplaces';

import { getUsers } from '../../../redux/users/usersSlice';

const selector = (state) => {
  const marketplaces = marketplacesSelectors.selectAll(state);

  return {
    auth: state.auth,
    marketplaces: state.marketplaces,
    hasShopify: marketplaces.find((m) => m.marketplace === 'Shopify'),
  };
};
const useShopifyOrders = () => {
  const { auth, marketplaces, hasShopify } = useSelector(selector);
  const dispatch = useDispatch();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized && auth.initialized) {
      if (!auth.isAdmin) dispatch(getMarketplaces());
      else dispatch(getUsers());
      setInitialized(true);
    }
  }, [initialized, auth.initialized, auth.isAdmin, dispatch]);

  return {
    isLoading: !initialized || marketplaces.isLoading,
    isAdmin: auth.isAdmin,
    hasShopify,
  };
};

export default useShopifyOrders;
