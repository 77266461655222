import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import WelcomeHeroLayout from './WelcomeHeroLayout';
import { image_one } from '../../../../../assets/landing';

const styles = (theme) => ({
  background: {
    backgroundImage: `url(${image_one})`,
    backgroundColor: '#fff', // Average color of the background image.
    backgroundPosition: 'center',
  },
  heroText: {
    width: '75%',
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(10),
    },
  },
});

function ProductHero(props) {
  const { classes } = props;

  return (
    <WelcomeHeroLayout backgroundClassName={classes.background}>
      <Typography
        color='inherit'
        align='center'
        variant='h5'
        className={classes.heroText}
      >
        Fillstorship combines our experience in manufacturing and warehousing
        with ecommerce order processing so your products and kits can be
        fulfilled during the process. The results are faster turn time to
        customer, reduced warehousing cost, reduced shrinkage loss, and less
        handling charges.
      </Typography>
    </WelcomeHeroLayout>
  );
}

ProductHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHero);
