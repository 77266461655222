import { useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useNotification } from '../components/layout/Notifier';

export const useErrorHandler = (errorStateSelector) => {
  const error = useSelector(errorStateSelector, shallowEqual);
  const showNotification = useNotification();
  useEffect(() => {
    if (error) {
      showNotification(error.toString(), 'error');
    }
  }, [error, showNotification]);
};

export default useErrorHandler;
