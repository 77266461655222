import React from 'react';
import { useTheme, makeStyles, createStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import LinearProgress from '@material-ui/core/LinearProgress';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import green from '@material-ui/core/colors/green';

import BarcodeReader from '../../common/BarcodeReader';
import WebcamCapturer from '../../common/WebcamCapturer';
import WebcamDraggableContainer from '../../layout/WebcamDraggableContainer';

import useOrderDialog from './useOrderDialog';

const useStyles = makeStyles((theme) =>
  createStyles({
    dialogPaper: {
      overflow: 'unset',
    },
    pickedRow: {
      backgroundColor: green[500],
      color: 'white',
    },
    pickedCell: {
      backgroundColor: green[500],
      color: 'white',
    },
    webcam: {
      width: '100%',
    },
  }),
);

const OrderDialog = ({
  disabledWebcam = false,
  isLoading,
  onClose,
  onSubmitFulfill,
  onSubmitPicked,
  order,
  products = [],
  productName,
  userId,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const {
    status,
    pickedMapper = {},
    allPicked,
    dialogState,
    onScan,
    onProductClick,
    onScanError,
    onCapture,
  } = useOrderDialog({
    order,
    products,
    productName,
    userId,
  });

  const renderTableRow = ({ id, sku, description, quantity, picked }) => {
    const cellStyle = picked === quantity ? classes.pickedCell : undefined;
    return (
      <TableRow key={id} onClick={() => onProductClick(id)}>
        <TableCell className={cellStyle}>{sku}</TableCell>
        <TableCell className={cellStyle}>{description}</TableCell>
        <TableCell className={cellStyle} align='right'>
          {quantity}
        </TableCell>
        <TableCell className={cellStyle} align='right'>
          {picked}
        </TableCell>
      </TableRow>
    );
  };

  const renderPickList = () => {
    return (
      <>
        {!disabledWebcam && (
          <WebcamDraggableContainer>
            <WebcamCapturer className={classes.webcam} onCapture={onCapture} />
          </WebcamDraggableContainer>
        )}
        <BarcodeReader onError={onScanError} onScan={onScan} />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>SKU</TableCell>
              <TableCell>Description</TableCell>
              <TableCell align='right'>Quantity</TableCell>
              <TableCell align='right'>Picked</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(pickedMapper).map(renderTableRow)}
          </TableBody>
        </Table>
      </>
    );
  };

  return (
    !!status && (
      <Dialog
        classes={{ paper: classes.dialogPaper }}
        fullScreen={fullScreen}
        open={!!status}
      >
        {!dialogState ? (
          <>
            <DialogTitle>Unsupported Status</DialogTitle>
            <DialogContent>
              <DialogContentText>Unsupported Status</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color='primary' onClick={onClose}>
                Close
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogTitle>{dialogState.title}</DialogTitle>
            <DialogContent>
              <DialogContentText>{dialogState.content}</DialogContentText>
              {dialogState.pickable && renderPickList()}
            </DialogContent>
            <DialogActions>
              <Button color='primary' disabled={isLoading} onClick={onClose}>
                {dialogState.closeLabel || 'Close'}
              </Button>
              {dialogState.submitable && (
                <Button
                  color='primary'
                  disabled={isLoading}
                  onClick={onSubmitFulfill}
                >
                  Submit Fulfillment
                </Button>
              )}
              {dialogState.pickable && (
                <Button
                  color='primary'
                  disabled={!allPicked || isLoading}
                  onClick={onSubmitPicked}
                >
                  Submit Pick
                </Button>
              )}
            </DialogActions>
            {isLoading && (
              <LinearProgress aria-describedby='Loading' aria-busy />
            )}
          </>
        )}
      </Dialog>
    )
  );
};

export default OrderDialog;
