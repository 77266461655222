import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import unitActions from './unitsActions';

const initialState = {
  isLoading: false,
  error: '',

  isUpdating: false,
  updateError: '',
};

export const createUnit = createAsyncThunk(
  'units/createUnit',
  async (unit, thunkAPI) => {
    return unitActions
      .createUnit(unit)
      .then((r) => {
        return r.data;
      })
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

export const getUnits = createAsyncThunk(
  'units/getUnits',
  (params = {}, thunkAPI) => {
    const { id, ...rest } = params;
    if (id) {
      return unitActions.getUnitsByUserId(id, rest);
    }
    return unitActions.getUnits(rest);
  },
);

export const updateUnit = createAsyncThunk(
  'units/updateUnit',
  async (unit, thunkAPI) => {
    return unitActions
      .updateUnit(unit)
      .then((r) => r.data)
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

export const deleteUnit = createAsyncThunk(
  'units/deleteUnit',
  (id, thunkAPI) => {
    return unitActions
      .deleteUnit(id)
      .then((r) => r.data)
      .catch((err) => thunkAPI.rejectWithValue(err.response.data));
  },
);

export const breakCase = createAsyncThunk('units/breakCase', (id, thunkAPI) => {
  return unitActions
    .breakCase(id)
    .then((r) => r.data)
    .catch((err) => thunkAPI.rejectWithValue(err.response.data));
});

const unitsAdapter = createEntityAdapter({
  selectId: (entity) => entity._id,
});

const updatePending = (state, action) => {
  state.isUpdating = true;
  state.updateError = '';
};

const updateError = (state, action) => {
  state.isUpdating = false;
  state.updateError = action.payload.error;
};

const slice = createSlice({
  name: 'units',
  initialState: unitsAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: {
    [createUnit.pending]: updatePending,
    [createUnit.rejected]: updateError,
    [createUnit.fulfilled]: (state, action) => {
      state.isUpdating = false;
      state.ids = [action.payload._id, ...state.ids];
      state.entities[action.payload._id] = action.payload;
    },

    [getUnits.pending]: (state, action) => {
      state.isLoading = true;
      state.error = '';
    },
    [getUnits.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    [getUnits.fulfilled]: (state, action) => {
      state.isLoading = false;
      const { data } = action.payload;
      state.units = action.payload;
      unitsAdapter.removeAll(state);
      unitsAdapter.upsertMany(state, data);
    },
    [updateUnit.pending]: updatePending,
    [updateUnit.rejected]: updateError,
    [updateUnit.fulfilled]: (state, action) => {
      state.isUpdating = false;
      const { _id, ...changes } = action.payload;
      unitsAdapter.updateOne(state, { id: _id, changes });
    },
    [deleteUnit.pending]: updatePending,
    [deleteUnit.rejected]: updateError,
    [deleteUnit.fulfilled]: (state, action) => {
      state.isUpdating = false;
      unitsAdapter.removeOne(state, action.payload.id);
    },
    [breakCase.pending]: updatePending,
    [breakCase.rejected]: updateError,
    [breakCase.fulfilled]: (state, action) => {
      state.isUpdating = false;
      const { _id, ...changes } = action.payload;
      unitsAdapter.updateOne(state, { id: _id, changes });
    },
  },
});

export const {
  selectById: selectUnitById,
  selectIds: selectUnitIds,
  selectEntities: selectUnitEntities,
  selectAll: selectAllUnits,
  selectTotal: selectTotalUnits,
} = unitsAdapter.getSelectors((state) => state.units);

export const createUnitsSelectors = () =>
  unitsAdapter.getSelectors((state) => state.units);

export default slice.reducer;
