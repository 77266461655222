import React from 'react';
import Box from '@material-ui/core/Box';
import MaterialTable, { MTableActions } from 'material-table';
import { FaRegStickyNote, FaBoxes } from 'react-icons/fa';
import { MdAddBox, MdEdit } from 'react-icons/md';
import PalletDialog from '../../dialogs/PalletDialog';
import DetailPanelsController from './DetailPanelsController';
import List from './List';
import Notes from './Notes';
import usePalletTable from './usePalletTable';

const casesColumns = [
  { title: 'SKU', field: 'case.sku' },
  { title: 'Barcode', field: 'case.barcode' },
  { title: 'Description', field: 'case.description' },
  { title: 'Quantity', field: 'quantity', align: 'right' },
];

const PalletTable = ({ onGetPallets, reloadAll, userId, isAdmin }) => {
  const {
    tableRef,
    isLoading,
    isUpdating,
    pallets,
    currentPallet,
    opened,
    onRowDelete,
    onClickAdd,
    onClickUpdate,
    onDialogClose,
    onDialogSubmit,
    onExportCsv,
  } = usePalletTable({
    onGetPallets,
    reloadAll,
    userId,
  });

  /** @type {Action[]} */
  const actions = [
    {
      icon: MdAddBox,
      isFreeAction: true,
      tooltip: 'Create Pallet',
      onClick: onClickAdd,
    },
    {
      icon: MdEdit,
      tooltip: 'Update',
      onClick: onClickUpdate,
      hidden: !isAdmin,
    },
  ];

  /** @type {Column[]} */
  const columns = [
    { title: 'SKU', field: 'sku' },
    { title: 'Total Cases from Pallets', field: 'totalCaseQtyOnPallet' },
    {
      title: '# of Cases per Pallet',
      field: 'cases',
      render: (rowData) =>
        rowData.cases.reduce((sum, { quantity }) => sum + quantity, 0),
    },
    {
      title: '# of Pallets',
      field: 'numOfPallets',
    },
    { title: 'SO #', field: 'soNum' },
    { title: 'Lot #', field: 'lotNum' },
    {
      title: 'Created',
      field: 'createdAt',
      type: 'datetime',
    },
  ];

  /** @type {DetailPanel[]} */
  const detailPanels = [
    {
      icon: FaBoxes,
      tooltip: 'Cases',
      render: (rowData) => (
        <List title='Cases' data={rowData.cases} columns={casesColumns} />
      ),
    },
    {
      icon: FaRegStickyNote,
      tooltip: 'Notes',
      render: (rowData) => <Notes notes={rowData.notes} />,
    },
  ];

  /** @type {Options} */
  const options = {
    rowStyle: (data, index) => {
      if (index % 2) return { backgroundColor: '#f7f7f7' };
    },
    exportButton: true,
    exportCsv: onExportCsv,
    pageSize: 10,
    pageSizeOptions: [5, 10, 20, 30, 50, 75, 100, 200, 500],
  };

  const renderActions = (props) => {
    return !props.data || !tableRef.current || props.disabled === undefined ? (
      <MTableActions {...props} />
    ) : (
      <Box display='flex' borderRight='1px solid rgba(224, 224, 224, 1)'>
        <DetailPanelsController
          detailPanels={detailPanels}
          disabled={props.disabled}
          onToggleDetailPanel={tableRef.current.onToggleDetailPanel}
          tableDataId={props.data.tableData.id}
        />
        <MTableActions {...props} />
      </Box>
    );
  };

  return (
    <>
      <MaterialTable
        tableRef={tableRef}
        actions={actions}
        columns={columns}
        components={{ Actions: renderActions }}
        data={pallets}
        editable={isAdmin ? { onRowDelete } : null}
        isLoading={isLoading || isUpdating}
        options={options}
        title='Pallets'
        localization={{ header: { actions: '' } }}
      />
      <PalletDialog
        isLoading={isLoading || isUpdating}
        opened={opened}
        onClose={onDialogClose}
        onSubmit={onDialogSubmit}
        pallet={currentPallet}
        userId={userId}
      />
    </>
  );
};

export default PalletTable;
