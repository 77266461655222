import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';

import { Field } from 'react-final-form';

import Container from '../Container';

import {
  CompletedChip,
  PendingChip,
  ProcessingChip,
  FailedChip,
} from './chips';
import Title from './Title';

import useAttachDocuments from './useAttachDocuments';

import { getFileNameFromURL } from './utils';

/**
 * @param {import('react-final-form-arrays').FieldArrayRenderProps & { disabled?:Boolean, readOnly?:Boolean }} param
 */
const AttachDocuments = ({ fields, disabled = false, readOnly = false }) => {
  const {
    failed,
    pending,
    processing,
    handleLoadFile,
    handleDownload,
    handleRemove,
    handleRemovePending,
    handleRemoveFailed,
  } = useAttachDocuments({
    fields,
  });

  return (
    <Container
      title={
        <Title
          disabled={disabled}
          readOnly={readOnly}
          onLoadFile={handleLoadFile}
        />
      }
    >
      <Grid container spacing={1}>
        {/* Completed */}
        {fields.map((name, index) => (
          <Grid key={name} item>
            <Field
              name={name}
              render={({ input }) => (
                <CompletedChip
                  clickable
                  disabled={disabled}
                  label={getFileNameFromURL(input.value)}
                  onClick={handleDownload(input.value)}
                  onDelete={readOnly ? undefined : handleRemove(index)}
                />
              )}
            />
          </Grid>
        ))}
        {/* Processing */}
        {processing && (
          <Grid item>
            <ProcessingChip
              color='primary'
              icon={<CircularProgress color='primary' size={16} />}
              label={processing.name}
            />
          </Grid>
        )}
        {/* Pending */}
        {pending.map((file, index) => (
          <Grid key={file.name + index} item>
            <PendingChip
              label={file.name}
              disabled={disabled}
              onDelete={handleRemovePending(index)}
            />
          </Grid>
        ))}
        {/* Failed */}
        {failed.map(({ file, error }, index) => (
          <Grid key={file.name + index} item>
            <Tooltip title={error?.message || ''}>
              <FailedChip
                label={file.name}
                disabled={disabled}
                onDelete={handleRemoveFailed(index)}
              />
            </Tooltip>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default AttachDocuments;
