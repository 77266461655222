import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import {
  createTask,
  deleteTask,
  tasksSelectors,
} from '../../../redux/tasks/tasksSlice';
import { usersSelectors } from '../../../redux/users/usersSlice';

const userSelector = createSelector(usersSelectors().selectAll, (rows) =>
  rows.map((row) => ({ ...row, tableData: {} })),
);
const taskSelector = createSelector(tasksSelectors().selectAll, (rows) =>
  rows.map((row) => ({ ...row, tableData: {} })),
);

const selector = (state) => {
  return {
    isLoading: state.tasks.isLoading,
    isUpdating: state.tasks.isUpdating,
  };
};

const useTaskTable = () => {
  const dispatch = useDispatch();
  const users = useSelector(userSelector);
  const tasks = useSelector(taskSelector);
  const { isLoading, isUpdating } = useSelector(selector);

  const onRowAdd = useCallback(
    async (data) => {
      await dispatch(createTask(data));
    },
    [dispatch],
  );

  const onRowDelete = useCallback(
    async (data) => {
      await dispatch(deleteTask(data._id));
    },
    [dispatch],
  );

  return { data: tasks, users, onRowAdd, onRowDelete, isLoading, isUpdating };
};

export default useTaskTable;
