import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import { selectAllShipments } from '../../../redux/shipments/shipmentsSlice';

const ShipmentSelection = ({
  label,
  onChange,
  autocompleteProps = {},
  ...props
}) => {
  const shipment = useSelector(selectAllShipments);
  const [value, setValue] = useState(null);

  useEffect(() => {
    setValue(null);
  }, [shipment]);

  const onAutoChange = (event, newValue) => {
    setValue(value);
    onChange(newValue);
  };

  return (
    <Autocomplete
      autoHighlight
      disableClearable
      getOptionLabel={(option) =>
        option ? `${option.order_no} - ${option?.billTo?.company}` : ''
      }
      id='shipment-selection-auto-complete-field'
      onChange={onAutoChange}
      options={
        shipment.sort(
          (a, b) => parseFloat(b.order_no) - parseFloat(a.order_no),
        ) || []
      }
      value={value}
      {...autocompleteProps}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant='outlined'
          InputLabelProps={{ ...params.InputLabelProps, shrink: true }}
          placeholder='order #'
          {...props}
        />
      )}
    />
  );
};

export default ShipmentSelection;
