import React from 'react';

import { useSelector } from 'react-redux';

const Admin = ({ children }) => {
  const isAdmin = useSelector((state) => state.auth.isAdmin);

  if (!isAdmin) return null;

  return <>{children}</>;
};

export default Admin;
