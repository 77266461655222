import { createSlice } from '@reduxjs/toolkit';

const materialDashSlice = createSlice({
  name: 'materialDash',
  initialState: {
    drawerOpen: true,
    createOpen: false,
    ordersOpen: false,
    fulfillmentOpen: false,
    search: null,
  },
  reducers: {
    setDrawerOpen: (state, action) => {
      state.drawerOpen = action.payload;
    },
    setNestedCreateOpen: (state, action) => {
      state.createOpen = action.payload;
    },
    setNestedOrdersOpen: (state, action) => {
      state.ordersOpen = action.payload;
    },
    setNestedFulfillmentOpen: (state, action) => {
      state.fulfillmentOpen = action.payload;
    },
    setFilter: (state, action) => {
      state.search = action.payload;
    },
  },
});

const {
  setDrawerOpen,
  setNestedCreateOpen,
  setNestedOrdersOpen,
  setNestedFulfillmentOpen,
  setFilter,
} = materialDashSlice.actions;

export {
  setDrawerOpen,
  setNestedCreateOpen,
  setNestedOrdersOpen,
  setNestedFulfillmentOpen,
  setFilter,
};
export default materialDashSlice.reducer;
