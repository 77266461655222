import React from 'react';
import Grid from '@material-ui/core/Grid';
import { renderField } from '../fields';
import { getFieldList } from './utils';

const ShippingInformation = ({ input, disabled, readOnly = false }) => {
  const type = input.name;
  const fieldList = getFieldList(type);

  return (
    <Grid container spacing={2} justify='center' alignItems='center'>
      {fieldList.map(
        ({ name, label, gridProps = {}, ...fieldProps }, index) => (
          <Grid key={name + label + index} item {...gridProps}>
            {renderField({
              ...fieldProps,
              name: `${type}.${name}`,
              label,
              disabled,
              readOnly,
            })}
          </Grid>
        ),
      )}
    </Grid>
  );
};

export default ShippingInformation;
